class AudioCommandExecutor {
  constructor(audioEngine) {
    this.audioEngine = audioEngine;
  }

  execute(audioCommands, handleSourceEnded = () => {}) {
    const startTime = this.audioEngine.getCurrentTime();
    audioCommands.forEach((c) => {
      switch (c.command) {
        case "scheduleNote":
          this.audioEngine.scheduleNote(
            c.instrument,
            c.tempo,
            c.startTime + startTime,
            c.note,
            c.duration,
            handleSourceEnded
          );
          break;
        case "scheduleDrum":
          this.audioEngine.scheduleDrum(
            c.drum,
            c.tempo,
            c.startTime + startTime,
            c.duration,
            handleSourceEnded
          );
          break;
        default:
          throw new Error(`Unexpected audio command: ${c}`);
      }
    });
  }
}

export default AudioCommandExecutor;
