/* eslint react/prop-types: 0 */
import {
  Face,
  Head,
  Headgear,
  Body,
  ShoeStyle1Left,
  ShoeStyle1Right,
  ShoeStyle2Left,
  ShoeStyle2Right,
  ShoeStyle3Left,
  ShoeStyle3Right,
  GloveStyle1Left,
  GloveStyle1Right,
  GloveStyle2Left,
  GloveStyle2Right,
  GloveStyle3Left,
  GloveStyle3Right,
} from "assets/robot.svg";

const RobotRig = (props) => {
  const faceColour = props.faceColour || "#FFFFFF";
  const headColour = props.headColour || "#FFFFFF";
  const headgearColour = props.headgearColour || "#FFFFFF";
  const bodyColour = props.bodyColour || "#FFFFFF";
  const shoeColour = props.shoeColour || "#FFFFFF";
  const gloveColour = props.gloveColour || "#FFFFFF";

  const faceStyle = props.faceStyle || 0;
  const headStyle = props.headStyle || 0;
  const headgearStyle = props.headgearStyle || 0;
  const bodyStyle = props.bodyStyle || 0;
  const shoeStyle = props.shoeStyle || 0;
  const gloveStyle = props.gloveStyle || 0;

  const containerWidth = 525;
  const containerHeight = 725;

  const shoeWidth = 140;
  const shoeHeight = 152;
  const shoeYOffset = 730;

  const gloveWidth = 125;
  const gloveHeight = 195;

  const ShoeLeft = [ShoeStyle1Left, ShoeStyle2Left, ShoeStyle3Left][shoeStyle];
  const ShoeRight = [ShoeStyle1Right, ShoeStyle2Right, ShoeStyle3Right][
    shoeStyle
  ];

  const GloveLeft = [GloveStyle1Left, GloveStyle2Left, GloveStyle3Left][
    gloveStyle
  ];
  const GloveRight = [GloveStyle1Right, GloveStyle2Right, GloveStyle3Right][
    gloveStyle
  ];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="-200 0 900 920"
      className={props.className}
    >
      <g id="Avatar">
        <g id="root">
          <g
            id="body"
            stroke="black"
            strokeWidth="4"
            vectorEffect="non-scaling-stroke"
            transform={
              "rotate(" +
              props.pose.body_rotation +
              " 269.5 538.5) " +
              "translate(" +
              props.pose.body_x_translation +
              " " +
              props.pose.body_y_translation +
              ")"
            }
          >
            <g
              id="leg_r"
              transform={
                "rotate(" + props.pose.leg_r_rotation + " 198.5 657.5" + ")"
              }
            >
              <path
                id="Vector 15"
                d="M231.534 670.655C231.791 652.312 217.046 637.278 198.702 637.178C180.579 637.079 165.764 651.609 165.511 669.73L164.466 744.345C164.209 762.688 178.954 777.722 197.298 777.822C215.421 777.921 230.236 763.391 230.489 745.27L231.534 670.655Z"
                fill="black"
              />
              <g
                id="lower_leg_r"
                transform={
                  "rotate(" +
                  props.pose.lower_leg_r_rotation +
                  " 198.5 746.5" +
                  ")"
                }
              >
                <ShoeRight
                  shoeColour={shoeColour}
                  x={100}
                  y={shoeYOffset}
                  width={shoeWidth}
                  height={shoeHeight}
                  viewBox={`-2 0 ${shoeWidth} ${shoeHeight}`}
                />
                <ellipse
                  visibility="hidden"
                  id="lower_leg_r_pivot"
                  cx="198.5"
                  cy="746.5"
                  rx="19.5"
                  ry="20.5"
                  fill="#C4C4C4"
                />
              </g>
              <ellipse
                visibility="hidden"
                id="leg_r_pivot"
                cx="198.5"
                cy="657.5"
                rx="19.5"
                ry="20.5"
                fill="#C4C4C4"
              />
            </g>
            <g
              id="leg_l"
              transform={
                "rotate(" + props.pose.leg_l_rotation + " 339.5 666.5" + ")"
              }
            >
              <path
                id="Vector 19"
                d="M370 670C370 651.775 355.225 637 337 637C318.775 637 304 651.775 304 670V745C304 763.225 318.775 778 337 778C355.225 778 370 763.225 370 745V670Z"
                fill="black"
              />

              <g
                id="lower_leg_l"
                transform={
                  "rotate(" +
                  props.pose.lower_leg_l_rotation +
                  " 339.5 744.5" +
                  ")"
                }
              >
                <ShoeLeft
                  shoeColour={shoeColour}
                  x={295}
                  y={shoeYOffset}
                  width={shoeWidth}
                  height={shoeHeight}
                  viewBox={`186 0 ${shoeWidth} ${shoeHeight}`}
                />
                <ellipse
                  visibility="hidden"
                  id="lower_leg_l_pivot"
                  cx="339.5"
                  cy="744.5"
                  rx="19.5"
                  ry="20.5"
                  fill="#C4C4C4"
                />
              </g>
              <ellipse
                visibility="hidden"
                id="leg_l_pivot"
                cx="339.5"
                cy="666.5"
                rx="19.5"
                ry="20.5"
                fill="#C4C4C4"
              />
            </g>
            <g
              id="torso"
              transform={
                "rotate(" + props.pose.torso_rotation + " 269.5 538.5" + ")"
              }
            >
              <ellipse
                visibility="hidden"
                id="torso_pivot"
                cx="269.5"
                cy="538.5"
                rx="19.5"
                ry="20.5"
                fill="#C4C4C4"
              />
              <Body
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                bodyColour={bodyColour}
                bodyStyle={bodyStyle}
              />
            </g>
            <g
              id="arm_l"
              transform={
                "rotate(" + props.pose.arm_l_rotation + " 405.5 472.5" + ")"
              }
            >
              <path
                id="Vector_8"
                d="M461.695 570.063L432.468 491.738C428.606 481.389 417.087 476.13 406.738 479.992C396.389 483.854 391.13 495.373 394.992 505.722L424.219 584.047C428.08 594.396 439.6 599.654 449.949 595.793C460.297 591.931 465.556 580.412 461.695 570.063Z"
                fill="black"
              />
              <g
                id="lower_arm_l"
                transform={
                  "rotate(" +
                  props.pose.lower_arm_l_rotation +
                  " 439.5 572.5" +
                  ")"
                }
              >
                <GloveLeft
                  gloveColour={gloveColour}
                  x={400}
                  y={570}
                  width={gloveWidth}
                  height={gloveHeight}
                  viewBox={`393 78 ${gloveWidth} ${gloveHeight}`}
                />
                <ellipse
                  visibility="hidden"
                  id="lower_arm_l_pivot"
                  cx="439.5"
                  cy="572.5"
                  rx="19.5"
                  ry="20.5"
                  fill="#C4C4C4"
                />
              </g>
              <ellipse
                visibility="hidden"
                id="arm_l_pivot"
                cx="405.5"
                cy="472.5"
                rx="19.5"
                ry="20.5"
                fill="#C4C4C4"
              />
            </g>
            <g
              id="arm_r"
              transform={
                "rotate(" + props.pose.arm_r_rotation + " 133.5 478.5" + ")"
              }
            >
              <path
                id="Vector"
                d="M99.4683 507.52L70.2418 585.845C66.3802 596.194 71.639 607.714 81.9878 611.575C92.3365 615.437 103.856 610.178 107.718 599.829L136.944 521.504C140.806 511.156 135.547 499.636 125.198 495.774C114.85 491.913 103.33 497.172 99.4683 507.52Z"
                fill="black"
              />
              <g
                id="lower_arm_r"
                transform={
                  "rotate(" +
                  props.pose.lower_arm_r_rotation +
                  " 96.5 572.5" +
                  ")"
                }
              >
                <GloveRight
                  gloveColour={gloveColour}
                  x={0}
                  y={580}
                  width={gloveWidth}
                  height={gloveHeight}
                  viewBox={`-2 78 ${gloveWidth} ${gloveHeight}`}
                />
                <ellipse
                  visibility="hidden"
                  id="lower_arm_r_pivot"
                  cx="96.5"
                  cy="572.5"
                  rx="19.5"
                  ry="20.5"
                  fill="#C4C4C4"
                />
              </g>
              <ellipse
                visibility="hidden"
                id="arm_r_pivot"
                cx="133.5"
                cy="468.5"
                rx="19.5"
                ry="20.5"
                fill="#C4C4C4"
              />
            </g>
            <g
              id="head-gear-and-face"
              transform={
                "rotate(" + props.pose.head_rotation + " 266.5 437.5" + ")"
              }
            >
              <Headgear
                containerWidth={containerWidth}
                headgearColour={headgearColour}
                headgearStyle={headgearStyle}
                containerHeight={containerHeight}
              />
              <Head
                containerWidth={containerWidth}
                headColour={headColour}
                headStyle={headStyle}
              />
              <Face
                containerWidth={containerWidth}
                faceColour={faceColour}
                faceStyle={faceStyle}
              />

              <ellipse
                visibility="hidden"
                id="head_pivot"
                cx="266.5"
                cy="437.5"
                rx="19.5"
                ry="20.5"
                fill="#C4C4C4"
              />
            </g>
            <ellipse
              visibility="hidden"
              id="body_pivot"
              cx="269.5"
              cy="538.5"
              rx="19.5"
              ry="20.5"
              fill="#C4C4C4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RobotRig;
