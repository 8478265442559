import Button from "button";
import IconButton from "icon_button";
import Arrow from "assets/arrow";
import styles from "./styles.module.scss";

const Heading = ({
  title,
  children,
  className,
  invertBackgroundColours = false,
  transparent = false,
  backPath,
  islandPage = false,
}) => {
  const headerClasses = classNames(styles.heading, className, {
    [styles.heading_inverted]: invertBackgroundColours,
    [styles.transparent]: transparent,
  });

  const iconButtonBreakpoint = 1280;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useResize(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const backButton =
    typeof backPath === "string" ? (
      <Button.RectangleTertiary
        className={styles.link_button}
        transparent={true}
        transparent_border={true}
        icon={<Arrow type="previous" />}
        href={backPath}
        text="Back"
      />
    ) : null;

  const backIcon =
    typeof backPath === "string" ? (
      <IconButton
        icon={<Arrow type="previous" />}
        aria-label="Back"
        href={backPath}
      />
    ) : null;

  const iconOrButton = () =>
    windowWidth < iconButtonBreakpoint ? backIcon : backButton;

  return (
    <header className={headerClasses}>
      <div className={styles.back}>
        {backPath && islandPage && (
          <Button.RectangleTertiary href={backPath} text="Back" />
        )}
        {backPath && !islandPage && iconOrButton()}
      </div>
      {title}
      <div className={styles.controls}>{children}</div>
    </header>
  );
};

Heading.propTypes = {
  title: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  invertBackgroundColours: PropTypes.bool,
  transparent: PropTypes.bool,
  backPath: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  islandPage: PropTypes.bool,
};

export default Heading;
