import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.PlayTogether = {
  message0: "play together",
  args1: [
    {
      type: "input_statement",
      name: "statements",
    },
  ],
  message1: "%1",
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.PlayTogether.init = function () {
  this.jsonInit(Blockly.Blocks.PlayTogether);
};

Blockly.JavaScript.PlayTogether = function (block) {
  const children = helpers.statementBlocks(block);
  const statements = children.map((c) => Blockly.JavaScript[c.type](c));
  return `
    visualisation.beginPlayTogether();
    ${
      statements.length > 0
        ? statements.join("\nvisualisation.rewind();\n")
        : ""
    }
    visualisation.endPlayTogether();
  `;
};
