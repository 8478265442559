const MapBackground = ({ background = "#2253E7", accent = "#0B6FFB" }) => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="400" height="400" fill={background} />
      <g opacity="0.5">
        <rect x="203" y="185" width="41" height="15" rx="7.5" fill={accent} />
        <rect x="157" y="223" width="41" height="15" rx="7.5" fill={accent} />
        <rect x="182" y="161" width="41" height="15" rx="7.5" fill={accent} />
      </g>
    </svg>
  );
};

MapBackground.propTypes = {
  background: PropTypes.string,
  accent: PropTypes.string,
};

export default MapBackground;
