import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.TurnAntiClockwise = {
  message0: `turn left by %1 degrees`,
  args0: [
    {
      type: "input_value",
      name: "degrees",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.TurnAntiClockwise.init = function () {
  this.jsonInit(Blockly.Blocks.TurnAntiClockwise);
};

Blockly.JavaScript.TurnAntiClockwise = function (block) {
  const degrees = helpers.codeForArg("degrees", block);

  if (typeof degrees === "undefined") {
    return "";
  } else {
    return `visualisation.turnAntiClockwise(${degrees});`;
  }
};
