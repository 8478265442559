import BlocklyHelpers from "blockly_helpers";
import Art from "visualisations/art";
import Interpreter from "interpreter";

const FALLBACK_CODE = "";

const AvatarWithTshirt = ({ tshirtWorkspace, ...avatarProps }) => {
  const visualisationChannel = useMemo(() => {
    return { onReady: () => {} };
  }, []);

  useEffect(() => {
    if (!tshirtWorkspace) return;

    const [deprecated, code] = BlocklyHelpers.generateCode(tshirtWorkspace.xml);
    const blocksChannel = {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
    const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
    interpreter.run();
  }, [tshirtWorkspace, visualisationChannel]);

  return (
    <Art
      channel={visualisationChannel}
      fullscreen={false}
      outputType="AvatarOnly"
      seed={
        (tshirtWorkspace && tshirtWorkspace.seed) || Math.random().toString()
      }
      {...avatarProps}
    />
  );
};

AvatarWithTshirt.propTypes = {
  tshirtWorkspace: PropTypes.shape({
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
};

export default AvatarWithTshirt;
