import Blockly from "blockly";
import stamps from "visualisations/art/stamps";
import helpers from "blocks/helpers";

const options = Object.entries(stamps).map(([key, stamp]) => [stamp.name, key]);

Blockly.Blocks.Stamp = {
  message0: "stamp %1 of size %2",
  args0: [
    {
      type: "field_dropdown",
      name: "stamp",
      options,
    },
    {
      type: "input_value",
      name: "size",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.Stamp.init = function () {
  this.jsonInit(Blockly.Blocks.Stamp);
};

Blockly.JavaScript.Stamp = function (block) {
  const stamp = block.getFieldValue("stamp");
  const size = helpers.codeForArg("size", block);
  return `visualisation.stamp("${stamp}", ${size});`;
};
