import Button from "button";
import Link from "link";
import styles from "./styles.module.scss";

const ConfirmationPrompt = ({
  question,
  dismissText,
  onDismiss,
  confirmText,
  onConfirm,
  subText,
}) => {
  const [confirmEnabled, setConfirmEnabled] = useState(true);

  const handleConfirm = () => {
    setConfirmEnabled(false);
    onConfirm();
  };

  return (
    <div className={styles.confirmation_prompt}>
      <h2>{question}</h2>
      <p>{subText}</p>
      <Button.RectanglePrimary
        text={confirmText}
        onClick={handleConfirm}
        enabled={confirmEnabled}
      />

      {dismissText && (
        <Link className={styles.dismiss} onClick={onDismiss}>
          {dismissText}
        </Link>
      )}
    </div>
  );
};

ConfirmationPrompt.propTypes = {
  question: PropTypes.string,
  dismissText: PropTypes.string,
  onDismiss: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  subText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ConfirmationPrompt;
