import PropTypes from "prop-types";

const Arrow = ({ type }) => {
  switch (type) {
    case "first":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          title="Arrow first"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6585 5.75259C13.0741 5.38891 13.1163 4.75715 12.7526 4.34151C12.3889 3.92587 11.7571 3.88375 11.3415 4.24744L3.3415 11.2474C3.12448 11.4373 3 11.7117 3 12C3 12.2884 3.12448 12.5627 3.3415 12.7526L11.3415 19.7526C11.7571 20.1163 12.3889 20.0742 12.7526 19.6585C13.1163 19.2429 13.0741 18.6111 12.6585 18.2474L5.51859 12L12.6585 5.75259ZM20.6585 5.75259C21.0741 5.38891 21.1163 4.75715 20.7526 4.34151C20.3889 3.92587 19.7571 3.88375 19.3415 4.24744L11.3415 11.2474C11.1245 11.4373 11 11.7117 11 12C11 12.2884 11.1245 12.5627 11.3415 12.7526L19.3415 19.7526C19.7571 20.1163 20.3889 20.0742 20.7526 19.6585C21.1163 19.2429 21.0741 18.6111 20.6585 18.2474L13.5186 12L20.6585 5.75259Z"
            fill="black"
          />
        </svg>
      );
    case "last":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          title="Arrow last"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.34151 18.2474C2.92587 18.6111 2.88375 19.2429 3.24744 19.6585C3.61112 20.0742 4.24288 20.1163 4.65852 19.7526L12.6585 12.7526C12.8755 12.5627 13 12.2884 13 12C13 11.7117 12.8755 11.4373 12.6585 11.2474L4.65852 4.24744C4.24288 3.88376 3.61112 3.92587 3.24744 4.34151C2.88376 4.75715 2.92587 5.38891 3.34151 5.75259L10.4814 12L3.34151 18.2474ZM11.3415 18.2474C10.9259 18.6111 10.8838 19.2429 11.2474 19.6585C11.6111 20.0742 12.2429 20.1163 12.6585 19.7526L20.6585 12.7526C20.8755 12.5627 21 12.2884 21 12C21 11.7117 20.8755 11.4373 20.6585 11.2474L12.6585 4.24744C12.2429 3.88376 11.6111 3.92587 11.2474 4.34151C10.8838 4.75715 10.9259 5.38891 11.3415 5.75259L18.4814 12L11.3415 18.2474Z"
            fill="black"
          />
        </svg>
      );
    case "left":
    case "previous":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          title="Arrow previous"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7526 4.34151C17.1162 4.75715 17.0741 5.38891 16.6585 5.75259L9.51858 12L16.6585 18.2474C17.0741 18.6111 17.1162 19.2429 16.7526 19.6585C16.3889 20.0742 15.7571 20.1163 15.3415 19.7526L7.34148 12.7526C7.12447 12.5627 6.99999 12.2884 6.99999 12C6.99999 11.7117 7.12447 11.4373 7.34148 11.2474L15.3415 4.24744C15.7571 3.88375 16.3889 3.92587 16.7526 4.34151Z"
            fill="black"
          />
        </svg>
      );
    case "right":
    case "next":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          title="Arrow next"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.24744 19.6585C6.88375 19.2429 6.92587 18.6111 7.34151 18.2474L14.4814 12L7.34151 5.75256C6.92587 5.38888 6.88376 4.75712 7.24744 4.34148C7.61112 3.92585 8.24288 3.88373 8.65852 4.24741L16.6585 11.2474C16.8755 11.4373 17 11.7116 17 12C17 12.2883 16.8755 12.5627 16.6585 12.7526L8.65852 19.7526C8.24288 20.1162 7.61112 20.0741 7.24744 19.6585Z"
            fill="black"
          />
        </svg>
      );
    case "down":
      return (
        <svg
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          title="Arrow down"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.313814 0.313797C0.732233 -0.104622 1.41062 -0.104622 1.82904 0.313797L7.5 5.98475L13.171 0.313796C13.5894 -0.104622 14.2678 -0.104623 14.6862 0.313796C15.1046 0.732215 15.1046 1.41061 14.6862 1.82903L8.25761 8.2576C7.8392 8.67602 7.1608 8.67602 6.74238 8.2576L0.313814 1.82903C-0.104605 1.41061 -0.104605 0.732216 0.313814 0.313797Z"
            fill="white"
          />
        </svg>
      );
    case "up":
      return (
        <svg
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          title="Arrow up"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6862 8.25761C14.2678 8.67603 13.5894 8.67603 13.171 8.25761L7.5 2.58666L1.82904 8.25761C1.41063 8.67603 0.732234 8.67603 0.313815 8.25761C-0.104605 7.83919 -0.104605 7.1608 0.313815 6.74238L6.74239 0.313814C7.1608 -0.104605 7.8392 -0.104605 8.25762 0.313814L14.6862 6.74238C15.1046 7.1608 15.1046 7.8392 14.6862 8.25761Z"
            fill="white"
          />
        </svg>
      );
  }
};

Arrow.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Arrow;
