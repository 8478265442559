const passThroughClicks = { pointerEvents: "none" };

const MoreIsland = ({
  windowDimensions,
  scale = 1,
  className,
  onClick = () => {},
  style = {},
}) => (
  <svg
    width={windowDimensions.width * windowDimensions.zoom}
    height={windowDimensions.height * windowDimensions.zoom}
    viewBox={`0 0 ${550 * scale} ${651 * scale}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
    style={style}
  >
    <rect
      x="5"
      y="-5"
      width="210"
      height="110"
      rx="25"
      transform="matrix(-1 0 0 1 360 300.394)"
      stroke="#0B6FFB"
      strokeWidth="10"
    />
    <rect
      x="-1"
      y="1"
      width="198"
      height="98"
      rx="19"
      transform="matrix(-1 0 0 1 348 300.066)"
      fill="#8F613C"
      stroke="black"
      strokeWidth="2"
    />
    <rect
      x="-1"
      y="1"
      width="198"
      height="98"
      rx="19"
      transform="matrix(-1 0 0 1 348 250.066)"
      fill="#B377F5"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M330.002 399.016L331.002 349.016H329.002L330.002 399.016Z"
      fill="black"
    />
    <path
      d="M169.98 399.016L170.98 349.016H168.98L169.98 399.016Z"
      fill="black"
    />
    <circle
      r="20"
      transform="matrix(-1 0 0 1 199.832 300.066)"
      fill="#FFFAE8"
      id="node-start"
    />
    <circle
      r="20"
      transform="matrix(-1 0 0 1 299.98 300.066)"
      fill="#FFFAE8"
      id="node-learn-even-more"
    />
    <rect
      width="90.9128"
      height="20"
      transform="matrix(-1 0 0 1 290.745 290.066)"
      fill="#FFFAE8"
      {...passThroughClicks}
    />
    <circle
      r="9"
      transform="matrix(-1 0 0 1 299.942 299.366)"
      fill="#FFC700"
      stroke="black"
      strokeWidth="2"
      {...passThroughClicks}
    />
    <path
      d="M234 328.89V328.476L234.293 328.183L243.116 319.359L243.409 319.066H243.824H256.176H256.591L256.884 319.359L265.707 328.183L266 328.476V328.89V337.713V338.713H265H235H234V337.713V328.89Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="30"
      height="8.64706"
      transform="matrix(-1 0 0 1 265 329.066)"
      fill="#986F4D"
    />
    <path
      d="M188.209 268.239H218.209V257.581L207.391 248.828V238.239H197.8L188.209 247.063V268.239Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="28.188"
      height="9.31269"
      transform="matrix(-1 0 0 1 217.32 258.07)"
      fill="#986F4D"
    />
    <path
      d="M180.456 267.242V266.828L180.749 266.535L185.265 262.019L185.558 261.726H185.972H192.294H192.708L193.001 262.019L197.517 266.535L197.81 266.828V267.242V271.758V272.758H196.81H181.456H180.456V271.758V267.242Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="15.3536"
      height="4.42544"
      transform="matrix(-1 0 0 1 196.81 267.333)"
      fill="#986F4D"
    />
    <path
      d="M228.942 337.013V336.599L229.235 336.306L231.882 333.659L232.175 333.366H232.589H236.295H236.71L237.002 333.659L239.649 336.306L239.942 336.599V337.013V339.66V340.66H238.942H229.942H228.942V339.66V337.013Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="9"
      height="2.59412"
      transform="matrix(-1 0 0 1 238.942 337.066)"
      fill="#986F4D"
    />
    <rect
      x="254"
      y="249.227"
      width="28"
      height="8"
      transform="rotate(90 254 249.227)"
      fill="#986F4D"
      stroke="black"
      strokeWidth="2"
      id="node-signpost"
    />
    <rect
      x="236"
      y="248.227"
      width="28"
      height="18"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      id="node-signpost"
    />
    <rect
      x="241"
      y="252.229"
      width="18"
      height="2"
      fill="black"
      {...passThroughClicks}
    />
    <rect
      x="241"
      y="256.227"
      width="18"
      height="2"
      fill="black"
      {...passThroughClicks}
    />
    <rect
      x="241"
      y="260.225"
      width="18"
      height="2"
      fill="black"
      {...passThroughClicks}
    />
    <path
      d="M240.424 252.237L236.396 251.22V253.276L240.424 252.237Z"
      fill="black"
      {...passThroughClicks}
    />
    <path
      d="M259.377 262.209L263.404 263.227L263.404 261.171L259.377 262.209Z"
      fill="black"
      {...passThroughClicks}
    />
    <path
      d="M334.942 270.366V271.366H333.942H296.942H295.942V270.366V255.366V254.952L296.235 254.659L307.942 242.952V229.366V228.952L308.235 228.659L316.235 220.659L316.942 219.952L317.649 220.659L334.649 237.659L334.942 237.952V238.366V270.366Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M322.589 279.882V279.468L322.882 279.175L327.397 274.659L327.69 274.366H328.105H334.427H334.841L335.134 274.659L339.649 279.175L339.942 279.468V279.882V284.398V285.398H338.942H323.589H322.589V284.398V279.882Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="15.3536"
      height="4.42544"
      transform="matrix(-1 0 0 1 338.942 279.972)"
      fill="#986F4D"
    />
    <path
      d="M333.942 255.366H296.942V270.366H333.942V255.366Z"
      fill="#986F4D"
    />
    <path
      d="M289.589 268.882V268.468L289.882 268.175L294.397 263.659L294.69 263.366H295.105H301.427H301.841L302.134 263.659L306.649 268.175L306.942 268.468V268.882V273.398V274.398H305.942H290.589H289.589V273.398V268.882Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="15.3536"
      height="4.42544"
      transform="matrix(-1 0 0 1 305.942 268.972)"
      fill="#986F4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 20.3662C100 9.32052 108.954 0.366211 120 0.366211H180C191.046 0.366211 200 9.32052 200 20.3662V180.366C200 191.412 191.046 200.366 180 200.366H150H120H100V280.366C100 291.412 91.0457 300.366 80 300.366H20C8.9543 300.366 0 291.412 0 280.366V120.366C0 109.321 8.95431 100.366 20 100.366H50H80H100V20.3662Z"
      fill="black"
      fillOpacity="0.07"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M350 530.366C350 541.412 358.954 550.366 370 550.366H450V630.366C450 641.412 458.954 650.366 470 650.366H530C541.046 650.366 550 641.412 550 630.366V530.366V470.366C550 460.4 542.71 452.136 533.171 450.616C532.138 450.452 531.079 450.366 530 450.366L470 450.366H370C358.954 450.366 350 459.321 350 470.366L350 530.366Z"
      fill="black"
      fillOpacity="0.07"
    />
  </svg>
);

MoreIsland.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    zoom: PropTypes.number,
  }),
  scale: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default MoreIsland;
