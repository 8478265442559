import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.ChangeY = {
  message0: "change y by %1",
  args0: [
    {
      type: "input_value",
      name: "y",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.ChangeY.init = function () {
  this.jsonInit(Blockly.Blocks.ChangeY);
};

Blockly.JavaScript.ChangeY = function (block) {
  const y = helpers.codeForArg("y", block);

  if (typeof y === "undefined") {
    return "";
  } else {
    return `visualisation.changeY(${y});`;
  }
};
