import ShareIcon from "assets/share_icon.svg";
import Button from "button";

const SharingButton = ({ className, onClick, activityKey, workspace }) => {
  return (
    <Button.RectangleTertiary
      className={className}
      icon={<ShareIcon />}
      text="Share to gallery"
      onClick={onClick}
      href={`/gallery_submissions/${activityKey}/${workspace.id}/new`}
      textAlways={true}
    ></Button.RectangleTertiary>
  );
};

SharingButton.propTypes = {
  activityKey: PropTypes.string.isRequired,
  workspace: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SharingButton;
