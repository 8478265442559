import Blockly from "blockly/core";
import palette from "root/js_palette";

Blockly.Theme.defineTheme("RpfTheme", {
  base: Blockly.Themes.Classic,
  blockStyles: {
    rpf_start_blocks: {
      hat: "cap",
    },
  },
  componentStyles: {
    workspaceBackgroundColour: palette.primary.white,
    toolboxBackgroundColour: palette.primary.white,
  },
});
