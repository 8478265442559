import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.ChangePenSize = {
  message0: "change pen size by %1",
  args0: [
    {
      type: "input_value",
      name: "amount",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.ChangePenSize.init = function () {
  this.jsonInit(Blockly.Blocks.ChangePenSize);
};

Blockly.JavaScript.ChangePenSize = function (block) {
  const amount = helpers.codeForArg("amount", block);

  if (typeof amount === "undefined") {
    return "";
  } else {
    return `visualisation.changePenSize(${amount});`;
  }
};
