const poses = {
  default: {
    name: "arms out",
    arm_l_rotation: 288,
    arm_r_rotation: 72,
    leg_l_rotation: 0,
    leg_r_rotation: 0,
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: 0,
    lower_leg_l_rotation: 0,
    lower_leg_r_rotation: 0,
    head_rotation: 0,
    body_rotation: 0,
    body_x_translation: 0,
    body_y_translation: 0,
    torso_rotation: 0,
  },
  "arms up": {
    name: "arms up",
    arm_l_rotation: "-107",
    arm_r_rotation: "102",
    leg_l_rotation: "-24",
    leg_r_rotation: "21",
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: "4",
    lower_leg_l_rotation: 0,
    lower_leg_r_rotation: 0,
    head_rotation: 3,
    body_rotation: 0,
    body_x_translation: 0,
    body_y_translation: "31",
    torso_rotation: 0,
  },
  "arms down": {
    name: "arms down",
    arm_l_rotation: "-19",
    arm_r_rotation: "25",
    leg_l_rotation: "2",
    leg_r_rotation: "-4",
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: "4",
    lower_leg_l_rotation: 0,
    lower_leg_r_rotation: 0,
    head_rotation: "-3",
    body_rotation: 0,
    body_x_translation: "6",
    body_y_translation: "11",
    torso_rotation: "9",
  },
  "arms in": {
    name: "arms in",
    arm_l_rotation: "-11",
    arm_r_rotation: "9",
    leg_l_rotation: "-24",
    leg_r_rotation: "12",
    lower_arm_l_rotation: "91",
    lower_arm_r_rotation: "-99",
    lower_leg_l_rotation: "21",
    lower_leg_r_rotation: "-6",
    head_rotation: "-1",
    body_rotation: 0,
    body_x_translation: 0,
    body_y_translation: "9",
    torso_rotation: "-1",
  },
  "left arm up": {
    name: "left arm up",
    arm_l_rotation: "-101",
    arm_r_rotation: "14",
    leg_l_rotation: "-33",
    leg_r_rotation: 0,
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: 0,
    lower_leg_l_rotation: 0,
    lower_leg_r_rotation: 0,
    head_rotation: 3,
    body_rotation: 0,
    body_x_translation: 0,
    body_y_translation: 0,
    torso_rotation: "7",
  },
  "right arm up": {
    name: "right arm up",
    arm_l_rotation: "-13",
    arm_r_rotation: "114",
    leg_l_rotation: "-3",
    leg_r_rotation: "35",
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: 0,
    lower_leg_l_rotation: 0,
    lower_leg_r_rotation: 0,
    head_rotation: "10",
    body_rotation: "-1",
    body_x_translation: 0,
    body_y_translation: 0,
    torso_rotation: "-5",
  },
  "lean right": {
    name: "lean right",
    arm_l_rotation: "-69",
    arm_r_rotation: "40",
    leg_l_rotation: "-3",
    leg_r_rotation: "35",
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: 0,
    lower_leg_l_rotation: 0,
    lower_leg_r_rotation: 0,
    head_rotation: "10",
    body_rotation: "-43",
    body_x_translation: 0,
    body_y_translation: "-35",
    torso_rotation: "-5",
  },
  "lean left": {
    name: "lean left",
    arm_l_rotation: "-31",
    arm_r_rotation: "88",
    leg_l_rotation: "-33",
    leg_r_rotation: "32",
    lower_arm_l_rotation: 0,
    lower_arm_r_rotation: 0,
    lower_leg_l_rotation: "10",
    lower_leg_r_rotation: "9",
    head_rotation: "-9",
    body_rotation: "24",
    body_x_translation: 0,
    body_y_translation: "-18",
    torso_rotation: "-7",
  },
  "heel click right": {
    name: "heel click right",
    arm_l_rotation: "-114",
    arm_r_rotation: "87",
    leg_l_rotation: "-64",
    leg_r_rotation: "36",
    lower_arm_l_rotation: "28",
    lower_arm_r_rotation: "-35",
    lower_leg_l_rotation: "96",
    lower_leg_r_rotation: "-88",
    head_rotation: "18",
    body_rotation: "-52",
    body_x_translation: 0,
    body_y_translation: 0,
    torso_rotation: 0,
  },
  "heel click left": {
    name: "heel click left",
    arm_l_rotation: "-62",
    arm_r_rotation: "46",
    leg_l_rotation: "-65",
    leg_r_rotation: "36",
    lower_arm_l_rotation: "28",
    lower_arm_r_rotation: "5",
    lower_leg_l_rotation: "112",
    lower_leg_r_rotation: "-88",
    head_rotation: "-34",
    body_rotation: "50",
    body_x_translation: 0,
    body_y_translation: 0,
    torso_rotation: 0,
  },
  "high splits": {
    name: "high splits",
    arm_l_rotation: "-47",
    arm_r_rotation: "39",
    leg_l_rotation: "-88",
    leg_r_rotation: "77",
    lower_arm_l_rotation: "-25",
    lower_arm_r_rotation: "35",
    lower_leg_l_rotation: "9",
    lower_leg_r_rotation: 0,
    head_rotation: "-5",
    body_rotation: 0,
    body_x_translation: "-1",
    body_y_translation: "-32",
    torso_rotation: "-2",
  },
  "low splits": {
    name: "low splits",
    arm_l_rotation: "-85",
    arm_r_rotation: "84",
    leg_l_rotation: "-106",
    leg_r_rotation: "91",
    lower_arm_l_rotation: "-25",
    lower_arm_r_rotation: "35",
    lower_leg_l_rotation: "9",
    lower_leg_r_rotation: 0,
    head_rotation: "-5",
    body_rotation: "-2",
    body_x_translation: "-1",
    body_y_translation: "89",
    torso_rotation: "4",
  },
};

export default poses;
