import Button from "button";
import ExclamationIcon from "assets/exclamation_icon.svg";
import AvatarPoses from "./avatar_poses";
import GallerySelection from "./gallery_selection";
import activityImages from "activity_images";

import styles from "./styles.module.scss";

const SharingChooseImage = ({
  activityFlow,
  activityKey,
  preview_image: existingPose,
  avatarWorkspace,
  onSubmit,
  onBack,
}) => {
  const [selection, setSelection] = useState(existingPose);
  const [validationError, setValidationError] = useState(null);

  const availableImages = activityImages(activityKey);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selection === "") {
      setValidationError("Please select an image for your project");
    } else {
      onSubmit(selection);
    }
  };

  const handleChange = (e) => {
    const { target: { value: selection = "" } = {} } = e;
    setSelection(selection);
  };

  const handleOnBack = () => {
    onBack(selection);
  };

  return (
    <div className={styles.sharing_choosing_image}>
      <h2 className={styles.heading}>Choose an image</h2>
      <p className={styles.paragraph}>
        {activityFlow.sharingChooseImage.title}
      </p>
      <form
        className={styles.sharing_choosing_image_form}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <ul className={styles.sharing_choosing_image_radios}>
          {activityFlow.sharingChooseImage.type === "static" && (
            <GallerySelection
              images={availableImages}
              selection={selection}
              handleChange={handleChange}
              className={styles.sharing_choosing_image_robot}
            >
              {(img) => <img src={availableImages[img]} alt="" />}
            </GallerySelection>
          )}
          {activityFlow.sharingChooseImage.type === "pose" && (
            <AvatarPoses
              avatarWorkspace={avatarWorkspace}
              pose={selection}
              handleChange={handleChange}
            />
          )}
        </ul>
        {validationError && (
          <div
            className={classNames(
              styles.validation_error,
              styles.validation_error
            )}
          >
            <ExclamationIcon />
            {validationError}
          </div>
        )}
        <div className={styles.sharing_choosing_image_buttons}>
          <Button.RectanglePrimary
            outline={true}
            text={"Back"}
            type="button"
            onClick={handleOnBack}
          />
          <Button.RectanglePrimary text={"Next"} type="submit" />
        </div>
      </form>
    </div>
  );
};

SharingChooseImage.propTypes = {
  activityKey: PropTypes.string,
  activityFlow: PropTypes.shape({
    sharingChooseImage: PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  available: PropTypes.object,
  avatarWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  preview_image: PropTypes.string,
};

export default SharingChooseImage;
