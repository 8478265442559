const SadFace = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4 0C3.76081 0 0 3.76081 0 8.4V61.6C0 66.2392 3.76081 70 8.4 70H61.6C66.2392 70 70 66.2392 70 61.6V8.4C70 3.76081 66.2392 0 61.6 0H8.4ZM18.2001 16.8C14.3341 16.8 11.2001 19.934 11.2001 23.8V46.2C11.2001 50.066 14.3341 53.2 18.2001 53.2H51.8001C55.6661 53.2 58.8001 50.066 58.8001 46.2V23.8C58.8001 19.934 55.6661 16.8 51.8001 16.8H18.2001Z"
      fill="black"
    />
    <circle cx="24.4995" cy="26.834" r="3.5" fill="black" />
    <circle cx="45.4988" cy="26.834" r="3.5" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.833 46.6672C42.833 42.341 39.3259 38.8339 34.9997 38.8339C30.6734 38.8339 27.1663 42.341 27.1663 46.6672L24.1663 46.6672C24.1663 40.6841 29.0166 35.8339 34.9997 35.8339C40.9828 35.8339 45.833 40.6842 45.833 46.6672L42.833 46.6672Z"
      fill="black"
    />
  </svg>
);

export default SadFace;
