import styles from "./styles.module.scss";

const ProjectBadge = ({ badgeTemplate }) => {
  const routes = useRoutes();

  return (
    <div className={styles.badge_awarded}>
      <h3>
        You earned the
        <br />
        {badgeTemplate.title}&nbsp;badge!
      </h3>
      <div className={styles.badge}>
        <img src={badgeTemplate.image} alt={""} />
      </div>
      <p className={styles.link_to_profile}>
        Find your badges in your <a href={routes.profilePath()}>profile</a>
      </p>
    </div>
  );
};

ProjectBadge.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
};

export default ProjectBadge;
