import Blockly from "blockly";

Blockly.Blocks.PenDown = {
  message0: "start drawing",
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.PenDown.init = function () {
  this.jsonInit(Blockly.Blocks.PenDown);
};

Blockly.JavaScript.PenDown = function () {
  return "visualisation.penDown();";
};
