import Blockly from "blockly";

Blockly.Blocks.Number = {
  message0: "%1",
  args0: [
    {
      type: "field_number",
      name: "value",
      value: 0,
      min: -10000,
      max: 10000,
    },
  ],
  output: "Number",
};

Blockly.Blocks.Number.init = function () {
  this.jsonInit(Blockly.Blocks.Number);
  this.operator = true;
};

Blockly.JavaScript.Number = function (block) {
  return [block.getFieldValue("value"), Blockly.JavaScript.ORDER_NONE];
};
