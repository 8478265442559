import { useEffect } from "react";
import Layout from "layout";
import modifyDom from "./modify_dom";
import AppContext from "app_context";
import NotFoundPage from "not_found_page";
import Analytics from "analytics";
import SEO from "seo";
import Project from "project";
import Progress from "progress";
import ScratchUITutorial from "./components/scratch_ui_tutorial";
import ScratchUI from "./components/scratch_ui";
import Tutorial from "./components/tutorial";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";

const mobileBreakpointWidth = 1024;

const classOfStepContentToBeModified = "step_content_to_be_modified";

const ProjectPage = () => {
  const { embedded_scratch_project_slugs } = useContext(AppContext);
  const routes = useRoutes();
  const history = useHistory();
  const {
    repository_name: projectSlug,
    step_index: stepIndexString,
  } = useParams();
  const [stepIndex, setStepIndex] = useState(parseInt(stepIndexString, 10));
  const [stepNavigationVisible, setStepNavigationVisible] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [scratchUITutorialExpanded, setScratchUITutorialExpanded] = useState(
    true
  );

  const scrollToTop = useRef(null);

  const isScratchEditorProject = embedded_scratch_project_slugs.includes(
    projectSlug
  );
  const repositoryName = isScratchEditorProject
    ? `${projectSlug}-ccw`
    : projectSlug;
  const [content, steps] = useProjectContent({
    repositoryName: repositoryName,
  });

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileBreakpointWidth
  );

  useResize(() => {
    setIsMobile(window.innerWidth < mobileBreakpointWidth);
  }, []);

  const [handleSavedStateEvent, savedState] = useSavedState();

  const isBlockBasedProject = Project.isBlockBased(content);
  const technology = Project.technology(content);
  const step = steps[stepIndex];

  const showNextButton = stepIndex < steps.length - 1;
  const showBackToMapButton = stepIndex == steps.length - 1;
  const showBackButton = stepIndex > 0;

  const toggleNavigation = () =>
    setStepNavigationVisible(!stepNavigationVisible);

  const showStep = (stepIndex) => {
    history.replace(stepIndexToHref(stepIndex));
    setStepIndex(stepIndex);
    scrollToTop.current.scrollIntoView({ behavior: "smooth" });

    Analytics.pageView();
  };

  const stepIndexToHref = (position) =>
    routes.projectStepPath({ stepIndex: position });

  const handleStepNavigationLinkClick = () => setStepNavigationVisible(false);

  const handleQuizCompleted = () => setQuizCompleted(true);

  const handleQuizStartAgainClick = () => setQuizCompleted(false);

  const backPath =
    routes.islandPath({ island_name: technology.toLowerCase() }) +
    "#" +
    projectSlug;

  useEffect(() => modifyDom(classOfStepContentToBeModified), [
    content,
    stepIndex,
    isMobile,
    scratchUITutorialExpanded,
  ]);

  useEffect(() => setQuizCompleted(false), [stepIndex]);

  useEffect(() => {
    if (step) {
      if (!Project.isQuiz(step) || (Project.isQuiz(step) && quizCompleted)) {
        Progress.upsert({
          projectSlug,
          step: stepIndex,
          completion: step.completion,
        });
      }
    }
  }, [step, stepIndex, projectSlug, quizCompleted]);

  if (!content) return <p>Loading...</p>;

  if (!step) return <NotFoundPage />;

  return (
    <>
      <SEO
        title={`${step.title} - ${content.title} - ${technology} - Code Club World`}
        description={`Learn ${technology} by completing our project ${content.title}`}
      />
      <Layout
        invertBackgroundColours={true}
        savedState={isScratchEditorProject ? savedState : null}
      >
        {isScratchEditorProject && !isMobile ? (
          <>
            <ScratchUI
              projectSlug={projectSlug}
              handleSavedStateEvent={handleSavedStateEvent}
            />
            <ScratchUITutorial
              ref={scrollToTop}
              steps={steps}
              projectTitle={content.title}
              projectSlug={projectSlug}
              repositoryName={repositoryName}
              stepIndex={stepIndex}
              onStepChange={showStep}
              step={step}
              onExpandCollapse={setScratchUITutorialExpanded}
              expanded={scratchUITutorialExpanded}
              classOfStepContentToBeModified={classOfStepContentToBeModified}
              stepNavigationVisible={stepNavigationVisible}
              onStepNavigationClick={toggleNavigation}
              onStepNavigationLinkClick={handleStepNavigationLinkClick}
              onStepNavigationHide={() => setStepNavigationVisible(false)}
              quizCompleted={quizCompleted}
              onQuizCompleted={handleQuizCompleted}
              onStartAgainClick={handleQuizStartAgainClick}
              showNextButton={showNextButton}
              showBackButton={showBackButton}
              showBackToMapButton={showBackToMapButton}
            />
          </>
        ) : (
          <Tutorial
            ref={scrollToTop}
            steps={steps}
            projectTitle={content.title}
            projectSlug={projectSlug}
            repositoryName={repositoryName}
            stepIndex={stepIndex}
            onStepChange={showStep}
            step={step}
            classOfStepContentToBeModified={classOfStepContentToBeModified}
            stepNavigationVisible={stepNavigationVisible}
            onStepNavigationClick={toggleNavigation}
            onStepNavigationLinkClick={handleStepNavigationLinkClick}
            quizCompleted={quizCompleted}
            onQuizCompleted={handleQuizCompleted}
            onStartAgainClick={handleQuizStartAgainClick}
            stepIndexToHref={stepIndexToHref}
            isBlockBasedProject={isBlockBasedProject}
            showNextButton={showNextButton}
            showBackButton={showBackButton}
            showBackToMapButton={showBackToMapButton}
            backPath={backPath}
          />
        )}
      </Layout>
    </>
  );
};

export default ProjectPage;
