import SEO from "seo";
import Layout from "layout";
import Heading from "heading";
import MyDataContext from "my_data_context";
import getActivityKey from "activity_key";
// import Analytics from "analytics";
import HowDoesSharingWork from "./components/how_does_sharing_work";
import PreviouslyShared from "./components/previously_shared";
import SharingNaming from "./components/sharing_naming";
import SharingChooseImage from "./components/sharing_choose_image";
import SharingPreview from "./components/sharing_preview";
import SharingSuccess from "./components/sharing_success";
// import SharingError from "./components/sharing_error";
import styles from "./styles.module.scss";
import Workspace from "workspace";
import activityFlow from "activity_flow";

const NewGallerySubmissionPage = () => {
  const {
    user: { avatar_workspace: avatarWorkspace = null, known_as: author },
    workspaces,
    activities,
  } = useContext(MyDataContext);
  const routes = useRoutes();
  const [workspace] = useState(workspaces[0]);
  const activityKey = getActivityKey(activities, workspace.activity.id);
  const [formData, setFormData] = useState({
    name: workspace.name || "",
    preview_image: "",
  });

  const isSubmittedToGallery = (workspace) => {
    const gallery_submissions = workspace.gallery_submissions || [];
    return gallery_submissions.some(
      (gallery_submission) => gallery_submission.xml === workspace.xml
    );
  };

  const formStates = {
    howDoesSharingWork: "HowDoesSharingWork",
    previouslyShared: "PreviouslyShared",
    sharingNaming: "SharingNaming",
    sharingChooseImage: "SharingChooseImage",
    sharingPreview: "SharingPreview",
    sharingSuccess: "SharingSuccess",
    sharingError: "SubmitError",
  };
  const [formState, setFormState] = useState(
    isSubmittedToGallery(workspace)
      ? formStates.previouslyShared
      : formStates.howDoesSharingWork
  );

  const handleHowSharingWorksSubmit = () => {
    setFormState(formStates.sharingNaming);
  };

  const handleShareNameSubmit = (name) => {
    setFormData({ ...formData, name });
    if (activityFlow[activityKey].sharingChooseImage?.skip) {
      setFormState(formStates.sharingPreview);
    } else {
      setFormState(formStates.sharingChooseImage);
    }
  };

  const handleShareNameBack = (name) => {
    setFormData({ ...formData, name });
    setFormState(formStates.howDoesSharingWork);
  };

  const handleShareImageSubmit = (preview_image) => {
    setFormData({ ...formData, preview_image });
    setFormState(formStates.sharingPreview);
  };

  const handleShareImageBack = (preview_image) => {
    setFormData({ ...formData, preview_image });
    setFormState(formStates.sharingNaming);
  };

  const handleSharePreviewSubmit = () => {
    Workspace.submitToGallery(workspace, formData)
      .then(handleShareToGallerySuccess)
      .catch(handleShareToGalleryError);
  };

  const handleSharePreviewBack = () => {
    if (activityFlow[activityKey].sharingChooseImage?.skip) {
      setFormState(formStates.sharingNaming);
    } else {
      setFormState(formStates.sharingChooseImage);
    }
  };

  const handleShareToGallerySuccess = () => {
    setFormState(formStates.sharingSuccess);
  };

  const handleShareToGalleryError = (error) => {
    console.log("handleShareToGalleryError ", error); // eslint-disable-line no-console
  };

  const showForm = () => {
    switch (formState) {
      case formStates.previouslyShared:
        return <PreviouslyShared workspace={workspace} />;
      case formStates.howDoesSharingWork:
        return <HowDoesSharingWork onSubmit={handleHowSharingWorksSubmit} />;
      case formStates.sharingNaming:
        return (
          <SharingNaming
            activityFlow={activityFlow[activityKey]}
            activityKey={activityKey}
            name={formData.name}
            onBack={handleShareNameBack}
            onSubmit={handleShareNameSubmit}
            workspace={workspace}
          />
        );
      /* eslint-disable no-console */
      case formStates.sharingChooseImage:
        return (
          <SharingChooseImage
            activityFlow={activityFlow[activityKey]}
            activityKey={activityKey}
            preview_image={formData.preview_image}
            avatarWorkspace={avatarWorkspace}
            onSubmit={handleShareImageSubmit}
            onBack={handleShareImageBack}
          />
        );
      case formStates.sharingPreview:
        return (
          <SharingPreview
            gallerySubmission={{
              name: formData.name,
              preview_image: formData.preview_image,
              author,
            }}
            activityKey={activityKey}
            workspace={workspace}
            avatarWorkspace={avatarWorkspace}
            onSubmit={handleSharePreviewSubmit}
            onBack={handleSharePreviewBack}
          />
        );
      /* eslint-disable no-console */
      case formStates.sharingSuccess:
        return <SharingSuccess workspace={workspace} />;
      /* eslint-enable no-console */
      // case formStates.sharingError:
      //   return <ShareToGalleryError onConfirm={handleShareDismissal} />;
      default:
        return null;
    }
  };

  return (
    <>
      <SEO
        title="Share your project - Code Club World"
        description="Share your project"
      />
      <Layout>
        <Heading
          title={<h1>Share your project</h1>}
          className={styles.heading}
          backPath={routes.workspacePath({
            activity_key: activityKey,
            workspace: workspace,
          })}
          invertBackgroundColours={true}
        ></Heading>
        <div className={styles.sharing_container}>{showForm()}</div>
      </Layout>
    </>
  );
};

export default NewGallerySubmissionPage;
