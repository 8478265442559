const activityKey = (activities, activityId) => {
  if (activities) {
    const activity = activities.filter(
      (activity) => activity.id === activityId
    );
    if (activity && activityId) {
      return activity[0].key;
    }
  }
};

export default activityKey;
