import requireContext from "require-context.macro";
import { Scale } from "@tonaljs/tonal";

export const Instruments = [
  "piano",
  "piano_chords",
  "synth_chords",
  "punchy_bass",
  "jazzy_bass",
  "saw_lead",
  "bells",
  "drumkit",
];
export const Drums = ["bass"];

const context = requireContext("../../components/assets/music", false, /\.mp3/);

const instrumentSampleUrls = Instruments.map((instrument) => {
  return Scale.rangeOf("c major")("A3", "C5").map((_, idx) => {
    return {
      name: instrument,
      note: idx,
      url: context(`./${instrument}_${idx}.mp3`),
    };
  });
}).flat();

const drumSampleUrls = Drums.map((drum) => {
  var note = 0;
  return {
    name: drum,
    note: note,
    url: context(`./${drum}_${note}.mp3`),
  };
});

export const SampleUrls = instrumentSampleUrls.concat(drumSampleUrls);
