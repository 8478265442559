import BlocklyHelpers from "blockly_helpers";
import Avatar from "visualisations/avatar";
import Interpreter from "interpreter";

const FALLBACK_CODE = "";

const AvatarPreview = ({ avatarWorkspace }) => {
  const visualisationChannel = useMemo(() => {
    return { onReady: () => {} };
  }, []);

  useEffect(() => {
    if (!avatarWorkspace) return;

    const [deprecated, code] = BlocklyHelpers.generateCode(avatarWorkspace.xml);
    const blocksChannel = {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
    const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
    interpreter.run();
  }, [avatarWorkspace, visualisationChannel]);

  return <Avatar channel={visualisationChannel} outputType="preview" />;
};

AvatarPreview.propTypes = {
  avatarWorkspace: PropTypes.shape({
    xml: PropTypes.string.isRequired,
  }),
};

export default AvatarPreview;
