const default_atts = {
  penStyle: "pen",
  penShape: "line",
  scatter: false,
  penDash: "solid",
};
const penStyles = {
  solidLine: {
    name: "solid line",
    atts: default_atts,
  },
  dashedLine: {
    name: "dashed line",
    atts: { ...default_atts, penDash: "dashed" },
  },
  dottedLine: {
    name: "dotted line",
    atts: { ...default_atts, penDash: "dotted" },
  },
  sprayPaint: {
    name: "spray paint",
    atts: { ...default_atts, penStyle: "spraypaint" },
  },
  crayon: {
    name: "crayon",
    atts: { ...default_atts, penStyle: "crayon" },
  },
  heart: {
    name: "hearts",
    atts: { ...default_atts, penShape: "heart", scatter: true },
  },
  clouds: {
    name: "clouds",
    atts: {
      ...default_atts,
      penStyle: "spraypaint",
      penShape: "cloud",
    },
  },
  snowflake: {
    name: "snowflakes",
    atts: {
      ...default_atts,
      penShape: "snowflake",
      scatter: true,
    },
  },
  circle: {
    name: "circles",
    atts: {
      ...default_atts,
      penShape: "circle",
    },
  },
};

export default penStyles;
