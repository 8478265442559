import Blockly from "blockly";

Blockly.Blocks.PenUp = {
  message0: "stop drawing",
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.PenUp.init = function () {
  this.jsonInit(Blockly.Blocks.PenUp);
};

Blockly.JavaScript.PenUp = function () {
  return "visualisation.penUp();";
};
