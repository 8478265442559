import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.GoToXY = {
  message0: "go to x: %1 y: %2",
  args0: [
    {
      type: "input_value",
      name: "x",
      check: "Number",
    },
    {
      type: "input_value",
      name: "y",
      check: "Number",
    },
  ],
  inputsInline: true,
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.GoToXY.init = function () {
  this.jsonInit(Blockly.Blocks.GoToXY);
};

Blockly.JavaScript.GoToXY = function (block) {
  const x = helpers.codeForArg("x", block);
  const y = helpers.codeForArg("y", block);

  if (typeof x === "undefined" || typeof y === "undefined") {
    return "";
  } else {
    return `visualisation.goTo(${x}, ${y});`;
  }
};
