import TickMark from "assets/tick_mark.svg";
import XMark from "assets/x_mark.svg";
import FeaturedStar from "assets/featured_star.svg";
import ColouredButton from "coloured_button";
import styles from "./styles.module.scss";

const ModeratorButtons = ({
  user,
  exhibit,
  handleModeration,
  handleFeatured,
}) => {
  return (
    <>
      {user.moderator && (
        <>
          <div>
            <ColouredButton
              className={styles.first_button}
              aria-label={"accepted"}
              icon={<TickMark skinny={true} />}
              active={exhibit.state === "accepted"}
              onClick={() => handleModeration(exhibit, "accepted", false)}
            />
            <ColouredButton
              colour={"red"}
              aria-label={"rejected"}
              active={exhibit.state === "rejected"}
              icon={<XMark skinny={true} />}
              onClick={() => handleModeration(exhibit, "rejected", false)}
            />
          </div>
          {exhibit.state !== "rejected" && (
            <div>
              <ColouredButton
                colour={"blue"}
                aria-label={!exhibit.featured_at ? "Featured" : "Not featured"}
                active={!!exhibit.featured_at}
                icon={<FeaturedStar />}
                onClick={() => handleFeatured(exhibit, false)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

ModeratorButtons.propTypes = {
  user: PropTypes.object.isRequired,
  exhibit: PropTypes.object.isRequired,
  handleModeration: PropTypes.func.isRequired,
  handleFeatured: PropTypes.func.isRequired,
};

export default ModeratorButtons;
