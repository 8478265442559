import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.ChangeTempo = {
  message0: "change tempo by %1",
  args0: [
    {
      type: "input_value",
      name: "delta",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.ChangeTempo.init = function () {
  this.jsonInit(Blockly.Blocks.ChangeTempo);
};

Blockly.JavaScript.ChangeTempo = function (block) {
  const delta = helpers.codeForArg("delta", block);

  if (typeof delta === "undefined") {
    return "";
  } else {
    return `visualisation.changeTempo(${delta});`;
  }
};
