import classNames from "classnames";
import styles from "./styles.module.scss";

const Link = ({
  className,
  onClick,
  href,
  tabindex,
  children,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
}) => {
  const classes = classNames(styles.link, className);

  return href ? (
    <a
      className={classes}
      href={href}
      tabIndex={tabindex}
      onClick={onClick}
      {...(ariaLabel && { "aria-label": ariaLabel })}
      {...(ariaLabelledBy && {
        "aria-labelledby": ariaLabelledBy,
      })}
    >
      {children}
    </a>
  ) : (
    <button
      className={classes}
      onClick={onClick}
      tabIndex={tabindex}
      {...(ariaLabel && { "aria-label": ariaLabel })}
      {...(ariaLabelledBy && {
        "aria-labelledby": ariaLabelledBy,
      })}
    >
      {children}
    </button>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  "aria-label": PropTypes.string,
  "aria-labelledby": PropTypes.string,
  onClick: PropTypes.func,
  tabindex: PropTypes.number,
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Link;
