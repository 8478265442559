import Project from "project";
import Heading from "heading";
import Chevron from "assets/chevron.svg";
import StepContentOrQuiz from "../step_content_or_quiz";
import Button from "button";
import styles from "./styles.module.scss";
import stepContentStyles from "project_page/step_content_styles.module.scss";

const Tutorial = forwardRef((props, ref) => {
  const {
    steps,
    projectTitle,
    projectSlug,
    repositoryName,
    stepIndex,
    onStepChange,
    step,
    classOfStepContentToBeModified,
    stepNavigationVisible,
    onStepNavigationClick,
    onStepNavigationLinkClick,
    quizCompleted,
    onQuizCompleted,
    onStartAgainClick,
    stepIndexToHref,
    isBlockBasedProject,
    showNextButton,
    showBackButton,
    showBackToMapButton,
    backPath,
  } = props;

  const routes = useRoutes();

  return (
    <>
      <div ref={ref} />

      <Heading
        title={<h1>{projectTitle}</h1>}
        className={styles.heading}
        backPath={backPath}
      />

      <div className={styles.project_page}>
        <button
          className={styles.navigation_toggle}
          onClick={onStepNavigationClick}
        >
          Contents <Chevron />
        </button>

        <nav
          className={classNames(styles.steps_navigation, {
            [styles.visible_on_mobile]: stepNavigationVisible,
          })}
        >
          <ol>
            {steps.map(({ title, position }) => {
              return (
                <li
                  key={position}
                  className={classNames({
                    [styles.active_item]: position === stepIndex,
                  })}
                >
                  <a
                    href={stepIndexToHref(position)}
                    onClick={(event) => {
                      event.preventDefault();
                      onStepChange(position);
                      onStepNavigationLinkClick();
                    }}
                  >
                    {title}
                  </a>
                </li>
              );
            })}
          </ol>
        </nav>

        <div className={stepContentStyles.step_content_container}>
          <div
            className={classNames(stepContentStyles.step_content, {
              [stepContentStyles.block_project]: isBlockBasedProject,
              [stepContentStyles.text_project]: !isBlockBasedProject,
            })}
          >
            <StepContentOrQuiz
              projectSlug={projectSlug}
              repositoryName={repositoryName}
              step={step}
              quizCompleted={quizCompleted}
              onQuizCompleted={onQuizCompleted}
              projectStyles={stepContentStyles}
              onStartAgainClick={onStartAgainClick}
            >
              <div
                className={classOfStepContentToBeModified}
                dangerouslySetInnerHTML={{ __html: step.content }}
              />
            </StepContentOrQuiz>
          </div>
          <div className={styles.next_back_buttons}>
            {showBackButton && (!Project.isQuiz(step) || quizCompleted) && (
              <Button.RectangleSecondary
                text="Back"
                outline={true}
                onClick={() => onStepChange(stepIndex - 1)}
                className={styles.back}
              />
            )}
            {showNextButton && (!Project.isQuiz(step) || quizCompleted) && (
              <Button.RectangleSecondary
                text="Next"
                onClick={() => onStepChange(stepIndex + 1)}
                className={styles.next}
              />
            )}
            {showBackToMapButton && (
              <Button.RectangleSecondary
                href={routes.mapRedirectorPath()}
                text="Back to the map"
                className={styles.back_to_map}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Tutorial;
