const lerp = (value1, value2, amount) => {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  var diff = (value2 - value1) % 360;
  diff = ((diff * 2) % 360) - diff;
  return value1 + diff * amount;
};

const merge = (pose1, pose2, factor) => {
  let merged = {};
  for (let key of Object.keys(pose1)) {
    merged[key] = lerp(parseFloat(pose1[key]), parseFloat(pose2[key]), factor);
  }
  return merged;
};

const exponentialEasing = (jerkiness) => {
  return (value) => {
    return Math.pow(value % 1, jerkiness);
  };
};

export { merge, exponentialEasing };
