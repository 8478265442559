const SavedIcon = () => (
  <svg
    width="31"
    height="21"
    viewBox="0 0 31 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5042 20.6806H8.25838C6.37471 20.6817 4.55961 19.9738 3.1741 18.6976C1.78859 17.4214 0.93417 15.6705 0.780776 13.7931C0.627382 11.9157 1.18625 10.0493 2.34623 8.56517C3.50622 7.08102 5.18232 6.08786 7.0412 5.78319C7.91249 4.2379 9.17842 2.95163 10.7096 2.05582C12.2408 1.16002 13.9824 0.686795 15.7564 0.684514C18.0085 0.676285 20.196 1.43675 21.9573 2.84021C23.687 4.21217 24.9167 6.11557 25.4564 8.2563C27.0132 8.49551 28.4223 9.31386 29.4015 10.5475C30.3808 11.7812 30.8579 13.3392 30.7376 14.9097C30.6172 16.4802 29.9082 17.9473 28.7524 19.0173C27.5965 20.0874 26.0793 20.6814 24.5042 20.6806ZM15.7564 3.18512C14.4255 3.18668 13.1189 3.54166 11.9701 4.21375C10.8213 4.88584 9.87165 5.85094 9.21813 7.01037L8.63329 8.05885L7.44609 8.25255C6.2105 8.45957 5.09768 9.123 4.32799 10.1115C3.5583 11.1 3.18786 12.3415 3.29001 13.5901C3.39217 14.8388 3.95947 16.0036 4.87959 16.8538C5.79972 17.7041 7.00557 18.1778 8.25838 18.1812H24.5042C25.4492 18.1822 26.3598 17.8262 27.0536 17.1845C27.7474 16.5428 28.1733 15.6627 28.246 14.7205C28.3186 13.7782 28.0327 12.8432 27.4455 12.1027C26.8583 11.3622 26.0131 10.8708 25.079 10.7269L23.4344 10.477L23.0321 8.86239C22.6284 7.23903 21.6924 5.79774 20.3736 4.76866C19.0548 3.73958 17.4292 3.18205 15.7564 3.18512ZM13.9169 16.5691L9.54305 12.1953L11.3051 10.4332L13.9169 13.0451L19.6654 7.29655L21.4275 9.05859L13.9169 16.5679V16.5691Z"
      fill="black"
    />
  </svg>
);

export default SavedIcon;
