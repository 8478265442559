const Run = () => (
  <svg
    width={23}
    height={26}
    viewBox="0 0 23 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.20002 25.5C0.934801 25.5 0.680445 25.3903 0.492908 25.1949C0.30537 24.9995 0.200012 24.7346 0.200012 24.4583V1.5415C0.200042 1.36048 0.245353 1.1826 0.33148 1.02539C0.417607 0.868173 0.541576 0.737049 0.691169 0.644942C0.840762 0.552834 1.01081 0.502922 1.18457 0.500124C1.35832 0.497327 1.52977 0.541742 1.68202 0.62899L21.6822 12.0874C21.839 12.1773 21.9697 12.3094 22.0608 12.4699C22.1518 12.6304 22.1999 12.8135 22.1999 12.9999C22.1999 13.1864 22.1518 13.3694 22.0608 13.5299C21.9697 13.6904 21.839 13.8225 21.6822 13.9124L1.68202 25.3708C1.53437 25.4555 1.36857 25.4999 1.20002 25.5Z"
      fill="black"
    />
  </svg>
);

export default Run;
