import Blockly from "blockly";

Blockly.Blocks.Clear = {
  message0: "clear",
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.Clear.init = function () {
  this.jsonInit(Blockly.Blocks.Clear);
};

Blockly.JavaScript.Clear = () => {
  return `visualisation.clear();`;
};
