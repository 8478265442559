import Cursor from "./cursor.svg";
import Canvas from "./canvas";
import Robot from "assets/robot.svg";
import styles from "./styles.module.scss";
import useRobotStyles from "use_robot_styles";
import { useState, useEffect } from "react";

const CanvasAndAvatar = ({
  fullscreen,
  art,
  canvasDimensions,
  seed,
  canvasStep = null,
  iterateRun = false,
  uniqueIdentifier = "",
  gallery = false,
}) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [heading, setHeading] = useState(art.heading);

  const robotStyles = useRobotStyles();
  const tShirtColour = art.backgroundColour || art.defaultBackgroundColour;

  const calcHeading = (drawCommand) => {
    const deltaX = drawCommand.fromX - drawCommand.toX;
    const deltaY = drawCommand.fromY - drawCommand.toY;
    const angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
    return angle - 90;
  };

  useEffect(() => {
    if (
      canvasStep == 0 ||
      art.drawCommands == null ||
      art.drawCommands.length === 0
    ) {
      setX(0);
      setY(0);
      setHeading(90);
    } else if (
      canvasStep !== null &&
      (iterateRun || (canvasStep != 0 && canvasStep < art.drawCommands.length))
    ) {
      setX(art.drawCommands[canvasStep - 1].toX);
      setY(art.drawCommands[canvasStep - 1].toY);
      setHeading(calcHeading(art.drawCommands[canvasStep - 1]));
    } else {
      setX(art.x);
      setY(art.y);
      setHeading(art.heading);
    }
  }, [iterateRun, canvasStep, art]);

  const canvas = (id, title, canvasStep = null) => {
    let canvasId = id;
    let drawCommands = art.drawCommands;
    if (canvasStep !== null) {
      canvasId += `-${canvasStep}`;
      drawCommands = art.drawCommands.slice(0, canvasStep);
    }
    return (
      <Canvas
        canvasId={canvasId}
        canvasDimensions={canvasDimensions}
        backgroundColour={tShirtColour}
        drawCommands={drawCommands}
        seed={seed}
        title={title}
      />
    );
  };

  return (
    <>
      <div
        className={classNames(styles.frame, {
          [styles.fullscreen]: fullscreen,
          [styles.gallery]: gallery,
        })}
      >
        <div className={styles.canvas}>
          {" "}
          {canvas(
            uniqueIdentifier
              ? `canvas-and-avatar-${uniqueIdentifier}`
              : "canvas-and-avatar",
            "The artwork that will appear on your T-shirt",
            canvasStep
          )}{" "}
          {!gallery && (
            <Cursor
              x={x}
              y={y}
              heading={heading}
              colour={art.penColour}
              canvasDimensions={canvasDimensions}
            />
          )}{" "}
        </div>{" "}
      </div>{" "}
      {!gallery && (
        <div
          className={classNames(styles.avatar_container, {
            [styles.fullscreen]: fullscreen,
            [styles.gallery]: gallery,
          })}
        >
          <Robot
            {...robotStyles}
            displayTshirt={true}
            tShirtColour={tShirtColour}
          >
            {canvas(
              uniqueIdentifier
                ? `canvas-and-avatar-tshirt-${uniqueIdentifier}`
                : "canvas-and-avatar-tshirt",
              "T-shirt artwork",
              canvasStep
            )}
          </Robot>
        </div>
      )}
    </>
  );
};

CanvasAndAvatar.propTypes = {
  fullscreen: PropTypes.bool.isRequired,
  art: PropTypes.shape({
    backgroundColour: PropTypes.string,
    defaultBackgroundColour: PropTypes.string.isRequired,
    drawCommands: PropTypes.arrayOf(PropTypes.object).isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    heading: PropTypes.number.isRequired,
    penColour: PropTypes.string.isRequired,
  }).isRequired,
  canvasDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  seed: PropTypes.string.isRequired,
  canvasStep: PropTypes.number,
  iterateRun: PropTypes.bool,
  uniqueIdentifier: PropTypes.string,
  gallery: PropTypes.bool,
};

export default CanvasAndAvatar;
