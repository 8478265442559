import Layout from "layout";
import Activity from "activity";
import Blocks from "blocks";
import Block from "blocks/block";
import Category from "blocks/category";
import Colours from "blocks/colours";
import SEO from "seo";
import useAutoSave from "use_auto_save";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";

const DEFAULT_STAMP_SIZE = 100;

const ArtPage = () => {
  const blocksChannel = useMemo(() => ({}), []);
  const artChannel = {};

  const [autoSaveRequested, handleAutoSaveEvent] = useAutoSave();
  const [handleSavedStateEvent, savedState] = useSavedState();

  return (
    <>
      <SEO
        title="Design a T-shirt - Code Club World"
        description="Use blocks to code a T-shirt design for your avatar."
      />
      <Layout savedState={savedState}>
        <Activity
          title="Design a T-shirt"
          activityKey="art"
          visualisationChannel={artChannel}
          blocksChannel={blocksChannel}
          maxBlocksPerSecond={20}
          autoSaveRequested={autoSaveRequested()}
          onAutoSaveEvent={handleAutoSaveEvent}
          onSavedStateEvent={handleSavedStateEvent}
          savedState={savedState}
        >
          <Blocks channel={blocksChannel} onAutoSaveEvent={handleAutoSaveEvent}>
            <Category
              name="Basic"
              colour={Colours.dark_grey}
              css-icon="blocklyTreeIcon iconBasic"
            >
              <Block type="SetPenStyle" defaults={["pen"]} />
              <Block type="SetBackgroundColour" defaults={["#ff6633"]} />
              <Block type="Stamp" defaults={[0, DEFAULT_STAMP_SIZE]} />
              <Block type="MoveNSteps" defaults={[100]} />
              <Block type="GoToRandomPosition" />
              <Block type="TurnClockwise" defaults={[90]} />
              <Block type="TurnAntiClockwise" defaults={[90]} />
              <Block type="controls_repeat_ext" defaults={[4]} />
            </Category>
            <Category
              name="Pen"
              colour={Colours.teal}
              css-icon="blocklyTreeIcon iconPen"
            >
              <Block type="SetPenColour" defaults={["#3366ff"]} />
              <Block type="ChangeColourBy" defaults={[10]} />
              <Block type="SetPenSize" defaults={[1]} />
              <Block type="ChangePenSize" defaults={[2]} />
              <Block type="SetPenStyle" defaults={["pen"]} />
              <Block type="SetBackgroundColour" defaults={["#ff6633"]} />
              <Block type="Stamp" defaults={[0, DEFAULT_STAMP_SIZE]} />
              <Block type="DrawShapeOfSize" defaults={["triangle", 50]} />
              <Block type="Clear" />
              <Block type="PenDown" />
              <Block type="PenUp" />
            </Category>
            <Category
              name="Motion"
              colour={Colours.blue}
              css-icon="blocklyTreeIcon iconMotion"
            >
              <Block type="MoveNSteps" defaults={[50]} />
              <Block type="TurnClockwise" defaults={[90]} />
              <Block type="TurnAntiClockwise" defaults={[90]} />
              <Block type="PointInDirection" defaults={[90]} />
              <Block type="GoToRandomPosition" />
              <Block type="GoToXY" defaults={[100, 100]} />
              <Block type="SetX" defaults={[100]} />
              <Block type="ChangeX" defaults={[10]} />
              <Block type="SetY" defaults={[100]} />
              <Block type="ChangeY" defaults={[10]} />
            </Category>
            <Category
              name="Control"
              colour={Colours.orange}
              css-icon="blocklyTreeIcon iconControl"
            >
              <Block type="controls_repeat_ext" defaults={[4]} />
            </Category>
            <Category
              name="Operators"
              colour={Colours.green}
              css-icon="blocklyTreeIcon iconOperators"
            >
              <Block type="RandomNumber" defaults={[1, 100]} />
              <Block type="Number" defaults={[100]} />
            </Category>
          </Blocks>
        </Activity>
      </Layout>
    </>
  );
};

export default ArtPage;
