import styles from "./styles.module.scss";

const lineColors = [
  "#ff5739",
  "#ffc700",
  "#0b6ffb",
  "#61ca97",
  "#b377f5",
  "#63b4ff",
  "#ff7a00",
  "#a0dcb8",
];

const BeatGrid = ({
  width,
  height,
  margin,
  beat,
  onBeatChanged,
  playDrum,
  renderDetail,
}) => {
  const triangleWidth = 13;
  const triangleMargin = 2;

  const numberOfBeats = 8;
  const numberOfLines = 8;

  const rightInset = renderDetail ? 0 : triangleWidth + triangleMargin * 2;

  const availableHorizontalArea = width - margin * 2 - rightInset;
  const xCenterForTriangle = width - margin - rightInset / 2;
  const availableVerticalArea = height - margin * 2;

  const buttonMaxWidth = availableHorizontalArea / numberOfBeats;
  const buttonMaxHeight = availableVerticalArea / numberOfLines;

  const buttonSpacing = renderDetail ? margin : margin / 2;
  const buttonSize = Math.min(buttonMaxWidth, buttonMaxHeight) - buttonSpacing;
  const buttonOffsets = {
    x: (buttonMaxWidth - buttonSize) / 2,
    y: (buttonMaxHeight - buttonSize) / 2,
  };

  const buttonPosition = (lineNumber, noteIndex) => {
    return {
      x: margin + buttonMaxWidth * noteIndex + buttonOffsets.x,
      y: margin + buttonMaxHeight * lineNumber + buttonOffsets.y,
    };
  };

  const drawButton = (line, beat) => {
    const pos = buttonPosition(line, beat);
    const lineColour = lineColors[line % lineColors.length];
    const restColour = renderDetail ? "#BF5AA4" : "#C4C4C4";
    const fillColor = grid[line][beat] === "-" ? restColour : lineColour;
    const strokeColor = grid[line][beat] === "-" ? "none" : "black";
    const strokeWidth = renderDetail ? "2" : "1";
    const radius = buttonSize / 5;
    return (
      <rect
        width={buttonSize}
        height={buttonSize}
        fill={fillColor}
        rx={radius}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        x={pos.x}
        y={pos.y}
        pointerEvents="none"
      />
    );
  };

  const renderTriangle = () => {
    return (
      <path
        transform={`translate(${xCenterForTriangle - triangleWidth / 2},${
          height / 2 - triangleMargin
        })`}
        d="M5.96478 7.7696C6.02441 7.84066 6.10421 7.89875 6.19729 7.93889C6.29038 7.97903 6.39396 8 6.49912 8C6.60428 8 6.70786 7.97903 6.80094 7.93889C6.89403 7.89875 6.97382 7.84066 7.03346 7.7696L12.8839 0.836743C12.9516 0.756778 12.9913 0.663118 12.9987 0.565938C13.0061 0.468757 12.9809 0.371773 12.9258 0.285524C12.8708 0.199274 12.7879 0.127057 12.6863 0.0767191C12.5847 0.0263811 12.4683 -0.000152706 12.3496 6.61103e-07H0.648661C0.53025 0.000401918 0.414212 0.027277 0.313027 0.0777355C0.211842 0.128194 0.129337 0.200327 0.0743855 0.286377C0.019434 0.372427 -0.00588502 0.469139 0.00115111 0.566112C0.00818723 0.663084 0.0473123 0.756648 0.114319 0.836743L5.96478 7.7696Z"
        pointerEvents="none"
        fill="black"
      />
    );
  };

  const [instruments, beats] = beat.split("@");
  const lines = beats.split("|");
  const grid = lines.map((line) => line.split(""));
  const buttons = [];
  for (var i = 0; i < numberOfBeats; i++) {
    for (var j = 0; j < numberOfLines; j++) {
      buttons.push(drawButton(j, i));
    }
  }

  const gridAsBeatString = () => {
    const beat = grid.map((line) => line.join("")).join("|");
    return [instruments, beat].join("@");
  };

  const calculateGridClickFromEventPosition = (evt) => {
    const e = evt.target;
    const dim = e.getBoundingClientRect();
    const x = evt.clientX - dim.left;
    const y = evt.clientY - dim.top;
    const beat = Math.floor((x - margin) / buttonMaxWidth);
    const line = Math.floor((y - margin) / buttonMaxHeight);
    return { line, beat };
  };

  const getInstrumentForLine = (line) => instruments.split("")[line];

  const onClick = (evt) => {
    const gridClick = calculateGridClickFromEventPosition(evt);
    if (grid[gridClick.line][gridClick.beat] === "-") {
      grid[gridClick.line][gridClick.beat] = ".";
      playDrum(getInstrumentForLine(gridClick.line));
    } else {
      grid[gridClick.line][gridClick.beat] = "-";
    }
    onBeatChanged(gridAsBeatString());
  };

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        styles.beatgrid,
        onBeatChanged ? "blocklyDraggable" : ""
      )}
      onClick={onBeatChanged && onClick}
    >
      <rect
        width={width}
        height={height}
        rx="5"
        stroke="none"
        fill={renderDetail ? "#F875D5" : "white"}
      />
      {rightInset && renderTriangle()}
      {buttons}
    </svg>
  );
};

BeatGrid.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number,
  renderDetail: PropTypes.bool,
  beat: PropTypes.string,
  onBeatChanged: PropTypes.func,
  playDrum: PropTypes.func,
};

export default BeatGrid;
