import styles from "./styles.module.scss";

const previewConfig = {
  margin: 4,
  stripeColor: "#ffffff",
  restStrokeWidth: 0,
  noteScale: 1.5,
  showNoteName: false,
  showTriangle: true,
};

const detailedConfig = {
  margin: 10,
  stripeColor: "#f5f5f5",
  restStrokeWidth: 1.5,
  noteScale: 1,
  showNoteName: true,
  showTriangle: false,
};

const Stave = ({
  notes,
  width,
  height,
  renderDetail,
  melody,
  onMelodyChanged,
  playNote,
}) => {
  const config = renderDetail ? detailedConfig : previewConfig;

  const {
    margin,
    stripeColor,
    restStrokeWidth,
    noteScale,
    showNoteName,
    showTriangle,
  } = config;

  const borderStroke = 1;
  const triangleWidth = 13;
  const triangleMargin = 2;

  const double = (num) => num * 2;
  const half = (num) => num / 2;

  const innerHeight = height - double(margin);
  const numberOfNotePositions = notes.length;

  const numberOfNoteBands = half(numberOfNotePositions + 1);
  const numberOfRestBands = 1;

  const bandHeight = innerHeight / (numberOfNoteBands + numberOfRestBands);
  const restBandHeight = bandHeight;

  const noteIntervalHeight = half(bandHeight);

  const rightInset = showTriangle ? triangleWidth + double(triangleMargin) : 0;
  const noteDrawSize = bandHeight * noteScale;
  const noteDrawRadius = half(noteDrawSize);
  const restDrawSize = noteDrawSize;
  const restDrawRadius = half(noteDrawSize);

  const yCenterForNote = (note) => yCenterForNoteByIndex(notes.indexOf(note));
  const yCenterForNoteByIndex = (noteIndex) =>
    height -
    margin -
    restBandHeight -
    noteIndex * noteIntervalHeight -
    noteIntervalHeight;

  const yPositionForRest =
    yCenterForNoteByIndex(0) + restBandHeight - restDrawRadius;
  const yPositionForNote = (note) => yCenterForNote(note) - noteDrawRadius;

  const yPositionForBandBehindNoteIndex = (index) =>
    yCenterForNoteByIndex(index) - noteIntervalHeight;

  const innerWidth = width - double(margin) - rightInset;
  const numberOfNotes = melody.length;
  const columnWidth = innerWidth / numberOfNotes;
  const xStartOfColumnAtIndex = (index) => margin + columnWidth * index;
  const xPositionForRestAtIndex = (index) =>
    xStartOfColumnAtIndex(index) + half(columnWidth - restDrawSize);
  const xPositionForNoteAtIndex = (index) =>
    xStartOfColumnAtIndex(index) + half(columnWidth - noteDrawSize);
  const xCenterForTriangle = width - margin - half(rightInset);

  const noteColors = ["#FFC700"];
  const colorForNote = (note) =>
    noteColors[notes.indexOf(note) % noteColors.length];

  const renderStave = () => {
    var rows = [];
    for (var i = 0; i < numberOfNotePositions; i += 4) {
      rows.push(
        <rect
          key={i}
          x={borderStroke}
          y={yPositionForBandBehindNoteIndex(i)}
          width={width - double(borderStroke)}
          height={bandHeight}
          fill={stripeColor}
          pointerEvents="none"
        />
      );
    }
    return <g>{rows}</g>;
  };

  const renderTriangle = () => {
    return (
      <path
        transform={`translate(${xCenterForTriangle - half(triangleWidth)},${
          half(height) - triangleMargin
        })`}
        d="M5.96478 7.7696C6.02441 7.84066 6.10421 7.89875 6.19729 7.93889C6.29038 7.97903 6.39396 8 6.49912 8C6.60428 8 6.70786 7.97903 6.80094 7.93889C6.89403 7.89875 6.97382 7.84066 7.03346 7.7696L12.8839 0.836743C12.9516 0.756778 12.9913 0.663118 12.9987 0.565938C13.0061 0.468757 12.9809 0.371773 12.9258 0.285524C12.8708 0.199274 12.7879 0.127057 12.6863 0.0767191C12.5847 0.0263811 12.4683 -0.000152706 12.3496 6.61103e-07H0.648661C0.53025 0.000401918 0.414212 0.027277 0.313027 0.0777355C0.211842 0.128194 0.129337 0.200327 0.0743855 0.286377C0.019434 0.372427 -0.00588502 0.469139 0.00115111 0.566112C0.00818723 0.663084 0.0473123 0.756648 0.114319 0.836743L5.96478 7.7696Z"
        pointerEvents="none"
        fill="black"
      />
    );
  };

  const renderRest = (index) => {
    return (
      <rect
        key={index}
        x={xPositionForRestAtIndex(index)}
        y={yPositionForRest}
        width={restDrawSize}
        height={restDrawSize}
        rx={restDrawRadius}
        fill="#dadada"
        stroke="black"
        strokeWidth={restStrokeWidth}
        pointerEvents="none"
      />
    );
  };

  const renderNote = (index, note) => {
    return (
      <g key={index}>
        <rect
          x={xPositionForNoteAtIndex(index)}
          y={yPositionForNote(note)}
          width={noteDrawSize}
          height={noteDrawSize}
          rx={noteDrawRadius}
          fill={colorForNote(note)}
          stroke="black"
          strokeWidth="2"
          pointerEvents="none"
        />
        <text
          x={xPositionForNoteAtIndex(index) + noteDrawRadius}
          y={yPositionForNote(note) + noteDrawRadius}
          style={{ userSelect: "none" }}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
          pointerEvents="none"
        >
          {showNoteName ? note.toUpperCase() : ""}
        </text>
      </g>
    );
  };

  const initialDragState = {
    dragStarted: false,
    didDrag: false,
  };

  const [dragState, setDragState] = useState(initialDragState);

  const calculateNoteFromEventPosition = (evt) => {
    const e = evt.target;
    const dim = e.getBoundingClientRect();
    const x = evt.clientX - dim.left;
    const y = evt.clientY - dim.top;
    const column = Math.floor((x - margin) / columnWidth);
    const noteIndex =
      notes.length - 1 - Math.floor((y - margin) / noteIntervalHeight);
    if (noteIndex < 0) {
      return [column, "-"];
    } else {
      return [column, notes[noteIndex]];
    }
  };

  const inCurrentNote = (evt, note) => {
    const e = evt.target;
    const dim = e.getBoundingClientRect();
    const y = evt.clientY - dim.top;
    const currentCenter = yCenterForNote(note);
    const currentBounds = {
      top: currentCenter - noteDrawRadius,
      bottom: currentCenter + noteDrawRadius,
    };
    return y >= currentBounds.top && y <= currentBounds.bottom;
  };

  const setNote = (column, note) => {
    if (column >= 0 && column < melody.length && note) {
      onMelodyChanged(melody.replaceAt(column, note));
      if (note != "-") {
        playNote(note);
      }
    }
  };

  const startDragging = (evt) => {
    const [column, note] = calculateNoteFromEventPosition(evt);
    setDragState({
      dragStarted: true,
      startColumn: column,
      startNote: note,
      didDrag: false,
    });
  };

  const dragged = (evt) => {
    if (dragState.dragStarted) {
      const note = calculateNoteFromEventPosition(evt)[1];
      if (dragState.didDrag || note != dragState.startNote) {
        if (!dragState.didDrag) {
          setDragState({ ...dragState, didDrag: true });
        }
        const currentNote = melody[dragState.startColumn];
        if (note != currentNote) {
          setNote(dragState.startColumn, note);
        }
      }
    }
  };

  const endDragging = (evt) => {
    if (dragState.dragStarted) {
      const note = calculateNoteFromEventPosition(evt)[1];
      const currentNote = melody[dragState.startColumn];
      const clicked = !dragState.didDrag;
      if (clicked) {
        if (inCurrentNote(evt, currentNote)) {
          setNote(dragState.startColumn, currentNote);
        } else {
          setNote(dragState.startColumn, note);
        }
      } else {
        if (note != currentNote) {
          setNote(dragState.startColumn, note);
        }
      }
      setDragState(initialDragState);
    }
  };

  const decodeTouchEvent = (evt) => {
    var touch = evt.changedTouches[0];
    switch (evt.type) {
      case "touchstart":
        return ["mousedown", touch];
      case "touchmove":
        return ["mousemove", touch];
      case "touchend":
        return ["mouseup", touch];
    }
  };

  const onTouch = (evt) => {
    const multitouchEvent = evt.touches.length > 1;
    const multitouchLiftedFinger =
      evt.type == "touchend" && evt.touches.length > 0;

    if (multitouchEvent || multitouchLiftedFinger) {
      return;
    }
    const newEvent = document.createEvent("MouseEvents");
    const [type, touch] = decodeTouchEvent(evt);

    newEvent.initMouseEvent(
      type,
      true,
      true,
      evt.target.ownerDocument.defaultView,
      0,
      touch.screenX,
      touch.screenY,
      touch.clientX,
      touch.clientY,
      evt.ctrlKey,
      evt.altKey,
      evt.shiftKey,
      evt.metaKey,
      0,
      null
    );
    evt.target.dispatchEvent(newEvent);
  };

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        styles.stave,
        onMelodyChanged ? "blocklyDraggable" : ""
      )}
      onMouseDown={onMelodyChanged && startDragging}
      onMouseMove={onMelodyChanged && dragged}
      onMouseUp={onMelodyChanged && endDragging}
      onMouseLeave={onMelodyChanged && endDragging}
      onTouchStart={onMelodyChanged && onTouch}
      onTouchMove={onMelodyChanged && onTouch}
      onTouchEnd={onMelodyChanged && onTouch}
    >
      <rect width={width} height={height} rx="5" stroke="black" fill="white" />
      {renderStave()}
      {rightInset && renderTriangle()}
      {melody
        .split("")
        .map((note, index) =>
          note == "-" ? renderRest(index) : renderNote(index, note)
        )}
    </svg>
  );
};

Stave.propTypes = {
  notes: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  renderDetail: PropTypes.bool,
  melody: PropTypes.string,
  onMelodyChanged: PropTypes.func,
  playNote: PropTypes.func,
};

export default Stave;
