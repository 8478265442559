import Workspace from "workspace";
import Layout from "layout";
import Button from "button";
import SEO from "seo";
import Runner from "runner";
import Avatar from "visualisations/avatar";
import Interpreter from "interpreter";
import MyDataContext from "my_data_context";
import BlocklyHelpers from "blockly_helpers";
import SharingPrompt from "activity/components/sharing_prompt";
import RemixingConfirmation from "activity/components/remixing_confirmation";
import Overlay from "overlay";
import Label from "label";
import RemixIcon from "assets/remix_icon.svg";
import ShareIcon from "assets/share_icon.svg";
import getActivityKey from "activity_key";
import Analytics from "analytics";

import styles from "./styles.module.scss";

const FALLBACK_CODE = "visualisation.startRun();visualisation.playNote(0, 0);";

const GalleryActivityPage = () => {
  const routes = useRoutes();
  const { activities, gallery_submission, user } = useContext(MyDataContext);
  const {
    id: gallerySubmissionId,
    xml,
    seed,
    activity_id,
    name,
    author,
    remix_count: remixCount,
    avatar_workspace: submissionAvatarWorkspace,
    featured_at: featuredAt,
  } = gallery_submission;

  const blocksChannel = useMemo(() => {
    const [deprecated, code] = BlocklyHelpers.generateCode(xml);
    return {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
  }, [xml]);

  const activityKey = getActivityKey(activities, activity_id);
  blocksChannel.initialXml = xml;
  blocksChannel.seed = seed || Math.random().toString();

  const fakeWorkspace = { id: gallerySubmissionId, seed, xml };
  const avatarWorkspace =
    activityKey === "avatar" ? fakeWorkspace : submissionAvatarWorkspace;

  const visualisationChannel = useMemo(() => {
    return { avatarWorkspace, onReady: () => {}, onStyled: () => {} };
  }, [avatarWorkspace]);

  useEffect(() => {
    if (activityKey !== "avatar") return;
    const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
    interpreter.run();
  }, [activityKey, blocksChannel, visualisationChannel]);

  const [overlayState, setOverlayState] = useState();
  const overlayStates = {
    remixing: "Remixing",
    copyLink: "CopyLink",
  };

  const overlay = () => {
    switch (overlayState) {
      case overlayStates.copyLink:
        return (
          <SharingPrompt
            url={routes.galleryActivityUrl({ id: gallerySubmissionId })}
            dismissText={`Back to ${name}`}
            onDismiss={() => setOverlayState(null)}
          />
        );
      case overlayStates.remixing:
        return <RemixingConfirmation onConfirm={handleStartRemixing} />;
      default:
        return null;
    }
  };

  const handleStartRemixing = () => {
    setOverlayState(null);
    Workspace.create(activityKey, {
      xml,
      seed,
      remixedFrom: gallery_submission,
    }).then((savedWorkspace) => {
      if (savedWorkspace.is_remix) {
        const workspacePath = routes.workspacePath({ id: savedWorkspace.id });
        window.location.assign(workspacePath);
      }
    });
  };

  const activityLabels = Label({ remixCount });

  useEffect(() => {
    Analytics.galleryActivityEvent(
      activityKey,
      gallerySubmissionId,
      !!featuredAt
    );
  }, [gallerySubmissionId, activityKey, featuredAt]);

  return (
    <>
      <SEO title={`${name} by ${author} - Code Club World`} description="" />
      <Layout invertBackgroundColours={true} hideFeedbackBar={true}>
        {overlayState && <Overlay>{overlay()}</Overlay>}
        {!user.logged_in && (
          <div className={styles.cta_container}>
            <div className={styles.cta}>
              <p>Code Club World helps kids learn to code.</p>

              <Button.RectangleTertiary
                href={routes.rootPath()}
                text="Find out more"
                outline={true}
                transparent={true}
              />
            </div>
          </div>
        )}
        <div className={styles.gallery_submission_container}>
          <div className={styles.left_column}>
            <div className={styles.metadata_right}>
              <h1>{name}</h1>
              <p>by {author}</p>
            </div>
            {activityLabels && (
              <div className={styles.labels}>{activityLabels}</div>
            )}
            <div className={styles.remix_box}>
              <p>
                Try remixing this project to see how it was made and make it
                your own!
              </p>
            </div>
            <div className={styles.remix_buttons}>
              <Button.RectangleTertiary
                icon={<RemixIcon />}
                text="Remix"
                onClick={() => setOverlayState(overlayStates.remixing)}
                textAlways={true}
                className={styles.link_button}
              ></Button.RectangleTertiary>
              <Button.RectangleTertiary
                icon={<ShareIcon />}
                text="Sharing"
                onClick={() => setOverlayState(overlayStates.copyLink)}
                textAlways={true}
                className={styles.link_button}
              ></Button.RectangleTertiary>
            </div>
          </div>
          <div className={styles.right_column}>
            <div className={styles.metadata_left}>
              <h1>{name}</h1>
              <p>by {author}</p>
            </div>
            <div className={styles.runner}>
              {activityKey == "avatar" ? (
                <Avatar channel={visualisationChannel} />
              ) : (
                <Runner
                  beforeRun={() => {
                    true;
                  }}
                  fullscreen={false}
                  noFullscreen={true}
                  onFullscreen={() => true}
                  runOnPageLoad={true}
                  activityKey={activityKey}
                  visualisationChannel={visualisationChannel}
                  blocksChannel={blocksChannel}
                  iterateRun={true}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default GalleryActivityPage;
