export default {
  readOnly: false,
  trashcan: false,
  media: "/media/",
  move: {
    scrollbars: true,
    drag: true,
  },
  zoom: {
    pinch: true,
    wheel: true,
  },
  theme: "RpfTheme",
  renderer: "zelos",
};
