import Blockly from "blockly";

Blockly.Blocks.BoomboxClicked = {
  message0: `when boombox clicked`,
  nextStatement: "BoomboxClick",
  style: "rpf_start_blocks",
};

Blockly.Blocks.BoomboxClicked.init = function () {
  this.jsonInit(Blockly.Blocks.BoomboxClicked);
};

Blockly.JavaScript.BoomboxClicked = function () {
  return "";
};
