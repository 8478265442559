const ExpandIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9361 14.1485C10.7966 14.0079 10.6307 13.8963 10.448 13.8202C10.2652 13.744 10.0691 13.7048 9.87109 13.7048C9.67308 13.7048 9.47702 13.744 9.29423 13.8202C9.11144 13.8963 8.94554 14.0079 8.80609 14.1485L3.87109 19.0685L3.87109 15.2135C3.87109 14.8157 3.71306 14.4342 3.43175 14.1529C3.15045 13.8716 2.76892 13.7135 2.37109 13.7135C1.97327 13.7135 1.59174 13.8716 1.31043 14.1529C1.02913 14.4342 0.871094 14.8157 0.871094 15.2135L0.871094 22.7135C0.871094 23.1114 1.02913 23.4929 1.31043 23.7742C1.59174 24.0555 1.97327 24.2135 2.37109 24.2135H9.87109C10.2689 24.2135 10.6504 24.0555 10.9318 23.7742C11.2131 23.4929 11.3711 23.1114 11.3711 22.7135C11.3711 22.3157 11.2131 21.9342 10.9318 21.6529C10.6504 21.3716 10.2689 21.2135 9.87109 21.2135H6.00109L10.9361 16.2785C11.0767 16.1391 11.1883 15.9732 11.2644 15.7904C11.3406 15.6076 11.3798 15.4115 11.3798 15.2135C11.3798 15.0155 11.3406 14.8195 11.2644 14.6367C11.1883 14.4539 11.0767 14.288 10.9361 14.1485Z"
        fill="black"
      />
      <path
        d="M24.4317 0.652841C24.713 0.934146 24.871 1.31568 24.871 1.7135V9.2135C24.871 9.61133 24.713 9.99286 24.4317 10.2742C24.1504 10.5555 23.7688 10.7135 23.371 10.7135C22.9732 10.7135 22.5916 10.5555 22.3103 10.2742C22.029 9.99286 21.871 9.61133 21.871 9.2135V5.3435L16.936 10.2785C16.7966 10.4191 16.6307 10.5307 16.4479 10.6068C16.2651 10.683 16.069 10.7222 15.871 10.7222C15.673 10.7222 15.4769 10.683 15.2941 10.6068C15.1113 10.5307 14.9454 10.4191 14.806 10.2785C14.6654 10.1391 14.5538 9.97316 14.4777 9.79037C14.4015 9.60758 14.3623 9.41152 14.3623 9.2135C14.3623 9.01548 14.4015 8.81942 14.4777 8.63664C14.5538 8.45385 14.6654 8.28795 14.806 8.1485L19.726 3.2135L15.871 3.2135C15.4732 3.2135 15.0916 3.05547 14.8103 2.77416C14.529 2.49286 14.371 2.11133 14.371 1.7135C14.371 1.31568 14.529 0.934146 14.8103 0.652841C15.0916 0.371537 15.4732 0.213501 15.871 0.213501L23.371 0.213501C23.7688 0.213501 24.1504 0.371537 24.4317 0.652841Z"
        fill="black"
      />
    </svg>
  );
};

export default ExpandIcon;
