import axios from "axios";
import { Drums } from "audio_samples";
import { SampleUrls } from "audio_samples";

class SampleLoadingError extends Error {
  constructor(message) {
    super(message);
    this.name = "SampleLoadingError";
  }
}

const wait = (ms) => new Promise((r) => setTimeout(r, ms));

const retryIfTestFails = (promiseFunction, test, maxTries, delay = 4000) => {
  return new Promise((resolve, reject) => {
    return promiseFunction()
      .then(test)
      .then(resolve)
      .catch((reason) => {
        if (reason instanceof SampleLoadingError && maxTries > 0) {
          console.error(reason); // eslint-disable-line no-console
          return wait(delay)
            .then(
              retryIfTestFails.bind(
                null,
                promiseFunction,
                test,
                maxTries - 1,
                delay
              )
            )
            .then(resolve)
            .catch(reject);
        } else {
          reject(reason);
        }
      });
  });
};

class AudioEngine {
  constructor(audioContext) {
    this.context = audioContext;
    this.analyser = this.context.createAnalyser();
    this.sourceNodes = [];
    this.analyser.fftSize = 32;
    this.analyser.connect(this.context.destination);
  }

  drumSampleBuffer(drum) {
    const drumIndex = Math.round(Math.abs(drum)) % Drums.length;
    const sampleName = Drums[drumIndex];
    return AudioEngine.sampleIndex[sampleName][0];
  }

  instrumentSampleBuffer(instrument, note) {
    return AudioEngine.sampleIndex[instrument][note];
  }

  scheduleBufferPlay(buffer, tempo, duration, startTime, onEnded) {
    const beatTime = 60 / tempo;
    const endTime = startTime + duration * beatTime;
    const source = this.context.createBufferSource();
    const gainNode = this.context.createGain();
    source.buffer = buffer;
    source.connect(gainNode);

    gainNode.connect(this.analyser);

    source.start(startTime);
    source.stop(endTime);

    source.onended = (evt) => {
      this.sourceNodes = this.sourceNodes.filter((e) => e != evt.target);
      onEnded(evt);
    };

    gainNode.gain.setValueAtTime(1, startTime);
    gainNode.gain.linearRampToValueAtTime(0, endTime);

    this.sourceNodes.push(source);

    return { source, endTime };
  }

  stopAll() {
    this.sourceNodes.forEach((node) => node.stop(0));
    this.sourceNodes = [];
  }

  finished() {
    return this.sourceNodes.length == 0;
  }

  loadUrl(url) {
    return axios({
      url: url,
      method: "GET",
      responseType: "arraybuffer",
    });
  }

  randomlyFail(response) {
    if (Math.random() > 0.4) {
      throw "NOPE";
    } else {
      return response;
    }
  }

  getSampleData(sample) {
    return (
      this.loadUrl(sample.url)
        // uncomment this line to test reloading
        //.then(this.randomlyFail)
        .then((response) => this.context.decodeAudioData(response.data))
        .then((decodedData) => {
          return { ...sample, data: decodedData };
        })
        .catch((e) => {
          if (e) {
            console.warn("Sample loading error", sample.url, e); // eslint-disable-line no-console
            return { ...sample, data: null };
          }
        })
    );
  }

  loadSamples(samples) {
    const promises = samples.map((sample) => {
      if (sample.data != null) {
        return Promise.resolve(sample);
      } else {
        return this.getSampleData(sample);
      }
    });

    return Promise.all(promises).then((loadedSamples) => {
      AudioEngine.setAllSamples(loadedSamples);
    });
  }

  scheduleNote(instrument, tempo, startTime, note, duration, onStop) {
    const buffer = this.instrumentSampleBuffer(instrument, note);

    return this.scheduleBufferPlay(buffer, tempo, duration, startTime, onStop);
  }

  scheduleDrum(drum, tempo, startTime, duration, onStop) {
    const buffer = this.drumSampleBuffer(drum);

    return this.scheduleBufferPlay(buffer, tempo, duration, startTime, onStop);
  }

  getAnimationData() {
    var bufferLength = this.analyser.frequencyBinCount;
    var dataArray = new Uint8Array(bufferLength);
    this.analyser.getByteFrequencyData(dataArray);
    return dataArray;
  }

  getCurrentTime() {
    return this.context.currentTime;
  }

  static setAllSamples(samples) {
    AudioEngine.allSamples = samples;
    AudioEngine.addToSampleIndex(samples);
  }

  static getAllSamples() {
    return AudioEngine.allSamples;
  }

  static addToSampleIndex(samples) {
    samples.forEach(({ name, note, data }) => {
      AudioEngine.sampleIndex[name] = AudioEngine.sampleIndex[name] || {};
      AudioEngine.sampleIndex[name][note] = data;
    });
  }

  loadSamplesWithRetry(maxRetries, delay) {
    const loadSamples = () => {
      return this.loadSamples(AudioEngine.getAllSamples());
    };
    const checkSamplesLoaded = () => {
      const unloadedSamples = AudioEngine.getAllSamples().filter(
        (sample) => !sample.data
      );
      if (unloadedSamples.length > 0) {
        throw new SampleLoadingError(
          `${unloadedSamples.length} samples with unloaded data remain`
        );
      }
    };
    return retryIfTestFails(
      loadSamples,
      checkSamplesLoaded,
      maxRetries,
      delay
    ).catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(
        error,
        `Some samples could not be loaded after ${maxRetries} attempts`
      );
    });
  }

  init() {
    if (!AudioEngine.loader) {
      const maxRetries = 3;
      const delayBetweenRetries = 1000;
      AudioEngine.loader = this.loadSamplesWithRetry(
        maxRetries,
        delayBetweenRetries
      );
    }
    return AudioEngine.loader;
  }
}

if (AudioEngine.getAllSamples() == null) {
  AudioEngine.sampleIndex = {};
  AudioEngine.setAllSamples([...SampleUrls]);
}

export default AudioEngine;
