import Button from "button";
import Link from "link";
import Analytics from "analytics";
import MyDataContext from "my_data_context";
import CsrfContext from "csrf_context";
import styles from "./styles.module.scss";
import CommunityIcon from "assets/community_icon.svg";
import MapIcon from "assets/map_icon.svg";
import AvatarIcon from "assets/avatar_icon.svg";
import CodeClubWorldLogo from "assets/code_club_world_logo.svg";
import SavedStateIndicator from "saved_state_indicator";

const Navigation = ({ hideNavigationLinks = false, savedState = null }) => {
  const { user } = useContext(MyDataContext);
  const routes = useRoutes();
  const csrfToken = useContext(CsrfContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useResize(() => setWindowWidth(window.innerWidth), []);
  const isNarrowWindow = () => windowWidth <= 768;

  return (
    <div className={styles.navigation_bar}>
      <div className={styles.left}>
        <a className={styles.logo} href="/">
          <CodeClubWorldLogo scale={0.84} />
        </a>
      </div>
      {!hideNavigationLinks && <SectionNavigation />}
      <div className={styles.right}>
        <SavedStateIndicator state={savedState} iconOnly={isNarrowWindow()} />
        {!user.logged_in && (
          <>
            <form action={routes.loginPath()} method="post">
              <input
                type="hidden"
                name="authenticity_token"
                value={csrfToken}
              />
              <Button.RectangleTertiary
                className={styles.button_log_in}
                text="Log in"
                onClick={() => Analytics.accountActivity("sign_in", "nav")}
                transparent_border={true}
              ></Button.RectangleTertiary>
            </form>
            <form action={routes.signupPath()} method="post">
              <input
                type="hidden"
                name="authenticity_token"
                value={csrfToken}
              />
              <Button.RectangleTertiary
                className={styles.button_sign_up}
                text="Sign up"
                onClick={() => Analytics.accountActivity("sign_up", "nav")}
              ></Button.RectangleTertiary>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
Navigation.propTypes = {
  hideNavigationLinks: PropTypes.bool,
  savedState: PropTypes.string,
};

const SectionNavigation = () => {
  const routes = useRoutes();
  const onIslandPage = useRouteMatch(routes.island_path);
  const onMapPage = useRouteMatch(routes.map_path);
  const map_path =
    onIslandPage || onMapPage ? routes.mapPath() : routes.mapRedirectorPath();

  return (
    <div className={styles.middle}>
      <SectionNavigation.Item
        path={routes.galleryPath()}
        icon={<CommunityIcon />}
        text="Gallery"
      />
      <SectionNavigation.Item path={map_path} icon={<MapIcon />} text="Map" />
      <SectionNavigation.Item
        path={routes.profilePath()}
        icon={<AvatarIcon />}
        text="Profile"
      />
    </div>
  );
};
SectionNavigation.Item = ({ path, icon, text }) => {
  const active = window.location.pathname == path;

  return (
    <Link
      href={path}
      className={classNames(styles.item, {
        [styles.active]: active,
      })}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{text}</div>
    </Link>
  );
};
SectionNavigation.Item.displayName = "SectionNavigation.Item";

SectionNavigation.Item.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export default Navigation;
