const DanceSpotlight = ({ className, colour, opacity }) => (
  <svg
    viewBox="0 0 142 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="44" y="228.668" width="50" height="10" fill="black" />
    <path
      d="M70.6139 8.67248e-06L77.6706 156.849L31.9108 174.371L-69 53.4622L70.6139 8.67248e-06Z"
      fill={`url(#${className})`}
      opacity={opacity}
    />
    <rect
      x="31.2651"
      y="174.083"
      width="50"
      height="50"
      transform="rotate(-20.9533 31.2651 174.083)"
      fill="black"
    />
    <rect
      x="64"
      y="233.668"
      width="50"
      height="10"
      transform="rotate(-90 64 233.668)"
      fill="black"
    />
    <defs>
      <linearGradient
        id={className}
        x1="0.806962"
        y1="26.7311"
        x2="54.0903"
        y2="165.878"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={colour} stopOpacity="0" />
        <stop offset="1" stopColor={colour} />
      </linearGradient>
    </defs>
  </svg>
);

DanceSpotlight.propTypes = {
  className: PropTypes.string,
  colour: PropTypes.string,
  opacity: PropTypes.number,
};

export default DanceSpotlight;
