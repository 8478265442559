const Splat = ({ key, x, y, size, colour }) => {
  const height = 21;
  const width = 24;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.388 2.16935C15.508 0.493714 14.3375 0.0249351 13.7373 0C10.5859 0.224416 12.3867 3.74026 11.6364 4.48831C10.7792 5.34286 10.2858 3.74026 9.01025 4.2639C7.73471 4.78753 8.78516 5.98442 8.41 6.88208C8.03484 7.77974 7.43458 8.07896 4.50834 6.88208C1.58209 5.68519 0.498326 6.62433 0.0814597 8.07896C-0.368728 9.64987 1.10805 10.8468 3.0077 10.8468C5.33369 10.8468 6.08401 10.6223 6.7593 11.894C7.43459 13.1657 6.7593 14.587 5.70885 15.5595C4.6584 16.5319 1.80719 17.28 3.38286 19.0005C4.95853 20.721 6.5342 17.9532 7.43459 16.7564C8.78516 14.961 10.9611 15.5595 10.5859 16.9808C10.1333 18.6958 10.4531 20.5714 12.2366 20.5714C13.5122 20.5714 14.4125 19.6738 14.0374 17.7288C13.4819 14.8489 16.0632 14.587 17.4889 16.2327C19.7645 18.8596 22.2159 21.3195 23.6415 18.7013C25.1837 15.869 21.3155 14.961 20.265 14.3626C19.2146 13.7642 18.1641 12.7169 17.864 11.146C17.5639 9.57506 19.1228 8.17732 20.265 7.77974C21.9843 7.1813 22.8097 5.37956 21.6907 4.2639C20.3701 2.94732 19.0395 4.16416 18.5393 4.93714C17.9391 5.76 16.4684 7.1813 15.388 6.28364C14.0374 5.16156 15.2379 4.2639 15.388 2.16935Z"
        fill={colour}
      />
    </svg>
  );
};

Splat.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Splat;
