const repeat = (n, pattern) => {
  let result = [];

  for (let i = 0; i < n; i += 1) {
    result = result.concat(pattern);
  }

  return result;
};

export default repeat;
