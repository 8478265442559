import * as Sentry from "@sentry/react";
import beforeSend from "./before_send";

const setupSentry = (env, dsn) => {
  if (env && env.length > 0 && dsn && dsn.length > 0) {
    try {
      Sentry.init({
        environment: env,
        dsn: dsn,
        beforeSend: beforeSend,
      });
    } catch (e) {
      console.error("Failed to set up Sentry. Errors won't be reported."); // eslint-disable-line no-console
      console.error(e); // eslint-disable-line no-console
    }
  }
};

export default setupSentry;
