import styles from "./styles.module.scss";

const Section = forwardRef(
  ({ title, paragraph, children, image, skinny = false }, ref) => {
    const classes = classNames(styles.section_container, {
      [styles.skinny]: skinny,
      [styles.wide]: !skinny,
    });
    return (
      <section ref={ref}>
        <div className={classes}>
          <div className={styles.section_copy}>
            {title && <h2 id={styles.title}>{title}</h2>}
          </div>
          <div className={styles.subsection}>
            <div className={styles.subsection_copy}>
              {paragraph && <p>{paragraph}</p>}
              {children && children}
            </div>
            {image && <img alt="" src={image} />}
          </div>
        </div>
      </section>
    );
  }
);

Section.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string,
  skinny: PropTypes.bool,
};

export default Section;
