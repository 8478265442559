import AppContext from "app_context";

// This hook provides access to the routes that are presented by AppDataPresenter.
// It provides helper functions to generate parameterized routes, e.g:
//
// const routes = useRoutes();
// const path = routes.newWorkspacePath();
// const url = routes.newWorkspaceUrl();
//
// By default, it interpolates params from the current URL of the page.
// Or by explicitly passing params into the helper function call:
//
// const path = routes.newWorkspacePath({ activityKey: "something-else" });

const useRoutes = () => {
  const appData = useContext(AppContext);
  const urlParams = useParams();

  const helpers = {};

  for (const [name, path] of Object.entries(appData.routes)) {
    const pathFunctionName = camelCase(name);
    const urlFunctionName = pathFunctionName.replace(/Path$/, "Url");
    helpers[pathFunctionName] = pathHelper(path, urlParams);
    helpers[urlFunctionName] = urlHelper(path, urlParams);
    helpers[name] = path;
  }

  return helpers;
};

const urlHelper = (path, urlParams) => {
  return (providedParams) => {
    const { protocol, host } = location;
    const result = pathHelper(path, urlParams)(providedParams);
    return `${protocol}//${host}${result}`;
  };
};

const pathHelper = (path, urlParams) => {
  return (providedParams) => {
    let newPath = `${path}`;

    for (const [key, value] of Object.entries(providedParams || {})) {
      newPath = newPath.replace(`:${snakeCase(key)}`, value);
    }

    for (const [key, value] of Object.entries(urlParams || {})) {
      newPath = newPath.replace(`:${snakeCase(key)}`, value);
    }

    return newPath;
  };
};

export default useRoutes;
