import BlocklyHelpers from "blockly_helpers";
import Interpreter from "interpreter";
import Runner from "runner";

const FALLBACK_CODE = "";

const ArtworkPreview = ({
  artworkWorkspace,
  playing = false,
  gallery = false,
  onFinished = null,
}) => {
  const visualisationChannel = useMemo(() => {
    return {
      onReady: () => {},
    };
  }, []);

  const blocksChannel = useMemo(() => {
    if (!artworkWorkspace) return;
    const [deprecated, code] = BlocklyHelpers.generateCode(
      artworkWorkspace.xml
    );
    return {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
  }, [artworkWorkspace]);

  useEffect(() => {
    const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
    visualisationChannel.reset();
    interpreter.run();
  }, [artworkWorkspace, visualisationChannel, blocksChannel]);

  blocksChannel.seed = Math.random().toString();

  return (
    <Runner
      beforeRun={() => {
        true;
      }}
      fullscreen={false}
      noFullscreen={true}
      onFullscreen={() => true}
      runOnPageLoad={true}
      activityKey={"art"}
      visualisationChannel={visualisationChannel}
      blocksChannel={blocksChannel}
      iterateRun={playing}
      overwriteRun={playing}
      gallery={gallery}
      uniqueIdentifier={artworkWorkspace && artworkWorkspace.id}
      onFinished={onFinished}
    />
  );
};

ArtworkPreview.propTypes = {
  artworkWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
  onFinished: PropTypes.func,
  playing: PropTypes.bool,
  gallery: PropTypes.bool,
};

export default ArtworkPreview;
