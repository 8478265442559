const Next = ({ scale = 1 }) => (
  <svg
    width={19 * scale}
    height={16 * scale}
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 7C0.947715 7 0.5 7.44772 0.5 8C0.5 8.55228 0.947715 9 1.5 9L1.5 7ZM18.2071 8.70711C18.5976 8.31658 18.5976 7.68342 18.2071 7.29289L11.8431 0.928933C11.4526 0.538409 10.8195 0.538409 10.4289 0.928933C10.0384 1.31946 10.0384 1.95262 10.4289 2.34315L16.0858 8L10.4289 13.6569C10.0384 14.0474 10.0384 14.6805 10.4289 15.0711C10.8195 15.4616 11.4526 15.4616 11.8431 15.0711L18.2071 8.70711ZM1.5 9L17.5 9L17.5 7L1.5 7L1.5 9Z"
      fill="white"
    />
  </svg>
);

Next.propTypes = {
  scale: PropTypes.number,
};

export default Next;
