import Ellipsis from "assets/ellipsis.svg";
import IconButton from "icon_button";
import defaultStyles from "./styles.module.scss";

const EllipsisMenu = ({ open = false, items = [], index, styles }) => {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const [openEllipsisMenu, setOpenEllipsisMenu] = useState(null);

  useEffect(() => {
    function handler(event) {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setOpenEllipsisMenu(null);
      }
    }
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [buttonRef, menuRef]);

  const handleClick = (index) => {
    if (openEllipsisMenu === index) {
      setOpenEllipsisMenu(null);
    } else {
      setOpenEllipsisMenu(index);
    }
  };
  return (
    <>
      <IconButton
        icon={<Ellipsis />}
        className={classNames(styles.button, {
          [styles.active]: open,
        })}
        aria-label="Menu"
        onClick={() => handleClick(index)}
      />
      {openEllipsisMenu === index ? (
        <div
          ref={menuRef}
          className={classNames(defaultStyles.menu, styles.menu)}
        >
          <ul>
            {items.map((item, idx) => {
              return <li key={idx}>{item}</li>;
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
};

EllipsisMenu.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClickOutside: PropTypes.func,
  styles: PropTypes.object,
  index: PropTypes.number,
};

export default EllipsisMenu;
