import Button from "button";
import styles from "./styles.module.scss";
import RobotSuccess from "./robot_success.png";

const SharingSuccess = ({ workspace }) => {
  return (
    <div className={styles.sharing_wrapper}>
      <img className={styles.sharing_success_robot} src={RobotSuccess} alt="" />
      <h2 className={styles.heading}>Project submitted</h2>
      <p className={styles.paragraph}>
        Once your project is approved by our team it will appear in the
        community gallery!
      </p>
      <div className={styles.sharing_buttons}>
        <Button.RectanglePrimary
          text={"Back to editor"}
          outline={true}
          href={`/activities/${workspace.activity.key}/${workspace.id}`}
        />
        <Button.RectanglePrimary
          text={"See my shared projects"}
          href={"/profile?tab=MySharedProjects"}
        />
      </div>
    </div>
  );
};

SharingSuccess.propTypes = {
  workspace: PropTypes.object,
};

export default SharingSuccess;
