import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.SetPenSize = {
  message0: "set pen size to %1",
  args0: [
    {
      type: "input_value",
      name: "size",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.SetPenSize.init = function () {
  this.jsonInit(Blockly.Blocks.SetPenSize);
};

Blockly.JavaScript.SetPenSize = function (block) {
  const size = helpers.codeForArg("size", block);

  if (typeof size === "undefined") {
    return "";
  } else {
    return `visualisation.setPenSize(${size});`;
  }
};
