const patchSounds = (workspace) => {
  const sounds = workspace.getAudioManager().SOUNDS_;

  for (const sound of Object.values(sounds)) {
    patchSound(sound);
  }
};

const patchSound = (sound) => {
  sound._play = sound.play;
  sound.play = () => {
    const playPromise = sound._play();

    if (playPromise !== undefined) {
      return playPromise.catch(() => {});
    }
  };

  sound._cloneNode = sound.cloneNode;
  sound.cloneNode = () => {
    const newNode = sound._cloneNode();
    patchSound(newNode);

    return newNode;
  };
};

export default patchSounds;
