const MusicIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1432 0H5.42566C5.19851 7.10625e-05 4.98069 0.090338 4.82007 0.250958C4.65945 0.411578 4.56918 0.629406 4.56911 0.856557V13.7435C4.25725 13.674 3.93876 13.6386 3.61925 13.6379C1.62035 13.6379 0 14.9505 0 16.5703C0 18.1898 1.62035 19.5024 3.61925 19.5024C5.61789 19.5024 7.23851 18.1898 7.23851 16.5703V4.19029H17.3306V11.2682C17.0187 11.1987 16.7003 11.1633 16.3807 11.1626C14.3818 11.1626 12.7615 12.4752 12.7615 14.095C12.7615 15.7148 14.3818 17.0271 16.3807 17.0271C18.3794 17.0271 20 15.7145 20 14.095V0.856825C19.9999 0.629603 19.9096 0.411707 19.749 0.251037C19.5883 0.0903662 19.3704 7.10625e-05 19.1432 0Z"
      fill="black"
    />
  </svg>
);

export default MusicIcon;
