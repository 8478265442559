import Quotes from "assets/quotes.svg";
import styles from "./styles.module.scss";

const Quote = ({ text, author }) => (
  <>
    <div className={styles.quote_image}>
      <img alt="" src={Quotes} />
    </div>
    <div className={styles.quote}>
      <p>{text}</p>
      <p>
        <b>{author}</b>
      </p>
    </div>
  </>
);

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

const WhatAreTheySayingSection = forwardRef((props, ref) => {
  return (
    <section ref={ref}>
      <div className={styles.section_container}>
        <div className={styles.section_copy}>
          <h2>What are parents and kids saying?</h2>
        </div>
        <div className={styles.subsection}>
          <div className={styles.subsection_copy}>
            <Quote
              text={
                "I love the concept of having islands to explore in making the journey through learning coding, it is fabulous and eye-catching."
              }
              author={"Parent"}
            />
          </div>
          <div className={styles.subsection_copy}>
            <Quote
              text={
                "I’ve not coded before, but found this really fun! … I LOVED making the robot dance. It was so much fun and made me laugh!"
              }
              author={"Learner, aged 11"}
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default WhatAreTheySayingSection;
