const WorkspacePresenter = {};

WorkspacePresenter.sortAndAugmentWithTitle = ({
  workspaces,
  titlePrefix,
  ascending = false,
}) => {
  const multiplier = ascending ? -1 : +1;
  return workspaces
    .sort(
      (a, b) => multiplier * (new Date(b.created_at) - new Date(a.created_at))
    )
    .map((workspace, i) => {
      const index = ascending ? i + 1 : workspaces.length - i;
      return Object.assign(
        { title: workspace.name || `${titlePrefix} ${index}` },
        workspace
      );
    });
};

export default WorkspacePresenter;
