import styles from "./styles.module.scss";
import IconButton from "icon_button";
import Arrow from "assets/arrow";
import Analytics from "analytics";

const HintBar = ({ hints, eventCategory }) => {
  const [activeHint, setActiveHint] = useState(0);

  const nextButtonEnabled = activeHint !== hints.length - 1;
  const backButtonEnabled = activeHint !== 0;

  const handleNext = () => {
    setActiveHint(activeHint + 1);
    Analytics.clickHint(eventCategory);
  };

  const handleBack = () => {
    setActiveHint(activeHint - 1);
    Analytics.clickHint(eventCategory);
  };

  return (
    <div className={styles.hint_wrapper}>
      <IconButton
        icon={<Arrow type="previous" />}
        aria-label="Back"
        onClick={handleBack}
        enabled={backButtonEnabled}
      />
      <p className={styles.active_hint}>{hints[activeHint]}</p>
      <IconButton
        icon={<Arrow type="next" />}
        aria-label="Forward"
        onClick={handleNext}
        enabled={nextButtonEnabled}
      />
    </div>
  );
};

HintBar.propTypes = {
  hints: PropTypes.array.isRequired,
  eventCategory: PropTypes.string.isRequired,
};

export default HintBar;
