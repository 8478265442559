const Star = ({ className }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M12.2777 1.5476C13.0385 0.0814993 15.1379 0.0876855 15.89 1.55825L16.9639 3.65775C17.4158 4.54139 18.44 4.96917 19.3862 4.6695L21.6344 3.95749C23.209 3.45877 24.6891 4.94763 24.1811 6.51933L23.4559 8.76322C23.1507 9.70764 23.5724 10.7344 24.4534 11.1915L26.5465 12.2777C28.0126 13.0385 28.0064 15.1379 26.5359 15.8901L24.4364 16.9639C23.5527 17.4159 23.1249 18.4401 23.4246 19.3863L24.1366 21.6344C24.6353 23.2091 23.1465 24.6892 21.5748 24.1812L19.3309 23.456C18.3865 23.1507 17.3597 23.5725 16.9026 24.4534L15.8164 26.5466C15.0556 28.0127 12.9562 28.0065 12.204 26.5359L11.1302 24.4364C10.6782 23.5528 9.654 23.125 8.70781 23.4247L6.45968 24.1367C4.88501 24.6354 3.4049 23.1465 3.91289 21.5748L4.63814 19.3309C4.94338 18.3865 4.52164 17.3598 3.64068 16.9026L1.54754 15.8164C0.081441 15.0556 0.0876271 12.9562 1.55819 12.2041L3.65769 11.1302C4.54133 10.6783 4.96912 9.65406 4.66944 8.70786L3.95743 6.45974C3.45872 4.88507 4.94757 3.40496 6.51928 3.91295L8.76317 4.6382C9.70758 4.94344 10.7343 4.5217 11.1915 3.64074L12.2777 1.5476Z" />
  </svg>
);

Star.propTypes = {
  className: PropTypes.string,
};

export default Star;
