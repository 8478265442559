import Badges from "assets/landing_page_grid_section_badges.svg";
import styles from "./styles.module.scss";

const EarnBadgesSection = forwardRef((props, ref) => {
  return (
    <section className={styles.section_container}>
      <div ref={ref} className={styles.section_wrapper}>
        <div className={styles.section_copy}>
          <h1>Earn Badges</h1>
          <p>
            Earn a badge for each project completed, building up your collection
            and proving your coding skills!
          </p>
        </div>

        <img alt="" src={Badges} />
      </div>
    </section>
  );
});

export default EarnBadgesSection;
