import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.SetBackgroundColour = {
  message0: "set background colour to %1",
  args0: [
    {
      type: "input_value",
      name: "colour",
      check: "Colour",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.SetBackgroundColour.init = function () {
  this.jsonInit(Blockly.Blocks.SetBackgroundColour);
};

Blockly.JavaScript.SetBackgroundColour = (block) => {
  const colour = helpers.codeForArg("colour", block);

  if (typeof colour === "undefined") {
    return "";
  } else {
    return `visualisation.setBackgroundColour("${colour}");`;
  }
};
