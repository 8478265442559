const Stop = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.85714 0H17.1429C17.9006 0 18.6273 0.301019 19.1632 0.836838C19.699 1.37266 20 2.09938 20 2.85714V17.1429C20 17.9006 19.699 18.6273 19.1632 19.1632C18.6273 19.699 17.9006 20 17.1429 20H2.85714C2.09938 20 1.37266 19.699 0.836838 19.1632C0.301019 18.6273 0 17.9006 0 17.1429V2.85714C0 2.09938 0.301019 1.37266 0.836838 0.836838C1.37266 0.301019 2.09938 0 2.85714 0Z"
      fill="black"
    />
  </svg>
);

export default Stop;
