import Api from "api";

const Progress = {};

Progress.upsert = ({ projectSlug, step, completion = "" }) => {
  return new Promise((resolve, reject) => {
    Api.post(`/projects/${projectSlug}/progress`, { step, completion })
      .then(() => resolve(true))
      .catch((error) => {
        reject(error);
      });
  });
};

export default Progress;
