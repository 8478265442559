import Button from "button";
import styles from "./styles.module.scss";
import AllIcon from "assets/all_icon.svg";
import DanceIcon from "assets/dance_icon.svg";
import MusicIcon from "assets/music_icon.svg";
import ArtIcon from "assets/art_icon.svg";
import AvatarIcon from "assets/avatar_icon.svg";
import FeaturedStar from "assets/featured_star.svg";

const ActivityFilters = ({
  buttonProps,
  featuredButtonProps,
  handleClick = () => {},
}) => {
  return (
    <div className={styles.filters}>
      <Button.RectangleSecondary
        className={styles.filter_button}
        icon={<AllIcon />}
        onClick={() => handleClick()}
        {...buttonProps()}
        text="All"
        aria-label="Show all projects"
      />
      {featuredButtonProps && (
        <Button.RectangleSecondary
          className={styles.filter_button}
          icon={<FeaturedStar />}
          onClick={() => handleClick("featured")}
          {...featuredButtonProps()}
          text="Featured"
          aria-label="Show featured projects"
        />
      )}
      <Button.RectangleSecondary
        className={styles.filter_button}
        icon={<DanceIcon />}
        onClick={() => handleClick("dance")}
        {...buttonProps("dance")}
        text="Dance"
        aria-label="Show projects related to Dance"
      />
      <Button.RectangleSecondary
        className={styles.filter_button}
        icon={<MusicIcon />}
        onClick={() => handleClick("music")}
        {...buttonProps("music")}
        text="Music"
        aria-label="Show projects related to Music"
      />
      <Button.RectangleSecondary
        className={styles.filter_button}
        icon={<ArtIcon />}
        onClick={() => handleClick("art")}
        {...buttonProps("art")}
        text="Art"
        aria-label="Show projects related to Art"
      />
      <Button.RectangleSecondary
        className={styles.filter_button}
        icon={<AvatarIcon />}
        onClick={() => handleClick("avatar")}
        {...buttonProps("avatar")}
        text="Avatar"
        aria-label="Show projects related to Avatars"
      />
    </div>
  );
};

ActivityFilters.propTypes = {
  buttonProps: PropTypes.func,
  featuredButtonProps: PropTypes.func,
  handleClick: PropTypes.func,
};

export default ActivityFilters;
