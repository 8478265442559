import styles from "./styles.module.scss";

const CardGrid = ({ children, extraClass }) => {
  return <div className={classNames(styles.grid, extraClass)}>{children}</div>;
};

export default CardGrid;

CardGrid.propTypes = {
  extraClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
