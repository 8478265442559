import StaticImages from "assets/community/share_images";
import activityFlow from "activity_flow";

const activityImages = (activityKey) => {
  if (
    activityKey in StaticImages &&
    activityFlow[activityKey].sharingChooseImage?.type === "static"
  ) {
    return StaticImages[activityKey];
  }
  return {};
};

export default activityImages;
