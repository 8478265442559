const IslandSmallLabel = ({ titleText, x, y, styles, scale = 1 }) => (
  <svg
    x={x}
    y={y}
    width={scale * 76}
    height={scale * 52}
    viewBox="0 0 76 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter={`url(#filter0_d${titleText})`}>
      <rect x="4.92188" width="67" height="44" rx="10" fill="white" />
      <text
        x="50%"
        y="45.5%"
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.island_label_title}
        // intentionally not using design system typography
        // since this text will be scaled by the SVG scaling
        style={{ fontSize: "20px" }}
      >
        {titleText}
      </text>
    </g>
    <defs>
      <filter
        id={`filter0_d${titleText}`}
        x="0.921875"
        y="0"
        width="75"
        height="52"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

IslandSmallLabel.propTypes = {
  titleText: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  styles: PropTypes.shape({
    island_label_title: PropTypes.string.isRequired,
  }).isRequired,
  scale: PropTypes.number,
};

export default IslandSmallLabel;
