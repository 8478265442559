import Analytics from "analytics";
import Layout from "layout";
import Heading from "heading";
import CardGrid from "card_grid";
import Card from "card";
import Button from "button";
import IconButton from "icon_button";
import WorkspacePreview from "workspace_preview";
import HeartIconPath from "assets/heart_icon.svg";
import Arrow from "assets/arrow";
import MyDataContext from "my_data_context";
import CsrfContext from "csrf_context";
import styles from "./styles.module.scss";
import GallerySubmission from "gallery_submission";
import Footer from "footer";
import SEO from "seo";
import ActivityFilters from "../activity_filters";
import GalleryPageAdminFilters from "../gallery_page_admin_filters";
import useAudioContext from "../../hooks/use_audio_context";
import getActivityKey from "activity_key";
import ModeratorButtons from "./moderator_buttons";
import GallerySortSelect from "../gallery_sort_select";
import isPlayable from "activity_playable";

const metaData = {
  title: {
    default: "Community Gallery - Code Club World",
    art: "Community t-shirts - Code Club World",
    avatar: "Community avatars - Code Club World",
    dance: "Community dances - Code Club World",
    music: "Community music - Code Club World",
  },
  description:
    "Share your projects with our community of learners, and remix your friends' projects!",
};

const GalleryPage = () => {
  const {
    user,
    community_gallery,
    community_gallery_pages = {},
    featured_gallery,
    activities,
  } = useContext(MyDataContext);
  const [gallery, setGallery] = useState(community_gallery);
  const [featuredGallery, setFeaturedGallery] = useState(featured_gallery);
  const [playingId, setPlayingId] = useState();
  const csrfToken = useContext(CsrfContext);
  const routes = useRoutes();
  const activityKey = new URLSearchParams(useLocation().search).get("activity");
  const stateKey = new URLSearchParams(useLocation().search).get("state");
  const sortKey = new URLSearchParams(useLocation().search).get("sort");
  const featuredFlag =
    new URLSearchParams(useLocation().search).get("featured") == "true";
  const audioContext = useAudioContext();

  const {
    current_page: currentPage = 1,
    total_pages: totalPages = 1,
    first_page: firstPage = true,
    last_page: lastPage = true,
  } = community_gallery_pages;
  const prevPage = Math.max(currentPage - 1, 1);
  const nextPage = Math.min(currentPage + 1, totalPages);

  const paginationControlClasses = classNames(styles.pagination_controls, {
    [styles.pagination_controls_left]: !firstPage && lastPage,
    [styles.pagination_controls_right]: firstPage && !lastPage,
  });

  const galleryPath = ({
    key = null,
    state = null,
    page = null,
    featured = false,
    sort = null,
  }) => {
    let path = routes.galleryPath();

    if (featured) {
      path = `${path}?featured=true`;
    } else if (key) {
      path = `${path}?activity=${key}`;
    }

    if (state) {
      path =
        key || featured ? `${path}&state=${state}` : `${path}?state=${state}`;
    }

    if (page) {
      path =
        key || featured || state
          ? `${path}&page=${page}`
          : `${path}?page=${page}`;
    }

    if (sort) {
      path =
        key || featured || state || page
          ? `${path}&sort=${sort}`
          : `${path}?sort=${sort}`;
    }

    return path;
  };

  const buttonProps = (key) => {
    return {
      active: key
        ? activityKey === key && !featuredFlag
        : !activityKey && !featuredFlag,
      outline: key
        ? activityKey !== key || featuredFlag
        : !!activityKey || featuredFlag,
      href: key
        ? galleryPath({ key, state: stateKey, sort: sortKey })
        : galleryPath({ state: stateKey, sort: sortKey }),
    };
  };

  const featuredButtonProps = () => {
    return {
      active: featuredFlag,
      outline: !featuredFlag,
      href: galleryPath({ featured: true, state: stateKey, sort: sortKey }),
    };
  };

  const stateButtonProps = (state) => {
    return {
      active: state ? stateKey === state : !stateKey,
      outline: state ? stateKey !== state : !!stateKey,
      href: state
        ? galleryPath({ key: activityKey, state, sort: sortKey })
        : galleryPath({ key: activityKey, sort: sortKey }),
    };
  };

  const setExhibitState = (id, { state, featured_at }, featuredItem) => {
    const moderationSource = featuredItem ? featuredGallery : gallery;

    const copy = moderationSource.slice();
    const exhibit = copy.find((i) => i.id === id);
    exhibit.state = state;
    exhibit.featured_at = featured_at;
    if (featuredItem) {
      setFeaturedGallery(copy);
    } else {
      setGallery(copy);
    }
  };

  const handleModeration = async (exhibit, state, featuredItem = false) => {
    if (state === "rejected") {
      await GallerySubmission.featuredGallerySubmission(exhibit.id, false).then(
        () => {
          return Promise.resolve();
        }
      );
    }

    GallerySubmission.gallerySubmission(exhibit.id, state).then(() => {
      let { id, featured_at = null } = exhibit;
      if (state === "rejected") {
        featured_at = null;
      }
      setExhibitState(id, { state, featured_at }, featuredItem);
    });
  };

  const handleFeatured = async (exhibit, featuredItem = false) => {
    const featured = !exhibit.featured_at;
    let state = exhibit.state;
    if (state === "pending") {
      await GallerySubmission.gallerySubmission(exhibit.id, "accepted").then(
        () => {
          state = "accepted";
          return Promise.resolve();
        }
      );
    }
    GallerySubmission.featuredGallerySubmission(exhibit.id, featured).then(
      (response) => {
        const {
          data: { featured_at },
        } = response;
        setExhibitState(exhibit.id, { state, featured_at }, featuredItem);
      }
    );
  };

  const handlePlay = (index, featuredItem = false) => () => {
    const playbackSources = featuredItem ? featuredGallery : gallery;
    const { id, activity, featured_at: featuredAt } = playbackSources[index];
    Analytics.galleryPlayEvent(activity, id, !!featuredAt);
    if (audioContext.current.state === "suspended") {
      audioContext.current.resume().then(() => setPlayingId(id));
    } else {
      setPlayingId(id);
    }
  };

  const handleStop = () => {
    setPlayingId(null);
  };

  const handleFinished = () => {
    setPlayingId(null);
  };

  const handleSortChange = (value) => {
    const url = galleryPath({
      key: activityKey,
      state: stateKey,
      page: currentPage,
      featured: featuredFlag,
      sort: value,
    });
    window.location.assign(url);
  };

  const handleFilterClick = (filter = "all") => {
    Analytics.galleryEvent("filter", filter);
  };

  const exhibitDate = (date) => {
    if (user.moderator) {
      const dateMatch = /([\d]+)-([\d]+)-([\d]+)/.exec(date);
      return ` on ${dateMatch[3]}/${dateMatch[2]}/${dateMatch[1]}`;
    }
    return "";
  };

  return (
    <>
      <SEO
        title={metaData.title[activityKey] || metaData.title.default}
        description={metaData.description}
      />
      <Layout invertBackgroundColours={true}>
        <Heading
          title={<h1>Gallery</h1>}
          className={styles.heading}
          backPath={false}
          invertBackgroundColours={true}
        />
        {!user.logged_in && (
          <div className={styles.join_us_container}>
            <form
              action={routes.signupPath()}
              method="post"
              className={styles.join_us_form}
            >
              <img src={HeartIconPath} alt="" />
              <h2>Join us!</h2>
              <p>Sign up to share your creations to the Community.</p>
              <input
                type="hidden"
                name="authenticity_token"
                value={csrfToken}
              />
              <Button.RectangleTertiary
                text="Create an account"
                outline={true}
                onClick={() =>
                  Analytics.accountActivity("sign_up", "community")
                }
              ></Button.RectangleTertiary>
            </form>
          </div>
        )}
        <ActivityFilters
          buttonProps={buttonProps}
          featuredButtonProps={featuredButtonProps}
          handleClick={handleFilterClick}
        />
        {user.moderator && (
          <GalleryPageAdminFilters stateButtonProps={stateButtonProps} />
        )}
        {!featuredFlag && !!featuredGallery.length && (
          <div className={styles.featured_block}>
            <h2 className={styles.featured_block_heading}>Featured projects</h2>
            <CardGrid extraClass={styles.featured_card_grid}>
              {featuredGallery.map((exhibit, index) => {
                exhibit.activity_key = getActivityKey(
                  activities,
                  exhibit.activity_id
                );
                return (
                  <Card
                    aria-labelledby="View Project"
                    className={user.moderator ? styles.moderator_card : null}
                    testId={exhibit.activity_key}
                    key={exhibit.id}
                    title={exhibit.name}
                    isFeatured={false}
                    remixCount={exhibit.remix_count}
                    subtitle={`by ${exhibit.author}${exhibitDate(
                      exhibit.created_at
                    )}`}
                    playing={exhibit.id === playingId}
                    handlePlay={
                      isPlayable(exhibit.activity_key)
                        ? handlePlay(index, true)
                        : null
                    }
                    handleStop={handleStop}
                    imageSrc={
                      <WorkspacePreview
                        activityKey={exhibit.activity_key}
                        avatarWorkspace={exhibit.avatar_workspace}
                        workspace={exhibit}
                        playing={exhibit.id === playingId}
                        onFinished={handleFinished}
                        audioContext={audioContext.current}
                        previewImage={exhibit.preview_image}
                      />
                    }
                    href={routes.galleryActivityPath({
                      activityKey: exhibit.activity_key,
                      id: exhibit.id,
                    })}
                  >
                    {user.moderator && (
                      <div className={styles.moderator_buttons_wrapper}>
                        <ModeratorButtons
                          user={user}
                          exhibit={exhibit}
                          handleModeration={handleModeration}
                          handleFeatured={handleFeatured}
                        />
                      </div>
                    )}
                  </Card>
                );
              })}
            </CardGrid>
          </div>
        )}
        <GallerySortSelect
          handleSortChange={handleSortChange}
          sortKey={sortKey}
        />
        <CardGrid extraClass={styles.main_card_grid}>
          {gallery.map((exhibit, index) => {
            exhibit.activity_key = getActivityKey(
              activities,
              exhibit.activity_id
            );
            return (
              <Card
                aria-labelledby="View Project"
                className={user.moderator ? styles.moderator_card : null}
                testId={exhibit.activity_key}
                key={exhibit.id}
                title={exhibit.name}
                subtitle={`by ${exhibit.author}${exhibitDate(
                  exhibit.created_at
                )}`}
                playing={exhibit.id === playingId}
                handlePlay={
                  isPlayable(exhibit.activity_key) ? handlePlay(index) : null
                }
                handleStop={handleStop}
                isFeatured={!!exhibit.featured_at}
                remixCount={exhibit.remix_count}
                imageSrc={
                  <WorkspacePreview
                    activityKey={exhibit.activity_key}
                    avatarWorkspace={exhibit.avatar_workspace}
                    workspace={exhibit}
                    playing={exhibit.id === playingId}
                    onFinished={handleFinished}
                    audioContext={audioContext.current}
                    previewImage={exhibit.preview_image}
                  />
                }
                href={routes.galleryActivityPath({
                  activityKey: exhibit.activity_key,
                  id: exhibit.id,
                })}
              >
                {user.moderator && (
                  <div className={styles.moderator_buttons_wrapper}>
                    <ModeratorButtons
                      user={user}
                      exhibit={exhibit}
                      handleModeration={handleModeration}
                      handleFeatured={handleFeatured}
                    />
                  </div>
                )}
              </Card>
            );
          })}
        </CardGrid>
        <div className={styles.pagination}>
          <div className={paginationControlClasses}>
            {!firstPage && (
              <>
                <IconButton
                  icon={<Arrow type="first" />}
                  aria-label="First page"
                  href={galleryPath({
                    key: activityKey,
                    state: stateKey,
                    page: 1,
                    featured: featuredFlag,
                    sort: sortKey,
                  })}
                ></IconButton>
                <IconButton
                  solid={true}
                  icon={<Arrow type="previous" />}
                  aria-label="Previous page"
                  href={galleryPath({
                    key: activityKey,
                    state: stateKey,
                    page: prevPage,
                    featured: featuredFlag,
                    sort: sortKey,
                  })}
                ></IconButton>
              </>
            )}
            <div className={styles.pagination_pages}>
              <span className={"screen_reader_only"}>Page</span> {currentPage}{" "}
              <span className={"screen_reader_only"}>of</span>{" "}
              <span aria-hidden={true}>/</span> {totalPages}
            </div>
            {!lastPage && (
              <>
                <IconButton
                  solid={true}
                  icon={<Arrow type="next" />}
                  aria-label="Next page"
                  href={galleryPath({
                    key: activityKey,
                    state: stateKey,
                    page: nextPage,
                    featured: featuredFlag,
                    sort: sortKey,
                  })}
                ></IconButton>
                <IconButton
                  icon={<Arrow type="last" />}
                  aria-label="Last page"
                  href={galleryPath({
                    key: activityKey,
                    state: stateKey,
                    page: totalPages,
                    featured: featuredFlag,
                    sort: sortKey,
                  })}
                ></IconButton>
              </>
            )}
          </div>
        </div>
        <Footer />
      </Layout>
    </>
  );
};

export default GalleryPage;
