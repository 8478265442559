import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.MoveToPoseNBeats = {
  message0: "move to pose %1 over %2 beats",
  args0: [
    {
      type: "input_value",
      name: "pose",
      check: "Pose",
    },
    {
      type: "input_value",
      name: "duration",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.MoveToPoseNBeats.init = function () {
  this.jsonInit(Blockly.Blocks.MoveToPoseNBeats);
};

Blockly.JavaScript.MoveToPoseNBeats = function (block) {
  const pose = helpers.codeForArg("pose", block);
  const duration = helpers.codeForArg("duration", block);

  if (typeof duration === "undefined" || typeof pose === "undefined") {
    return "";
  } else {
    return `visualisation.moveToPose("${pose}", ${duration});`;
  }
};
