const XMark = ({ size = 18, skinny = false }) => {
  if (skinny) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.9999 13.6004L7.1999 18.4004C6.98324 18.6171 6.71657 18.7254 6.3999 18.7254C6.08324 18.7254 5.81657 18.6171 5.5999 18.4004C5.38324 18.1837 5.2749 17.9171 5.2749 17.6004C5.2749 17.2837 5.38324 17.0171 5.5999 16.8004L10.3999 12.0004L5.5999 7.20039C5.38324 6.98372 5.2749 6.71706 5.2749 6.40039C5.2749 6.08372 5.38324 5.81706 5.5999 5.60039C5.81657 5.38372 6.08324 5.27539 6.3999 5.27539C6.71657 5.27539 6.98324 5.38372 7.1999 5.60039L11.9999 10.4004L16.7999 5.60039C17.0166 5.38372 17.2832 5.27539 17.5999 5.27539C17.9166 5.27539 18.1832 5.38372 18.3999 5.60039C18.6166 5.81706 18.7249 6.08372 18.7249 6.40039C18.7249 6.71706 18.6166 6.98372 18.3999 7.20039L13.5999 12.0004L18.3999 16.8004C18.6166 17.0171 18.7249 17.2837 18.7249 17.6004C18.7249 17.9171 18.6166 18.1837 18.3999 18.4004C18.1832 18.6171 17.9166 18.7254 17.5999 18.7254C17.2832 18.7254 17.0166 18.6171 16.7999 18.4004L11.9999 13.6004Z" />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="white"
          d="M17.4375 2.97309L15.0269 0.5625L9 6.58941L2.97309 0.5625L0.5625 2.97309L6.58941 9L0.5625 15.0269L2.97309 17.4375L9 11.4106L15.0269 17.4375L17.4375 15.0269L11.4106 9L17.4375 2.97309Z"
        />
      </svg>
    );
  }
};

XMark.propTypes = {
  size: PropTypes.number,
  skinny: PropTypes.bool,
};

export default XMark;
