import styles from "./styles.module.scss";
import classNames from "classnames";
import Link from "link";
import IconButton from "icon_button";
import Label from "label";
import FeaturedStar from "assets/featured_star.svg";
import ReviewIcon from "assets/review.svg";
import Stop from "assets/stop.svg";
import Run from "assets/run.svg";

const Card = ({
  testId,
  title,
  subtitle,
  imageSrc,
  href,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  className,
  children,
  remixCount,
  isFeatured,
  isPending,
  playing,
  handlePlay,
  handleStop,
  childrenInline = false,
}) => {
  if (typeof imageSrc === "string") {
    imageSrc = <img src={imageSrc} alt="" />;
  }

  const cardLabel = () => {
    let icon = null;
    if (isFeatured) {
      icon = (
        <>
          <FeaturedStar className={styles.card_label_featured_icon} />
          Featured
        </>
      );
    } else if (isPending) {
      icon = (
        <>
          <ReviewIcon className={styles.card_label_review_icon} />
          Being reviewed
        </>
      );
    }
    if (icon) {
      return <span className={styles.card_label}>{icon}</span>;
    }
    return null;
  };

  return (
    <div
      className={classNames(styles.card, className, {
        card__linked: href,
      })}
      {...(testId && { "data-testid": `${testId}-card` })}
    >
      {cardLabel()}
      {href ? (
        <Link
          className={styles.title_link}
          href={href}
          {...(ariaLabel && {
            "aria-label": `${ariaLabel} link`,
          })}
          {...(ariaLabelledBy && {
            "aria-labelledby": ariaLabelledBy,
          })}
        >
          {imageSrc}
        </Link>
      ) : (
        <div className={styles.title_link}>{imageSrc}</div>
      )}

      <div className={styles.card_details}>
        {href ? (
          <Link
            className={styles.description}
            href={href}
            {...(ariaLabel && {
              "aria-label": `${ariaLabel} link`,
            })}
            {...(ariaLabelledBy && {
              "aria-labelledby": ariaLabelledBy,
            })}
          >
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </Link>
        ) : (
          <div className={styles.description}>
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        )}
        {handlePlay &&
          (playing ? (
            <IconButton
              className={styles.inner_buttons}
              contentClassName={styles.inner_buttons__icon}
              testId="stop"
              icon={<Stop />}
              onClick={handleStop}
              solid={true}
              aria-label="Stop the preview"
            />
          ) : (
            <IconButton
              className={styles.inner_buttons}
              contentClassName={styles.inner_buttons__icon}
              testId="run"
              icon={<Run />}
              onClick={handlePlay}
              solid={true}
              aria-label="Play the preview"
            />
          ))}
        {childrenInline && children}
      </div>
      {!childrenInline && children}
      <div className={styles.labels}>{Label({ remixCount })}</div>
    </div>
  );
};

Card.propTypes = {
  testId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageSrc: PropTypes.node, // TODO
  isFeatured: PropTypes.bool,
  isPending: PropTypes.bool,
  remixCount: PropTypes.number,
  href: PropTypes.string,
  "aria-label": PropTypes.string,
  "aria-labelledby": PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  playing: PropTypes.bool,
  handlePlay: PropTypes.func,
  handleStop: PropTypes.func,
  childrenInline: PropTypes.bool,
};

Card.Button = ({ className, ...rest }) => (
  <Card
    className={classNames(styles.static_card, className, {
      button_card: rest?.href,
    })}
    {...rest}
  />
);

Card.Button.displayName = "Card.Button";

Card.Button.propTypes = {
  className: PropTypes.string,
};

export default Card;
