const Raindrop = ({ key, x, y, size, colour }) => {
  const height = 24;
  const width = 18;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.7 1.3035e-08C8.8986 1.3035e-08 9.09 0.0792001 9.2304 0.2196L9.3714 0.3594C10.0524 1.0344 11.9208 2.8854 13.6752 5.2962C15.552 7.8744 17.4 11.226 17.4 14.55C17.4 17.7756 16.4406 20.1642 14.8128 21.744C13.191 23.319 10.9974 24 8.7 24C6.402 24 4.209 23.3184 2.5872 21.744C0.96 20.1648 0 17.7762 0 14.55C0 11.229 1.8078 7.9158 3.6678 5.3532C5.4018 2.9646 7.2654 1.1166 8.0088 0.3792L8.1696 0.2196C8.23926 0.149961 8.32195 0.094724 8.41296 0.0570448C8.50396 0.0193656 8.6015 -1.83503e-05 8.7 1.3035e-08Z"
        fill={colour}
      />
    </svg>
  );
};

Raindrop.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Raindrop;
