export const GallerySelection = ({
  children,
  images,
  selection,
  handleChange,
  className = "",
  nameAttribute,
}) => {
  let imgIterable = images;
  if (images.constructor === Object) {
    imgIterable = Object.keys(images);
  }
  return (
    <>
      {imgIterable.map((key, i) => (
        <li key={i}>
          <input
            type="radio"
            id={`sharingImage${i}`}
            name="image"
            value={nameAttribute ? key[nameAttribute] : key}
            onChange={handleChange}
            checked={
              nameAttribute
                ? key[nameAttribute] === selection
                : key === selection
            }
          ></input>
          <label htmlFor={`sharingImage${i}`}>
            <div className={className}>{children(key)}</div>
          </label>
        </li>
      ))}
    </>
  );
};

GallerySelection.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  images: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  selection: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  nameAttribute: PropTypes.string,
};

export default GallerySelection;
