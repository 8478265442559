import OfflineAudioEngine from "offline_audio_engine";
import BlocklyHelpers from "blockly_helpers";
import Interpreter from "interpreter";
import wireUpMusicApi from "wire_up_music_api";

const audioCommandsFromMusicWorkspace = (workspace) => {
  let playback;
  const setPlayback = (value) => {
    playback = typeof value === "function" ? value(playback) : value;
  };
  const offlineAudioEngine = new OfflineAudioEngine();

  const musicChannel = {};
  wireUpMusicApi({
    channel: musicChannel,
    playback,
    setPlayback,
    audioEngine: offlineAudioEngine,
  });

  const blocksChannel = { generateCode: () => code };
  const [, code] = BlocklyHelpers.generateCode(workspace.xml);
  const interpreter = Interpreter.create(blocksChannel, musicChannel);
  musicChannel.reset();
  interpreter.run();

  return offlineAudioEngine.getCommands();
};

export default audioCommandsFromMusicWorkspace;
