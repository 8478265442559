import activityFlow from "activity_flow";

const Activity = {};

Activity.badgeKeyFor = (nodeKey) => {
  if (nodeKey in activityFlow) {
    return `ccw-${nodeKey.replace(/_/g, "-")}-maker`;
  }
};

export default Activity;
