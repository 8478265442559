import { merge, exponentialEasing } from "./animation";
import availablePoses from "./poses";
import palette from "root/js_palette";
import useMusicActivityTunes from "use_music_activity_tunes";
import RobotOnStage from "./robot_on_stage";

const Dance = ({ channel, gallery = false }) => {
  const defaultDance = {
    scheduledPoses: [],
    currentPose: availablePoses.default,
    randomPoses: {},
    spotlights: {
      left: {
        colour: palette.tone1.yellow,
        opacity: 1,
      },
      right: {
        colour: palette.tone1.red,
        opacity: 1,
      },
    },
  };
  const defaultSong = null;

  const [dance, setDance] = useState(defaultDance);
  const [song, setSong] = useState(defaultSong);
  const [ready, setReady] = useState(true);
  const requestAnimationRef = useRef();
  const audioRef = useRef();

  const { avatarWorkspace, previewImage } = channel;

  const previewPose = Object.values(availablePoses).find(
    (p) => p.name === previewImage
  );

  const { availableSongs, useGeneratedAudio } = useMusicActivityTunes();

  channel.startMusic = (songKey) => {
    setSong(availableSongs[songKey]);
  };

  channel.reset = () => {
    channel.clear();
  };

  channel.clear = () => {
    stopAnimating();
    setDance(defaultDance);
    setSong(defaultSong);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    audioRef.current.volume = 1;
  };

  channel.onFinishedOrStopped = () => {
    channel.clear();
  };

  channel.startRun = () => {};

  channel.moveToPose = (poseKey, duration) => {
    setDance((dance) => {
      const lastPose = dance.scheduledPoses[dance.scheduledPoses.length - 1];
      const poseToAdd = { poseKey, duration, startTime: endTime(lastPose) };

      return { ...dance, scheduledPoses: [...dance.scheduledPoses, poseToAdd] };
    });
  };

  channel.holdPose = (duration) => {
    setDance((dance) => {
      const lastPose = dance.scheduledPoses[dance.scheduledPoses.length - 1];
      const poseKey = lastPose ? lastPose.poseKey : "default";

      if (duration <= 1) {
        const poseToAdd = { poseKey, duration, startTime: endTime(lastPose) };

        return {
          ...dance,
          scheduledPoses: [...dance.scheduledPoses, poseToAdd],
        };
      } else {
        const holdDuration = duration - 1;
        const moveDuration = 1;

        const holdStartTime = endTime(lastPose);
        const moveStartTime = holdStartTime + holdDuration;

        const holdPose = {
          poseKey,
          duration: holdDuration,
          startTime: holdStartTime,
        };

        const movePose = {
          poseKey,
          duration: moveDuration,
          startTime: moveStartTime,
        };

        return {
          ...dance,
          scheduledPoses: [...dance.scheduledPoses, holdPose, movePose],
        };
      }
    });
  };

  const endTime = (scheduledPose) =>
    scheduledPose ? scheduledPose.startTime + scheduledPose.duration : 0;

  const posesAtTime = (dance, time) => {
    const lastIndex = dance.scheduledPoses.length - 1;
    const lastPose = dance.scheduledPoses[lastIndex];

    const totalTime = endTime(lastPose);
    let timeLimit = 0;

    for (const [i, scheduledPose] of dance.scheduledPoses.entries()) {
      timeLimit += scheduledPose.duration;

      if (time <= timeLimit) {
        const untilPoseEnd = timeLimit - time;
        const ratioIntoPose = 1 - untilPoseEnd / scheduledPose.duration;

        const previousIndex = i === 0 ? lastIndex : i - 1;
        const previousPoseKey = dance.scheduledPoses[previousIndex].poseKey;

        const isFirstPose = time < dance.scheduledPoses[0].duration;
        const currentPoseKey = isFirstPose ? "default" : previousPoseKey;
        const currentPoseIdx = isFirstPose ? -1 : previousIndex;

        return {
          nextPose: { key: scheduledPose.poseKey, idx: i },
          currentPose: { key: currentPoseKey, idx: currentPoseIdx },
          untilPoseEnd,
          ratioIntoPose,
        };
      }
    }

    // Hold the final pose for some number of beats after the dance finishes.
    const poseToHold = lastPose ? lastPose.poseKey : "default";
    const timeElapsedAfterDance = time - totalTime;

    const secondsPerBeat = 60 / song.bpm;
    const durationOfBeats = song.fadeOutBeats * secondsPerBeat;

    return {
      nextPose: null,
      currentPose: { key: poseToHold, idx: lastIndex },
      untilPoseEnd: durationOfBeats - timeElapsedAfterDance,
      ratioIntoPose: timeElapsedAfterDance / durationOfBeats,
    };
  };

  useEffect(() => {
    channel.onReady();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const startAnimating = () => {
    requestAnimationRef.current = requestAnimationFrame(animate);
    setReady(false);
  };

  const stopAnimating = () => {
    cancelAnimationFrame(requestAnimationRef.current);
    setReady(true);
  };

  const randomPoseKey = () => {
    const poseKeys = Object.keys(availablePoses);
    const randomIdx = Math.floor(Math.random() * poseKeys.length);

    return poseKeys[randomIdx];
  };

  const lookupPose = (dance, pose) => {
    if (!pose) {
      return null;
    }

    if (pose.key === "random") {
      const lookup = dance.randomPoses;
      const lookupKey = pose.idx;

      if (!lookup[lookupKey]) {
        lookup[lookupKey] = randomPoseKey();
      }

      return availablePoses[lookup[lookupKey]];
    } else {
      return availablePoses[pose.key];
    }
  };

  const updateSpotlight = (dance, side, beatNumber) => {
    const spotlight = dance.spotlights[side];
    const pattern = song.spotlightPattern;

    spotlight.colour = pattern[beatNumber % pattern.length][side];
  };

  const fadeOutAfterDanceFinishes = (dance, { nextPose, ratioIntoPose }) => {
    if (nextPose) {
      return;
    }

    const fadedValue = Math.max(1 - ratioIntoPose, 0);

    audioRef.current.volume = fadedValue;
    dance.spotlights.left.opacity = fadedValue;
    dance.spotlights.right.opacity = fadedValue;
    if (ratioIntoPose > 1.0) {
      stopAnimating();
    }
  };

  const beatsPerSecond = (s) => s.bpm / 60;

  const elapsedBeats = (s) => {
    const elapsed = Math.max(audioRef.current.currentTime, 0);
    return elapsed * beatsPerSecond(s);
  };

  const totalBeats = (s) => {
    return audioRef.current.duration * beatsPerSecond(s);
  };

  const animate = () => {
    const jerkiness = 6.0;
    const easing = exponentialEasing(jerkiness);
    const startPose = availablePoses.default;

    setDance((dance) => {
      if (!song) {
        return dance;
      }
      if (elapsedBeats(song) >= totalBeats(song)) {
        stopAnimating();
        return defaultDance;
      }
      const newDance = { ...dance };
      const posesToMerge = posesAtTime(dance, elapsedBeats(song));

      const pose1 = lookupPose(dance, posesToMerge.currentPose) || startPose;
      const pose2 = lookupPose(dance, posesToMerge.nextPose) || pose1;

      const factor = easing(posesToMerge.ratioIntoPose);
      newDance.currentPose = merge(pose1, pose2, factor);

      const beatNumber = Math.floor(elapsedBeats(song));
      if (gallery === false) {
        updateSpotlight(dance, "left", beatNumber);
        updateSpotlight(dance, "right", beatNumber);
      }

      fadeOutAfterDanceFinishes(dance, posesToMerge);

      return newDance;
    });

    requestAnimationRef.current = requestAnimationFrame(animate);
  };

  useGeneratedAudio(song, setSong);

  useLayoutEffect(() => {
    if (song && song.path && song.filetype) {
      audioRef.current.src = song.path;
      audioRef.current.type = song.filetype;
      audioRef.current
        .play()
        .then(startAnimating)
        .catch((error) => {
          /* eslint-disable no-console */
          if (error instanceof DOMException) {
            console.log(
              "The song was paused before play actually started - see https://goo.gl/LdLk22"
            );
          } else {
            throw error;
          }
          /* eslint-enable no-console */
        });
    }
  }, [song]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready) {
      channel.reset();
      channel.onReady();
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef}>
        <source />
        Your browser does not support the audio element.
      </audio>

      <RobotOnStage
        gallery={gallery}
        avatarWorkspace={avatarWorkspace}
        currentPose={ready && previewPose ? previewPose : dance.currentPose}
        spotlights={dance.spotlights}
      />
    </>
  );
};

Dance.propTypes = {
  channel: PropTypes.object,
  gallery: PropTypes.bool,
};

export default Dance;
