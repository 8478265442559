import axios from "axios";

class ProjectAPIClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getProject(repositoryName) {
    return axios.get(`${this.baseUrl}/en/projects/${repositoryName}`);
  }

  getQuiz(repositoryName, quizName) {
    return axios.get(
      `${this.baseUrl}/en/projects/${repositoryName}/quizzes/${quizName}`
    );
  }
}

export default ProjectAPIClient;
