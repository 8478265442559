const InspirationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5.45455C13.736 5.45455 15.4008 6.14415 16.6283 7.37167C17.8558 8.59918 18.5455 10.264 18.5455 12C18.5455 14.4218 17.2255 16.5382 15.2727 17.6727V19.6364C15.2727 19.9257 15.1578 20.2032 14.9532 20.4078C14.7486 20.6123 14.4711 20.7273 14.1818 20.7273H9.81818C9.52886 20.7273 9.25138 20.6123 9.04679 20.4078C8.84221 20.2032 8.72727 19.9257 8.72727 19.6364V17.6727C6.77455 16.5382 5.45455 14.4218 5.45455 12C5.45455 10.264 6.14415 8.59918 7.37167 7.37167C8.59918 6.14415 10.264 5.45455 12 5.45455ZM14.1818 21.8182V22.9091C14.1818 23.1984 14.0669 23.4759 13.8623 23.6805C13.6577 23.8851 13.3802 24 13.0909 24H10.9091C10.6198 24 10.3423 23.8851 10.1377 23.6805C9.93312 23.4759 9.81818 23.1984 9.81818 22.9091V21.8182H14.1818ZM20.7273 10.9091H24V13.0909H20.7273V10.9091ZM0 10.9091H3.27273V13.0909H0V10.9091ZM13.0909 0V3.27273H10.9091V0H13.0909ZM4.27636 2.72727L6.6 5.06182L5.05091 6.6L2.72727 4.28727L4.27636 2.72727ZM17.4 5.05091L19.7127 2.72727L21.2727 4.28727L18.9491 6.6L17.4 5.05091Z"
      fill="black"
    />
  </svg>
);

export default InspirationIcon;
