const CollapseIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.943697 23.5563C1.08314 23.6969 1.24904 23.8085 1.43183 23.8847C1.61462 23.9608 1.81068 24 2.0087 24C2.20672 24 2.40277 23.9608 2.58556 23.8847C2.76835 23.8085 2.93425 23.6969 3.0737 23.5563L8.0087 18.6363L8.0087 22.4913C8.0087 22.8892 8.16673 23.2707 8.44804 23.552C8.72934 23.8333 9.11087 23.9913 9.5087 23.9913C9.90652 23.9913 10.2881 23.8333 10.5694 23.552C10.8507 23.2707 11.0087 22.8892 11.0087 22.4913L11.0087 14.9913C11.0087 14.5935 10.8507 14.212 10.5694 13.9307C10.2881 13.6494 9.90652 13.4913 9.5087 13.4913L2.0087 13.4913C1.61087 13.4913 1.22934 13.6494 0.948039 13.9307C0.666735 14.212 0.508698 14.5935 0.508698 14.9913C0.508698 15.3892 0.666734 15.7707 0.948039 16.052C1.22934 16.3333 1.61087 16.4913 2.0087 16.4913L5.8787 16.4913L0.943697 21.4263C0.803105 21.5658 0.691513 21.7317 0.61536 21.9145C0.539208 22.0973 0.5 22.2933 0.5 22.4913C0.5 22.6893 0.539208 22.8854 0.61536 23.0682C0.691513 23.251 0.803104 23.4169 0.943697 23.5563Z"
        fill="black"
      />
      <path
        d="M14.4305 10.0694C14.1492 9.78805 13.9912 9.40652 13.9912 9.0087L13.9912 1.5087C13.9912 1.11087 14.1492 0.729342 14.4306 0.448037C14.7119 0.166733 15.0934 0.00869669 15.4912 0.00869672C15.889 0.00869676 16.2706 0.166733 16.5519 0.448037C16.8332 0.729342 16.9912 1.11087 16.9912 1.5087L16.9912 5.3787L21.9262 0.443697C22.0657 0.303104 22.2316 0.191513 22.4143 0.11536C22.5971 0.0392073 22.7932 -1.49206e-07 22.9912 -1.31895e-07C23.1892 -1.14583e-07 23.3853 0.0392074 23.5681 0.11536C23.7509 0.191513 23.9168 0.303104 24.0562 0.443697C24.1968 0.583141 24.3084 0.749043 24.3845 0.931832C24.4607 1.11462 24.4999 1.31068 24.4999 1.5087C24.4999 1.70672 24.4607 1.90277 24.3845 2.08556C24.3084 2.26835 24.1968 2.43425 24.0562 2.5737L19.1362 7.5087L22.9912 7.5087C23.389 7.5087 23.7706 7.66673 24.0519 7.94804C24.3332 8.22934 24.4912 8.61087 24.4912 9.0087C24.4912 9.40652 24.3332 9.78805 24.0519 10.0694C23.7706 10.3507 23.389 10.5087 22.9912 10.5087L15.4912 10.5087C15.0934 10.5087 14.7119 10.3507 14.4305 10.0694Z"
        fill="black"
      />
    </svg>
  );
};

export default CollapseIcon;
