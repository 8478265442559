const ExclamationIcon = () => {
  return (
    <svg
      title="Exclamation icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12.9998C12.3167 12.9998 12.5874 12.8915 12.812 12.6748C13.0374 12.4581 13.15 12.1915 13.15 11.8748V7.9748C13.15 7.65814 13.0374 7.39581 12.812 7.18781C12.5874 6.97914 12.3167 6.8748 12 6.8748C11.6834 6.8748 11.4127 6.98314 11.188 7.1998C10.9627 7.41647 10.85 7.68314 10.85 7.9998V11.8998C10.85 12.2165 10.9627 12.4791 11.188 12.6878C11.4127 12.8958 11.6834 12.9998 12 12.9998ZM12 17.1998C12.3334 17.1998 12.6127 17.0871 12.838 16.8618C13.0627 16.6371 13.175 16.3581 13.175 16.0248C13.175 15.6915 13.0627 15.4125 12.838 15.1878C12.6127 14.9625 12.3334 14.8498 12 14.8498C11.6667 14.8498 11.3877 14.9625 11.163 15.1878C10.9377 15.4125 10.825 15.6915 10.825 16.0248C10.825 16.3581 10.9377 16.6371 11.163 16.8618C11.3877 17.0871 11.6667 17.1998 12 17.1998ZM12 22.1998C10.5834 22.1998 9.25405 21.9331 8.01205 21.3998C6.77072 20.8665 5.69172 20.1415 4.77505 19.2248C3.85838 18.3081 3.13338 17.2291 2.60005 15.9878C2.06672 14.7458 1.80005 13.4165 1.80005 11.9998C1.80005 10.5831 2.06672 9.2538 2.60005 8.0118C3.13338 6.77047 3.85838 5.69147 4.77505 4.7748C5.69172 3.85814 6.77072 3.13314 8.01205 2.5998C9.25405 2.06647 10.5834 1.7998 12 1.7998C13.4167 1.7998 14.746 2.06647 15.988 2.5998C17.2294 3.13314 18.3084 3.85814 19.225 4.7748C20.1417 5.69147 20.8667 6.77047 21.4 8.0118C21.9334 9.2538 22.2001 10.5831 22.2001 11.9998C22.2001 13.4165 21.9334 14.7458 21.4 15.9878C20.8667 17.2291 20.1417 18.3081 19.225 19.2248C18.3084 20.1415 17.2294 20.8665 15.988 21.3998C14.746 21.9331 13.4167 22.1998 12 22.1998ZM12 19.9248C14.2 19.9248 16.071 19.1541 17.613 17.6128C19.1544 16.0708 19.925 14.1998 19.925 11.9998C19.925 9.7998 19.1544 7.9288 17.613 6.3868C16.071 4.84547 14.2 4.0748 12 4.0748C9.80005 4.0748 7.92938 4.84547 6.38805 6.3868C4.84605 7.9288 4.07505 9.7998 4.07505 11.9998C4.07505 14.1998 4.84605 16.0708 6.38805 17.6128C7.92938 19.1541 9.80005 19.9248 12 19.9248Z"
        fill="black"
      />
    </svg>
  );
};

export default ExclamationIcon;
