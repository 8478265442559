const SavedStateEvent = {
  SAVING_STARTED: "saving-started",
  SAVING_TIMER_EXPIRED: "saving-timer-expired",
  SAVING_FINISHED: "saving-finished",
  JUST_SAVED_TIMER_EXPIRED: "just-saved-timer-expired",
  SAVING_ERROR: "saving-error",
};

SavedStateEvent.wrap = (onEvent, fn, args) =>
  new Promise((resolve, reject) => {
    onEvent(SavedStateEvent.SAVING_STARTED);
    fn(...args)
      .then((result) => {
        onEvent(SavedStateEvent.SAVING_FINISHED);
        resolve(result);
      })
      .catch((error) => {
        onEvent(SavedStateEvent.SAVING_ERROR);
        reject(error);
      });
  });

export default SavedStateEvent;
