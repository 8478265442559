import HomeIsland from "./islands/home_island.svg";
import ScratchIsland from "./islands/scratch_island.svg";
import PythonIsland from "./islands/python_island.svg";
import MoreIsland from "./islands/more_island.svg";
import MapBackgroundAsDataUri from "map_background/data_uri";

const Map = React.forwardRef((props, ref) => {
  const {
    windowDimensions,
    scale = 1,
    isLandscape,
    styles,
    routes,
    colour1,
    colour2,
  } = props;
  const dataUri = MapBackgroundAsDataUri(colour1, colour2);

  return (
    <svg
      width={windowDimensions.width * windowDimensions.zoom}
      height={windowDimensions.height * windowDimensions.zoom}
      viewBox={`${1300 * (0.5 - scale / 2)} ${760 * (0.5 - scale / 2)} ${
        1300 * scale
      } ${760 * scale}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.map}
      style={{ backgroundImage: dataUri }}
      ref={ref}
    >
      <a id="home-island" href={routes.islandPath({ island_name: "home" })}>
        <title>Visit Home island</title>
        <HomeIsland
          x={isLandscape ? 180 : 646}
          y={isLandscape ? 318 : 460}
          styles={styles}
        />
      </a>
      <a
        id="scratch-island"
        href={routes.islandPath({ island_name: "scratch" })}
      >
        <title>Visit Scratch island</title>
        <ScratchIsland
          x={isLandscape ? 469 : 376}
          y={isLandscape ? 80 : 277}
          styles={styles}
        />
      </a>
      <a id="python-island" href={routes.islandPath({ island_name: "python" })}>
        <title>Visit Python island</title>
        <PythonIsland
          x={isLandscape ? 678 : 670}
          y={isLandscape ? 339 : 70}
          styles={styles}
        />
      </a>
      <a id="more-island" href={routes.islandPath({ island_name: "more" })}>
        <title>Visit More island</title>
        <MoreIsland
          x={isLandscape ? 965 : 565}
          y={isLandscape ? 291 : 6}
          styles={styles}
        />
      </a>
    </svg>
  );
});

Map.propTypes = {
  windowDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    zoom: PropTypes.number,
  }),
  scale: PropTypes.number,
  isLandscape: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    map: PropTypes.string.isRequired,
  }).isRequired,
  routes: PropTypes.object,
  visitedHomeIsland: PropTypes.string,
  colour1: PropTypes.string,
  colour2: PropTypes.string,
};
Map.displayName = "Map";
export default Map;
