import styles from "./styles.module.scss";
import Button from "button";
import useBadge from "use_badge";

const StartYourJourneySection = forwardRef((props, ref) => {
  const { issued } = useBadge("ccw-avatar-maker");
  const routes = useRoutes();

  return (
    <section className={styles.section_container}>
      <div ref={ref}>
        <div className={styles.section_copy}>
          <h1>Ready to start your journey?</h1>
        </div>

        {issued ? (
          <Button.RectanglePrimary href={routes.mapPath()} text="Let's go!" />
        ) : (
          <Button.RectanglePrimary
            href={routes.newLaunchPath()}
            text="Let's go!"
          />
        )}
      </div>
    </section>
  );
});

export default StartYourJourneySection;
