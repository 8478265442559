import Blockly from "blockly/core";
import AppContext from "app_context";
import AutoSaveEvent from "auto_save_event";
import Link from "link";
import Overlay from "overlay";
import ConfirmationPrompt from "confirmation_prompt";
import BlocklyHelpers from "blockly_helpers";
import defaultXml from "./default_xml";
import defaultProps from "./default_props";
import patchSounds from "./patch_sounds";
import helpers from "./helpers";
import styles from "./styles.js";
import "./toolbox_style.scss";
import "./locale";
import "./theme";
import "./custom_blocks";
import "./block_colours";

const BlocklyVersion = "4.20201217.0";

const wireUpChannel = (channel, workspace) => {
  channel.generateCode = () => {
    return Blockly.JavaScript.workspaceToCode(workspace);
  };

  channel.setStatementPrefix = (prefix) => {
    return (Blockly.JavaScript.STATEMENT_PREFIX = prefix);
  };

  channel.highlightBlock = (id) => {
    return workspace.highlightBlock(id);
  };

  channel.generateWorkspaceXml = () => {
    const dom = Blockly.Xml.workspaceToDom(workspace);
    return Blockly.Xml.domToText(dom);
  };

  channel.numberOfConnectedBlocks = () => {
    return helpers.numberOfConnectedBlocks(workspace);
  };
};

const injectWorkspace = (location, toolbox, readOnly = false) => {
  const startScale = window.innerWidth < 600 ? 1 : 1.2;

  Blockly.ContextMenuRegistry.registry.unregister("blockInline");
  return Blockly.inject(location, {
    ...defaultProps,
    zoom: { ...defaultProps.zoom, startScale },
    toolbox: toolbox,
    collapse: false,
    disable: false,
    readOnly: readOnly,
    media: `https://unpkg.com/blockly@${BlocklyVersion}/media/`,
    maxInstances: {
      BoomboxClicked: 1,
      MapOpenedRun: 1,
    },
  });
};

const injectReadOnlyWorkspace = (location, toolbox) => {
  return injectWorkspace(location, toolbox, true);
};

const ensureAllButLastRunBlockDeletable = (workspace) => {
  return (event) => {
    const runBlocks = workspace.getBlocksByType("Run");
    if (event.type == Blockly.Events.BLOCK_DELETE) {
      if (runBlocks.length == 1) {
        runBlocks.forEach((b) => b.setDeletable(false));
      }
    } else if (event.type == Blockly.Events.BLOCK_CREATE) {
      if (runBlocks.length > 1) {
        runBlocks.forEach((b) => b.setDeletable(true));
      }
    }
  };
};

const addChangeListeners = (workspace, listeners) => {
  listeners.forEach((listener) => {
    workspace.addChangeListener(listener);
  });
};

const removeChangeListeners = (workspace, listeners) => {
  listeners.forEach((listener) => {
    workspace.removeChangeListener(listener);
  });
};

const checkForFinishedLoading = (loadingRef) => {
  return (event) => {
    if (loadingRef.current) {
      if (event.type === Blockly.Events.FINISHED_LOADING) {
        loadingRef.current = false;
      }
    }
  };
};

const scheduleAutoSave = (loadingRef, debounceTimer, onAutoSaveEvent) => {
  return (event) => {
    if (!event.isUiEvent && !loadingRef.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        onAutoSaveEvent(AutoSaveEvent.REQUESTED);
      }, 1000);
    }
  };
};

const preflightXML = (xml) => {
  const dom = Blockly.Xml.textToDom(xml);
  const deprecated = BlocklyHelpers.deprecateOldBlocks(dom);
  return [deprecated, Blockly.Xml.domToText(dom)];
};

const BlockWorkspace = ({ workspace, channel, onAutoSaveEvent, xml }) => {
  const debounceTimer = useRef();
  const loadingRef = useRef(false);

  useEffect(() => wireUpChannel(channel, workspace), [channel, workspace]);

  useEffect(() => {
    const changeListeners = [
      Blockly.Events.disableOrphans,
      ensureAllButLastRunBlockDeletable(workspace),
      scheduleAutoSave(loadingRef, debounceTimer, onAutoSaveEvent),
      checkForFinishedLoading(loadingRef),
    ];
    addChangeListeners(workspace, changeListeners);
    return () => removeChangeListeners(workspace, changeListeners);
  }, [workspace, onAutoSaveEvent]);

  useEffect(() => {
    return function () {
      clearTimeout(debounceTimer.current); //eslint-disable-line react-hooks/exhaustive-deps
    };
  }, []);

  useResize(() => {
    Blockly.svgResize(workspace);
  }, [workspace]);

  useEffect(() => {
    const dom = Blockly.Xml.textToDom(xml);
    loadingRef.current = true;
    Blockly.Xml.domToWorkspace(dom, workspace);
    channel.onReady();
  }, [xml, channel, workspace]);

  return null;
};

const Blocks = ({ channel = {}, onAutoSaveEvent = () => {}, children }) => {
  const appData = useContext(AppContext);
  const blocklyRef = useRef();
  const blocklyAreaRef = useRef();
  const toolboxRef = useRef();
  const [primaryWorkspace, setPrimaryWorkspace] = useState();
  const [initialXml, setInitialXml] = useState();

  useEffect(() => {
    const candidateXml = channel.initialXml || defaultXml;
    const [deprecations, filteredXml] = preflightXML(candidateXml);
    const blockDiv = blocklyRef.current;
    const toolboxDiv = toolboxRef.current;
    const workspace = deprecations
      ? injectReadOnlyWorkspace(blockDiv, toolboxDiv)
      : injectWorkspace(blockDiv, toolboxDiv);
    patchSounds(workspace);
    setInitialXml(filteredXml);
    setPrimaryWorkspace(workspace);
  }, [channel]);

  const handleSetWorkspaceXml = (event) => {
    event.preventDefault();
    const xml = event.target.elements["workspace-xml"].value;
    primaryWorkspace.clear();
    setInitialXml(xml);
  };

  const history = useHistory();

  const subText = (
    <>
      This activity uses old blocks, so it won’t work any more. Make a new
      version to see our improvements or contact{" "}
      <Link href="mailto:codeclubworld@raspberrypi.org">
        codeclubworld@raspberrypi.org
      </Link>{" "}
      for help.
    </>
  );

  return (
    <>
      {primaryWorkspace && primaryWorkspace.options.readOnly && (
        <Overlay>
          <ConfirmationPrompt
            question="Sorry!"
            subText={subText}
            confirmText="Back"
            onConfirm={history.goBack}
          />
        </Overlay>
      )}
      <div ref={blocklyAreaRef} style={styles.blocksArea}>
        <div ref={blocklyRef} style={styles.blocks} />
        <xml
          ref={toolboxRef}
          is="blockly"
          xmlns="https://developers.google.com/blockly/xml"
          style={styles.toolbox}
        >
          {children}
        </xml>
        {primaryWorkspace && (
          <BlockWorkspace
            workspace={primaryWorkspace}
            channel={channel}
            xml={initialXml}
            onAutoSaveEvent={onAutoSaveEvent}
          />
        )}
      </div>
      {appData.env.RAILS_ENV === "test" && (
        <form onSubmit={(e) => handleSetWorkspaceXml(e)}>
          <textarea name="workspace-xml" defaultValue={initialXml} />
          <button>Set Workspace XML</button>
        </form>
      )}
    </>
  );
};

Blocks.propTypes = {
  channel: PropTypes.object,
  onAutoSaveEvent: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

BlockWorkspace.propTypes = {
  channel: PropTypes.object,
  workspace: PropTypes.object,
  onAutoSaveEvent: PropTypes.func,
  xml: PropTypes.string,
};

export default Blocks;
