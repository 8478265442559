import Layout from "layout";
import Activity from "activity";
import Blocks from "blocks";
import Block from "blocks/block";
import Category from "blocks/category";
import Colours from "blocks/colours";
import SEO from "seo";
import defaultXml from "./default_xml";
import useAutoSave from "use_auto_save";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";

const DancePage = () => {
  const blocksChannel = useMemo(() => ({}), []);
  const danceChannel = {};

  const [autoSaveRequested, handleAutoSaveEvent] = useAutoSave();
  const [handleSavedStateEvent, savedState] = useSavedState();

  return (
    <>
      <SEO
        title="Robo-boogie - Code Club World"
        description="Use blocks to code a robot to dance."
      />
      <Layout savedState={savedState}>
        <Activity
          title="Robo-boogie"
          activityKey="dance"
          visualisationChannel={danceChannel}
          blocksChannel={blocksChannel}
          defaultXml={defaultXml}
          autoSaveRequested={autoSaveRequested()}
          onAutoSaveEvent={handleAutoSaveEvent}
          onSavedStateEvent={handleSavedStateEvent}
          savedState={savedState}
        >
          <Blocks channel={blocksChannel} onAutoSaveEvent={handleAutoSaveEvent}>
            <Category
              name="Poses"
              colour={Colours.teal}
              css-icon="blocklyTreeIcon iconSteps"
            >
              <Block type="MoveToPose" defaults={["arms up"]} />
              <Block type="MoveToPoseNBeats" defaults={["arms up", 1]} />
              <Block type="HoldPose" defaults={[1]} />
            </Category>
            <Category
              name="Control"
              colour={Colours.orange}
              css-icon="blocklyTreeIcon iconControl"
            >
              <Block type="controls_repeat_ext" defaults={[3]} />
            </Category>
            <Category
              name="Operators"
              colour={Colours.green}
              css-icon="blocklyTreeIcon iconOperators"
            >
              <Block type="Pose" defaults={["arms up"]} />
              <Block type="Number" defaults={[3]} />
            </Category>
          </Blocks>
        </Activity>
      </Layout>
    </>
  );
};

export default DancePage;
