import stepContentStyles from "project_page/step_content_styles.module.scss";
import BurgerMenuIcon from "assets/burger_menu_icon.svg";
import ScratchUITutorialDropdown from "../scratch_ui_tutorial_dropdown";
import ScratchUITutorialFooter from "../scratch_ui_tutorial_footer";
import Draggable from "react-draggable";
import ExpandIcon from "assets/expand_icon.svg";
import CollapseIcon from "assets/collapse_icon.svg";
import StepContentOrQuiz from "../step_content_or_quiz";
import styles from "./styles.module.scss";

const ScratchUITutorial = forwardRef((props, ref) => {
  const {
    steps,
    projectTitle,
    projectSlug,
    repositoryName,
    stepIndex,
    onStepChange,
    step,
    onExpandCollapse,
    expanded,
    classOfStepContentToBeModified,
    stepNavigationVisible,
    onStepNavigationClick,
    onStepNavigationLinkClick,
    onStepNavigationHide,
    quizCompleted,
    onQuizCompleted,
    onStartAgainClick,
    showNextButton,
    showBackButton,
    showBackToMapButton,
  } = props;

  const nodeRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const leftBounds = -window.innerWidth + 400;
  const topBounds = -100;
  const rightBounds = 500;
  const bottomBounds = window.innerHeight - 220;

  return (
    <>
      {isDragging && <div className={styles.cover} />}
      <div className={styles.draggable_wrapper}>
        <Draggable
          nodeRef={nodeRef}
          handle=".handle"
          bounds={{
            left: leftBounds,
            top: topBounds,
            right: rightBounds,
            bottom: bottomBounds,
          }}
          onStart={() => {
            setIsDragging(true);
          }}
          onStop={() => {
            setIsDragging(false);
          }}
        >
          <div className={styles.tutorial} ref={nodeRef}>
            <div
              className={classNames(styles.header, "handle", {
                [styles.header_collapsed]: !expanded,
              })}
            >
              <div className={styles.title_wrapper}>
                <h1 className={styles.noselect}>{projectTitle}</h1>
              </div>

              <div className={styles.header_button_wrapper}>
                <button
                  className={styles.header_button}
                  onClick={() => onExpandCollapse(!expanded)}
                  onTouchStart={() => onExpandCollapse(!expanded)}
                  aria-label="Minimise tutorial"
                >
                  {expanded ? (
                    <CollapseIcon aria-label={"Collapse icon"} />
                  ) : (
                    <ExpandIcon aria-label={"Expand icon"} />
                  )}
                </button>

                {expanded && (
                  <button
                    className={styles.header_button}
                    onClick={onStepNavigationClick}
                    onTouchStart={onStepNavigationClick}
                    aria-label="Project steps navigation"
                  >
                    <BurgerMenuIcon />
                  </button>
                )}
              </div>
            </div>
            {stepNavigationVisible && expanded && (
              <ScratchUITutorialDropdown
                steps={steps}
                onLinkClick={(stepIndex) => {
                  onStepChange(stepIndex);
                  onStepNavigationLinkClick();
                }}
                onHide={onStepNavigationHide}
              />
            )}
            {expanded && (
              <>
                <div className={styles.step_content_wrapper}>
                  <div
                    className={stepContentStyles.step_content_container}
                    ref={ref}
                  >
                    <div
                      className={classNames(
                        stepContentStyles.step_content,
                        stepContentStyles.inScratchOverlay
                      )}
                    >
                      <StepContentOrQuiz
                        projectSlug={projectSlug}
                        repositoryName={repositoryName}
                        step={step}
                        quizCompleted={quizCompleted}
                        onQuizCompleted={onQuizCompleted}
                        projectStyles={stepContentStyles}
                        onStartAgainClick={onStartAgainClick}
                        inScratchOverlay={true}
                      >
                        <div
                          className={classOfStepContentToBeModified}
                          dangerouslySetInnerHTML={{
                            __html: step.content,
                          }}
                        />
                      </StepContentOrQuiz>
                    </div>
                  </div>
                </div>
                <div>
                  <ScratchUITutorialFooter
                    handle="handle"
                    handleBack={() => onStepChange(stepIndex - 1)}
                    stepIndex={stepIndex}
                    numberOfSteps={steps.length}
                    handleNext={() => onStepChange(stepIndex + 1)}
                    parentClassName={"overlay_tutorial"}
                    showNextButton={showNextButton}
                    showBackButton={showBackButton}
                    showBackToMapButton={showBackToMapButton}
                  />
                </div>
              </>
            )}
          </div>
        </Draggable>
      </div>
    </>
  );
});

ScratchUITutorial.propTypes = {
  steps: PropTypes.array,
  projectTitle: PropTypes.string,
  projectSlug: PropTypes.string,
  repositoryName: PropTypes.string,
  stepIndex: PropTypes.number,
  onStepChange: PropTypes.func,
  step: PropTypes.object,
  onExpandCollapse: PropTypes.func,
  expanded: PropTypes.bool,
  stepNavigationVisible: PropTypes.bool,
  onStepNavigationClick: PropTypes.func,
  onStepNavigationLinkClick: PropTypes.func,
  onStepNavigationHide: PropTypes.func,
  quizCompleted: PropTypes.bool,
  onQuizCompleted: PropTypes.func,
  onStartAgainClick: PropTypes.func,
  showNextButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showBackToMapButton: PropTypes.bool,
};

export default ScratchUITutorial;
