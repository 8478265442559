import Map from "./map.svg";
import Layout from "layout";
import Heading from "heading";
import Button from "button";
import styles from "./styles.module.scss";
import { useRememberMapPosition } from "map_position";
import MyDataContext from "my_data_context";
import Draggable from "draggable";
import SEO from "seo";
import executeCustomiseWorkspace from "execute_customise_workspace";

const MapPage = () => {
  const routes = useRoutes();
  const draggable = useMemo(() => ({}), []);
  const mapRef = useRef();

  const { customise_workspace } = useContext(MyDataContext);
  const customiseResult = executeCustomiseWorkspace(customise_workspace) || {};

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    // an equation that scales from ~3.5 to 1
    // purely trial and error based with a little spreadsheet formula magic
    var zoom = 38 * width ** -0.416;
    return {
      width,
      height,
      zoom,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useResize(() => {
    setWindowDimensions(getWindowDimensions());
  }, []);

  useRememberMapPosition();

  useEffect(() => {
    draggable.moveToCentrePosition(
      mapRef.current.getBoundingClientRect().width / 2,
      mapRef.current.getBoundingClientRect().height / 2
    );
  }, [draggable, windowDimensions]);

  const isWideWindow = () => windowDimensions?.width > 620;

  return (
    <>
      <SEO
        title="Map - Code Club World"
        description="Explore our map to find activities and projects that teach you to make games, art, music, and websites. Learn Scratch, Python, HTML and CSS"
      />
      <Layout>
        <Heading
          transparent={true}
          invertBackgroundColours={false}
          backPath={false}
        >
          <Button.RectangleTertiary
            href={routes.customisePath()}
            text="Customise"
          />
        </Heading>
        <div className={styles.map_page}>
          <Draggable channel={draggable}>
            <Map
              windowDimensions={windowDimensions}
              scale={isWideWindow() ? 2.0 : 1.5}
              isLandscape={isWideWindow()}
              styles={styles}
              routes={routes}
              ref={mapRef}
              colour1={customiseResult.mapColour1}
              colour2={customiseResult.mapColour2}
            />
          </Draggable>
          {/* TODO Remove when Hour Of Code is up */}
          <img
            className={styles.hoc_pixel}
            src="https://code.org/api/hour/begin_rp_ccw.png"
            alt=""
          />
        </div>
      </Layout>
    </>
  );
};

MapPage.propTypes = {};

export default MapPage;
