import MyDataContext from "my_data_context";
import WorkspacePresenter from "workspace_presenter";
import BlocklyHelpers from "blockly_helpers";
import "blocks/custom_blocks/start_music_block";
import audioCommandsFromMusicWorkspace from "audio_commands_from_music_workspace";
import audioUrlFromAudioCommands from "audio_url_from_audio_commands";
import availableSongs from "visualisations/dance/songs";
import allContent from "workspaces_page/content";
import palette from "root/js_palette";
import repeat from "repeat";

const useMusicActivityTunes = () => {
  const { music_workspaces: musicWorkspaces } = useContext(MyDataContext);

  let augmentedWorkspaces = WorkspacePresenter.sortAndAugmentWithTitle({
    workspaces: musicWorkspaces,
    titlePrefix: allContent["music"].cardTitlePrefix,
    ascending: true,
  });

  BlocklyHelpers.appendTunesToStartMusicDropdown(augmentedWorkspaces);

  const tunes = {};
  augmentedWorkspaces.forEach((w) => {
    tunes[w.id] = {
      name: w.title,
      workspace: w,
      filetype: "audio/wav",
      bpm: 120,
      fadeOutBeats: 16,
      spotlightPattern: [
        ...repeat(2, [
          { left: palette.tone1.red, right: "transparent" },
          { left: "transparent", right: palette.tone1.red },
        ]),
        ...repeat(2, [
          { left: palette.tone1.yellow, right: "transparent" },
          { left: "transparent", right: palette.tone1.yellow },
        ]),
        ...repeat(2, [
          { left: palette.primary.white, right: palette.primary.white },
          { left: "transparent", right: "transparent" },
        ]),
        ...repeat(2, [
          { left: palette.tone1.red, right: palette.tone1.red },
          { left: palette.tone1.yellow, right: palette.tone1.yellow },
        ]),
      ],
    };
  });
  Object.assign(availableSongs, tunes);

  const useGeneratedAudio = (song, setSong) => {
    useEffect(() => {
      if (song && !song.path) {
        const audioCommands = audioCommandsFromMusicWorkspace(song.workspace);
        audioUrlFromAudioCommands(audioCommands).then((url) =>
          setSong((s) => ({ ...s, path: url }))
        );
      }
    }, [song, setSong]);
  };

  return { availableSongs, useGeneratedAudio };
};

export default useMusicActivityTunes;
