import Runner from "runner";
import styles from "./styles.module.scss";

const FullscreenableRunner = ({ children, ...props }) => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <>
      {fullscreen && <div className={styles.fullscreen_overlay} />}
      <div
        className={classNames(styles.right_container, {
          [styles.fullscreen]: fullscreen,
        })}
      >
        <Runner
          onFullscreen={setFullscreen}
          fullscreen={fullscreen}
          {...props}
        />
        {children}
      </div>
    </>
  );
};

FullscreenableRunner.propTypes = {
  beforeRun: PropTypes.func.isRequired,
  runOnPageLoad: PropTypes.bool,
  activityKey: PropTypes.string.isRequired,
  visualisationChannel: PropTypes.object.isRequired,
  blocksChannel: PropTypes.object.isRequired,
  maxBlocksPerSecond: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FullscreenableRunner;
