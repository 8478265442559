// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "third_party_globals";
import "application_globals";
import Root from "root";
import smoothscroll from "smoothscroll-polyfill";
import {} from "styles/styles.scss";

Root.bootstrap();
smoothscroll.polyfill();

// So that we can include a link to this image in our OpenGraph data generated in a Rails template
import {} from "assets/og_generic_image.png";
