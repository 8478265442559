const LinkIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.39864 22.1013C3.00108 22.7046 3.71679 23.1829 4.50464 23.5087C5.2925 23.8346 6.13698 24.0015 6.98956 24C7.84233 24.0015 8.68699 23.8345 9.47506 23.5087C10.2631 23.1828 10.9791 22.7046 11.5818 22.1013L15.2545 18.4272L13.4181 16.5909L9.74541 20.2649C9.01355 20.9935 8.0229 21.4025 6.99021 21.4025C5.95752 21.4025 4.96687 20.9935 4.23501 20.2649C3.50579 19.5334 3.09632 18.5426 3.09632 17.5097C3.09632 16.4768 3.50579 15.486 4.23501 14.7545L7.90905 11.0818L6.07268 9.24541L2.39864 12.9181C1.1828 14.1369 0.5 15.7882 0.5 17.5097C0.5 19.2313 1.1828 20.8825 2.39864 22.1013ZM22.6013 11.0818C23.8165 9.86267 24.4989 8.21154 24.4989 6.49021C24.4989 4.76889 23.8165 3.11776 22.6013 1.89864C21.3825 0.682805 19.7313 0 18.0097 0C16.2882 0 14.6369 0.682805 13.4181 1.89864L9.74541 5.57268L11.5818 7.40905L15.2545 3.73501C15.9864 3.00643 16.977 2.59738 18.0097 2.59738C19.0424 2.59738 20.0331 3.00643 20.7649 3.73501C21.4941 4.46653 21.9036 5.45731 21.9036 6.49021C21.9036 7.52311 21.4941 8.51389 20.7649 9.24541L17.0909 12.9181L18.9272 14.7545L22.6013 11.0818Z"
      fill="white"
    />
    <path
      d="M7.90798 18.4286L6.07031 16.5922L17.0924 5.57141L18.9288 7.40908L7.90798 18.4286Z"
      fill="white"
    />
  </svg>
);

export default LinkIcon;
