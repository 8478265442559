import styles from "./styles.module.scss";

const ColouredButton = ({
  colour = "green",
  className,
  enabled = true,
  active,
  icon,
  onClick = () => {},
  href,
  text,
  "aria-label": ariaLabel = "",
  textOnHover = false,
  textAlways = false,
}) => {
  const classes = classNames(
    styles.button,
    styles[colour],
    {
      [styles.active]: active,
      [styles.disabled]: !enabled,
      [styles.with_icon]: icon != undefined && !textAlways,
      [styles.text_on_hover]: textOnHover,
    },
    className
  );

  const iconClasses = classNames(styles.icon, {
    [styles.icon_with_text]: !textOnHover,
    [styles.icon_without_text]: textOnHover,
    [styles.icon_only]: !textOnHover && text === undefined,
  });

  const content = (
    <div className={styles.content}>
      {icon && <div className={iconClasses}>{icon}</div>}
      <span className={styles.text}>{text}</span>
    </div>
  );

  if (href) {
    return (
      <a
        className={classes}
        aria-label={ariaLabel}
        href={href}
        onClick={onClick}
      >
        {content}
      </a>
    );
  } else {
    return (
      <button
        className={classes}
        aria-label={ariaLabel}
        onClick={onClick}
        disabled={!enabled}
      >
        {content}
      </button>
    );
  }
};

ColouredButton.propTypes = {
  colour: PropTypes.string,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.element,
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textAlways: PropTypes.bool,
  "aria-label": PropTypes.string,
  textOnHover: PropTypes.bool,
};

export default ColouredButton;
