import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.RestBars = {
  message0: "rest for %1 bars",
  args0: [
    {
      type: "input_value",
      name: "duration",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.RestBars.init = function () {
  this.jsonInit(Blockly.Blocks.RestBars);
};

Blockly.JavaScript.RestBars = function (block) {
  const duration = helpers.codeForArg("duration", block);
  const beatsPerBar = 4;

  if (typeof duration === "undefined") {
    return "";
  } else {
    return `visualisation.rest(${duration * beatsPerBar});`;
  }
};
