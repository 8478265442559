import styles from "./styles.module.scss";
const GallerySortSelect = ({ handleSortChange, sortKey }) => {
  const handleOnChange = (e) => {
    const value = e.target.selectedOptions[0].value;
    handleSortChange(value);
  };

  return (
    <div className={styles.gallery_sort_select_wrapper}>
      <label className={styles.gallery_sort_select_text} htmlFor={"sort_id"}>
        Sort projects by:{" "}
      </label>
      <select
        className={styles.gallery_sort_select_primary}
        onChange={handleOnChange}
        data-testid={"test_select"}
        value={sortKey || ""}
        id={"sort_id"}
      >
        {[
          { label: "New to old", value: "new_to_old" },
          { label: "Old to new", value: "old_to_new" },
          { label: "Most remixed", value: "remix_count" },
        ].map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

GallerySortSelect.propTypes = {
  handleSortChange: PropTypes.func.isRequired,
  sortKey: PropTypes.string,
};

export default GallerySortSelect;
