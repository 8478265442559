import ShoeStyle1 from "./thumbnails/shoe-style-1.svg";
import ShoeStyle2 from "./thumbnails/shoe-style-2.svg";
import ShoeStyle3 from "./thumbnails/shoe-style-3.svg";

export const ShoeStyleThumbnails = [ShoeStyle1, ShoeStyle2, ShoeStyle3];

import GloveStyle1 from "./thumbnails/glove-style-1.svg";
import GloveStyle2 from "./thumbnails/glove-style-2.svg";
import GloveStyle3 from "./thumbnails/glove-style-3.svg";

export const GloveStyleThumbnails = [GloveStyle1, GloveStyle2, GloveStyle3];

import BodyStyle1 from "./thumbnails/body-style-1.svg";
import BodyStyle2 from "./thumbnails/body-style-2.svg";
import BodyStyle3 from "./thumbnails/body-style-3.svg";

export const BodyStyleThumbnails = [BodyStyle1, BodyStyle2, BodyStyle3];

import FaceStyle1 from "./thumbnails/face-style-1.svg";
import FaceStyle2 from "./thumbnails/face-style-2.svg";
import FaceStyle3 from "./thumbnails/face-style-3.svg";

export const FaceStyleThumbnails = [FaceStyle1, FaceStyle2, FaceStyle3];

import HeadStyle1 from "./thumbnails/head-style-1.svg";
import HeadStyle2 from "./thumbnails/head-style-2.svg";
import HeadStyle3 from "./thumbnails/head-style-3.svg";

export const HeadStyleThumbnails = [HeadStyle1, HeadStyle2, HeadStyle3];

import HeadgearStyle1 from "./thumbnails/headgear-style-1.svg";
import HeadgearStyle2 from "./thumbnails/headgear-style-2.svg";
import HeadgearStyle3 from "./thumbnails/headgear-style-3.svg";

export const HeadgearStyleThumbnails = [
  HeadgearStyle1,
  HeadgearStyle2,
  HeadgearStyle3,
];
