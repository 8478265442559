import Canvas from "./canvas";
import Robot from "assets/robot.svg";
import useRobotStyles from "use_robot_styles";

const AvatarOnly = ({ art, canvasDimensions, seed, ...avatarProps }) => {
  const displayTshirt = art.drawCommands.length > 0 || !!art.backgroundColour;
  const tShirtColour = art.backgroundColour || art.defaultBackgroundColour;
  const robotStyles = useRobotStyles();

  const canvas = (id) => (
    <Canvas
      canvasId={id}
      canvasDimensions={canvasDimensions}
      backgroundColour={tShirtColour}
      drawCommands={art.drawCommands}
      seed={seed}
      title="T-shirt artwork"
    />
  );

  return (
    <Robot
      {...robotStyles}
      displayTshirt={displayTshirt}
      tShirtColour={tShirtColour}
      {...avatarProps}
    >
      {canvas("avatar-only")}
    </Robot>
  );
};

AvatarOnly.propTypes = {
  art: PropTypes.shape({
    backgroundColour: PropTypes.string,
    defaultBackgroundColour: PropTypes.string,
    drawCommands: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  canvasDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  seed: PropTypes.string.isRequired,
};

export default AvatarOnly;
