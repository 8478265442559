const Circle = ({ key, x, y, size, colour }) => {
  const height = 24;
  const width = 24;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={colour} />
    </svg>
  );
};

Circle.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Circle;
