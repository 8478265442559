import Activity from "activity";
import Blocks from "blocks";
import Block from "blocks/block";
import Category from "blocks/category";
import Colours from "blocks/colours";
import Layout from "layout";
import SEO from "seo";
import useAutoSave from "use_auto_save";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";

const AvatarPage = () => {
  const avatarChannel = {};
  const blocksChannel = useMemo(() => ({}), []);

  const [autoSaveRequested, handleAutoSaveEvent] = useAutoSave();
  const [handleSavedStateEvent, savedState] = useSavedState();

  avatarChannel.onStyled = () => {};

  return (
    <>
      <SEO
        title="Avatar maker - Code Club World"
        description="Use blocks to code your own robot avatar."
      />
      <Layout savedState={savedState}>
        <Activity
          title="Avatar Maker"
          activityKey="avatar"
          visualisationChannel={avatarChannel}
          blocksChannel={blocksChannel}
          maxBlocksPerSecond={20}
          runOnPageLoad={true}
          autoSaveRequested={autoSaveRequested()}
          onAutoSaveEvent={handleAutoSaveEvent}
          onSavedStateEvent={handleSavedStateEvent}
          savedState={savedState}
        >
          <Blocks channel={blocksChannel} onAutoSaveEvent={handleAutoSaveEvent}>
            <Category
              name="Looks"
              colour={Colours.purple}
              css-icon="blocklyTreeIcon iconLooks"
            >
              <Block type="AvatarColour" defaults={[null, "#ffcc00"]} />
              <Block type="AvatarStyle" />
            </Category>
          </Blocks>
        </Activity>
      </Layout>
    </>
  );
};

AvatarPage.propTypes = {};

export default AvatarPage;
