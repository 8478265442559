const SavingIcon = () => (
  <svg
    width="31"
    height="21"
    viewBox="0 0 31 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.034 20.6806H7.78817C5.90449 20.6818 4.0894 19.9737 2.70389 18.6972C1.31837 17.4207 0.463955 15.6693 0.310561 13.7914C0.157167 11.9135 0.716037 10.0466 1.87602 8.56209C3.036 7.07756 4.71211 6.08413 6.57098 5.77939C7.44227 4.23369 8.70821 2.94709 10.2394 2.05105C11.7706 1.15501 13.5122 0.681668 15.2862 0.679387C17.5383 0.671156 19.7258 1.43181 21.4871 2.83564C23.2168 4.20796 24.4465 6.11185 24.9862 8.25314C26.543 8.49241 27.9521 9.31097 28.9313 10.545C29.9105 11.779 30.3877 13.3374 30.2674 14.9083C30.147 16.4792 29.4379 17.9466 28.2821 19.0169C27.1263 20.0873 25.609 20.6815 24.034 20.6806ZM15.2862 3.18064C13.9553 3.18221 12.6487 3.53727 11.4999 4.20954C10.3511 4.8818 9.40143 5.84715 8.74792 7.00689L8.16307 8.05564L6.97588 8.24939C5.74028 8.45645 4.62746 9.12006 3.85777 10.1088C3.08808 11.0976 2.71764 12.3394 2.8198 13.5884C2.92195 14.8373 3.48925 16.0024 4.40938 16.8529C5.3295 17.7033 6.53535 18.1772 7.78817 18.1806H24.034C24.979 18.1816 25.8896 17.8255 26.5834 17.1837C27.2772 16.5418 27.7031 15.6615 27.7757 14.719C27.8484 13.7765 27.5625 12.8413 26.9753 12.1006C26.3881 11.3599 25.5429 10.8684 24.6088 10.7244L22.9642 10.4744L22.5618 8.85939C22.1582 7.23561 21.2222 5.79394 19.9034 4.76459C18.5846 3.73524 16.959 3.17757 15.2862 3.18064ZM17.0983 15.6806H13.4742V11.9306H10.2875L15.2862 6.93064L20.2849 11.9306H17.0983V15.6806Z"
      fill="black"
    />
  </svg>
);

export default SavingIcon;
