// This function is called by generated code from the RandomNumber block. We
// need to implement this inside the JS Interpreter when we switch over to that.
const randomNumberBetween = (a, b, prng = Math.random) => {
  const numbers = [a, b];
  numbers.sort();

  const [low, high] = numbers;
  const range = high - low;

  if (Number.isInteger(low) && Number.isInteger(high)) {
    return Math.floor(prng() * (range + 1) + low);
  } else {
    return prng() * range + low;
  }
};

export default randomNumberBetween;
