import Blockly from "blockly";
import helpers from "blocks/helpers";
import { AvatarBodyPartOptions } from "./avatar_blocks_configuration.js";

Blockly.Blocks.AvatarColour = {
  message0: "set colour of %1 to %2",
  args0: [
    {
      type: "field_dropdown",
      name: "body_part",
      options: AvatarBodyPartOptions.concat([["everything", "all"]]),
    },
    {
      type: "input_value",
      name: "colour",
      check: "Colour",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
  tooltip: "Sets a part of the avatar to a colour",
};

Blockly.Blocks.AvatarColour.init = function () {
  this.jsonInit(Blockly.Blocks.AvatarColour);
};

Blockly.JavaScript.AvatarColour = function (block) {
  const colour = helpers.codeForArg("colour", block);
  const bodyPart = block.getFieldValue("body_part");

  if (typeof colour === "undefined") {
    return "";
  } else if (bodyPart === "all") {
    return `visualisation.setColourForAll('${colour}');\n`;
  } else {
    return `visualisation.setColour('${bodyPart}', '${colour}');\n`;
  }
};
