import Analytics from "analytics";
import Button from "button";
import MyDataContext from "my_data_context";
import CsrfContext from "csrf_context";
import styles from "./styles.module.scss";

const BadgeNotification = ({ badgeTemplate }) => {
  const { user } = useContext(MyDataContext);
  const routes = useRoutes();
  const csrfToken = useContext(CsrfContext);
  let cta, copy;

  if (user.logged_in) {
    cta = <Button.RectangleSecondary href={routes.mapPath()} text="Continue" />;
  } else {
    cta = (
      <>
        <form action={routes.signupPath()} method="post">
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Button.RectangleSecondary
            text="Create an account"
            className={styles.prompt_button}
            onClick={() =>
              Analytics.accountActivity(
                "sign_up",
                `badge_awarded/${badgeTemplate.title}`
              )
            }
          />
        </form>
        <form action={routes.loginPath()} method="post">
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Button.RectangleTertiary
            className={styles.prompt_button}
            text="Log in"
          ></Button.RectangleTertiary>
        </form>
        <Button.RectangleTertiary
          className={styles.prompt_button}
          href={routes.mapPath()}
          text="Not right now"
        ></Button.RectangleTertiary>
      </>
    );
    copy = (
      <p>
        To save your badge and avatar, <br /> create an account.
      </p>
    );
  }

  return (
    <div className={styles.badge_notification}>
      <h2>
        You earned the <br /> {badgeTemplate.title} <br /> badge!
      </h2>
      <img className={styles.badge} src={badgeTemplate.image} alt={""} />
      {copy && copy}

      {cta}
    </div>
  );
};

BadgeNotification.propTypes = {
  badgeTemplate: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default BadgeNotification;
