const MusicAudio = ({ song, songPlaying, onSongEnded = () => {} }) => {
  const audioRef = useRef();
  const loadedAudioDataRef = useRef(false);

  useLayoutEffect(() => {
    if (song && song.path && song.filetype) {
      loadedAudioDataRef.current = false;
      audioRef.current.src = song.path;
      audioRef.current.type = song.filetype;
    }
  }, [song]);

  useLayoutEffect(() => {
    audioRef.current.muted = !songPlaying;
    if (songPlaying && audioRef.current.paused && loadedAudioDataRef.current)
      audioRef.current.play();
  }, [songPlaying]);

  const handleLoadedAudioData = () => (loadedAudioDataRef.current = true);

  return (
    <audio
      ref={audioRef}
      autoPlay
      muted
      onLoadedData={handleLoadedAudioData}
      onEnded={onSongEnded}
    >
      <source />
      Your browser does not support the audio element.
    </audio>
  );
};

MusicAudio.propTypes = {
  song: PropTypes.shape({ path: PropTypes.string, filetype: PropTypes.string }),
  songPlaying: PropTypes.bool.isRequired,
  onSongEnded: PropTypes.func,
};

export default MusicAudio;
