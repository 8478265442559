import MyDataContext from "my_data_context";
import Workspace from "workspace";
import Heading from "heading";
import Overlay from "overlay";
import SharingPrompt from "./components/sharing_prompt";
import styles from "./styles.module.scss";
import IssuedBadge from "issued_badge";
import Analytics from "analytics";
import AwardNotification from "award_notification";
import FullscreenableRunner from "./components/fullscreenable_runner";
import RemixingConfirmation from "./components/remixing_confirmation";
import GetInspiredButton from "./components/get_inspired_button";
import EditNamePrompt from "./components/edit_name_prompt";
import Button from "button";
import IconButton from "icon_button";
import IconChooser from "assets/icon_chooser";
import LinkIcon from "assets/link_icon.svg";
import RemixButton from "./components/remix_button";
import ShareToGalleryMenuItem from "./components/share_to_gallery_menu_item";
import EllipsisMenu from "ellipsis_menu";
import ellipsisMenuStyles from "./ellipsis_menu_styles.module.scss";
import AutoSaveEvent from "auto_save_event";
import SavedStateEvent from "saved_state_indicator/saved_state_event";
import SavedStateIndicator from "saved_state_indicator";
import useBadge from "use_badge";
import { useEffect } from "react";
import remixed_workspace_diff from "remixed_workspace";

const ellipsesMenuBreakpoint = 1280;

const Activity = ({
  title,
  activityKey,
  visualisationChannel,
  children,
  blocksChannel,
  defaultXml,
  maxBlocksPerSecond,
  runOnPageLoad,
  autoSaveRequested = false,
  onAutoSaveEvent = () => {},
  onSavedStateEvent = () => {},
  savedState,
  backPath,
  showNavMenu = true,
}) => {
  const history = useHistory();
  const routes = useRoutes();
  const sharingMenuRef = useRef();
  const { workspaces, user } = useContext(MyDataContext);
  const [workspace, setWorkspace] = useState(workspaces[0]);

  const [awardNotification, setAwardNotification] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [workspaceName, setName] = useState(workspace.name || title);

  useResize(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const [overlayState, setOverlayState] = useState();
  const overlayStates = {
    remixing: "Remixing",
    copyLink: "CopyLink",
    editName: "EditNamePrompt",
  };

  //menu item only for logged in users
  const canShareToCommunityGallery = user.logged_in;

  const handleClick = (e) => {
    if (sharingMenuRef.current && sharingMenuRef.current.contains(e.target)) {
      return;
    }
  };

  const handleShare = () => {
    saveWorkspace();
  };

  const handleShareDismissal = () => {
    setOverlayState(null);
  };

  const handleEditName = () => {
    setOverlayState(overlayStates.editName);
  };

  const handleEditNameSave = (name) => {
    setName(name);
    saveWorkspace(name);
    setOverlayState(null);
  };

  const handleEditNameDismissal = () => {
    setOverlayState(null);
  };

  const handleStartRemixing = () => {
    setOverlayState(null);
    handleRemix(workspace);
  };

  const handleCopyLink = () => {
    setOverlayState(overlayStates.copyLink);
    Analytics.event("share_with_url", {
      event_category: "Sharing",
      event_label: title,
    });
  };

  const overlay = () => {
    switch (overlayState) {
      case overlayStates.editName:
        return (
          <EditNamePrompt
            name={workspaceName}
            onSave={handleEditNameSave}
            onDismiss={handleEditNameDismissal}
          />
        );
      case overlayStates.copyLink:
        return (
          <SharingPrompt
            url={routes.sharedWorkspaceUrl({ id: workspace.id })}
            dismissText="Back to activity"
            onDismiss={handleShareDismissal}
          />
        );
      case overlayStates.remixing:
        return <RemixingConfirmation onConfirm={handleStartRemixing} />;
      default:
        return null;
    }
  };

  const badgeSlug = `ccw-${activityKey}-maker`;
  const { badgeTemplate, issued } = useBadge(badgeSlug);
  const [badgeIssued, setBadgeIssued] = useState(issued);

  const eligibleForBadge = ({
    belongs_to_me,
    is_remix,
    numberOfConnectedBlocks,
    workspace_xml,
    original_xml,
  }) => {
    const globalBadgeChecks = !badgeIssued && belongs_to_me;
    if (is_remix) {
      const remixedDiff = remixed_workspace_diff(workspace_xml, original_xml);
      return globalBadgeChecks && remixedDiff.length >= 4;
    } else {
      return globalBadgeChecks && numberOfConnectedBlocks >= 6;
    }
  };

  const awardBadgeIfNeeded = async (data) => {
    if (badgeTemplate && eligibleForBadge(data)) {
      const created = await IssuedBadge.create(badgeTemplate);

      if (created) {
        setBadgeIssued(true);
        setAwardNotification(true);

        Analytics.badgeIssued(badgeTemplate);
      }
    }
  };

  const saveWorkspace = (name) => {
    name = name || workspaceName;
    const xml = blocksChannel.generateWorkspaceXml();
    const seed = blocksChannel.seed;
    if (workspace.is_new_record) {
      SavedStateEvent.wrap(onSavedStateEvent, Workspace.create, [
        activityKey,
        { xml, seed, name },
      ]).then(handleWorkspaceSaved);
    } else if (workspace.belongs_to_me) {
      SavedStateEvent.wrap(onSavedStateEvent, Workspace.update, [
        workspace,
        activityKey,
        { xml, seed, name },
      ]).then(handleWorkspaceSaved);
    }
  };

  blocksChannel.initialXml = workspace.xml || defaultXml;
  blocksChannel.seed = workspace.seed || Math.random().toString();

  useEffect(() => {
    if (!autoSaveRequested) return;
    onAutoSaveEvent(AutoSaveEvent.PROCESSED);
    const xml = blocksChannel.generateWorkspaceXml();

    setWorkspace((w) => {
      if (!w.is_new_record && w.belongs_to_me) {
        const seed = blocksChannel.seed;
        SavedStateEvent.wrap(onSavedStateEvent, Workspace.update, [
          w,
          activityKey,
          { xml, seed, name: w.name },
        ]);
      }
      return w;
    });
  }, [
    autoSaveRequested,
    onAutoSaveEvent,
    onSavedStateEvent,
    activityKey,
    blocksChannel,
  ]);

  const handleWorkspaceSaved = async (savedWorkspace) => {
    if (workspace.is_new_record || savedWorkspace.is_remix) {
      const workspacePath = routes.workspacePath({ id: savedWorkspace.id });
      history.replace(workspacePath);
    }

    setWorkspace(savedWorkspace);

    awardBadgeIfNeeded({
      belongs_to_me: savedWorkspace.belongs_to_me,
      is_remix: savedWorkspace.is_remix,
      numberOfConnectedBlocks: blocksChannel.numberOfConnectedBlocks(),
      workspace_xml: savedWorkspace.xml,
      original_xml: savedWorkspace.remixed_from_submission?.xml,
    });
  };

  if (!backPath) {
    const accessedViaSharedLink =
      !workspace.belongs_to_me && !workspace.gallery_submissions;
    if (accessedViaSharedLink) {
      backPath = routes.rootPath();
    } else if (workspace.belongs_to_me) {
      backPath = routes.workspacesPath({ activity_key: activityKey });
    } else if (workspace.gallery_submissions) {
      backPath = routes.galleryPath();
    }
  }

  const handleRemix = () => {
    const xml = blocksChannel.generateWorkspaceXml();
    const seed = blocksChannel.seed;
    SavedStateEvent.wrap(onSavedStateEvent, Workspace.create, [
      activityKey,
      { xml, seed, remixedFrom: workspace },
    ]).then(handleWorkspaceSaved);
  };

  const mobileMenu = () => (
    <div>
      <EllipsisMenu
        styles={ellipsisMenuStyles}
        items={[
          <Button.RectangleTertiary
            key={1}
            className={styles.link_button}
            icon={<IconChooser type={"edit"} />}
            text="Edit project name"
            onClick={handleEditName}
            textAlways={true}
          ></Button.RectangleTertiary>,
          <GetInspiredButton
            key={2}
            className={styles.link_button}
            activityKey={activityKey}
          />,
          ...(!workspace.is_new_record && workspace.belongs_to_me
            ? [
                <ShareToGalleryMenuItem
                  key={3}
                  workspace={workspace}
                  activityKey={activityKey}
                  linkClassName={styles.link_button}
                  formClassName={styles.sharing_form}
                  onShare={handleShare}
                  canShareToCommunityGallery={canShareToCommunityGallery}
                />,
                <Button.RectangleTertiary
                  key={4}
                  className={styles.link_button}
                  icon={<LinkIcon />}
                  text="Copy link"
                  onClick={handleCopyLink}
                  textAlways={true}
                ></Button.RectangleTertiary>,
              ]
            : []),
          ...(!workspace.belongs_to_me
            ? [
                <RemixButton
                  key={5}
                  className={styles.link_button}
                  onClick={() => setOverlayState(overlayStates.remixing)}
                />,
              ]
            : []),
        ]}
      ></EllipsisMenu>
    </div>
  );

  const desktopMenu = () => (
    <>
      <div className={styles.desktop_buttons}>
        <GetInspiredButton
          className={styles.link_button}
          activityKey={activityKey}
        />
      </div>
      {!workspace.is_new_record && workspace.belongs_to_me && (
        <>
          <div className={styles.desktop_buttons}>
            <ShareToGalleryMenuItem
              workspace={workspace}
              activityKey={activityKey}
              linkClassName={styles.link}
              formClassName={styles.sharing_form}
              onShare={handleShare}
              canShareToCommunityGallery={canShareToCommunityGallery}
            />
          </div>
          <div className={styles.desktop_buttons}>
            <Button.RectangleTertiary
              className={styles.link_button}
              icon={<LinkIcon />}
              text="Copy link"
              onClick={handleCopyLink}
              textAlways={true}
            ></Button.RectangleTertiary>
          </div>
        </>
      )}
      <div className={styles.desktop_buttons}>
        {!workspace.belongs_to_me && (
          <RemixButton
            className={styles.link_button}
            onClick={() => setOverlayState(overlayStates.remixing)}
          />
        )}
      </div>
    </>
  );

  const activityNavMenu = () =>
    windowWidth < ellipsesMenuBreakpoint ? mobileMenu() : desktopMenu();

  const editableName = (
    <div className={styles.editable_name_wrapper}>
      <h1 className={styles.editable_name_rendered}>{workspaceName}</h1>
      {windowWidth >= ellipsesMenuBreakpoint && (
        <IconButton
          icon={<IconChooser type={"edit"} />}
          aria-label="Edit project name"
          onClick={handleEditName}
        />
      )}
    </div>
  );
  return (
    <>
      <Heading
        title={editableName}
        backPath={backPath}
        className={styles.heading}
      >
        <div className={classNames(styles.saved_state_indicator)}>
          <SavedStateIndicator state={savedState} iconOnly={true} />
        </div>
        {showNavMenu && activityNavMenu()}

        {overlayState && <Overlay>{overlay()}</Overlay>}
      </Heading>

      {awardNotification && (
        <AwardNotification
          badge={badgeTemplate}
          onClose={() => setAwardNotification(false)}
        />
      )}

      <div
        className={styles.activity}
        onClick={handleClick}
        onKeyPress={handleClick}
        role="button"
        tabIndex="0"
      >
        <div className={styles.blockly_container}>{children}</div>
        <FullscreenableRunner
          beforeRun={saveWorkspace}
          runOnPageLoad={runOnPageLoad}
          activityKey={activityKey}
          visualisationChannel={visualisationChannel}
          blocksChannel={blocksChannel}
          maxBlocksPerSecond={maxBlocksPerSecond}
        />
      </div>
    </>
  );
};

Activity.propTypes = {
  title: PropTypes.string.isRequired,
  activityKey: PropTypes.string.isRequired,
  visualisationChannel: PropTypes.object.isRequired,
  blocksChannel: PropTypes.object.isRequired,
  defaultXml: PropTypes.string,
  maxBlocksPerSecond: PropTypes.number,
  runOnPageLoad: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  ready: PropTypes.bool,
  autoSaveRequested: PropTypes.bool,
  onAutoSaveEvent: PropTypes.func,
  onSavedStateEvent: PropTypes.func,
  savedState: PropTypes.string,
  backPath: PropTypes.string,
  showNavMenu: PropTypes.bool,
};

export default Activity;
