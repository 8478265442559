const useResize = (callback, dependencies) => {
  useEffect(() => {
    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, dependencies || []); // eslint-disable-line react-hooks/exhaustive-deps
};

useResize.triggerManually = (delay = 0) =>
  setTimeout(() => {
    if (typeof dispatchEvent !== "undefined") {
      dispatchEvent(new Event("resize"));
    }
  }, delay);

export default useResize;
