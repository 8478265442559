import Blockly from "blockly/core";
import Colours from "./colours";

const setColour = (blockName, colour) => {
  const block = Blockly.Blocks[blockName];
  const init = block.init;

  block.init = function () {
    init.call(this);
    this.setColour(colour);
  };
};

const { teal, blue, orange, purple, green, yellow, grey } = Colours;

// Looks category
setColour("Colour", purple); // Legacy block
setColour("AvatarColour", purple);
setColour("AvatarStyle", purple);
setColour("CustomiseMapColour", purple);

// Pen category
setColour("Clear", teal);
setColour("PenDown", teal);
setColour("PenUp", teal);
setColour("Stamp", teal);
setColour("SetPenColour", teal);
setColour("SetBackgroundColour", teal);
setColour("ChangeColourBy", teal);
setColour("SetPenSize", teal);
setColour("SetPenStyle", teal);
setColour("ChangePenSize", teal);
setColour("DrawShapeOfSize", teal);

// Music category
setColour("RestBars", teal);
setColour("SetTempo", teal);
setColour("ChangeTempo", teal);
setColour("PlayMelody", teal);
setColour("PlayBeat", teal);
setColour("CustomiseBoomboxSong", teal);

// Motion category
setColour("MoveNSteps", blue);
setColour("TurnClockwise", blue);
setColour("TurnAntiClockwise", blue);
setColour("PointInDirection", blue);
setColour("GoToXY", blue);
setColour("GoToRandomPosition", blue);
setColour("SetX", blue);
setColour("ChangeX", blue);
setColour("SetY", blue);
setColour("ChangeY", blue);

// Poses category
setColour("MoveToPose", teal);
setColour("MoveToPoseNBeats", teal);
setColour("HoldPose", teal);

// Control category
setColour("controls_repeat_ext", orange);
setColour("PlayTogether", orange);
setColour("StartMusic", orange);

// Operators category
setColour("Number", green);
setColour("RandomNumber", green);
setColour("Pose", green);

// Events category
setColour("Run", yellow);
setColour("DanceRun", yellow);
setColour("MapOpenedRun", yellow);
setColour("BoomboxClicked", yellow);

setColour("Deprecated", grey);

Blockly.BlockSvg.prototype.setHighlighted = function (highlighted) {
  if (!this.rendered) {
    return;
  }
  if (highlighted) {
    this.addSelect();
  } else {
    this.removeSelect();
  }
};
