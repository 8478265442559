import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "landing_page";
import ParentsPage from "parents_page";
import LaunchPage from "launch_page";
import WorkspacesPage from "workspaces_page";
import AvatarPage from "avatar_page";
import ArtPage from "art_page";
import MusicPage from "music_page";
import MapPage from "map_page";
import IslandPage from "island_page";
import CustomisePage from "customise_page";
import MapRedirector from "map_redirector";
import DancePage from "dance_page";
import ProfilePage from "profile_page";
import GalleryPage from "gallery_page";
import ProjectPage from "project_page";
import DesignSystemPage from "design_system_page";
import RobotPoserPage from "robot_poser_page";
import NotFoundPage from "not_found_page";
import GalleryActivityPage from "gallery_activity_page";
import NewGallerySubmissionPage from "new_gallery_submission_page";
import MyDataContext from "my_data_context";
import AppContext from "app_context";
import CsrfContext from "csrf_context";
import setupSentry from "setup_sentry";
import SEO from "../seo";
import "./reset.scss";
import "./fonts.scss";
import "./utils.scss";

const Root = ({ myData, appData, csrfToken }) => {
  const { SENTRY_ENV, SENTRY_DSN } = appData.env;
  setupSentry(SENTRY_ENV, SENTRY_DSN);

  return (
    <React.StrictMode>
      <SEO
        title="Free Coding Games for Kids | Learn to Code | Code Club World"
        description="Code Club World helps you make cool stuff while learning to code, with free coding games and activities for kids to explore. Learn Scratch, Python, HTML and CSS."
      />
      <MyDataContext.Provider value={myData}>
        <AppContext.Provider value={appData}>
          <CsrfContext.Provider value={csrfToken}>
            <Router>
              <Switch>
                <Route path={appData.routes.new_launch_path}>
                  <LaunchPage />
                </Route>
                <Route path={appData.routes.launch_path}>
                  <LaunchPage />
                </Route>
                <Route path={appData.routes.customise_path}>
                  <CustomisePage />
                </Route>
                <Route path={appData.routes.new_workspace_path}>
                  <ActivitySwitch />
                </Route>
                <Route path={appData.routes.workspace_path}>
                  <ActivitySwitch />
                </Route>
                <Route path={appData.routes.gallery_activity_path}>
                  <GalleryActivityPage />
                </Route>
                <Route path={appData.routes.workspaces_path}>
                  <WorkspacesPage />
                </Route>
                <Route path={appData.routes.new_gallery_submission_path}>
                  <NewGallerySubmissionPage />
                </Route>
                <Route path={appData.routes.map_redirector_path}>
                  <MapRedirector />
                </Route>
                <Route path={appData.routes.map_path}>
                  <MapPage />
                </Route>
                <Route path={appData.routes.island_path}>
                  <IslandPage />
                </Route>
                <Route path={appData.routes.profile_path}>
                  <ProfilePage />
                </Route>
                <Route path={appData.routes.gallery_path}>
                  <GalleryPage />
                </Route>
                <Route path={appData.routes.project_step_path}>
                  <ProjectPage />
                </Route>
                {appData.routes.design_system_path && (
                  <Route path={appData.routes.design_system_path}>
                    <DesignSystemPage />
                  </Route>
                )}
                {appData.routes.robot_poser_path && (
                  <Route path={appData.routes.robot_poser_path}>
                    <RobotPoserPage />
                  </Route>
                )}
                <Route path={appData.routes.parents_path}>
                  <ParentsPage />
                </Route>
                <Route path={appData.routes.root_path}>
                  <LandingPage />
                </Route>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Router>
          </CsrfContext.Provider>
        </AppContext.Provider>
      </MyDataContext.Provider>
    </React.StrictMode>
  );
};

const ActivitySwitch = () => {
  const { activity_key } = useParams();

  switch (activity_key) {
    case "avatar":
      return <AvatarPage />;
    case "art":
      return <ArtPage />;
    case "music":
      return <MusicPage />;
    case "dance":
      return <DancePage />;
  }
};

Root.bootstrap = () => {
  document.addEventListener("DOMContentLoaded", Root.addToDom);
};

Root.addToDom = () => {
  const div = document.getElementById("react-root");

  const csrfToken = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");

  if (div !== null) {
    const appData = JSON.parse(div.dataset.app);
    const myData = JSON.parse(div.dataset.me);

    ReactDOM.render(
      <Root myData={myData} appData={appData} csrfToken={csrfToken} />,
      div
    );
  }
};

Root.propTypes = {
  myData: PropTypes.object,
  appData: PropTypes.object,
  csrfToken: PropTypes.string,
};

export default Root;
