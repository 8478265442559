import { Instruments } from "audio_samples";
import randomNumberBetween from "random_number_between";

const wireUpMusicApi = ({
  channel,
  playback,
  setPlayback,
  initialWait = 0,
  audioEngine,
  onFinished = () => {},
}) => {
  channel.reset = () => {
    setPlayback({
      startTime: initialWait + audioEngine.getCurrentTime(),
      sources: [],
    });
  };

  channel.clear = () => {
    if (!audioEngine) return;

    audioEngine.stopAll();
  };

  channel.onFinishedOrStopped = () => {
    channel.clear();
  };

  channel.startRun = () => {
    setPlayback((state) => ({
      ...state,
      instrument: Instruments[0],
      tempo: 120,
      nextNoteTime: state.startTime,
      playTogether: { endTime: state.startTime },
    }));
  };

  channel.playNote = (note, duration) => {
    setPlayback((state) => {
      const { source, endTime } = audioEngine.scheduleNote(
        state.instrument,
        state.tempo,
        state.nextNoteTime,
        note,
        duration,
        handleSourceEnded
      );
      return {
        ...state,
        nextNoteTime: endTime,
        sources: [...state.sources, source],
      };
    });
  };

  channel.playDrum = (drum, duration) => {
    setPlayback((state) => {
      const { source, endTime } = audioEngine.scheduleDrum(
        drum,
        state.tempo,
        state.nextNoteTime,
        duration,
        handleSourceEnded
      );

      return {
        ...state,
        nextNoteTime: endTime,
        sources: [...state.sources, source],
      };
    });
  };

  channel.beginPlayTogether = () => {
    setPlayback((state) => ({
      ...state,
      playTogether: {
        ...state.playTogether,
        beginTime: state.nextNoteTime,
      },
    }));
  };

  channel.rewind = () => {
    setPlayback((state) => {
      const endTime = Math.max(state.nextNoteTime, state.playTogether.endTime);

      return {
        ...state,
        nextNoteTime: state.playTogether.beginTime,
        playTogether: {
          ...state.playTogether,
          endTime,
        },
      };
    });
  };

  channel.endPlayTogether = () => {
    setPlayback((state) => {
      const endTime = Math.max(state.nextNoteTime, state.playTogether.endTime);

      return {
        ...state,
        nextNoteTime: endTime,
      };
    });
  };

  channel.rest = (beats) => {
    setPlayback((state) => {
      const beatTime = 60 / state.tempo; // 1 beat at 120 beats per minute is 0.5s
      const endTime = state.nextNoteTime + beats * beatTime;

      return { ...state, nextNoteTime: endTime };
    });
  };

  channel.setInstrument = (instrument) => {
    setPlayback((state) => ({ ...state, instrument: instrument }));
  };

  channel.setTempo = (bpm) => {
    setPlayback((state) => {
      return { ...state, tempo: bpm };
    });
  };

  channel.changeTempo = (delta) => {
    setPlayback((state) => {
      return { ...state, tempo: state.tempo + delta };
    });
  };

  channel.randomNumberBetween = (a, b) => {
    return randomNumberBetween(a, b);
  };

  const handleSourceEnded = () => {
    if (audioEngine && audioEngine.finished()) {
      channel.onReady();

      if (onFinished) {
        onFinished();
      }
    }
  };

  // This method is used in the unit tests.
  channel.playbackState = () => playback;

  return channel;
};

export default wireUpMusicApi;
