import Blockly from "blockly";
import poses from "visualisations/dance/poses";

const options = Object.entries(poses).map(([key, pose]) => [pose.name, key]);
options.push(["random pose", "random"]);

Blockly.Blocks.Pose = {
  message0: "%1",
  args0: [
    {
      type: "field_dropdown",
      name: "pose",
      options,
    },
  ],
  output: "Pose",
};

Blockly.Blocks.Pose.init = function () {
  this.jsonInit(Blockly.Blocks.Pose);
  this.operator = true;
};

Blockly.JavaScript.Pose = function (block) {
  return [block.getFieldValue("pose"), Blockly.JavaScript.ORDER_NONE];
};
