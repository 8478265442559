import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  forwardRef,
} from "react";
import ReactDOM from "react-dom";
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import camelCase from "camelcase";
import { snakeCase } from "snake-case";

global.React = React;
global.ReactDOM = ReactDOM;
global.PropTypes = PropTypes;

// Hooks:
global.useContext = useContext;
global.useEffect = useEffect;
global.useHistory = useHistory;
global.useLayoutEffect = useLayoutEffect;
global.useParams = useParams;
global.useRef = useRef;
global.useState = useState;
global.useMemo = useMemo;
global.useCallback = useCallback;
global.useLocation = useLocation;
global.useRouteMatch = useRouteMatch;

global.forwardRef = forwardRef;

// Helpers:
global.classNames = classNames;
global.camelCase = camelCase;
global.snakeCase = snakeCase;
