import styles from "./styles.module.scss";

const HowItWorksSection = forwardRef((props, ref) => {
  return (
    <section ref={ref}>
      <div className={styles.section_container}>
        <div className={styles.section_copy}>
          <h2>How it works</h2>
        </div>
        <div className={styles.subsection}>
          <div className={styles.subsection_copy}>
            <h3>1</h3>
            <p>
              New to coding? Kids can build their confidence on Home Island.
              Simply drag and drop coding blocks, click “Run” and they’ll see
              their creations come to life!
            </p>
          </div>
          <div className={styles.subsection_copy}>
            <h3>2</h3>
            <p>
              Scratch Island is where your kids can learn more about block-based
              programming, with a pathway of creative challenges.
            </p>
          </div>
          <div className={styles.subsection_copy}>
            <h3>3</h3>
            <p>
              Python Island provides an introduction to text-based programming,
              with more fun, themed projects.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default HowItWorksSection;
