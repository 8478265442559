import Blockly from "blockly";

const Block = ({ defaults = [], children, ...props }) => {
  const inputArgs = blockArguments(props.type);

  return (
    <block is="blockly" {...props}>
      {inputArgs.map(([name, type], i) => {
        const value = defaults[i];
        const isBlank = value == null;

        if (isBlank) {
          return null;
        } else if (type) {
          return <DefaultBlock key={i} name={name} type={type} value={value} />;
        } else {
          return <DefaultValue key={i} name={name} value={value} />;
        }
      })}

      {children}
    </block>
  );
};

const blockArguments = (blockName) => {
  if (argsForBuiltIns[blockName]) {
    return argsForBuiltIns[blockName];
  } else {
    const blockDefinition = Blockly.Blocks[blockName] || {};
    const args = blockDefinition.args0 || [];

    return args.map(({ type, name, check }) => {
      const c = [].concat(check); // cast check to an array
      return type === "input_value" ? [name, c[0]] : [name];
    });
  }
};

const argsForBuiltIns = {
  controls_repeat_ext: [["TIMES", "Number"]],
};

const DefaultBlock = ({ name, type, value }) => {
  const inputArgs = blockArguments(type);
  const firstInputName = inputArgs[0][0];

  return (
    <value is="blockly" name={name}>
      <Block type={type}>
        <DefaultValue name={firstInputName} value={value} />
      </Block>
    </value>
  );
};

const DefaultValue = ({ name, value }) => (
  <field is="blockly" name={name}>
    {value}
  </field>
);

Block.propTypes = {
  defaults: PropTypes.arrayOf(PropTypes.node),
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DefaultBlock.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.node,
};

DefaultValue.propTypes = {
  name: PropTypes.string,
  value: PropTypes.node,
};

export default Block;
