import {
  BodyStyleThumbnails,
  FaceStyleThumbnails,
  GloveStyleThumbnails,
  HeadStyleThumbnails,
  HeadgearStyleThumbnails,
  ShoeStyleThumbnails,
} from "./thumbnails.js";

const AvatarBlocksConfiguration = [
  {
    label: "headgear",
    key: "headgear",
    styles: HeadgearStyleThumbnails,
  },
  {
    label: "head",
    key: "head",
    styles: HeadStyleThumbnails,
  },
  {
    label: "face",
    key: "face",
    styles: FaceStyleThumbnails,
  },
  {
    label: "body",
    key: "body",
    styles: BodyStyleThumbnails,
  },
  {
    label: "gloves",
    key: "glove",
    styles: GloveStyleThumbnails,
  },
  {
    label: "shoes",
    key: "shoe",
    styles: ShoeStyleThumbnails,
  },
];

export const AvatarBodyPartOptions = AvatarBlocksConfiguration.map((p) => [
  p.label,
  p.key,
]);

export const defaultBodyPartKey = AvatarBlocksConfiguration[0].key;

export const AvatarBodyPartStyleOptions = AvatarBlocksConfiguration.filter(
  (p) => p.styles.length > 0
).map((p) => [p.label, p.key]);

export function avatarStyleOptions(key) {
  const part = AvatarBlocksConfiguration.find((p) => p.key == key);

  return part.styles.map((style, i) => [
    {
      src: style,
      width: 25,
      height: 25,
      alt: "*",
    },
    avatarStyleKey(key, i),
  ]);
}

function avatarStyleKey(bodyPartKey, index) {
  return [bodyPartKey, index].join("-");
}

export function avatarStyleIndexFromKey(styleKey) {
  return parseInt(styleKey.split("-")[1]);
}
