import palette from "root/js_palette";
import HidingUnderMusic from "assets/dance/hiding_under.mp3";
import GamesWorldbeatMusic from "assets/dance/games_worldbeat.mp3";
import SurfsUpDudeMusic from "assets/dance/surfs_up_dude.mp3";
import repeat from "repeat";

const songs = {
  hiding_under: {
    name: "hiding under",
    path: HidingUnderMusic,
    filetype: "audio/mpeg",
    bpm: 128,
    fadeOutBeats: 17,
    spotlightPattern: [
      ...repeat(4, [
        { left: palette.tone1.yellow, right: palette.tone1.red },
        { left: "transparent", right: "transparent" },
      ]),
      ...repeat(4, [
        { left: palette.tone1.yellow, right: "transparent" },
        { left: "transparent", right: palette.tone1.red },
      ]),
      ...repeat(4, [
        { left: palette.tone1.yellow, right: palette.tone1.red },
        { left: palette.tone1.red, right: palette.tone1.yellow },
      ]),
      ...repeat(2, [
        { left: "transparent", right: "transparent" },
        { left: palette.tone1.red, right: palette.tone1.red },
        { left: palette.tone1.yellow, right: "transparent" },
        { left: "transparent", right: palette.tone1.yellow },
      ]),
    ],
  },
  games_worldbeat: {
    name: "games worldbeat",
    path: GamesWorldbeatMusic,
    filetype: "audio/mpeg",
    bpm: 58,
    fadeOutBeats: 3.9,
    spotlightPattern: [
      ...repeat(2, [
        ...repeat(2, [
          { left: palette.primary.white, right: palette.primary.white },
          { left: palette.tone2.blue, right: palette.tone2.blue },
        ]),
        { left: palette.tone2.green, right: palette.tone2.green },
        { left: palette.primary.white, right: "transparent" },
        { left: "transparent", right: palette.primary.white },
        { left: "transparent", right: "transparent" },
      ]),
      ...repeat(2, [
        { left: palette.tone1.yellow, right: "transparent" },
        { left: "transparent", right: "transparent" },
        { left: "transparent", right: palette.tone1.red },
        { left: "transparent", right: "transparent" },

        { left: "transparent", right: palette.tone1.yellow },
        { left: "transparent", right: "transparent" },
        { left: palette.tone1.red, right: "transparent" },
        { left: "transparent", right: "transparent" },
      ]),
    ],
  },
  surfs_up_dude: {
    name: "surf’s up dude",
    path: SurfsUpDudeMusic,
    filetype: "audio/mpeg",
    bpm: 154.6,
    fadeOutBeats: 20,
    spotlightPattern: [
      ...repeat(2, [
        { left: palette.tone1.red, right: "transparent" },
        { left: "transparent", right: palette.tone1.red },
      ]),
      ...repeat(2, [
        { left: palette.tone1.yellow, right: "transparent" },
        { left: "transparent", right: palette.tone1.yellow },
      ]),
      ...repeat(2, [
        { left: palette.primary.white, right: palette.primary.white },
        { left: "transparent", right: "transparent" },
      ]),
      ...repeat(2, [
        { left: palette.tone1.red, right: palette.tone1.red },
        { left: palette.tone1.yellow, right: palette.tone1.yellow },
      ]),
    ],
  },
};

export default songs;
