const activityFlow = {
  art: {
    name: "t-shirt",
    sharingChooseImage: {
      skip: true,
    },
  },
  music: {
    name: "music",
    sharingChooseImage: {
      title: "Select an instrument to be used as your cover image.",
      type: "static",
    },
  },
  dance: {
    name: "dance",
    sharingChooseImage: {
      title:
        "Select a dance pose to be used as your cover image in the community gallery.",
      type: "pose",
    },
  },
  avatar: {
    name: "avatar",
    sharingChooseImage: {
      skip: true,
    },
  },
  default: {
    title: "",
    sharingChooseImage: {
      skip: true,
    },
  },
};

export default activityFlow;
