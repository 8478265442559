import Api from "api";

const GallerySubmission = {};

GallerySubmission.gallerySubmission = (id, state) =>
  new Promise((resolve, reject) => {
    Api.post(`/gallery_submissions/${id}/${state}`)
      .then(() => resolve(true))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

GallerySubmission.featuredGallerySubmission = (id, featured = false) =>
  new Promise((resolve, reject) => {
    Api.post(`/gallery_submissions/${id}/featured`, { featured })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

GallerySubmission.delete = (id) =>
  new Promise((resolve, reject) => {
    Api.delete(`/gallery_submissions/${id}`)
      .then((response) => resolve(response))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });
export default GallerySubmission;
