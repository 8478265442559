import Blockly from "blockly";
import ReactDOM from "react-dom";

class CustomReactField extends Blockly.Field {
  constructor(value, validator) {
    super(value, validator);
    this.SERIALIZABLE = true;
    this.CURSOR = "pointer";
    this.fixedSize = new Blockly.utils.Size(10, 10);
  }

  showEditor_() {
    // https://developers.google.com/blockly/reference/js/Blockly.Field#showEditor_
    this.dropDownDiv = Blockly.DropDownDiv.getContentDiv();
    if (this.dropDownDivClass) {
      this.dropDownDiv.classList.add(this.dropDownDivClass);
    }
    ReactDOM.render(this.renderDropDownWithValue(), this.dropDownDiv);
    Blockly.DropDownDiv.setColour(
      this.sourceBlock_.style.colourPrimary,
      this.sourceBlock_.style.colourTertiary
    );
    Blockly.DropDownDiv.showPositionedByField(
      this,
      this.dropdownDispose.bind(this)
    );
  }

  initView() {
    this.blockUIGroup = Blockly.utils.dom.createSvgElement(Blockly.utils.Svg.G);
    this.fieldGroup_.append(this.blockUIGroup);
    ReactDOM.render(this.renderBlockUIWithValue(), this.blockUIGroup);
  }

  updateSize_() {
    this.size_ = this.fixedSize;
  }

  onValueChanged(new_value) {
    this.setValue(new_value);
    ReactDOM.render(this.renderDropDownWithValue(), this.dropDownDiv);
    ReactDOM.render(this.renderBlockUIWithValue(), this.blockUIGroup);
  }

  dispose() {
    super.dispose();
    if (this.blockUIGroup) {
      ReactDOM.unmountComponentAtNode(this.blockUIGroup);
    }
  }

  dropdownDispose() {
    if (this.dropDownDiv) {
      ReactDOM.unmountComponentAtNode(this.dropDownDiv);
    }
  }

  renderBlockUIWithValue() {
    return this.renderSVGforBlockUI(this.getValue());
  }

  renderDropDownWithValue() {
    return this.renderSVGforDropDown(
      this.getValue(),
      this.onValueChanged.bind(this)
    );
  }

  renderSVGforBlockUI(/* value */) {
    return <text>Override me</text>;
  }

  renderSVGforDropDown(/* value, setValue */) {
    return <text>Override me</text>;
  }
}

export default CustomReactField;
