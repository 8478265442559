const Cloud = ({ key, x, y, size, colour }) => {
  const height = 17;
  const width = 24;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6235 7.05882C19.6235 6.63529 19.7647 6.21176 19.7647 5.64706C19.7647 2.54118 17.2235 0 14.1176 0C11.8588 0 10.0235 1.27059 9.03529 3.10588C8.75294 2.96471 8.18824 2.82353 7.76471 2.82353C5.78824 2.82353 4.23529 4.37647 4.23529 6.35294C4.23529 6.63529 4.23529 6.91765 4.37647 7.05882C1.83529 7.48235 0 9.45882 0 12C0 14.6824 2.25882 16.9412 4.94118 16.9412H19.0588C21.7412 16.9412 24 14.6824 24 12C24 9.45882 22.1647 7.34118 19.6235 7.05882Z"
        fill={colour}
      />
    </svg>
  );
};

Cloud.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Cloud;
