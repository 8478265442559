export default `
  <xml xmlns="http://www.w3.org/1999/xhtml">
    <block type="MapOpenedRun" deletable="false">
    </block>
    <block type="BoomboxClicked" deletable="false" x="200">
      <next>
        <block type="CustomiseBoomboxSong" deletable="false" movable="false">
        </block>
      </next>
    </block>
  </xml>
`;
