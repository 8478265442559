import Draggable from "draggable";
import AvatarWithTshirt from "avatar_with_tshirt";
import RobotShadow from "assets/robot_shadow.svg";
import MyDataContext from "my_data_context";
import styles from "./styles.module.scss";

const MapAvatar = ({ x, y }) => {
  const { user } = useContext(MyDataContext);

  const positionProps = (width, height, baseline) => {
    const left = x - width / 2;
    const top = y - height / 2 - baseline;

    return { width, height, style: { left, top } };
  };

  return (
    <Draggable.Relative>
      <RobotShadow
        className={styles.robot_shadow}
        {...positionProps(60, 60, 5)}
      />
      <AvatarWithTshirt
        tshirtWorkspace={user.tshirt_workspace}
        className={styles.map_avatar}
        {...positionProps(52.759, 88, 32)}
      />
    </Draggable.Relative>
  );
};

MapAvatar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
};

export default MapAvatar;
