import CustomReactField from "./custom_react_field";
import Blockly from "blockly";
import { Scale } from "@tonaljs/tonal";
import Run from "assets/run.svg";
import Stave from "stave";
import Button from "button";
import AudioEngine from "visualisations/music/audio_engine";
import { AudioContext } from "standardized-audio-context";
import { Instruments } from "audio_samples";

const audioEngine = new AudioEngine(new AudioContext());
audioEngine.init();

const notes = "ABCDEFGabc";
const midiNoteForMelodyNote = (melodyNote) => {
  const isLowerCase = (note) => note.toLowerCase() === note;
  const octave = (note) => (isLowerCase(note) ? 5 : 4);
  const upperNote = (note) => note.toUpperCase();
  const midiOctave = (note) =>
    "ABab".includes(note) > 0 ? octave(note) - 1 : octave(note);
  const midiNote = (note) => upperNote(note) + midiOctave(note);
  return midiNote(melodyNote);
};

const MelodyBlockUI = ({ melody }) => {
  return (
    <Stave
      notes={notes}
      width={154}
      height={40}
      margin={5}
      renderDetail={false}
      melody={melody}
    />
  );
};

const scheduleNote = (note, instrument, time) => {
  const midiNote = midiNoteForMelodyNote(note);
  const eighthNote = 0.5;
  audioEngine.scheduleNote(
    instrument,
    120,
    time,
    noteIndexForMidiNote(midiNote),
    eighthNote,
    () => {}
  );
};

const playSynthNote = (note, instrument) => {
  scheduleNote(note, instrument, audioEngine.getCurrentTime());
};

const playSynthMelody = (melody, instrument) => {
  const tempo = 0.25;
  const now = audioEngine.getCurrentTime();
  melody.split("").forEach((note, index) => {
    if (note != "-") {
      scheduleNote(note, instrument, now + index * tempo);
    }
  });
};

const MelodyDropDownUI = ({ melody, setMelody, instrument }) => {
  const melodyChanged = (new_melody) => {
    setMelody(new_melody);
  };

  const playNote = (note) => {
    playSynthNote(note, instrument);
  };

  const playMelody = () => {
    playSynthMelody(melody, instrument);
  };

  const randomNote = () => {
    const chars = notes + "-";
    return chars.split("")[Math.floor(Math.random() * chars.length)];
  };

  const randomise = () => {
    const new_melody = melody.split("").map(randomNote).join("");
    setMelody(new_melody);
  };

  return (
    <>
      <Stave
        notes={notes}
        width={278}
        height={160}
        margin={4}
        renderDetail={true}
        melody={melody}
        onMelodyChanged={melodyChanged}
        playNote={playNote}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          columnGap: "10px",
          alignItems: "center",
          height: "40px",
          padding: "10px",
          backgroundColor: "#BF5AA4",
        }}
      >
        <Button.BlocklyControl text="Random" onClick={randomise} />
        <Button.BlocklyControl
          text="Play"
          icon={<Run />}
          onClick={playMelody}
        />
      </div>
    </>
  );
};

MelodyBlockUI.propTypes = {
  melody: PropTypes.string,
};

MelodyDropDownUI.propTypes = {
  melody: PropTypes.string,
  setMelody: PropTypes.func,
  instrument: PropTypes.string,
};

class MelodyField extends CustomReactField {
  constructor(value, validator) {
    super(value, validator);
    this.fixedSize = new Blockly.utils.Size(154, 40);
  }

  renderSVGforBlockUI(value) {
    return <MelodyBlockUI melody={value} />;
  }

  renderSVGforDropDown(value, setValue) {
    const instrument = this.sourceBlock_.getFieldValue("instrument");
    return (
      <MelodyDropDownUI
        melody={value}
        setMelody={setValue}
        instrument={instrument}
      />
    );
  }

  static fromJson(options) {
    return new MelodyField(options.variable);
  }
}

Blockly.fieldRegistry.register("field_select_melody", MelodyField);

const underscoresToSpaces = (e) => e.replace("_", " ");
const instrument_options = Instruments.map((e) => [underscoresToSpaces(e), e]);

Blockly.Blocks.PlayMelody = {
  init: function () {
    this.jsonInit({
      message0: "play %1 on %2",
      previousStatement: "Statement",
      nextStatement: "Statement",
      args0: [
        {
          type: "field_select_melody",
          name: "melody",
          variable: "E--G--FD",
        },
        {
          type: "field_dropdown",
          name: "instrument",
          options: instrument_options,
        },
      ],
    });
  },
};

const noteIndexForMidiNote = (note) => {
  return Scale.rangeOf("c major")("A3", "C5").findIndex((n) => n === note);
};

Blockly.JavaScript.PlayMelody = function (block) {
  const notes = block.getFieldValue("melody");
  const instrument = block.getFieldValue("instrument");
  const eighthNote = 0.5;

  const instrument_commands = `visualisation.setInstrument("${instrument}");`;
  const note_commands = notes
    .split("")
    .map((note) => {
      if (note == "-") {
        return `visualisation.rest(${eighthNote});`;
      } else {
        const idx = noteIndexForMidiNote(midiNoteForMelodyNote(note));
        return `visualisation.playNote(${idx}, ${eighthNote});`;
      }
    })
    .join("\n");
  return instrument_commands + note_commands;
};
