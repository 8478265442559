import MyDataContext from "my_data_context";
import AppContext from "app_context";
import styles from "./styles.module.scss";

const BadgeCTA = ({ badgeSlug }) => {
  const myData = useContext(MyDataContext);
  const { issued_badges: issuedBadges } = myData;
  const appData = useContext(AppContext);
  const { badge_templates: badgeTemplates } = appData;

  const badgeTemplate = badgeTemplates.find((b) => b.slug === badgeSlug);
  const issued =
    badgeTemplate &&
    issuedBadges.some((a) => a.badge_template_id === badgeTemplate.id);

  return (
    <>
      {badgeTemplate && (
        <div
          className={classNames({
            [styles.badge_cta]: true,
            [styles.badge_cta_awarded]: issued,
            [styles.badge_disabled]: !issued,
          })}
        >
          <img src={badgeTemplate.image} alt={""} />
          {issued ? <p>You got the badge!</p> : <p>Get the badge!</p>}
        </div>
      )}
    </>
  );
};

BadgeCTA.propTypes = {
  badgeSlug: PropTypes.string.isRequired,
};

export default BadgeCTA;
