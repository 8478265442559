import Interpreter from "interpreter";
import Art from "visualisations/art";
import Avatar from "visualisations/avatar";
import Dance from "visualisations/dance";
import Music from "visualisations/music";
import Customise from "visualisations/customise";
import Run from "assets/run.svg";
import Stop from "assets/stop.svg";
import Grow from "assets/grow.svg";
import Shrink from "assets/shrink.svg";
import Button from "button";
import styles from "./styles.module.scss";
import { useEffect } from "react";

const visualisations = {
  art: Art,
  avatar: Avatar,
  dance: Dance,
  music: Music,
  customise: Customise,
};

const Runner = ({
  beforeRun,
  runOnPageLoad,
  activityKey,
  visualisationChannel,
  blocksChannel,
  maxBlocksPerSecond,
  onFullscreen,
  fullscreen,
  noFullscreen,
  iterateRun = false,
  overwriteRun,
  gallery = false,
  uniqueIdentifier = null,
  onFinished = null,
}) => {
  const [interpreter, setInterpreter] = useState();
  const [ready, setReady] = useState(false);
  const [run, setRun] = useState(overwriteRun || false);

  blocksChannel.onReady = () => {
    if (runOnPageLoad) {
      runProgram();
    }
  };

  visualisationChannel.onReady = () => setReady(true);
  visualisationChannel.onFinishedIteration = () => {
    setReady(true);
    if (interpreter) {
      setRun(false);
      interpreter.stop();
    }
    if (onFinished) {
      onFinished();
    }
  };

  const handleRun = () => {
    blocksChannel.seed = Math.random().toString();
    beforeRun();
    runProgram();
    setRun(true);
  };

  const runProgram = () => {
    setReady(false);

    visualisationChannel.reset();

    try {
      const interpreter = Interpreter.create(
        blocksChannel,
        visualisationChannel,
        maxBlocksPerSecond
      );
      setInterpreter(interpreter);
      interpreter.run();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      setReady(true);
    }
  };

  const handleStop = () => {
    if (interpreter) {
      setRun(false);
      interpreter.stop();
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (typeof overwriteRun !== "undefined") {
      if (overwriteRun) {
        handleRun();
      } else {
        handleStop();
      }
    }
  }, [overwriteRun]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const SelectedVisualisation = visualisations[activityKey];
  const visualisation = (
    <SelectedVisualisation
      channel={visualisationChannel}
      fullscreen={fullscreen}
      seed={blocksChannel.seed}
      run={run}
      iterateRun={iterateRun}
      uniqueIdentifier={uniqueIdentifier}
      gallery={gallery}
    />
  );

  const visualisationChannelStyles = gallery
    ? styles.visualisation_container_gallery
    : styles.visualisation_container;
  return (
    <>
      {!gallery && (
        <div className={styles.program_controls}>
          <>
            <Button.ControlPrimary
              text="Run"
              icon={<Run />}
              compact={true}
              onClick={handleRun}
              enabled={ready}
              className={classNames(styles.run_button, {
                [styles.fullscreen]: fullscreen,
              })}
            />
            <Button.ControlSecondary
              text="Stop"
              icon={<Stop />}
              compact={true}
              onClick={handleStop}
              enabled={!ready}
            />
          </>
          {!noFullscreen && (
            <>
              {fullscreen ? (
                <Button.ControlSecondary
                  text="Shrink"
                  icon={<Shrink />}
                  compact={true}
                  onClick={() => onFullscreen(false)}
                  className={styles.shrink_button}
                />
              ) : (
                <Button.ControlSecondary
                  text="Grow"
                  icon={<Grow />}
                  compact={true}
                  onClick={() => onFullscreen(true)}
                  className={styles.grow_button}
                />
              )}
            </>
          )}
        </div>
      )}
      <div className={visualisationChannelStyles}> {visualisation} </div>
    </>
  );
};

Runner.propTypes = {
  beforeRun: PropTypes.func.isRequired,
  runOnPageLoad: PropTypes.bool,
  activityKey: PropTypes.string.isRequired,
  visualisationChannel: PropTypes.object.isRequired,
  blocksChannel: PropTypes.object.isRequired,
  maxBlocksPerSecond: PropTypes.number,
  onFullscreen: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  noFullscreen: PropTypes.bool,
  iterateRun: PropTypes.bool,
  uniqueIdentifier: PropTypes.string,
  onFinished: PropTypes.func,
  overwriteRun: PropTypes.bool,
  gallery: PropTypes.bool,
};

export default Runner;
