import styles from "./styles.module.scss";
import AppContext from "app_context";
import CsrfContext from "csrf_context";
import MyDataContext from "my_data_context";

const ScratchUI = ({ projectSlug, handleSavedStateEvent }) => {
  const csrfToken = useContext(CsrfContext);
  const routes = useRoutes();
  const { scratch_program_id } = useContext(MyDataContext);
  const { SENTRY_ENV, SENTRY_DSN } = useContext(AppContext).env;

  const scratchEditorRef = useRef();

  usePostedMessage((event) => {
    if (window.location.origin !== event.origin) return;
    if (!scratchEditorRef.current) return;
    if (event.source !== scratchEditorRef.current.contentWindow) return;
    if (!event.data.scratchEditor) return;

    handleSavedStateEvent(event.data.scratchEditor.eventType);
  });

  const buildScratchEditorUrl = (
    slug,
    token,
    program_id,
    sentryEnv,
    sentryDsn
  ) => {
    const url = new URL(routes.scratchEditorUrl());
    const params = {
      projectSlug: slug,
      csrfToken: token,
      sentryEnv: sentryEnv,
      sentryDsn: sentryDsn,
    };
    if (program_id) params.projectId = program_id;
    const query = new URLSearchParams(params);
    url.search = query;
    return url.toString();
  };

  const scratchEditorUrl = buildScratchEditorUrl(
    projectSlug,
    csrfToken,
    scratch_program_id,
    SENTRY_ENV,
    SENTRY_DSN
  );

  return (
    <iframe
      className={styles.scratch}
      src={scratchEditorUrl}
      title="Scratch Editor"
      name="scratch-editor"
      ref={scratchEditorRef}
    ></iframe>
  );
};

ScratchUI.propTypes = {
  projectSlug: PropTypes.string,
  handleSavedStateEvent: PropTypes.func,
};

export default ScratchUI;
