import palette from "root/js_palette";

const AvatarIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill={palette.primary.black}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 0C1.61178 0 0 1.61178 0 3.6V26.4C0 28.3882 1.61178 30 3.6 30H26.4C28.3882 30 30 28.3882 30 26.4V3.6C30 1.61178 28.3882 0 26.4 0H3.6ZM7.8 7.2C6.14315 7.2 4.8 8.54314 4.8 10.2V19.8C4.8 21.4569 6.14314 22.8 7.8 22.8H22.2C23.8569 22.8 25.2 21.4569 25.2 19.8V10.2C25.2 8.54315 23.8569 7.2 22.2 7.2H7.8Z"
      />
      <circle cx="10.5" cy="11.5005" r="1.5" />
      <circle cx="19.5" cy="11.5005" r="1.5" />
      <path d="M15 20.0005C17.2091 20.0005 19 18.2096 19 16.0005H11C11 18.2096 12.7909 20.0005 15 20.0005Z" />
    </svg>
  );
};

AvatarIcon.propTypes = {};

export default AvatarIcon;
