import Blockly from "blockly";

Blockly.Blocks.Deprecated = {
  message0: "Sorry. This block no longer works.",
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.Deprecated.init = function () {
  this.jsonInit(Blockly.Blocks.Deprecated);
};

Blockly.JavaScript.Deprecated = () => {
  return ``;
};
