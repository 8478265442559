import BulletGreenTick from "assets/bullet_green_tick.svg";
import styles from "./styles.module.scss";
import Analytics from "analytics";

const Bulletpoint = ({ text }) => (
  <div className={styles.bulletpoint}>
    <img alt="" src={BulletGreenTick} />
    <p>{text}</p>
  </div>
);

Bulletpoint.propTypes = {
  text: PropTypes.string.isRequired,
};

function handleBlur() {
  if (
    document.activeElement &&
    document.activeElement.nodeName.toLowerCase() === "iframe"
  ) {
    // Call GA event
    Analytics.clickVideo("promo video parents page");
  }
}

const WhyCCWSection = forwardRef((props, ref) => {
  React.useEffect(() => {
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  });
  return (
    <section ref={ref}>
      <div className={styles.section_container}>
        <div className={styles.section_copy}>
          <h2>Why use Code Club World</h2>
          <p className={styles.description}>
            Parents have told us they want fun, educational tools their kids can
            enjoy, which allow them to relate to other young people in a safe,
            secure space.
          </p>
        </div>
        <div className={styles.subsection}>
          <figure className={styles.section_figure}>
            <iframe
              src="https://www.youtube.com/embed/FUNucqFmd28"
              title="YouTube video player: Coding games for kids"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </figure>
          <div className={styles.subsection_copy}>
            <h3>In Code Club World your kids will:</h3>
            <div className={styles.bulletpoints_container}>
              <Bulletpoint
                text={
                  "Get started with creative activities like creating a robot avatar and teaching it to dance, or designing a t-shirt"
                }
              />
              <Bulletpoint
                text={
                  "Learn block and text-based programming such as Scratch and Python, with fun, themed challenges"
                }
              />
              <Bulletpoint
                text={
                  "Earn badges as they learn, to grow their confidence,  and encourage their progression"
                }
              />
              <Bulletpoint
                text={
                  "Share work safely, with friends, family, and the Code Club World community"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default WhyCCWSection;
