import Card from "card";
import WorkspacePreview from "workspace_preview";
import timeAgoInWords from "time_ago_in_words";
import ellipsisMenuStyles from "workspaces_page/ellipsis_menu_styles.module.scss";
import EllipsisMenu from "ellipsis_menu";
import Link from "link";
import useAudioContext from "../../../hooks/use_audio_context";
import isPlayable from "activity_playable";

const MyProjectsCard = ({
  styles,
  allContent,
  playingId,
  onFinished,
  handleStop,
  handlePlay,
  confirmDelete,
  w,
  index,
  localWorkspaces,
}) => {
  const audioContext = useAudioContext();

  const resumeAudioContextThenPlay = (index) => {
    if (audioContext.current.state === "suspended") {
      audioContext.current.resume().then(() => handlePlay(index));
    } else {
      handlePlay(index);
    }
  };

  const title =
    w.name ||
    `${allContent[w.activity.key].cardTitlePrefix} ${
      localWorkspaces.length - index
    }`;

  return (
    <Card
      testId={w.activity.key}
      title={title}
      subtitle={`Last modified ${timeAgoInWords(new Date(w.updated_at))}`}
      playing={w.id === playingId}
      handlePlay={
        isPlayable(w.activity.key)
          ? () => resumeAudioContextThenPlay(index)
          : null
      }
      handleStop={handleStop}
      childrenInline={true}
      imageSrc={
        <WorkspacePreview
          activityKey={w.activity.key}
          workspace={w}
          playing={w.id === playingId}
          onFinished={onFinished}
          audioContext={audioContext.current}
        />
      }
      href={`/activities/${w.activity.key}/${w.id}`}
    >
      <>
        <EllipsisMenu
          index={index}
          styles={ellipsisMenuStyles}
          items={[
            <Link
              key={1}
              className={styles.menu_link}
              onClick={() => confirmDelete(w)}
            >
              Delete
            </Link>,
          ]}
        ></EllipsisMenu>
      </>
    </Card>
  );
};

MyProjectsCard.propTypes = {
  styles: PropTypes.object,
  allContent: PropTypes.object,
  playingId: PropTypes.string,
  onFinished: PropTypes.func,
  handleStop: PropTypes.func,
  handlePlay: PropTypes.func,
  confirmDelete: PropTypes.func,
  w: PropTypes.object,
  index: PropTypes.number,
  localWorkspaces: PropTypes.array,
  audioContext: PropTypes.object,
};

export default MyProjectsCard;
