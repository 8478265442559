import { memo } from "react";
import ArtworkPreview from "artwork_preview";
import AvatarPreview from "avatar_preview";
import DancePreview from "dance_preview";
import MusicPreview from "music_preview";
import activityImages from "activity_images";

const WorkspacePreview = ({
  activityKey,
  avatarWorkspace,
  workspace,
  previewImage,
  playing,
  audioContext,
  onFinished,
}) => {
  const previewProps = {};
  if (
    Object.keys(activityImages(activityKey)).length > 0 &&
    previewImage in activityImages(activityKey)
  ) {
    previewProps.previewImage = activityImages(activityKey)[previewImage];
  }
  switch (activityKey) {
    case "art":
      return (
        <ArtworkPreview
          artworkWorkspace={workspace}
          playing={playing}
          onFinished={onFinished}
          gallery={true}
        />
      );
    case "avatar":
      return <AvatarPreview avatarWorkspace={workspace} />;
    case "dance":
      return (
        <DancePreview
          previewImage={previewImage}
          avatarWorkspace={avatarWorkspace}
          danceWorkspace={workspace}
          playing={playing}
          onFinished={onFinished}
          gallery={true}
        />
      );
    case "music":
      return (
        <MusicPreview
          musicWorkspace={workspace}
          playing={playing}
          onFinished={onFinished}
          audioContext={audioContext}
          {...previewProps}
        />
      );
  }
};

const playingPropEqual = (prevProps, nextProps) =>
  prevProps.playing == nextProps.playing;

WorkspacePreview.propTypes = {
  activityKey: PropTypes.string,
  audioContext: PropTypes.object,
  avatarWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
  onFinished: PropTypes.func,
  playing: PropTypes.bool,
  previewImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
};

export default memo(WorkspacePreview, playingPropEqual);
