import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.RandomNumber = {
  message0: "pick random %1 to %2",
  args0: [
    {
      type: "input_value",
      name: "a",
      check: "Number",
    },
    {
      type: "input_value",
      name: "b",
      check: "Number",
    },
  ],
  inputsInline: true,
  output: "Number",
};

Blockly.Blocks.RandomNumber.init = function () {
  this.jsonInit(Blockly.Blocks.RandomNumber);
  this.operator = true;
};

Blockly.JavaScript.RandomNumber = function (block) {
  const a = helpers.codeForArg("a", block);
  const b = helpers.codeForArg("b", block);

  if (typeof a === "undefined" || typeof b === "undefined") {
    return "";
  } else {
    return [
      `visualisation.randomNumberBetween(${a}, ${b})`,
      Blockly.JavaScript.ORDER_NONE,
    ];
  }
};
