import MakeThingsWithCodeImg from "assets/landing_page_make_things_with_code.svg";
import Button from "button";
import styles from "./styles.module.scss";
import useBadge from "use_badge";

const Hero = () => {
  const { issued } = useBadge("ccw-avatar-maker");
  const routes = useRoutes();

  return (
    <div className={styles.background}>
      <div className={styles.main}>
        <img src={MakeThingsWithCodeImg} alt="" className={styles.hero} />
        <section className={styles.copy_and_cta}>
          <h1>{"Parents' guide"}</h1>
          <p>
            Code Club World is a free online platform for kids who want to learn
            to make stuff with code, and get started on their coding journey
            with fun activities and games. No experience necessary!
          </p>

          {issued ? (
            <Button.RectanglePrimary
              href={routes.mapPath()}
              text="Continue your kids journey!"
              outline={true}
              className={styles.button_no_border}
            />
          ) : (
            <Button.RectanglePrimary
              href={routes.newLaunchPath()}
              text="Get your kid started"
              outline={true}
              className={styles.button_no_border}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default Hero;
