import IslandLabel from "./island_label.svg";

const HomeIsland = ({ x, y, styles, scale = 1 }) => (
  <svg
    x={x}
    y={y}
    width={scale * 247}
    height={scale * 302}
    viewBox="0 0 247 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-outside-1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="55.5502"
      width="247"
      height="247"
      fill="black"
    >
      <rect fill="white" y="55.5502" width="247" height="247" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.186 63.5502C91.7008 63.5502 84.822 70.4291 84.822 78.9146V140.372H23.3644C14.8789 140.372 8 147.251 8 155.737L8 201.83C8 210.315 14.8789 217.194 23.3644 217.194H84.822V278.652C84.822 287.137 91.7008 294.016 100.186 294.016H146.28C154.765 294.016 161.644 287.137 161.644 278.652V217.194H223.102C231.587 217.194 238.466 210.315 238.466 201.83V155.737C238.466 147.251 231.587 140.372 223.101 140.372H161.644V78.9146C161.644 70.429 154.765 63.5502 146.28 63.5502H100.186Z"
      />
    </mask>
    <path
      d="M84.822 140.372V148.054H92.5042V140.372H84.822ZM8 155.737L15.6822 155.737V155.737H8ZM8 201.83H0.317803H8ZM84.822 217.194H92.5042V209.512H84.822V217.194ZM161.644 217.194V209.512H153.962V217.194H161.644ZM161.644 140.372H153.962V148.054H161.644V140.372ZM92.5042 78.9146C92.5042 74.6718 95.9436 71.2324 100.186 71.2324V55.868C87.4581 55.868 77.1398 66.1863 77.1398 78.9146H92.5042ZM92.5042 140.372V78.9146H77.1398V140.372H92.5042ZM23.3644 148.054H84.822V132.69H23.3644V148.054ZM15.6822 155.737C15.6822 151.494 19.1216 148.054 23.3644 148.054V132.69C10.6361 132.69 0.317806 143.008 0.317806 155.737H15.6822ZM15.6822 201.83L15.6822 155.737L0.317806 155.737L0.317803 201.83H15.6822ZM23.3644 209.512C19.1216 209.512 15.6822 206.072 15.6822 201.83H0.317803C0.317803 214.558 10.6361 224.876 23.3644 224.876V209.512ZM84.822 209.512H23.3644V224.876H84.822V209.512ZM92.5042 278.652V217.194H77.1398V278.652H92.5042ZM100.186 286.334C95.9436 286.334 92.5042 282.894 92.5042 278.652H77.1398C77.1398 291.38 87.4581 301.698 100.186 301.698V286.334ZM146.28 286.334H100.186V301.698H146.28V286.334ZM153.962 278.652C153.962 282.894 150.522 286.334 146.28 286.334V301.698C159.008 301.698 169.326 291.38 169.326 278.652H153.962ZM153.962 217.194V278.652H169.326V217.194H153.962ZM223.102 209.512H161.644V224.876H223.102V209.512ZM230.784 201.83C230.784 206.072 227.344 209.512 223.102 209.512V224.876C235.83 224.876 246.148 214.558 246.148 201.83H230.784ZM230.784 155.737V201.83H246.148V155.737H230.784ZM223.101 148.054C227.344 148.054 230.784 151.494 230.784 155.737H246.148C246.148 143.008 235.83 132.69 223.101 132.69V148.054ZM161.644 148.054H223.101V132.69H161.644V148.054ZM153.962 78.9146V140.372H169.326V78.9146H153.962ZM146.28 71.2324C150.522 71.2324 153.962 74.6718 153.962 78.9146H169.326C169.326 66.1863 159.008 55.868 146.28 55.868V71.2324ZM100.186 71.2324H146.28V55.868H100.186V71.2324Z"
      fill="#0273F5"
      mask="url(#path-1-outside-1)"
    />
    <mask id="path-3-inside-2" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.186 63.5004C91.7008 63.5004 84.822 70.3793 84.822 78.8648V140.322H23.3644C14.8789 140.322 8 147.201 8 155.687L8 201.78C8 210.265 14.8789 217.144 23.3644 217.144H84.822V278.602C84.822 287.087 91.7008 293.966 100.186 293.966H146.28C154.765 293.966 161.644 287.087 161.644 278.602V217.144H223.102C231.587 217.144 238.466 210.265 238.466 201.78V155.687C238.466 147.201 231.587 140.322 223.101 140.322H161.644V78.8648C161.644 70.3792 154.765 63.5004 146.28 63.5004H100.186Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.186 63.5004C91.7008 63.5004 84.822 70.3793 84.822 78.8648V140.322H23.3644C14.8789 140.322 8 147.201 8 155.687L8 201.78C8 210.265 14.8789 217.144 23.3644 217.144H84.822V278.602C84.822 287.087 91.7008 293.966 100.186 293.966H146.28C154.765 293.966 161.644 287.087 161.644 278.602V217.144H223.102C231.587 217.144 238.466 210.265 238.466 201.78V155.687C238.466 147.201 231.587 140.322 223.101 140.322H161.644V78.8648C161.644 70.3792 154.765 63.5004 146.28 63.5004H100.186Z"
      fill="#8F613C"
    />
    <path
      d="M84.822 140.322V141.859H86.3584V140.322H84.822ZM8 155.687H9.53644H8ZM8 201.78H6.46356H8ZM84.822 217.144H86.3584V215.608H84.822V217.144ZM161.644 217.144V215.608H160.107V217.144H161.644ZM161.644 140.322H160.107V141.859H161.644V140.322ZM86.3584 78.8648C86.3584 71.2278 92.5494 65.0368 100.186 65.0368V61.9639C90.8523 61.9639 83.2855 69.5307 83.2855 78.8648H86.3584ZM86.3584 140.322V78.8648H83.2855V140.322H86.3584ZM23.3644 141.859H84.822V138.786H23.3644V141.859ZM9.53644 155.687C9.53644 148.05 15.7274 141.859 23.3644 141.859V138.786C14.0303 138.786 6.46356 146.353 6.46356 155.687H9.53644ZM9.53644 201.78L9.53644 155.687H6.46356L6.46356 201.78H9.53644ZM23.3644 215.608C15.7274 215.608 9.53644 209.417 9.53644 201.78H6.46356C6.46356 211.114 14.0303 218.681 23.3644 218.681V215.608ZM84.822 215.608H23.3644V218.681H84.822V215.608ZM86.3584 278.602V217.144H83.2855V278.602H86.3584ZM100.186 292.43C92.5494 292.43 86.3584 286.239 86.3584 278.602H83.2855C83.2855 287.936 90.8523 295.503 100.186 295.503V292.43ZM146.28 292.43H100.186V295.503H146.28V292.43ZM160.107 278.602C160.107 286.239 153.917 292.43 146.28 292.43V295.503C155.614 295.503 163.18 287.936 163.18 278.602H160.107ZM160.107 217.144V278.602H163.18V217.144H160.107ZM223.102 215.608H161.644V218.681H223.102V215.608ZM236.929 201.78C236.929 209.417 230.738 215.608 223.102 215.608V218.681C232.436 218.681 240.002 211.114 240.002 201.78H236.929ZM236.929 155.687V201.78H240.002V155.687H236.929ZM223.101 141.859C230.738 141.859 236.929 148.05 236.929 155.687H240.002C240.002 146.353 232.436 138.786 223.101 138.786V141.859ZM161.644 141.859H223.101V138.786H161.644V141.859ZM160.107 78.8648V140.322H163.18V78.8648H160.107ZM146.28 65.0368C153.917 65.0368 160.107 71.2278 160.107 78.8648H163.18C163.18 69.5307 155.614 61.9639 146.28 61.9639V65.0368ZM100.186 65.0368H146.28V61.9639H100.186V65.0368Z"
      fill="black"
      mask="url(#path-3-inside-2)"
    />
    <mask id="path-5-inside-3" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.186 25.0894C91.7008 25.0894 84.822 31.9682 84.822 40.4538V101.911H23.3644C14.8789 101.911 8 108.79 8 117.276L8 163.369C8 171.854 14.8789 178.733 23.3644 178.733H84.822V240.191C84.822 248.676 91.7008 255.555 100.186 255.555H146.28C154.765 255.555 161.644 248.676 161.644 240.191V178.733H223.102C231.587 178.733 238.466 171.854 238.466 163.369V117.276C238.466 108.79 231.587 101.911 223.101 101.911H161.644V40.4537C161.644 31.9682 154.765 25.0894 146.28 25.0894H100.186Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.186 25.0894C91.7008 25.0894 84.822 31.9682 84.822 40.4538V101.911H23.3644C14.8789 101.911 8 108.79 8 117.276L8 163.369C8 171.854 14.8789 178.733 23.3644 178.733H84.822V240.191C84.822 248.676 91.7008 255.555 100.186 255.555H146.28C154.765 255.555 161.644 248.676 161.644 240.191V178.733H223.102C231.587 178.733 238.466 171.854 238.466 163.369V117.276C238.466 108.79 231.587 101.911 223.101 101.911H161.644V40.4537C161.644 31.9682 154.765 25.0894 146.28 25.0894H100.186Z"
      fill="#61CA97"
    />
    <path
      d="M84.822 101.911V103.448H86.3584V101.911H84.822ZM8 117.276H9.53644H8ZM8 163.369H6.46356H8ZM84.822 178.733H86.3584V177.197H84.822V178.733ZM161.644 178.733V177.197H160.107V178.733H161.644ZM161.644 101.911H160.107V103.448H161.644V101.911ZM86.3584 40.4538C86.3584 32.8168 92.5494 26.6258 100.186 26.6258V23.5529C90.8523 23.5529 83.2855 31.1197 83.2855 40.4538H86.3584ZM86.3584 101.911V40.4538H83.2855V101.911H86.3584ZM23.3644 103.448H84.822V100.375H23.3644V103.448ZM9.53644 117.276C9.53644 109.639 15.7274 103.448 23.3644 103.448V100.375C14.0303 100.375 6.46356 107.942 6.46356 117.276H9.53644ZM9.53644 163.369L9.53644 117.276H6.46356L6.46356 163.369H9.53644ZM23.3644 177.197C15.7274 177.197 9.53644 171.006 9.53644 163.369H6.46356C6.46356 172.703 14.0303 180.27 23.3644 180.27V177.197ZM84.822 177.197H23.3644V180.27H84.822V177.197ZM86.3584 240.191V178.733H83.2855V240.191H86.3584ZM100.186 254.019C92.5494 254.019 86.3584 247.828 86.3584 240.191H83.2855C83.2855 249.525 90.8523 257.092 100.186 257.092V254.019ZM146.28 254.019H100.186V257.092H146.28V254.019ZM160.107 240.191C160.107 247.828 153.917 254.019 146.28 254.019V257.092C155.614 257.092 163.18 249.525 163.18 240.191H160.107ZM160.107 178.733V240.191H163.18V178.733H160.107ZM223.102 177.197H161.644V180.27H223.102V177.197ZM236.929 163.369C236.929 171.006 230.738 177.197 223.102 177.197V180.27C232.436 180.27 240.002 172.703 240.002 163.369H236.929ZM236.929 117.276V163.369H240.002V117.276H236.929ZM223.101 103.448C230.738 103.448 236.929 109.639 236.929 117.276H240.002C240.002 107.942 232.436 100.375 223.101 100.375V103.448ZM161.644 103.448H223.101V100.375H161.644V103.448ZM160.107 40.4537V101.911H163.18V40.4537H160.107ZM146.28 26.6258C153.917 26.6258 160.107 32.8168 160.107 40.4537H163.18C163.18 31.1197 155.614 23.5529 146.28 23.5529V26.6258ZM100.186 26.6258H146.28V23.5529H100.186V26.6258Z"
      fill="black"
      mask="url(#path-5-inside-3)"
    />
    <path
      d="M23.3639 216.376L22.5957 177.965H24.1321L23.3639 216.376Z"
      fill="black"
    />
    <path
      d="M223.101 216.376L222.333 177.965H223.869L223.101 216.376Z"
      fill="black"
    />
    <path
      d="M100.186 293.198L99.418 254.787H100.954L100.186 293.198Z"
      fill="black"
    />
    <path
      d="M146.28 293.198L145.512 254.787H147.048L146.28 293.198Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.082 245.017H111.263V247.108H100.81V245.017H104.991L104.991 235.609H107.082L107.082 245.017Z"
      fill="#767676"
    />
    <path
      d="M107.082 245.017H106.314V245.785H107.082V245.017ZM111.263 245.017H112.031V244.249H111.263V245.017ZM111.263 247.108V247.876H112.031V247.108H111.263ZM100.81 247.108H100.041V247.876H100.81V247.108ZM100.81 245.017V244.249H100.041V245.017H100.81ZM104.991 245.017V245.785H105.759V245.017H104.991ZM104.991 235.609V234.841H104.223V235.609L104.991 235.609ZM107.082 235.609L107.85 235.609V234.841H107.082V235.609ZM107.082 245.785H111.263V244.249H107.082V245.785ZM110.495 245.017V247.108H112.031V245.017H110.495ZM111.263 246.34H100.81V247.876H111.263V246.34ZM101.578 247.108V245.017H100.041V247.108H101.578ZM100.81 245.785H104.991V244.249H100.81V245.785ZM105.759 245.017L105.759 235.609L104.223 235.609L104.223 245.017H105.759ZM104.991 236.377H107.082V234.841H104.991V236.377ZM106.314 235.609L106.314 245.017H107.85L107.85 235.609L106.314 235.609Z"
      fill="black"
    />
    <path
      d="M106.374 197.209L107.85 230.001L98.2824 233.665L77.1846 208.386L106.374 197.209Z"
      fill="url(#paint0_linear)"
    />
    <rect
      x="99.1396"
      y="234.048"
      width="8.91724"
      height="8.91724"
      transform="rotate(-20.9533 99.1396 234.048)"
      fill="#767676"
      stroke="black"
      strokeWidth="1.53644"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.715 245.017H135.534V247.108H145.987V245.017H141.806L141.806 235.609H139.715L139.715 245.017Z"
      fill="#767676"
    />
    <path
      d="M139.715 245.017H140.483V245.785H139.715V245.017ZM135.534 245.017H134.765V244.249H135.534V245.017ZM135.534 247.108V247.876H134.765V247.108H135.534ZM145.987 247.108H146.756V247.876H145.987V247.108ZM145.987 245.017V244.249H146.756V245.017H145.987ZM141.806 245.017V245.785H141.037V245.017H141.806ZM141.806 235.609V234.841H142.574V235.609L141.806 235.609ZM139.715 235.609L138.947 235.609V234.841H139.715V235.609ZM139.715 245.785H135.534V244.249H139.715V245.785ZM136.302 245.017V247.108H134.765V245.017H136.302ZM135.534 246.34H145.987V247.876H135.534V246.34ZM145.219 247.108V245.017H146.756V247.108H145.219ZM145.987 245.785H141.806V244.249H145.987V245.785ZM141.037 245.017L141.037 235.609L142.574 235.609L142.574 245.017H141.037ZM141.806 236.377H139.715V234.841H141.806V236.377ZM140.483 235.609L140.483 245.017H138.947L138.947 235.609L140.483 235.609Z"
      fill="black"
    />
    <path
      d="M140.424 197.209L138.948 230.001L148.515 233.665L169.613 208.386L140.424 197.209Z"
      fill="url(#paint1_linear)"
    />
    <rect
      x="-0.992139"
      y="0.442699"
      width="8.91724"
      height="8.91724"
      transform="matrix(-0.933872 -0.357606 -0.357606 0.933872 146.891 233.279)"
      fill="#767676"
      stroke="black"
      strokeWidth="1.53644"
    />
    <rect
      x="197.027"
      y="100.413"
      width="33.0334"
      height="17.6691"
      fill="#FFC700"
      stroke="black"
      strokeWidth="1.53644"
    />
    <circle
      cx="203.941"
      cy="111.168"
      r="3.8411"
      fill="black"
      stroke="black"
      strokeWidth="1.53644"
    />
    <circle cx="203.941" cy="111.168" r="1.53644" fill="#DADADA" />
    <circle
      cx="223.146"
      cy="111.168"
      r="3.8411"
      fill="black"
      stroke="black"
      strokeWidth="1.53644"
    />
    <rect
      x="210.855"
      y="108.095"
      width="5.37754"
      height="4.60932"
      fill="#0273F5"
      stroke="black"
      strokeWidth="1.53644"
    />
    <rect
      x="210.087"
      y="114.241"
      width="6.91398"
      height="1.53644"
      fill="black"
    />
    <rect
      x="199.332"
      y="102.718"
      width="28.4241"
      height="2.30466"
      fill="black"
    />
    <circle cx="223.147" cy="111.168" r="1.53644" fill="#DADADA" />
    <rect
      x="206.246"
      y="96.5719"
      width="14.5962"
      height="3.8411"
      stroke="black"
      strokeWidth="1.53644"
    />
    <rect
      x="29.554"
      y="88.1214"
      width="4.60932"
      height="4.60932"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
    />
    <mask
      id="path-29-outside-4"
      maskUnits="userSpaceOnUse"
      x="23.7129"
      y="112.241"
      width="16"
      height="11"
      fill="black"
    >
      <rect fill="white" x="23.7129" y="112.241" width="16" height="11" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3222 114.241H33.3951V117.314L38.0044 121.155L31.8586 119.618L25.7129 121.155L30.3222 117.314V114.241Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3222 114.241H33.3951V117.314L38.0044 121.155L31.8586 119.618L25.7129 121.155L30.3222 117.314V114.241Z"
      fill="#CA9B71"
    />
    <path
      d="M33.3951 114.241H34.9315C34.9315 113.392 34.2436 112.704 33.3951 112.704V114.241ZM30.3222 114.241V112.704C29.4737 112.704 28.7858 113.392 28.7858 114.241H30.3222ZM33.3951 117.314H31.8586C31.8586 117.77 32.0612 118.202 32.4115 118.494L33.3951 117.314ZM38.0044 121.155L37.6318 122.645C38.3269 122.819 39.0499 122.489 39.3743 121.85C39.6988 121.212 39.5385 120.433 38.988 119.974L38.0044 121.155ZM31.8586 119.618L32.2313 118.128C31.9866 118.067 31.7307 118.067 31.486 118.128L31.8586 119.618ZM25.7129 121.155L24.7293 119.974C24.1788 120.433 24.0185 121.212 24.343 121.85C24.6674 122.489 25.3904 122.819 26.0855 122.645L25.7129 121.155ZM30.3222 117.314L31.3058 118.494C31.6561 118.202 31.8586 117.77 31.8586 117.314H30.3222ZM33.3951 112.704H30.3222V115.777H33.3951V112.704ZM34.9315 117.314V114.241H31.8586V117.314H34.9315ZM32.4115 118.494L37.0208 122.335L38.988 119.974L34.3787 116.133L32.4115 118.494ZM38.377 119.664L32.2313 118.128L31.486 121.109L37.6318 122.645L38.377 119.664ZM26.0855 122.645L32.2313 121.109L31.486 118.128L25.3402 119.664L26.0855 122.645ZM29.3386 116.133L24.7293 119.974L26.6965 122.335L31.3058 118.494L29.3386 116.133ZM28.7858 114.241V117.314H31.8586V114.241H28.7858Z"
      fill="black"
      mask="url(#path-29-outside-4)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8583 99.6448H26.4807L22.6396 114.241L31.8581 114.241H41.0767L36.8515 99.6448L31.8583 99.6448Z"
      fill="#CA9B71"
    />
    <path
      d="M26.4807 99.6448V98.8766H25.8885L25.7378 99.4493L26.4807 99.6448ZM31.8583 99.6448L31.8583 98.8766H31.8583V99.6448ZM22.6396 114.241L21.8967 114.045L21.6431 115.009L22.6396 115.009L22.6396 114.241ZM31.8581 114.241L31.8581 115.009H31.8581V114.241ZM41.0767 114.241V115.009H42.0989L41.8147 114.027L41.0767 114.241ZM36.8515 99.6448L37.5894 99.4312L37.4289 98.8766L36.8515 98.8766L36.8515 99.6448ZM26.4807 100.413H31.8583V98.8766H26.4807V100.413ZM23.3826 114.436L27.2237 99.8403L25.7378 99.4493L21.8967 114.045L23.3826 114.436ZM31.8581 113.473L22.6396 113.473L22.6396 115.009L31.8581 115.009L31.8581 113.473ZM31.8581 115.009H41.0767V113.473H31.8581V115.009ZM41.8147 114.027L37.5894 99.4312L36.1136 99.8584L40.3388 114.455L41.8147 114.027ZM36.8515 98.8766L31.8583 98.8766L31.8583 100.413L36.8515 100.413L36.8515 98.8766Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1035 111.936L23.4081 100.925L20.3352 103.144L16.4941 97.9376L24.1763 91.9625H31.8585H39.5407L47.2229 97.9376L43.3818 103.144L40.309 100.925L42.6136 111.936H31.8585H21.1035Z"
      fill="white"
    />
    <path
      d="M23.4081 100.925L24.16 101.082L24.5664 99.1409L22.9583 100.302L23.4081 100.925ZM21.1035 111.936L20.3515 111.779L20.1578 112.704H21.1035V111.936ZM20.3352 103.144L19.717 103.6L20.1685 104.212L20.785 103.767L20.3352 103.144ZM16.4941 97.9376L16.0225 97.3312L15.4313 97.791L15.8759 98.3936L16.4941 97.9376ZM24.1763 91.9625V91.1943H23.9128L23.7047 91.3561L24.1763 91.9625ZM39.5407 91.9625L40.0124 91.3561L39.8043 91.1943H39.5407V91.9625ZM47.2229 97.9376L47.8411 98.3936L48.2857 97.791L47.6946 97.3312L47.2229 97.9376ZM43.3818 103.144L42.932 103.767L43.5486 104.212L44 103.6L43.3818 103.144ZM40.309 100.925L40.7587 100.302L39.1507 99.1409L39.557 101.082L40.309 100.925ZM42.6136 111.936V112.704H43.5593L43.3655 111.779L42.6136 111.936ZM22.6562 100.768L20.3515 111.779L21.8554 112.094L24.16 101.082L22.6562 100.768ZM20.785 103.767L23.8579 101.548L22.9583 100.302L19.8855 102.522L20.785 103.767ZM15.8759 98.3936L19.717 103.6L20.9534 102.688L17.1123 97.4815L15.8759 98.3936ZM23.7047 91.3561L16.0225 97.3312L16.9658 98.544L24.648 92.5689L23.7047 91.3561ZM31.8585 91.1943H24.1763V92.7307H31.8585V91.1943ZM31.8585 92.7307H39.5407V91.1943H31.8585V92.7307ZM39.0691 92.5689L46.7513 98.544L47.6946 97.3312L40.0124 91.3561L39.0691 92.5689ZM46.6047 97.4815L42.7636 102.688L44 103.6L47.8411 98.3936L46.6047 97.4815ZM43.8316 102.522L40.7587 100.302L39.8592 101.548L42.932 103.767L43.8316 102.522ZM39.557 101.082L41.8617 112.094L43.3655 111.779L41.0609 100.768L39.557 101.082ZM42.6136 111.168H31.8585V112.704H42.6136V111.168ZM21.1035 112.704H31.8585V111.168H21.1035V112.704Z"
      fill="black"
    />
    <mask
      id="path-35-outside-5"
      maskUnits="userSpaceOnUse"
      x="116.81"
      y="-0.116055"
      width="9.15108"
      height="14.018"
      fill="black"
    >
      <rect
        fill="white"
        x="116.81"
        y="-0.116055"
        width="9.15108"
        height="14.018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.579 4.85921C121.935 4.55716 122.125 4.08003 122.038 3.58767C121.908 2.84895 121.203 2.3557 120.465 2.48595C119.726 2.61621 119.233 3.32065 119.363 4.05937C119.45 4.55205 119.792 4.93555 120.229 5.09771L121.221 10.7215C121.287 11.0942 121.642 11.3432 122.015 11.2774C122.388 11.2117 122.637 10.8561 122.571 10.4834L121.579 4.85921Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.579 4.85921C121.935 4.55716 122.125 4.08003 122.038 3.58767C121.908 2.84895 121.203 2.3557 120.465 2.48595C119.726 2.61621 119.233 3.32065 119.363 4.05937C119.45 4.55205 119.792 4.93555 120.229 5.09771L121.221 10.7215C121.287 11.0942 121.642 11.3432 122.015 11.2774C122.388 11.2117 122.637 10.8561 122.571 10.4834L121.579 4.85921Z"
      fill="#FF5739"
    />
    <path
      d="M121.579 4.85921L120.584 3.68873L119.913 4.25903L120.066 5.12601L121.579 4.85921ZM120.229 5.09771L121.743 4.83091L121.59 3.96324L120.763 3.65704L120.229 5.09771ZM122.571 10.4834L124.084 10.2166L122.571 10.4834ZM122.575 6.02969C123.328 5.38896 123.736 4.37207 123.551 3.32087L120.525 3.85447C120.513 3.78799 120.541 3.72536 120.584 3.68873L122.575 6.02969ZM123.551 3.32087C123.273 1.74649 121.772 0.695249 120.198 0.972855L120.731 3.99905C120.634 4.01614 120.542 3.95141 120.525 3.85447L123.551 3.32087ZM120.198 0.972855C118.623 1.25046 117.572 2.75179 117.85 4.32617L120.876 3.79257C120.893 3.88951 120.828 3.98196 120.731 3.99905L120.198 0.972855ZM117.85 4.32617C118.035 5.37807 118.767 6.1944 119.696 6.53838L120.763 3.65704C120.816 3.67671 120.864 3.72604 120.876 3.79257L117.85 4.32617ZM118.716 5.36451L119.708 10.9883L122.734 10.4547L121.743 4.83091L118.716 5.36451ZM119.708 10.9883C119.921 12.1966 121.074 13.0036 122.282 12.7905L121.748 9.76435C122.211 9.68272 122.653 9.99171 122.734 10.4547L119.708 10.9883ZM122.282 12.7905C123.49 12.5775 124.297 11.425 124.084 10.2166L121.058 10.7502C120.976 10.2873 121.285 9.84599 121.748 9.76435L122.282 12.7905ZM124.084 10.2166L123.092 4.59241L120.066 5.12601L121.058 10.7502L124.084 10.2166Z"
      fill="black"
      mask="url(#path-35-outside-5)"
    />
    <path
      d="M118.387 26.6376L130.673 24.4712C133.782 23.923 135.856 20.9632 135.309 17.8621L134.111 11.0632C133.563 7.95707 130.597 5.89102 127.494 6.43827L115.207 8.60473C112.099 9.15285 110.024 12.1127 110.571 15.2138L111.77 22.0127C112.317 25.1138 115.278 27.1857 118.387 26.6376Z"
      fill="#FF5739"
      stroke="black"
      strokeWidth="1.53644"
    />
    <path
      d="M130.097 21.9002L118.05 24.0244C116.309 24.3314 114.641 23.1347 114.326 21.3537L113.199 14.9587C112.885 13.1776 114.043 11.4826 115.784 11.1757L127.831 9.05143C129.572 8.74446 131.24 9.94107 131.554 11.7221L132.682 18.1172C133 19.8975 131.842 21.5925 130.097 21.9002Z"
      fill="black"
    />
    <path
      d="M123.506 19.6888C122.869 19.801 122.22 19.5652 121.805 19.071C121.727 18.9784 121.738 18.8434 121.831 18.765C121.924 18.6866 122.059 18.6983 122.137 18.7909C122.451 19.1654 122.949 19.3481 123.43 19.2632C123.912 19.1783 124.318 18.8407 124.484 18.3769C124.526 18.2631 124.649 18.206 124.763 18.248C124.877 18.29 124.934 18.4129 124.892 18.5267C124.671 19.1331 124.138 19.5774 123.506 19.6888Z"
      fill="white"
      stroke="white"
      strokeWidth="0.76822"
    />
    <path
      d="M119.365 19.1599L118.424 19.3259C117.529 19.4836 116.679 18.8844 116.522 17.9944L116.141 15.8361C115.984 14.9419 116.583 14.0915 117.473 13.9346L118.415 13.7685C119.309 13.6109 120.159 14.2101 120.316 15.1L120.697 17.2583C120.854 18.1483 120.259 19.0022 119.365 19.1599Z"
      fill="white"
    />
    <path
      d="M128.153 17.6104L127.212 17.7765C126.317 17.9341 125.467 17.3349 125.31 16.445L124.93 14.2867C124.772 13.3925 125.371 12.542 126.261 12.3851L127.203 12.2191C128.097 12.0614 128.947 12.6606 129.104 13.5506L129.485 15.7089C129.642 16.5988 129.048 17.4528 128.153 17.6104Z"
      fill="white"
    />
    <path
      d="M104.442 18.7741C104.614 17.9611 105.419 17.4361 106.232 17.6074L109.029 18.1968C109.842 18.3682 110.367 19.1736 110.195 19.9867C110.024 20.7998 109.218 21.3248 108.405 21.1534L105.609 20.564C104.796 20.3926 104.271 19.5872 104.442 18.7741Z"
      fill="black"
    />
    <path
      d="M106.691 16.1603C106.862 15.3473 107.668 14.8223 108.481 14.9936L111.289 15.5856C112.102 15.757 112.627 16.5624 112.456 17.3755C112.285 18.1885 111.479 18.7136 110.666 18.5422L107.857 17.9502C107.044 17.7788 106.519 16.9734 106.691 16.1603Z"
      fill="black"
    />
    <path
      d="M109.489 17.8419L109.49 17.8411C110.42 16.2202 112.492 15.6528 114.107 16.5789L114.107 16.579L124.76 22.6843L124.761 22.6847C126.39 23.6159 126.949 25.6839 126.022 27.3007C126.022 27.3008 126.022 27.3008 126.022 27.3009L124.702 29.5952L124.701 29.5971C123.77 31.2269 121.702 31.7856 120.085 30.8582L120.085 30.8581L109.432 24.7529L109.431 24.7524C107.801 23.8212 107.242 21.753 108.17 20.1362L109.489 17.8419Z"
      fill="white"
      stroke="black"
      strokeWidth="1.53644"
    />
    <path
      d="M104.986 22.9675C105.072 22.1362 105.816 21.534 106.647 21.6192L109.494 21.9103C110.325 21.9955 110.927 22.74 110.842 23.5712C110.757 24.4024 110.012 25.0046 109.181 24.9195L106.335 24.6283C105.503 24.5432 104.901 23.7987 104.986 22.9675Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M125.464 22.1134L124.371 21.4875L118.844 31.1337L119.937 31.7596L125.464 22.1134Z"
      fill="black"
    />
    <path
      d="M133.871 24.1236L133.206 23.7395L132.822 24.4048L130.791 27.922C130.128 29.0715 128.652 29.4669 127.502 28.8033L125.644 27.7302C124.487 27.0625 124.098 25.592 124.763 24.4412L128.381 18.1736L128.766 17.5068C129.43 16.3574 130.906 15.9619 132.055 16.6256L133.914 17.6986L137.864 19.9795C139.014 20.6432 139.409 22.119 138.746 23.2685L138.361 23.9352C137.697 25.0847 136.221 25.4802 135.072 24.8165L133.871 24.1236Z"
      fill="#FFC700"
      stroke="black"
      strokeWidth="1.53644"
    />
    <path
      opacity="0.2"
      d="M125.093 22.3318L132.452 26.5808L132.933 25.7473L125.574 21.4984L125.093 22.3318Z"
      fill="black"
    />
    <rect
      x="112.522"
      y="26.6638"
      width="21.5101"
      height="13.828"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
    />
    <rect
      x="120.204"
      y="28.9685"
      width="6.14576"
      height="1.53644"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="91.7794"
        y1="202.797"
        x2="102.92"
        y2="231.889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC700" stopOpacity="0" />
        <stop offset="1" stopColor="#FFC700" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="155.018"
        y1="202.797"
        x2="143.878"
        y2="231.889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5739" stopOpacity="0" />
        <stop offset="1" stopColor="#FF5739" />
      </linearGradient>
    </defs>
    <IslandLabel
      levelText="Start"
      titleText="Home"
      x={63}
      y={107}
      styles={styles}
    />
  </svg>
);

HomeIsland.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
  scale: PropTypes.number,
  visited: PropTypes.string,
};

export default HomeIsland;
