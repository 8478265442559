import Button from "button";
import styles from "./styles.module.scss";
import Painting from "./painting.svg";
import HammerSpanner from "./hammer_spanner.svg";
import Tada from "./tada.svg";

const HowDoesSharingWork = ({ onSubmit }) => {
  return (
    <div className={styles.sharing_wrapper}>
      <h2 className={styles.heading}>How does sharing work?</h2>
      <div className={styles.sharing_how_sharing_works_content}>
        <div className={styles.sharing_how_sharing_works__bullet}>
          <img className={styles.sharing_success_robot} src={Painting} alt="" />
          <p className={styles.paragraph}>
            A version of your project will be shared to the gallery.
          </p>
        </div>
        <div className={styles.sharing_how_sharing_works__bullet}>
          <img
            className={styles.sharing_success_robot}
            src={HammerSpanner}
            alt=""
          />
          <p className={styles.paragraph}>
            You will still be able to make changes to your project, but the
            version in the gallery will stay the same.
          </p>
        </div>
        <div className={styles.sharing_how_sharing_works__bullet}>
          <img className={styles.sharing_success_robot} src={Tada} alt="" />
          <p className={styles.paragraph}>
            You can share as many versions of your project to the gallery as you
            like!
          </p>
        </div>
      </div>
      <div className={styles.sharing_buttons}>
        <Button.RectanglePrimary
          text={"Next"}
          type="button"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

HowDoesSharingWork.propTypes = {
  onSubmit: PropTypes.func,
};

export default HowDoesSharingWork;
