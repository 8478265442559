const RobotShadow = ({ width = 141, height = 142, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 141 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle
      cx="70.4341"
      cy="71.366"
      r="70.4341"
      fill="url(#paint0_radial)"
      fillOpacity="0.4"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(70.4341 71.366) rotate(90) scale(70.4341)"
      >
        <stop />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

RobotShadow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default RobotShadow;
