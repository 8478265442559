import CustomReactField from "./custom_react_field";
import Blockly from "blockly";
import { Scale } from "@tonaljs/tonal";
import Run from "assets/run.svg";
import Button from "button";
import AudioEngine from "visualisations/music/audio_engine";
import { AudioContext } from "standardized-audio-context";
import BeatGrid from "beat_grid";

const audioEngine = new AudioEngine(new AudioContext());
audioEngine.init();
const beatSelection = [
  "ABCEFGbc@..--.---|--.-.-.-|.-.---.-|--.--.--|-.---.--|..---.--|--.---.-|.-------",
  "ABCEFGbc@.---.---|--------|--.---.-|-.-.-.--|.-.-.---|------..|---.---.|-.---.--",
  "ABCEFGbc@--------|.-.-.-.-|--------|-.-.-.-.|.-.-.---|------.-|--------|--------",
  "ABCEFGbc@-.------|.---..--|--.---.-|--------|.---..--|-...--..|.-------|-------.",
  "ABCEFGbc@--.---.-|.---.---|-.---.-.|-.-.-.--|--------|------..|-------.|-.---.--",
  "ABCEFGbc@.----.--|.----.--|--.---.-|--.---.-|--------|..-...-.|--------|--------",
  "ABCEFGbc@.-------|.-------|--.--.--|-.-..-.-|--------|.-.-.-.-|--.-.--.|--------",
  "ABCEFGbc@.-.-.-.-|--------|--.---.-|--------|...-...-|---.---.|-.-.-.-.|--------",
];

const midiNoteForMelodyNote = (melodyNote) => {
  const isLowerCase = (note) => note.toLowerCase() === note;
  const octave = (note) => (isLowerCase(note) ? 5 : 4);
  const upperNote = (note) => note.toUpperCase();
  const midiOctave = (note) =>
    "ABab".includes(note) > 0 ? octave(note) - 1 : octave(note);
  const midiNote = (note) => upperNote(note) + midiOctave(note);
  return midiNote(melodyNote);
};

const BeatBlockUI = ({ beat }) => {
  return (
    <BeatGrid
      width={85}
      height={70}
      margin={4}
      renderDetail={false}
      beat={beat}
    />
  );
};

const scheduleNote = (note, instrument, time) => {
  const midiNote = midiNoteForMelodyNote(note);
  const eighthNote = 0.5;
  audioEngine.scheduleNote(
    instrument,
    120,
    time,
    noteIndexForMidiNote(midiNote),
    eighthNote,
    () => {}
  );
};

const playDrumNote = (note) => {
  scheduleNote(note, "drumkit", audioEngine.getCurrentTime());
};

const playDrumBeat = (beats) => {
  const tempo = 0.25;
  const now = audioEngine.getCurrentTime();
  const note_lists = parseBeatIntoNoteLists(beats);
  note_lists.forEach((notes, index) => {
    for (const note of notes) {
      if (note != "-") {
        scheduleNote(note, "drumkit", now + index * tempo);
      }
    }
  });
};

const BeatDropDownUI = ({ beat, setBeat }) => {
  const beatChanged = (new_beat) => {
    setBeat(new_beat);
  };

  const playDrum = (note) => {
    playDrumNote(note);
  };

  const playBeat = () => {
    playDrumBeat(beat);
  };

  const randomise = () => {
    const index = Math.floor(Math.random() * beatSelection.length);
    setBeat(beatSelection[index]);
  };

  return (
    <>
      <BeatGrid
        width={278}
        height={278}
        margin={4}
        renderDetail={true}
        beat={beat}
        onBeatChanged={beatChanged}
        playDrum={playDrum}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          columnGap: "10px",
          alignItems: "center",
          height: "40px",
          padding: "10px",
          backgroundColor: "#BF5AA4",
        }}
      >
        <Button.BlocklyControl text="Random" onClick={randomise} />
        <Button.BlocklyControl text="Play" icon={<Run />} onClick={playBeat} />
      </div>
    </>
  );
};

BeatBlockUI.propTypes = {
  beat: PropTypes.string,
};

BeatDropDownUI.propTypes = {
  beat: PropTypes.string,
  setBeat: PropTypes.func,
};

class BeatField extends CustomReactField {
  constructor(value, validator) {
    super(value, validator);
    this.dropDownDivClass = "playBeatDropDownContent";
    this.fixedSize = new Blockly.utils.Size(86, 70);
  }

  renderSVGforBlockUI(value) {
    return <BeatBlockUI beat={value} />;
  }

  renderSVGforDropDown(value, setValue) {
    return <BeatDropDownUI beat={value} setBeat={setValue} />;
  }

  static fromJson(options) {
    return new BeatField(options.variable);
  }
}

Blockly.fieldRegistry.register("field_select_beat", BeatField);

Blockly.Blocks.PlayBeat = {
  init: function () {
    this.jsonInit({
      message0: "play beat %1",
      previousStatement: "Statement",
      nextStatement: "Statement",
      args0: [
        {
          type: "field_select_beat",
          name: "beat",
          variable:
            "ABCEFGbc@.---.---|--.---.-|.-.-.-.-|------.-|-.---.--|..---.--|--.---.-|.-------",
        },
      ],
    });
  },
};

const noteIndexForMidiNote = (note) => {
  return Scale.rangeOf("c major")("A3", "C5").findIndex((n) => n === note);
};

const instrumentLines = (serializedBeat) => {
  const [instruments, beats] = serializedBeat.split("@");
  const lines = beats.split("|");
  return lines.map((line, index) => line.replaceAll(".", instruments[index]));
};

const parseBeatIntoNoteLists = (serializedBeat) => {
  const numberOfNotes = 8;
  const template = Array(numberOfNotes);
  const noteLists = Array.from(template, (_, i) =>
    instrumentLines(serializedBeat).map((a) => a[i])
  );
  return noteLists;
};

Blockly.JavaScript.PlayBeat = function (block) {
  const beats = block.getFieldValue("beat");
  const lines = instrumentLines(beats);
  const instrument = "drumkit";
  const eighthNote = 0.5;
  const commands = [];

  commands.push(`visualisation.setInstrument("${instrument}");`);
  commands.push(`visualisation.beginPlayTogether();`);
  for (const line of lines) {
    const notes = line.split("");
    const notRest = (note) => note !== "-";
    if (notes.some(notRest)) {
      for (const note of notes) {
        if (notRest(note)) {
          const idx = noteIndexForMidiNote(midiNoteForMelodyNote(note));
          commands.push(` visualisation.playNote(${idx}, ${eighthNote});`);
        } else {
          commands.push(` visualisation.rest(${eighthNote});`);
        }
      }
      commands.push(" visualisation.rewind();");
    }
  }
  commands.push(`visualisation.endPlayTogether();`);
  return commands.join("\n");
};
