import palette from "root/js_palette";

const CommunityIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill={palette.primary.black}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="13.5" height="13.5" rx="3.75" />
    <rect y="16.5" width="13.5" height="13.5" rx="3.75" />
    <rect x="16.5" y="16.5" width="13.5" height="13.5" rx="3.75" />
    <rect x="16.5" width="13.5" height="13.5" rx="3.75" />
  </svg>
);

export default CommunityIcon;
