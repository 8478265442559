import BassGalleryImage from "./bass.svg";
import BellsGalleryImage from "./bells.svg";
import BoomboxGalleryImage from "./boombox.svg";
import DrumGalleryImage from "./drum.svg";
import DrumkitGalleryImage from "./drumkit.svg";
import KeyboardGalleryImage from "./keyboard.svg";
import MixerGalleryImage from "./mixer.svg";
import NotesGalleryImage from "./notes.svg";
import TrumpetGalleryImage from "./trumpet.svg";
import ViolinGalleryImage from "./violin.svg";
import WaveGalleryImage from "./wave.svg";
import WmpGalleryImage from "./wmp.svg";

export default {
  music: {
    bass: BassGalleryImage,
    bells: BellsGalleryImage,
    boombox: BoomboxGalleryImage,
    drum: DrumGalleryImage,
    drumkit: DrumkitGalleryImage,
    keyboard: KeyboardGalleryImage,
    mixer: MixerGalleryImage,
    notes: NotesGalleryImage,
    trumpet: TrumpetGalleryImage,
    violin: ViolinGalleryImage,
    wave: WaveGalleryImage,
    wmp: WmpGalleryImage,
  },
};
