import Layout from "layout";
import Heading from "heading";
import styles from "./styles.module.scss";

const NotFoundPage = () => {
  return (
    <Layout>
      <Heading title={<h1>Page not found</h1>} backPath="/" />

      <div className={styles.not_found_page}>
        <h2>The page you were looking for doesn&apos;t exist.</h2>
        <p>You may have mistyped the address or the page may have moved.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
