import Indiana from "react-indiana-drag-scroll";
import styles from "./styles.module.scss";

const Draggable = ({ children, channel = {} }) => {
  const ref = useRef();
  const [firstScroll, setFirstScroll] = useState(true);
  const [, setViewportBox] = useState();

  channel.topLeftPosition = () => {
    const element = ref.current.getElement();
    return { left: element.scrollLeft, top: element.scrollTop };
  };

  channel.moveToTopLeftPosition = (x, y) => {
    const behavior = firstScroll ? "auto" : "smooth";
    ref.current.getElement().scrollTo({ left: x, top: y, behavior });

    setFirstScroll(false);
  };

  channel.moveToCentrePosition = (x, y) => {
    const viewport = ref.current.getElement();
    const viewportBox = viewport.getBoundingClientRect();

    const halfWidth = viewportBox.width / 2;
    const halfHeight = viewportBox.height / 2;

    channel.moveToTopLeftPosition(x - halfWidth, y - halfHeight);
  };

  channel.moveToCentreOfElement = (element) => {
    const box = channel.boxRelativeToContent(element);

    channel.moveToCentrePosition(box.centreX, box.centreY);

    return box;
  };

  channel.boxRelativeToContent = (element) => {
    const viewport = ref.current.getElement();

    const elementBox = element.getBoundingClientRect();
    const viewportBox = viewport.getBoundingClientRect();

    const xRelativeToViewport = elementBox.left - viewportBox.left;
    const yRelativeToViewport = elementBox.top - viewportBox.top;

    const xRelativeToContent = xRelativeToViewport + viewport.scrollLeft;
    const yRelativeToContent = yRelativeToViewport + viewport.scrollTop;

    const centreX = xRelativeToContent + elementBox.width / 2;
    const centreY = yRelativeToContent + elementBox.height / 2;

    return {
      left: xRelativeToContent,
      top: yRelativeToContent,
      width: elementBox.width,
      height: elementBox.height,
      centreX,
      centreY,
    };
  };

  useResize(() => {
    const viewport = ref.current.getElement();
    const currentBox = viewport.getBoundingClientRect();

    setViewportBox((previousBox) => {
      preserveCentre(previousBox, currentBox);
      return currentBox;
    });
  });

  const preserveCentre = (previousBox, currentBox) => {
    if (!previousBox) {
      return;
    }

    const widthDelta = currentBox.width - previousBox.width;
    const heightDelta = currentBox.height - previousBox.height;

    const element = ref.current.getElement();
    element.scrollBy(-widthDelta / 2, -heightDelta / 2);
  };

  return (
    <div className={styles.draggable}>
      <Indiana className={styles.viewport} ref={ref} nativeMobileScroll={false}>
        {children}
      </Indiana>
    </div>
  );
};

Draggable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  channel: PropTypes.object,
};

// Wrapping a component with this one will allow it to be positioned relative to
// the inner content of the draggable area. This is achieved by setting some CSS
// rules on the inner content, such as:
//
// .my_component {
//   position: absolute;
//   left: 100px;
//   top: 100px;
// }
Draggable.Relative = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(styles.relative, className)} {...rest}>
      {children}
    </div>
  );
};
Draggable.Relative.displayName = "Draggable.Relative";

Draggable.Relative.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default Draggable;
