import CsrfContext from "csrf_context";
import SharingButton from "../sharing_button";
import Button from "button";
import styles from "../../styles.module.scss";

const ShareToGalleryMenuItem = ({
  workspace,
  activityKey,
  linkClassName,
  formClassName,
  onShare,
  canShareToCommunityGallery,
}) => {
  const routes = useRoutes();
  const csrfToken = useContext(CsrfContext);

  return (
    <>
      {!canShareToCommunityGallery && workspace.belongs_to_me && (
        <form
          action={routes.loginPath()}
          method="post"
          className={formClassName}
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Button.RectangleTertiary
            className={linkClassName}
            text="Log in to share"
            textAlways={true}
          ></Button.RectangleTertiary>
        </form>
      )}
      {canShareToCommunityGallery && (
        <SharingButton
          className={styles.link_button}
          activityKey={activityKey}
          workspace={workspace}
          onClick={onShare}
        />
      )}
    </>
  );
};

ShareToGalleryMenuItem.propTypes = {
  activityKey: PropTypes.string.isRequired,
  workspace: PropTypes.object.isRequired,
  linkClassName: PropTypes.string.isRequired,
  formClassName: PropTypes.string.isRequired,
  onShare: PropTypes.func.isRequired,
  canShareToCommunityGallery: PropTypes.bool.isRequired,
};

export default ShareToGalleryMenuItem;
