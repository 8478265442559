import Blockly from "blockly";
import songs from "visualisations/dance/songs";

const options = Object.entries(songs).map(([key, song]) => [song.name, key]);

Blockly.Blocks.CustomiseBoomboxSong = {
  message0: "start music %1",
  args0: [
    {
      type: "field_dropdown",
      name: "boombox_song",
      options,
    },
  ],
  previousStatement: "BoomboxClick",
};

Blockly.Blocks.CustomiseBoomboxSong.init = function () {
  this.jsonInit(Blockly.Blocks.CustomiseBoomboxSong);
};

Blockly.JavaScript.CustomiseBoomboxSong = function (block) {
  const boombox_song = block.getFieldValue("boombox_song");
  if (typeof boombox_song === "undefined") {
    return "";
  } else {
    return `visualisation.customiseBoomboxSong("${boombox_song}");`;
  }
};
