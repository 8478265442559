const Colours = {
  purple: "#b377f5",
  teal: "#f577d9",
  blue: "#63b4ff",
  orange: "#ff7a00",
  green: "#61ca97",
  yellow: "#ffc700",
  grey: "#aaaaaa",
  dark_grey: "#767676",
};

export default Colours;
