import text from "!!raw-loader!./palette.scss";

// This palette is derived from the the scss palette in this directory.

const lines = text.split("\n").filter((l) => l.startsWith("$"));
const palette = {};

for (const line of lines) {
  const [name, colour] = line.replace("$", "").replace(";", "").split(":");
  const [prefix, suffix] = name.split(/_(.+)/);

  palette[prefix] = palette[prefix] || {};
  palette[prefix][suffix] = colour.trim();
}

export default palette;
