import CardGrid from "card_grid";
import ActivityFilters from "activity_filters";
import MyProjectsCard from "../my_projects_card";
import Workspace from "workspace";
import Overlay from "overlay";
import ConfirmationPrompt from "confirmation_prompt";
import allContent from "../../workspaces_page/content";
import MyDataContext from "my_data_context";

const MyProjects = ({ styles }) => {
  const { workspaces } = useContext(MyDataContext);

  const routes = useRoutes();
  const [playingId, setPlayingId] = useState();
  const activityKey = new URLSearchParams(useLocation().search).get("activity");
  const [localWorkspaces, setLocalWorkspaces] = useState(workspaces);

  const buttonProps = (key) => {
    return {
      active: key ? activityKey === key : !activityKey,
      outline: key ? activityKey !== key : !!activityKey,
      href: profilePath({ key: key }),
    };
  };

  const profilePath = ({ key = null }) => {
    let path = `${routes.profilePath()}?tab=MyProjects`;

    if (key) {
      path = `${path}&activity=${key}`;
    }

    return path;
  };

  useEffect(() => {
    setLocalWorkspaces(
      workspaces.filter((w) => !activityKey || w.activity.key == activityKey)
    );
  }, [activityKey, workspaces]);

  const handlePlay = (index) => {
    setPlayingId(localWorkspaces[index].id);
  };

  const handleStop = () => {
    setPlayingId(null);
  };

  const handleFinished = () => {
    setPlayingId(null);
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [workspaceToBeDeleted, setWorkspaceToBeDeleted] = useState();

  const confirmDelete = (workspace) => {
    setWorkspaceToBeDeleted(workspace);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = () => {
    Workspace.delete(
      workspaceToBeDeleted,
      workspaceToBeDeleted.activity.key
    ).then(() => {
      location.reload();
    });
  };

  return (
    <>
      <ActivityFilters buttonProps={buttonProps} />
      <CardGrid extraClass={styles.card_grid}>
        {localWorkspaces.map((w, index) => {
          return (
            <MyProjectsCard
              key={w.id}
              styles={styles}
              allContent={allContent}
              playingId={playingId}
              onFinished={handleFinished}
              handleStop={handleStop}
              handlePlay={handlePlay}
              confirmDelete={confirmDelete}
              w={w}
              index={index}
              localWorkspaces={localWorkspaces}
            />
          );
        })}
      </CardGrid>
      {showDeleteConfirmation && (
        <div className="js-test-overlay">
          <Overlay>
            <ConfirmationPrompt
              question={`Are you sure you want to delete this ${
                allContent[workspaceToBeDeleted.activity.key].cardTitlePrefix
              }?`}
              dismissText="Cancel"
              onDismiss={() => setShowDeleteConfirmation(false)}
              confirmText="Delete"
              onConfirm={handleDeleteConfirmation}
            />
          </Overlay>
        </div>
      )}
    </>
  );
};

MyProjects.propTypes = {
  styles: PropTypes.object,
};

export default MyProjects;
