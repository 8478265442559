import ConfettiAnimation from "confetti_animation";

const useConfettiAnimation = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useResize(() => setWindowWidth(window.innerWidth), []);

  const start = useCallback(() => {
    const isNarrowWindow = windowWidth <= 768;
    if (!isNarrowWindow) ConfettiAnimation.start();
  }, [windowWidth]);
  return start;
};

export default useConfettiAnimation;
