import PropTypes from "prop-types";

const IconChooser = ({ type }) => {
  switch (type) {
    case "save":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1998 6.92505V18.925C21.1998 19.5584 20.9791 20.0961 20.5378 20.538C20.0958 20.9794 19.5581 21.2001 18.9248 21.2001H5.0748C4.44147 21.2001 3.90414 20.9794 3.4628 20.538C3.0208 20.0961 2.7998 19.5584 2.7998 18.925V5.07505C2.7998 4.44172 3.0208 3.90405 3.4628 3.46205C3.90414 3.02072 4.44147 2.80005 5.0748 2.80005H17.0748L21.1998 6.92505ZM18.9248 7.87505L16.1248 5.07505H5.0748V18.925H18.9248V7.87505ZM11.9998 17.925C12.8331 17.925 13.5415 17.6334 14.1248 17.05C14.7081 16.4667 14.9998 15.7584 14.9998 14.925C14.9998 14.0917 14.7081 13.3834 14.1248 12.8C13.5415 12.2167 12.8331 11.925 11.9998 11.925C11.1665 11.925 10.4581 12.2167 9.8748 12.8C9.29147 13.3834 8.9998 14.0917 8.9998 14.925C8.9998 15.7584 9.29147 16.4667 9.8748 17.05C10.4581 17.6334 11.1665 17.925 11.9998 17.925ZM6.0748 10.075H15.0748V6.07505H6.0748V10.075ZM5.0748 7.87505V18.925V5.07505V7.87505Z"
            fill="white"
          />
        </svg>
      );
    case "edit":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.0502 19H6.3502L14.9252 10.425L13.6252 9.12502L5.0502 17.7V19ZM19.3252 9.00002L15.0502 4.75002L16.4002 3.37502C16.8002 2.97502 17.2919 2.77502 17.8752 2.77502C18.4585 2.77502 18.9585 2.97502 19.3752 3.37502L20.6752 4.70002C21.0919 5.10002 21.3042 5.58336 21.3122 6.15002C21.3209 6.71669 21.1252 7.20002 20.7252 7.60002L19.3252 9.00002ZM4.1002 21.1C3.78353 21.1 3.51286 20.9877 3.2882 20.763C3.06286 20.5377 2.9502 20.2667 2.9502 19.95V17.3C2.9502 17.15 2.97953 17.004 3.0382 16.862C3.0962 16.7207 3.18353 16.5917 3.3002 16.475L13.6002 6.17502L17.8752 10.45L7.5752 20.75C7.4752 20.8667 7.35453 20.9544 7.21319 21.013C7.07119 21.071 6.91686 21.1 6.7502 21.1H4.1002ZM14.2752 9.77502L13.6252 9.12502L14.9252 10.425L14.2752 9.77502Z"
            fill="black"
          />
        </svg>
      );
  }
};

IconChooser.propTypes = {
  type: PropTypes.string.isRequired,
};

export default IconChooser;
