import RobotRig from "visualisations/dance/robot_rig";
import PropTypes from "prop-types";

import { merge, exponentialEasing } from "visualisations/dance/animation";
import availablePoses from "visualisations/dance/poses";
import useRobotStyles from "use_robot_styles";
import { useEffect, useState } from "react";

const PoseControl = (props) => {
  return (
    <div>
      <span
        style={{
          textAlign: "left",
          width: "9rem",
          display: "inline-block",
        }}
      >
        {props.name}:
      </span>
      <button onClick={props.setValue({ set: props.min })}>{"<<"}</button>
      <button onClick={props.setValue({ step: -1 })}>{"<"}</button>
      <input
        type="range"
        min={props.min}
        max={props.max}
        onMouseUp={props.onMouseUp}
        defaultValue={props.value}
        disabled={props.disabled}
      />
      <button onClick={props.setValue({ step: 1 })}>{">"}</button>
      <button onClick={props.setValue({ set: props.max })}>{">>"}</button>
      <button onClick={props.setValue({ set: props.defaultValue })}>
        {"Reset"}
      </button>
    </div>
  );
};

PoseControl.propTypes = {
  name: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  onMouseUp: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.any,
  setValue: PropTypes.any,
  defaultValue: PropTypes.any,
};

const DancingPage = () => {
  const default_pose = availablePoses.default;

  const robotStyles = useRobotStyles(null);

  const [animating, setAnimating] = useState(false);
  const [mergedPose, setMergedPose] = useState({});
  const [poseList, setPoseList] = useState({
    before: [],
    current: default_pose,
    stock: JSON.parse(JSON.stringify(default_pose)),
    after: [],
  });
  const [poseJson, setPoseJson] = useState("");
  const [jsonErr, setJsonErr] = useState(null);

  const refJsonInput = useRef(null);

  useEffect(() => {
    setPoseJson(JSON.stringify(poseList.current, "", "    "));
  }, [poseList]);

  const alterCurrentPose = (changes) => {
    setPoseList((poseList) => {
      const pose = { ...poseList.current, ...changes };
      return { ...poseList, current: pose };
    });
  };

  const currentPose = () => {
    return poseList.current;
  };

  const animationPose = () => {
    if (!animating) {
      return currentPose();
    } else {
      return mergedPose;
    }
  };

  const startAnimating = () => {
    setMergedPose(allPoses()[0]);
    setAnimating(true);
    startTimeRef.current = 0;
    requestRef.current = requestAnimationFrame(animate);
  };

  const stopAnimating = () => {
    setAnimating(false);
    cancelAnimationFrame(requestRef.current);
  };

  const requestRef = useRef();
  const startTimeRef = useRef();

  const interpolatedPose = (keyframes, fractional_index, easing_function) => {
    const poseIdx = Math.floor(fractional_index) % keyframes.length;
    const nextPoseIdx = (poseIdx + 1) % keyframes.length;
    const factor = easing_function(fractional_index);
    return merge(keyframes[poseIdx], keyframes[nextPoseIdx], factor);
  };

  const animate = (time) => {
    const posesPerSecond = 2;
    const jerkiness = 6.0;
    const easing = exponentialEasing(jerkiness);
    if (startTimeRef.current != undefined) {
      const elapsed = time - startTimeRef.current;
      const pose = (elapsed * posesPerSecond) / 1000;
      setMergedPose(interpolatedPose(allPoses(), pose, easing));
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  const selectPose = (index) => {
    setPoseList((poseList) => {
      const poses = flattenPoses(poseList);
      return {
        before: poses.slice(0, index),
        current: poses[index],
        stock: JSON.parse(JSON.stringify(poses[index])),
        after: poses.slice(index + 1, poses.length),
      };
    });
  };

  const addPose = () => {
    setPoseList((poseList) => {
      return {
        before: [...poseList.before, poseList.current, ...poseList.after],
        current: { ...poseList.current },
        after: [],
      };
    });
  };

  const allPoses = () => {
    return flattenPoses(poseList);
  };

  const flattenPoses = (poseList) => {
    return [...poseList.before, poseList.current, ...poseList.after];
  };

  const selectLibraryPose = (pose) => {
    if (pose == "none") {
      selectPose(0);
      return;
    }
    setPoseList((poseList) => {
      return {
        ...poseList,
        current: availablePoses[pose],
        stock: availablePoses[pose],
      };
    });
  };

  const poseLibrary = Object.keys(availablePoses).map((pose, idx) => {
    return (
      <option key={idx} value={pose}>
        {availablePoses[pose].name}
      </option>
    );
  });

  const changeJson = (e) => {
    setPoseJson(e.target.value);
    try {
      alterCurrentPose(JSON.parse(refJsonInput.current.value));
      setJsonErr(null);
    } catch (e) {
      setJsonErr("JSON syntax error");
    }
  };

  const resetPose = () => {
    alterCurrentPose(poseList.stock);
  };

  const Sliders = ({ pose, defaultPose, animating }) => {
    return (
      <>
        {Object.keys(pose).map((name, idx) => {
          if (name == "name") {
            return <h2 key={name}>Pose name: {name}</h2>;
          }
          const displayName = name.replaceAll("_", " ");
          const setValue = ({ step = 0, set = null }) => () => {
            var val;
            if (step != 0) {
              val = parseInt(currentPose()[name]) + step;
            }
            if (set != null) {
              val = Number(set);
            }
            if (!isNaN(Number(val))) {
              alterCurrentPose({
                ...currentPose(),
                [name]: val,
              });
            }
          };
          return (
            <PoseControl
              name={displayName}
              key={idx}
              min="-180"
              max="180"
              disabled={animating}
              onMouseUp={(event) => {
                alterCurrentPose({
                  ...currentPose(),
                  [name]: Number(event.target.value),
                });
              }}
              setValue={setValue}
              value={pose[name]}
              defaultValue={defaultPose[name]}
            />
          );
        })}
      </>
    );
  };

  Sliders.propTypes = {
    pose: PropTypes.any,
    defaultPose: PropTypes.any,
    animating: PropTypes.any,
  };

  return (
    <>
      <h1>Robot poser</h1>
      <div
        id="top-shelf"
        style={{
          display: "flex",
          backgroundColor: "#cccccc",
          alignItems: "center",
        }}
      >
        <div id="current-pose" style={{ flex: 1 }}>
          <RobotRig
            pose={animationPose()}
            width="450"
            height="450"
            {...robotStyles}
          />
        </div>
        <div id="sliders" style={{ flex: 1 }}>
          <Sliders
            pose={poseList.current}
            defaultPose={poseList.stock}
            animating={animating}
          />
        </div>
        <div style={{ flex: 1 }}>
          <textarea
            type="text"
            value={poseJson}
            style={{ height: "340px", width: "340px" }}
            onChange={changeJson}
            ref={refJsonInput}
          ></textarea>
          <b style={{ display: "inherit", color: "red" }}>{jsonErr}</b>
          <button onClick={resetPose} style={{ display: "inherit" }}>
            Reset this mess
          </button>
        </div>
      </div>
      <div id="play-controls">
        <button onClick={addPose} disabled={animating}>
          <p>Add a pose</p>
        </button>
        <button onClick={startAnimating} disabled={animating}>
          <p>Play</p>
        </button>
        <button onClick={stopAnimating} disabled={!animating}>
          <p>Stop</p>
        </button>
        <label htmlFor="pose_library">Pose library</label>
        <select
          name="pose_library"
          onChange={(e) => selectLibraryPose(e.target.value)}
        >
          <option value="none"></option>
          {poseLibrary}
        </select>
      </div>
      <div id="keyframes">
        {allPoses().map((pose, index) => {
          return (
            <button
              key={index}
              onClick={() => selectPose(index)}
              disabled={pose === currentPose()}
            >
              <RobotRig pose={pose} width="100" height="150" {...robotStyles} />
            </button>
          );
        })}
      </div>
    </>
  );
};

export default DancingPage;
