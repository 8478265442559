import Button from "button";
import styles from "./styles.module.scss";

const PreviouslyShared = ({ workspace }) => (
  <div className={styles.previously_shared}>
    <h2 className={styles.heading}>Unable to share</h2>
    <p className={styles.paragraph}>
      You’ve already shared a project that is the same as this one!
    </p>
    <p className={styles.paragraph}>
      Try making some changes and sharing a new version!
    </p>
    <div className={styles.previously_shared_buttons}>
      <Button.RectanglePrimary
        text={"Back to editing"}
        href={`/activities/${workspace.activity.key}/${workspace.id}`}
      />
    </div>
  </div>
);

PreviouslyShared.propTypes = {
  workspace: PropTypes.object,
};

export default PreviouslyShared;
