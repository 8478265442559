import audioBufferToWav from "audiobuffer-to-wav";
import { OfflineAudioContext } from "standardized-audio-context";
import AudioEngine from "visualisations/music/audio_engine";
import AudioCommandExecutor from "audio_command_executor";

const audioUrlFromAudioCommands = async (audioCommands) => {
  const lastCommand = audioCommands[audioCommands.length - 1];
  const lengthInSeconds = lastCommand.startTime + lastCommand.duration;

  const sampleRate = 44100;
  const audioContext = new OfflineAudioContext({
    numberOfChannels: 2,
    length: sampleRate * lengthInSeconds,
    sampleRate: sampleRate,
  });
  const audioEngine = new AudioEngine(audioContext);
  await audioEngine.init();

  const audioCommandExecutor = new AudioCommandExecutor(audioEngine);
  audioCommandExecutor.execute(audioCommands);

  const buffer = await audioContext.startRendering();
  const blob = new Blob([audioBufferToWav(buffer)], { type: "audio/wav" });
  return window.URL.createObjectURL(blob);
};

export default audioUrlFromAudioCommands;
