import Blockly from "blockly";
const blackRightPointingTriangle = "\u25B6\ufe0e";

Blockly.Blocks.DanceRun = {
  message0: `when ${blackRightPointingTriangle} clicked`,
  nextStatement: "DanceSetup",
  style: "rpf_start_blocks",
};

Blockly.Blocks.DanceRun.init = function () {
  this.jsonInit(Blockly.Blocks.DanceRun);
};

Blockly.JavaScript.DanceRun = function () {
  return "visualisation.startRun();";
};
