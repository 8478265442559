import Blockly from "blockly";

Blockly.Blocks.GoToRandomPosition = {
  message0: "go to random position",
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.GoToRandomPosition.init = function () {
  this.jsonInit(Blockly.Blocks.GoToRandomPosition);
};

Blockly.JavaScript.GoToRandomPosition = function () {
  return "visualisation.goToRandomPosition();";
};
