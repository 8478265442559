export default `
  <xml xmlns="http://www.w3.org/1999/xhtml">
    <block type="DanceRun" deletable="false">
      <next>
        <block type="StartMusic" deletable="false" movable="false">
        </block>
      </next>
    </block>
  </xml>
`;
