import Blockly from "blockly";
import penStyles from "visualisations/art/pen_styles";

const styleOptions = Object.entries(penStyles).map(([key, { name }]) => [
  name,
  key,
]);

Blockly.Blocks.SetPenStyle = {
  message0: "set pen style to %1",
  args0: [
    {
      type: "field_dropdown",
      name: "style",
      options: styleOptions,
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.SetPenStyle.init = function () {
  this.jsonInit(Blockly.Blocks.SetPenStyle);
};

Blockly.JavaScript.SetPenStyle = (block) => {
  const style = block.getFieldValue("style");

  if (typeof style === "undefined") {
    return "";
  } else {
    return `visualisation.setPenStyle("${style}");`;
  }
};
