import Blockly from "blockly";

const helpers = {};

// This is a helper that generates the code for a pluggable input value such as
// a constant number that can be 'plugged into' another block.
//
// See: https://developers.google.com/blockly/guides/create-custom-blocks/generating-code#valuetocode
helpers.codeForArg = (argumentName, block) => {
  const precedence = Blockly.JavaScript.ORDER_NONE;
  const generatedCode = Blockly.JavaScript.valueToCode(
    block,
    argumentName,
    precedence
  );

  return generatedCode || undefined;
};

helpers.statementBlocks = (block) => {
  const blocks = [];
  let statement = block.getInputTargetBlock("statements");

  if (statement == null) {
    return blocks;
  }

  do {
    blocks.push(statement);
    statement = statement.getNextBlock();
  } while (statement);

  return blocks;
};

Array.prototype.sum = function (func) {
  return this.reduce((count, item) => {
    return count + func(item);
  }, 0);
};

Array.prototype.countIf = function (func) {
  return this.sum((item) => (func(item) ? 1 : 0));
};

String.prototype.replaceAt = function (index, char) {
  var a = this.split("");
  a[index] = char;
  return a.join("");
};

helpers.numberOfConnectedBlocks = (workspace) => {
  const count = workspace.getTopBlocks().sum((block) => {
    return block
      .getDescendants()
      .countIf((block) => !(block.type == "Run" || block.operator));
  });
  return count;
};

export default helpers;
