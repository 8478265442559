const setVerticalHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

const useVhFix = () => {
  useEffect(setVerticalHeight, []);
  useResize(setVerticalHeight);
};

export default useVhFix;
