import Button from "button";
import InspirationIcon from "assets/inspiration_icon.svg";

const GetInspiredButton = ({ className, activityKey }) => {
  return (
    <Button.RectangleTertiary
      className={className}
      icon={<InspirationIcon />}
      text="Get inspired"
      href={`/gallery?activity=${activityKey}`}
      textAlways={true}
    ></Button.RectangleTertiary>
  );
};

GetInspiredButton.propTypes = {
  className: PropTypes.string.isRequired,
  activityKey: PropTypes.string.isRequired,
};

export default GetInspiredButton;
