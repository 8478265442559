import stamps from "./stamps.jsx";

const length = (dx, dy) => {
  return Math.sqrt(dx * dx + dy * dy);
};

const svgDash = (dash, width) => {
  switch (dash) {
    case "solid":
      return null;
    case "dashed":
      return `${width * 2},${width}`;
    case "dotted":
      return `${width},${width}`;
  }
};

const onCanvas = (x, y, canvasBounds) => {
  const [xMin, yMin, xMax, yMax] = canvasBounds;

  if (x <= xMax && x >= xMin && y <= yMax && y >= yMin) {
    return true;
  } else {
    return false;
  }
};

const stampsForLine = (
  stamp,
  width,
  x1,
  y1,
  x2,
  y2,
  colour,
  scatter,
  rng,
  canvasBounds
) => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const clampedWidth = Math.max(5, width);
  const numberOfStamps = length(dx, dy) / (clampedWidth * 1.2);
  const noJitter = () => 0;
  const randomJitter = (amount) => {
    return rng() * amount * 2 - amount;
  };
  const jitter = scatter ? randomJitter : noJitter;

  const stampElements = [];
  for (var i = 0; i <= numberOfStamps; i++) {
    const x = x1 + (dx / numberOfStamps) * i + jitter(12);
    const y = y1 + (dy / numberOfStamps) * i + jitter(12);
    const rotation = jitter(15);

    if (onCanvas(x, y, canvasBounds)) {
      stampElements.push(
        <g key={i} transform={`rotate(${rotation},${x},${y})`}>
          {stamp.path({
            x: x,
            y: y,
            size: clampedWidth + jitter(clampedWidth / 2),
            colour,
          })}
        </g>
      );
    }
  }
  return stampElements;
};

const Line = ({
  x1,
  y1,
  x2,
  y2,
  dash,
  stroke,
  width,
  filter,
  shape,
  scatter,
  rng,
  canvasBounds,
}) => {
  if (shape == "line") {
    return (
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        strokeLinecap="round"
        strokeDasharray={svgDash(dash, width)}
        style={{
          stroke: stroke,
          strokeWidth: width,
          filter: filter,
        }}
      />
    );
  } else {
    const stamp = stamps[shape];
    return (
      <>
        <g filter={filter}>
          {stampsForLine(
            stamp,
            width,
            x1,
            y1,
            x2,
            y2,
            stroke,
            scatter,
            rng,
            canvasBounds
          )}
        </g>
      </>
    );
  }
};

Line.propTypes = {
  x1: PropTypes.number,
  y1: PropTypes.number,
  x2: PropTypes.number,
  y2: PropTypes.number,
  stroke: PropTypes.string,
  width: PropTypes.number,
  filter: PropTypes.string,
  pen: PropTypes.string,
  scatter: PropTypes.bool,
  rng: PropTypes.func,
  dash: PropTypes.string,
  shape: PropTypes.string,
  canvasBounds: PropTypes.array,
};

export default Line;
