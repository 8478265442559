import Island from "island_page/island";

const Project = {};

Project.isBlockBased = (content) =>
  content && content.software && content.software.indexOf("scratch") !== -1;

Project.technology = (content) =>
  Project.isBlockBased(content) ? "Scratch" : "Python";

Project.pathwayKey = (name) => {
  const islandKey = Island.keyForNodeKey(name);
  const islandKeysVsPathwayKeys = {
    scratch: "scratch-1",
    python: "python-1",
  };
  const pathwayKey = islandKeysVsPathwayKeys[islandKey];
  if (!pathwayKey) throw new Error(`Unknown pathway for project: ${name}`);
  return pathwayKey;
};

Project.badgeKeyFor = (name) => name.replace(/_/g, "-");

Project.canBadgeBeAwardedOn = (step) => step.title === "Reflection";

Project.isQuiz = (step) => step.knowledgeQuiz && step.knowledgeQuiz.length > 0;

export default Project;
