import Blockly from "blockly";
const blackRightPointingTriangle = "\u25B6\ufe0e";

Blockly.Blocks.Run = {
  message0: `when ${blackRightPointingTriangle} clicked`,
  nextStatement: "Statement",
  style: "rpf_start_blocks",
};

Blockly.Blocks.Run.init = function () {
  this.jsonInit(Blockly.Blocks.Run);
};

Blockly.JavaScript.Run = function () {
  return "visualisation.startRun();";
};
