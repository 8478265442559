const Robot = ({
  containerWidth = 532,
  containerHeight = 888,
  shoeColour = "#FFFFFF",
  gloveColour = "#FFFFFF",
  bodyColour = "#FFFFFF",
  headColour = "#FFFFFF",
  headgearColour = "#FFFFFF",
  faceColour = "#FFFFFF",
  displayTshirt = false,
  tShirtColour,
  shoeStyle = 0,
  gloveStyle = 0,
  bodyStyle = 0,
  faceStyle = 0,
  headStyle = 0,
  headgearStyle = 0,
  children,
  ...rest
}) => {
  return (
    <svg
      width={containerWidth}
      height={containerHeight}
      viewBox={`0 0 532 888`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title="Your avatar"
      {...rest}
    >
      <Legs containerWidth={containerWidth} />
      <Shoes
        containerWidth={containerWidth}
        shoeColour={shoeColour}
        shoeStyle={shoeStyle}
      />
      <Gloves
        containerWidth={containerWidth}
        gloveColour={gloveColour}
        gloveStyle={gloveStyle}
      />
      <Body
        containerWidth={containerWidth}
        bodyColour={bodyColour}
        bodyStyle={bodyStyle}
      />
      {displayTshirt && (
        <Tshirt containerWidth={containerWidth} tShirtColour={tShirtColour}>
          {children}
        </Tshirt>
      )}
      <Headgear
        containerWidth={containerWidth}
        headgearColour={headgearColour}
        headgearStyle={headgearStyle}
      />
      <Head
        containerWidth={containerWidth}
        headColour={headColour}
        headStyle={headStyle}
      />
      <Face
        containerWidth={containerWidth}
        faceColour={faceColour}
        faceStyle={faceStyle}
      />
    </svg>
  );
};

Robot.propTypes = {
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number,
  shoeColour: PropTypes.string,
  gloveColour: PropTypes.string,
  bodyColour: PropTypes.string,
  headColour: PropTypes.string,
  headgearColour: PropTypes.string,
  faceColour: PropTypes.string,
  displayTshirt: PropTypes.bool,
  tShirtColour: PropTypes.string,
  shoeStyle: PropTypes.number,
  gloveStyle: PropTypes.number,
  bodyStyle: PropTypes.number,
  faceStyle: PropTypes.number,
  headStyle: PropTypes.number,
  headgearStyle: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const xOffset = (width, containerWidth) => {
  return containerWidth / 2 - width / 2;
};

const Gloves = ({ containerWidth, gloveColour, gloveStyle }) => {
  const gloveStyles = [GloveStyle1, GloveStyle2, GloveStyle3];
  const GloveStyle = gloveStyles[gloveStyle];

  return (
    <GloveStyle containerWidth={containerWidth} gloveColour={gloveColour} />
  );
};

Gloves.propTypes = {
  containerWidth: PropTypes.number,
  gloveColour: PropTypes.string,
  gloveStyle: PropTypes.number,
};

const GloveStyle1Left = ({ gloveColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M471.2 269.119C477.8 269.119 483.2 263.719 483.2 257.119V234.419C483.2 227.819 477.8 222.419 471.2 222.419C464.6 222.419 459.2 227.819 459.2 234.419V257.119C459.2 263.719 464.6 269.119 471.2 269.119Z"
      fill="black"
    />
    <path
      d="M495.2 255.919C501.8 255.919 507.2 250.519 507.2 243.919V221.119C507.2 214.519 501.8 209.119 495.2 209.119C488.6 209.119 483.2 214.519 483.2 221.119V243.919C483.2 250.519 488.6 255.919 495.2 255.919Z"
      fill="black"
    />
    <path
      d="M488.6 237.219L468.6 243.719C451.3 249.319 432.7 239.819 427.1 222.419L397.1 129.619C391.5 112.319 401 93.7188 418.4 88.1188L438.4 81.6188C455.7 76.0188 474.3 85.5188 479.9 102.919L509.9 195.719C515.5 213.019 506 231.619 488.6 237.219Z"
      fill={gloveColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      opacity="0.2"
      d="M479.726 100.31L395.7 127.453L398.774 136.969L482.8 109.826L479.726 100.31Z"
      fill="black"
    />
    <path
      d="M439.5 258.019C446.1 258.719 452 253.919 452.7 247.319L455.1 224.719C455.8 218.119 451 212.219 444.4 211.519C437.8 210.819 431.9 215.619 431.2 222.219L428.8 244.819C428.1 251.419 432.9 257.319 439.5 258.019Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M418.2 88.0188L438.2 81.5188C440.8 80.7188 443.5 80.2188 446.1 80.0188L433.5 84.1188C416.2 89.7188 406.6 108.319 412.2 125.619L442.2 218.419C447 233.119 461.1 242.219 475.9 241.219L468.5 243.619C451.2 249.219 432.6 239.719 427 222.319L397 129.519C391.3 112.319 400.9 93.7188 418.2 88.0188Z"
      fill="black"
    />
  </svg>
);

GloveStyle1Left.propTypes = {
  gloveColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const GloveStyle1Right = ({ gloveColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M40.7 269.119C34.1 269.119 28.7 263.719 28.7 257.119V234.419C28.7 227.819 34.1 222.419 40.7 222.419C47.3 222.419 52.7 227.819 52.7 234.419V257.119C52.7 263.719 47.3 269.119 40.7 269.119Z"
      fill="black"
    />
    <path
      d="M16.7 255.919C10.1 255.919 4.70001 250.519 4.70001 243.919V221.119C4.70001 214.519 10.1 209.119 16.7 209.119C23.3 209.119 28.7 214.519 28.7 221.119V243.919C28.7 250.519 23.3 255.919 16.7 255.919Z"
      fill="black"
    />
    <path
      d="M23.3 237.219L43.3 243.719C60.6 249.319 79.2 239.819 84.8 222.419L114.8 129.619C120.4 112.319 110.9 93.7188 93.5 88.1188L73.5 81.6188C56.2 76.0188 37.6 85.5188 32 102.919L1.99999 195.719C-3.60001 213.019 5.99999 231.619 23.3 237.219Z"
      fill={gloveColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      opacity="0.2"
      d="M32.2167 100.265L29.1417 109.781L113.168 136.933L116.243 127.417L32.2167 100.265Z"
      fill="black"
    />
    <path
      d="M72.4 258.019C65.8 258.719 59.9 253.919 59.2 247.319L56.8 224.719C56.1 218.119 60.9 212.219 67.5 211.519C74.1 210.819 80 215.619 80.7 222.219L83.1 244.819C83.8 251.419 79 257.319 72.4 258.019Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M93.7 88.0188L73.7 81.5188C71.1 80.7188 68.4 80.2188 65.8 80.0188L78.4 84.1188C95.7 89.7188 105.3 108.319 99.7 125.619L69.7 218.419C64.9 233.219 50.8 242.319 36 241.319L43.4 243.719C60.7 249.319 79.3 239.819 84.9 222.419L114.9 129.619C120.5 112.319 111 93.7188 93.7 88.0188Z"
      fill="black"
    />
  </svg>
);

GloveStyle1Right.propTypes = {
  gloveColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const GloveStyle1 = ({ containerWidth, gloveColour }) => {
  return (
    <svg
      width="512"
      height="270"
      viewBox="-10 0 532 270"
      x={xOffset(512, containerWidth)}
      y="490"
    >
      <path
        d="M89.468 18.8393L60.2414 97.1641C56.3798 107.513 61.6387 119.033 71.9874 122.894C82.3361 126.756 93.8558 121.497 97.7174 111.148L126.944 32.8233C130.806 22.4746 125.547 10.9548 115.198 7.09327C104.849 3.23169 93.3295 8.49054 89.468 18.8393Z"
        fill="black"
      />
      <path
        d="M451.656 97.0628L422.43 18.7379C418.568 8.38918 407.048 3.13031 396.7 6.99189C386.351 10.8535 381.092 22.3732 384.954 32.7219L414.18 111.047C418.042 121.395 429.562 126.654 439.91 122.793C450.259 118.931 455.518 107.411 451.656 97.0628Z"
        fill="black"
      />
      <GloveStyle1Left gloveColour={gloveColour} />
      <GloveStyle1Right gloveColour={gloveColour} />
    </svg>
  );
};

GloveStyle1.propTypes = {
  containerWidth: PropTypes.number,
  gloveColour: PropTypes.string,
};

const GloveStyle2Left = ({ gloveColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M480.2 269.091C486.8 269.091 492.2 263.691 492.2 257.091V234.391C492.2 227.791 486.8 222.391 480.2 222.391C473.6 222.391 468.2 227.791 468.2 234.391V257.091C468.2 263.691 473.6 269.091 480.2 269.091Z"
      fill="black"
    />
    <path
      d="M504.2 255.891C510.8 255.891 516.2 250.491 516.2 243.891V221.091C516.2 214.491 510.8 209.091 504.2 209.091C497.6 209.091 492.2 214.491 492.2 221.091V243.891C492.2 250.491 497.6 255.891 504.2 255.891Z"
      fill="black"
    />
    <path
      d="M478.818 71.4589L396.028 98.2114L446.305 253.798L529.094 227.046L478.818 71.4589Z"
      fill={gloveColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M448.5 257.991C455.1 258.691 461 253.891 461.7 247.291L464.1 224.691C464.8 218.091 460 212.191 453.4 211.491C446.8 210.791 440.9 215.591 440.2 222.191L437.8 244.791C437.1 251.391 441.9 257.291 448.5 257.991Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M488.847 102.318L404.821 129.461L407.895 138.977L491.921 111.834L488.847 102.318Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M494.046 118.606L410.02 145.749L413.094 155.265L497.12 128.122L494.046 118.606Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M410.268 93.6385L395.994 98.251L446.271 253.838L460.545 249.225L410.268 93.6385Z"
      fill="black"
    />
  </svg>
);

GloveStyle2Left.propTypes = {
  gloveColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const GloveStyle2Right = ({ gloveColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M49.7 269.091C43.1 269.091 37.7 263.691 37.7 257.091V234.391C37.7 227.791 43.1 222.391 49.7 222.391C56.3 222.391 61.7 227.791 61.7 234.391V257.091C61.7 263.691 56.3 269.091 49.7 269.091Z"
      fill="black"
    />
    <path
      d="M25.7 255.891C19.1 255.891 13.7 250.491 13.7 243.891V221.091C13.7 214.491 19.1 209.091 25.7 209.091C32.3 209.091 37.7 214.491 37.7 221.091V243.891C37.7 250.491 32.3 255.891 25.7 255.891Z"
      fill="black"
    />
    <path
      d="M0.942259 227.054L83.7315 253.807L134.008 98.2203L51.2185 71.4678L0.942259 227.054Z"
      fill={gloveColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      opacity="0.2"
      d="M41.2341 102.208L38.1591 111.724L122.185 138.876L125.26 129.36L41.2341 102.208Z"
      fill="black"
    />
    <path
      d="M81.4 257.991C74.8 258.691 68.9 253.891 68.2 247.291L65.8 224.691C65.1 218.091 69.9 212.191 76.5 211.491C83.1 210.791 89 215.591 89.7 222.191L92.1 244.791C92.8 251.391 88 257.291 81.4 257.991Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M36.0653 118.544L32.9903 128.06L117.017 155.212L120.092 145.696L36.0653 118.544Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M69.4475 249.161L83.7215 253.774L133.998 98.1873L119.724 93.5748L69.4475 249.161Z"
      fill="black"
    />
  </svg>
);

GloveStyle2Right.propTypes = {
  gloveColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const GloveStyle2 = ({ containerWidth, gloveColour }) => {
  return (
    <svg
      width="530"
      height="270"
      viewBox="-10 0 550 270"
      x={xOffset(530, containerWidth)}
      y="490"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.2 6.99054C134.549 10.8513 139.809 22.3706 135.948 32.7196L106.729 111.043C102.868 121.392 91.349 126.651 81 122.791C70.651 118.93 65.3913 107.41 69.252 97.0615L98.471 18.7385C102.332 8.38952 113.851 3.12977 124.2 6.99054Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M405.7 6.99055C416.049 3.12978 427.568 8.38951 431.429 18.7385L460.648 97.0615C464.509 107.41 459.249 118.93 448.9 122.791C438.551 126.651 427.032 121.392 423.171 111.043L393.952 32.7196C390.091 22.3706 395.351 10.8513 405.7 6.99055Z"
        fill="black"
      />
      <GloveStyle2Left gloveColour={gloveColour} />
      <GloveStyle2Right gloveColour={gloveColour} />
    </svg>
  );
};

GloveStyle2.propTypes = {
  containerWidth: PropTypes.number,
  gloveColour: PropTypes.string,
};

const GloveStyle3Left = ({ gloveColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M468.5 269.146C475.1 269.146 480.5 263.746 480.5 257.146V234.446C480.5 227.846 475.1 222.446 468.5 222.446C461.9 222.446 456.5 227.846 456.5 234.446V257.146C456.5 263.746 461.8 269.146 468.5 269.146Z"
      fill="black"
    />
    <path
      d="M492.5 255.846C499.1 255.846 504.5 250.446 504.5 243.846V221.046C504.5 214.446 499.1 209.046 492.5 209.046C485.9 209.046 480.5 214.446 480.5 221.046V243.846C480.5 250.446 485.8 255.846 492.5 255.846Z"
      fill="black"
    />
    <path
      d="M475.9 240.446C453 247.846 428.5 235.246 421.1 212.446L397.6 139.646C390.2 116.746 402.8 92.2458 425.6 84.8458C448.5 77.4458 473 90.0458 480.4 112.846L503.9 185.646C511.3 208.546 498.7 233.046 475.9 240.446Z"
      fill={gloveColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M436.8 258.046C443.4 258.746 449.3 253.946 450 247.346L452.4 224.746C453.1 218.146 448.3 212.246 441.7 211.546C435.1 210.846 429.2 215.646 428.5 222.246L426.1 244.846C425.4 251.346 430.2 257.346 436.8 258.046Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M425.5 84.6458C427.9 83.8458 430.4 83.3458 432.8 82.9458C414.4 92.6458 405.1 114.346 411.7 134.846L435.2 207.646C441.8 228.046 462.1 240.246 482.6 237.346C480.4 238.546 478.1 239.446 475.7 240.246C452.8 247.646 428.3 235.046 420.9 212.246L397.4 139.446C390 116.546 402.6 92.0458 425.5 84.6458Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M481 201.646C472.6 201.646 464.1 194.346 460.5 183.146C456.3 170.046 460.5 157.346 470.3 154.146C480 151.046 490.9 158.846 495.2 171.946C497.1 177.946 497.3 184.146 495.7 189.446C494 195.146 490.2 199.346 485.4 200.946C484 201.346 482.5 201.646 481 201.646ZM468.3 180.646C471 189.146 477.7 194.846 482.9 193.246C486 192.246 487.4 189.046 487.9 187.146C489 183.446 488.8 178.846 487.4 174.446C484.7 165.946 478 160.246 472.8 161.846C467.6 163.546 465.5 172.146 468.3 180.646Z"
      fill="black"
    />
  </svg>
);

GloveStyle3Left.propTypes = {
  gloveColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const GloveStyle3Right = ({ gloveColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M38 269.146C31.4 269.146 26 263.746 26 257.146V234.446C26 227.846 31.4 222.446 38 222.446C44.6 222.446 50 227.846 50 234.446V257.146C50 263.746 44.6 269.146 38 269.146Z"
      fill="black"
    />
    <path
      d="M14 255.846C7.4 255.846 2 250.446 2 243.846V221.046C2 214.446 7.4 209.046 14 209.046C20.6 209.046 26 214.446 26 221.046V243.846C26 250.446 20.6 255.846 14 255.846Z"
      fill="black"
    />
    <path
      d="M30.5999 240.446C53.4999 247.846 78 235.246 85.4 212.446L108.9 139.646C116.3 116.746 103.7 92.2458 80.9 84.8458C58 77.4458 33.4999 90.0458 26.0999 112.846L2.59993 185.646C-4.80007 208.546 7.69993 233.046 30.5999 240.446Z"
      fill={gloveColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M69.7 258.046C63.1 258.746 57.2001 253.946 56.5001 247.346L54.1 224.746C53.4 218.146 58.2001 212.246 64.8001 211.546C71.4001 210.846 77.3001 215.646 78.0001 222.246L80.4001 244.846C81.0001 251.346 76.3 257.346 69.7 258.046Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M80.7 84.6458C78.2999 83.8458 75.8 83.3458 73.4 82.9458C91.8 92.6458 101.1 114.346 94.5 134.846L71 207.646C64.4 228.046 44.1 240.246 23.6 237.346C25.8 238.546 28.1 239.446 30.5 240.246C53.4 247.646 77.9 235.046 85.3 212.246L108.8 139.446C116.2 116.546 103.6 92.0458 80.7 84.6458Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M28.4 201.646C26.9 201.646 25.5 201.446 24 200.946C14.3 197.846 9.99995 185.046 14.1999 171.946C18.3999 158.846 29.4 151.046 39.1 154.146C43.9 155.746 47.7 159.846 49.4 165.646C51 170.946 50.8 177.146 48.9 183.146C45.3 194.246 36.9 201.646 28.4 201.646ZM34.8 161.646C30 161.646 24.5 167.046 22 174.446C19.3 182.946 21.3 191.546 26.5 193.146C31.7 194.846 38.4 189.046 41.1 180.546C42.5 176.146 42.7 171.546 41.6 167.846C41 166.046 39.7 162.746 36.6 161.746C36 161.746 35.4 161.646 34.8 161.646Z"
      fill="black"
    />
  </svg>
);

GloveStyle3Right.propTypes = {
  gloveColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const GloveStyle3 = ({ containerWidth, gloveColour }) => {
  return (
    <svg
      width="507"
      height="270"
      viewBox="-10 0 527 270"
      x={xOffset(507, containerWidth)}
      y="490"
    >
      <path
        d="M86.8505 18.8178L57.624 97.1426C53.7624 107.491 59.0212 119.011 69.37 122.873C79.7187 126.734 91.2384 121.475 95.1 111.127L124.327 32.8018C128.188 22.4531 122.929 10.9334 112.581 7.0718C102.232 3.21022 90.7121 8.46907 86.8505 18.8178Z"
        fill="black"
      />
      <path
        d="M448.864 97.0627L419.637 18.7379C415.776 8.38918 404.256 3.13031 393.907 6.99189C383.559 10.8535 378.3 22.3732 382.161 32.7219L411.388 111.047C415.249 121.395 426.769 126.654 437.118 122.793C447.467 118.931 452.725 107.411 448.864 97.0627Z"
        fill="black"
      />
      <GloveStyle3Left gloveColour={gloveColour} />
      <GloveStyle3Right gloveColour={gloveColour} />
    </svg>
  );
};

GloveStyle3.propTypes = {
  containerWidth: PropTypes.number,
  gloveColour: PropTypes.string,
};

const Headgear = ({ containerWidth, headgearColour, headgearStyle }) => {
  const headgearStyles = [HeadgearStyle1, HeadgearStyle2, HeadgearStyle3];
  const HeadgearStyle = headgearStyles[headgearStyle];

  return (
    <HeadgearStyle
      containerWidth={containerWidth}
      headgearColour={headgearColour}
    />
  );
};

Headgear.propTypes = {
  containerWidth: PropTypes.number,
  headgearColour: PropTypes.string,
  headgearStyle: PropTypes.number,
};

const HeadgearStyle1 = ({ containerWidth, headgearColour }) => {
  return (
    <svg
      width="66"
      height="132"
      viewBox="-6 0 78 132"
      x={xOffset(66, containerWidth)}
    >
      <path
        d="M33 64.8C50.8388 64.8 65.3 50.3388 65.3 32.5C65.3 14.6612 50.8388 0.200012 33 0.200012C15.1612 0.200012 0.700012 14.6612 0.700012 32.5C0.700012 50.3388 15.1612 64.8 33 64.8Z"
        fill="#F86A10"
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M49.3 48.8V131.5H16.7V48.8C16.7 39.8 24 32.5 33 32.5C42 32.5 49.3 39.8 49.3 48.8Z"
        fill={headgearColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path opacity="0.1" d="M49 86H17V131H49V86Z" fill="black" />
    </svg>
  );
};

HeadgearStyle1.propTypes = {
  containerWidth: PropTypes.number,
  headgearColour: PropTypes.string,
};

const HeadgearStyle2 = ({ containerWidth, headgearColour }) => {
  return (
    <svg
      width="210"
      height="104"
      viewBox="-9 -9 308 148"
      x={xOffset(210, containerWidth)}
    >
      <path
        d="M298.7 117.7C300 112.4 296.7 107 291.4 105.7C286 104.4 280.6 107.7 279.3 113C279.3 113.1 274.1 127.9 240.2 127.9V90.7C240.2 40.6 199.6 0 149.5 0C99.4 0 58.8 40.6 58.8 90.7V127.8C25.2 127.7 20.1 113.1 20 112.9C18.7 107.5 13.3 104.3 7.90002 105.6C2.60002 106.9 -0.700024 112.3 0.599976 117.6L0.299988 117.7C0.699988 119.2 2.89999 126.9 11.8 134.3C22.2 142.9 37.1 147.4 56.1 147.8C56.8 147.8 57.5 147.8 58.3 147.8C58.5 147.8 58.6 147.8 58.8 147.8H59.1H240.3H240.6C240.8 147.8 240.9 147.8 241.1 147.8C241.8 147.8 242.5 147.8 243.3 147.8C262.3 147.4 277.2 142.9 287.6 134.3C296.5 126.9 298.7 119.2 299.1 117.7H298.7Z"
        fill={headgearColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path opacity="0.4" d="M240.2 87.6H58.8V127.6H240.2V87.6Z" fill="black" />
    </svg>
  );
};

HeadgearStyle2.propTypes = {
  containerWidth: PropTypes.number,
  headgearColour: PropTypes.string,
};

const HeadgearStyle3 = ({ containerWidth, headgearColour }) => {
  return (
    <svg
      width="383"
      height="165"
      viewBox="0 -9 383 165"
      x={xOffset(383, containerWidth)}
    >
      <path
        d="M74 6.69997L161 99.7C172.1 111.5 166.2 131 150.4 134.6L26.4001 163.5C10.6001 167.2 -3.29994 152.4 1.40006 136.8L38.4001 14.9C43.1001 -0.500026 62.9 -5.10003 74 6.69997Z"
        fill={headgearColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        opacity="0.3"
        d="M74.8 42.3L127.4 98.5C134.1 105.7 130.5 117.4 121 119.6L46 137C36.5 139.2 28.1 130.3 30.9 120.9L53.3 47.3C56.2 37.9 68.1 35.1 74.8 42.3Z"
        fill="black"
      />
      <path
        d="M308.5 6.69995L221.5 99.7C210.4 111.5 216.3 131 232.1 134.6L356.1 163.5C371.9 167.2 385.8 152.4 381.1 136.8L344 15C339.3 -0.50005 319.6 -5.10005 308.5 6.69995Z"
        fill={headgearColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        opacity="0.3"
        d="M307.6 42.3L255 98.5C248.3 105.7 251.9 117.4 261.4 119.6L336.3 137C345.8 139.2 354.2 130.3 351.4 120.9L329 47.3C326.3 37.9 314.3 35.1 307.6 42.3Z"
        fill="black"
      />
    </svg>
  );
};

HeadgearStyle3.propTypes = {
  containerWidth: PropTypes.number,
  headgearColour: PropTypes.string,
};

const Head = ({ containerWidth, headColour, headStyle }) => {
  const headStyles = [HeadStyle1, HeadStyle2, HeadStyle3];
  const HeadStyle = headStyles[headStyle];

  return <HeadStyle containerWidth={containerWidth} headColour={headColour} />;
};

Head.propTypes = {
  containerWidth: PropTypes.number,
  headColour: PropTypes.string,
  headStyle: PropTypes.number,
};

const HeadStyle1 = ({ containerWidth, headColour }) => {
  return (
    <svg
      width="532"
      height="400"
      viewBox="-5 -5 537 418"
      x={xOffset(532, containerWidth)}
      y="100"
    >
      <path
        d="M414.3 398.7H117.6C53.1528 398.7 1 346.448 1 282.1V117.6C1 53.2517 53.1528 1 117.6 1H414.3C478.648 1 530.9 53.1528 530.9 117.6V282.1C530.9 346.448 478.747 398.7 414.3 398.7Z"
        fill={headColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M413 353.2H118.9C76.4 353.2 41.9 318.7 41.9 276.2V123.6C41.9 81.1001 76.4 46.6001 118.9 46.6001H413C455.5 46.6001 490 81.1001 490 123.6V276.2C490.1 318.7 455.6 353.2 413 353.2Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M117.6 399.7H414.3C479.2 399.7 531.9 347.1 531.9 282.1V260.1C531.9 325 479.3 377.7 414.3 377.7H117.6C52.6 377.7 0 325.1 0 260.1V282.1C0 347 52.6 399.7 117.6 399.7Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M179.1 64H414.7C414.7 64 473 67.2 473 126.6L179.1 64Z"
        fill={headColour}
      />
    </svg>
  );
};

HeadStyle1.propTypes = {
  containerWidth: PropTypes.number,
  headColour: PropTypes.string,
};

const HeadStyle2 = ({ containerWidth, headColour }) => {
  return (
    <svg
      width="533"
      height="400"
      viewBox="-9 -9 551 418"
      x={xOffset(533, containerWidth)}
      y="100"
    >
      <path
        d="M532.9 0H1V399.7H532.9V0Z"
        fill={headColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path d="M491 46.5H42.8V353.1H491V46.5Z" fill="black" />
      <path
        opacity="0.1"
        d="M532.4 377.7H0.5V399.7H532.4V377.7Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M415.9 66.3H180.3L474.2 128.9V66.3H415.9Z"
        fill={headColour}
      />
    </svg>
  );
};

HeadStyle2.propTypes = {
  containerWidth: PropTypes.number,
  headColour: PropTypes.string,
};

const HeadStyle3 = ({ containerWidth, headColour }) => {
  return (
    <svg
      width="533"
      height="400"
      viewBox="-9 -9 550 418"
      x={xOffset(533, containerWidth)}
      y="100"
    >
      <path
        d="M332.4 399.9H200.1C89.7 399.9 0.300049 310.4 0.300049 200.1C0.300049 89.7 89.8 0.300049 200.1 0.300049H332.4C442.8 0.300049 532.2 89.8 532.2 200.1C532.2 310.4 442.7 399.9 332.4 399.9Z"
        fill={headColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        opacity="0.1"
        d="M332.4 379.9H200.1C93.1 379.9 5.7 295.8 0.5 190.1C0.3 193.4 0.300049 196.7 0.300049 200.1C0.300049 310.5 89.8 399.9 200.1 399.9H332.4C442.8 399.9 532.2 310.4 532.2 200.1C532.2 196.7 532.1 193.4 532 190.1C526.7 295.8 439.4 379.9 332.4 379.9Z"
        fill="black"
      />
      <path
        d="M337 353.4H195.4C110.7 353.4 42.1 284.8 42.1 200.1C42.1 115.4 110.7 46.8 195.4 46.8H337C421.7 46.8 490.3 115.4 490.3 200.1C490.3 284.7 421.7 353.4 337 353.4Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M326.5 64.4H184.9C177.9 64.4 171.1 64.9 164.3 65.8L449.8 126.6C421.8 88.9 377 64.4 326.5 64.4Z"
        fill={headColour}
      />
    </svg>
  );
};

HeadStyle3.propTypes = {
  containerWidth: PropTypes.number,
  headColour: PropTypes.string,
};

const Face = ({ containerWidth, faceColour, faceStyle }) => {
  const faceStyles = [FaceStyle1, FaceStyle2, FaceStyle3];
  const FaceStyle = faceStyles[faceStyle];

  return <FaceStyle containerWidth={containerWidth} faceColour={faceColour} />;
};

Face.propTypes = {
  containerWidth: PropTypes.number,
  faceColour: PropTypes.string,
  faceStyle: PropTypes.number,
};

const FaceStyle1 = ({ containerWidth, faceColour }) => {
  return (
    <svg
      width="302"
      height="155"
      viewBox="0 0 302 155"
      x={xOffset(302, containerWidth)}
      y="215"
    >
      <path
        d="M151 154.012C136.2 154.012 122.5 146.112 115.1 133.312C113.7 130.912 114.5 127.912 116.9 126.512C119.3 125.112 122.3 125.912 123.7 128.312C129.3 138.012 139.8 144.112 151 144.112C162.2 144.112 172.7 138.112 178.3 128.312C179.7 125.912 182.7 125.112 185.1 126.512C187.5 127.912 188.3 130.912 186.9 133.312C179.5 146.112 165.7 154.012 151 154.012Z"
        fill={faceColour}
      />
      <path
        d="M59.7 125.612H37.8C17 125.612 0.200012 108.712 0.200012 88.0118V37.8118C0.200012 17.0118 17.1 0.211792 37.8 0.211792H59.7C80.5 0.211792 97.3 17.1118 97.3 37.8118V88.0118C97.3 108.712 80.5 125.612 59.7 125.612Z"
        fill={faceColour}
      />
      <path
        d="M264.1 125.4H242.2C221.4 125.4 204.6 108.5 204.6 87.8V37.6C204.6 16.8 221.5 0 242.2 0H264.1C284.9 0 301.7 16.9 301.7 37.6V87.8C301.7 108.5 284.9 125.4 264.1 125.4Z"
        fill={faceColour}
      />
    </svg>
  );
};

FaceStyle1.propTypes = {
  containerWidth: PropTypes.number,
  faceColour: PropTypes.string,
};

const FaceStyle2 = ({ containerWidth, faceColour }) => {
  return (
    <svg
      width="303"
      height="159"
      viewBox="0 0 303 159"
      x={xOffset(303, containerWidth)}
      y="215"
    >
      <path
        d="M120.3 159C117.9 159 115.8 157.3 115.4 154.8C115 152.1 116.8 149.5 119.5 149.1L181.9 138.8C184.6 138.4 187.2 140.2 187.6 142.9C188 145.6 186.2 148.2 183.5 148.6L121.1 158.9C120.8 159 120.6 159 120.3 159Z"
        fill={faceColour}
      />
      <path
        d="M60.2 125.4H38.3C17.5 125.4 0.700012 108.5 0.700012 87.8V37.6C0.700012 16.8 17.6 0 38.3 0H60.2C81 0 97.8 16.9 97.8 37.6V87.8C97.8 108.6 81 125.4 60.2 125.4Z"
        fill={faceColour}
      />
      <path
        d="M264.6 125.4H242.7C221.9 125.4 205.1 109.5 205.1 89.8999V70.8999C205.1 51.2999 221.9 35.3999 242.7 35.3999H264.6C285.4 35.3999 302.3 51.2999 302.3 70.8999V89.8999C302.3 109.5 285.4 125.4 264.6 125.4Z"
        fill={faceColour}
      />
    </svg>
  );
};

FaceStyle2.propTypes = {
  containerWidth: PropTypes.number,
  faceColour: PropTypes.string,
};

const FaceStyle3 = ({ containerWidth, faceColour }) => {
  return (
    <svg
      width="303"
      height="92"
      viewBox="0 0 303 92"
      x={xOffset(303, containerWidth)}
      y="255"
    >
      <path
        d="M183.3 91.2001H120.1C117.3 91.2001 115.1 89.0001 115.1 86.2001C115.1 83.4001 117.3 81.2001 120.1 81.2001H183.3C186.1 81.2001 188.3 83.4001 188.3 86.2001C188.3 89.0001 186.1 91.2001 183.3 91.2001Z"
        fill={faceColour}
      />
      <path
        d="M92.7 10H5.59998C2.79998 10 0.599976 7.8 0.599976 5C0.599976 2.2 2.79998 0 5.59998 0H92.7C95.5 0 97.7 2.2 97.7 5C97.7 7.8 95.5 10 92.7 10Z"
        fill={faceColour}
      />
      <path
        d="M297.5 10H210.4C207.6 10 205.4 7.8 205.4 5C205.4 2.2 207.6 0 210.4 0H297.5C300.3 0 302.5 2.2 302.5 5C302.5 7.8 300.3 10 297.5 10Z"
        fill={faceColour}
      />
    </svg>
  );
};

FaceStyle3.propTypes = {
  containerWidth: PropTypes.number,
  faceColour: PropTypes.string,
};

const Body = ({ containerWidth, bodyColour, bodyStyle }) => {
  const bodyStyles = [BodyStyle1, BodyStyle2, BodyStyle3];
  const BodyStyle = bodyStyles[bodyStyle];

  return <BodyStyle containerWidth={containerWidth} bodyColour={bodyColour} />;
};

Body.propTypes = {
  containerWidth: PropTypes.number,
  bodyColour: PropTypes.string,
  bodyStyle: PropTypes.number,
};

const BodyStyle1 = ({ containerWidth, bodyColour }) => {
  return (
    <svg
      width="305"
      height="286"
      viewBox="-9 -9 323 295"
      x={xOffset(305, containerWidth)}
      y="375"
    >
      <path
        d="M239.5 284.7H65.4C29.6 284.7 0.5 255.7 0.5 219.8V64.9C0.5 29.1 29.5 0 65.4 0H239.5C275.3 0 304.4 29 304.4 64.9V219.8C304.4 255.6 275.3 284.7 239.5 284.7Z"
        fill={bodyColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        opacity="0.2"
        d="M230.2 284.7H220.2V217.4V217.2C220.2 217 221.7 196.7 207.2 181.1C195.4 168.4 176.5 161.9 150.9 161.9C125.2 161.9 106.3 168.4 94.5 181.2C80 197 81.6 217 81.6 217.2V217.6V284.7H71.6V217.8C71.4 215.1 70.4 192.6 87.1 174.4C100.8 159.4 122.3 151.9 150.8 151.9C179.3 151.9 200.8 159.5 214.5 174.4C231.2 192.6 230.2 215.1 230 217.8V284.7H230.2Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M239.5 215.7H65.4C29.6 215.7 0.5 186.7 0.5 150.8V220.5C0.5 256.3 29.5 285.4 65.4 285.4H239.5C275.3 285.4 304.4 256.4 304.4 220.5V150.8C304.4 186.6 275.3 215.7 239.5 215.7Z"
        fill="black"
      />
    </svg>
  );
};

BodyStyle1.propTypes = {
  containerWidth: PropTypes.number,
  bodyColour: PropTypes.string,
};

const BodyStyle2 = ({ containerWidth, bodyColour }) => {
  return (
    <svg
      width="305"
      height="285"
      viewBox="-9 -9 323 294"
      x={xOffset(305, containerWidth)}
      y="375"
    >
      <path
        d="M304.3 0H0.5V284.6H304.3V0Z"
        fill={bodyColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        opacity="0.2"
        d="M231.3 152.4H118.9V160.5H231.3V152.4Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M231.2 284.3H222.3V186.2H82.5V284.3H73.6V177.3H231.2V284.3Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M104.9 152.4H72.9V160.5H104.9V152.4Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M304.3 215.7H0.5V284.7H304.3V215.7Z"
        fill="black"
      />
    </svg>
  );
};

BodyStyle2.propTypes = {
  containerWidth: PropTypes.number,
  bodyColour: PropTypes.string,
};

const BodyStyle3 = ({ containerWidth, bodyColour }) => {
  return (
    <svg
      width="305"
      height="285"
      viewBox="-9 0 314 285"
      x={xOffset(305, containerWidth)}
      y="375"
    >
      <path
        d="M162.3 284.7H143.1C64.5 284.7 0.800049 221 0.800049 142.4C0.800049 63.8001 64.5 0.100098 143.1 0.100098H162.3C240.9 0.100098 304.6 63.8001 304.6 142.4C304.6 221 240.9 284.7 162.3 284.7Z"
        fill={bodyColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        opacity="0.2"
        d="M152.7 254.5C122.9 254.5 98.6 230.2 98.6 200.4C98.6 170.6 122.9 146.3 152.7 146.3C182.5 146.3 206.8 170.6 206.8 200.4C206.8 230.2 182.5 254.5 152.7 254.5ZM152.7 156.2C128.3 156.2 108.5 176 108.5 200.4C108.5 224.8 128.3 244.6 152.7 244.6C177.1 244.6 196.9 224.8 196.9 200.4C196.9 176 177.1 156.2 152.7 156.2Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M162.3 214.8H143.1C76.6 214.8 20.7 169.1 5.09998 107.4C2.29998 118.6 0.800049 130.3 0.800049 142.3C0.800049 220.9 64.5 284.6 143.1 284.6H162.3C240.9 284.6 304.6 220.9 304.6 142.3C304.6 130.2 303.1 118.5 300.3 107.4C284.7 169.2 228.8 214.8 162.3 214.8Z"
        fill="black"
      />
    </svg>
  );
};

BodyStyle3.propTypes = {
  containerWidth: PropTypes.number,
  bodyColour: PropTypes.string,
};

const Tshirt = ({ containerWidth, tShirtColour, children }) => {
  const scale = 1.6;
  const width = 268 * scale;
  const height = 144 * scale;
  const tshirtPath =
    "M40.49 0.127655H219.003H221.912H232.73V0.274261C240.759 0.559021 248.05 5.72012 250.805 13.5653L250.86 13.5508L251.187 14.7712L251.209 14.85C251.222 14.8966 251.234 14.9433 251.247 14.9901L251.258 15.0305C251.281 15.1172 251.304 15.2039 251.326 15.2907L257.839 39.5993L221.912 49.226V135.664H34.9121V48.7052L0.458984 39.4735L6.99719 15.0724C7.01172 15.0165 7.02649 14.9606 7.04138 14.9046C7.0564 14.8487 7.07166 14.7929 7.08704 14.7372L7.43872 13.4249L7.49402 13.4397C10.3851 5.20709 18.2706 -0.0698853 26.7629 0.142151V0.127655H34.9121H40.49Z";

  return (
    <svg
      width={width}
      height={height}
      viewBox="-4 0 268 144"
      x={xOffset(width, containerWidth)}
      y="488"
    >
      <defs>
        <clipPath id="edge-of-tshirt">
          <path clipRule="evenodd" d={tshirtPath} />
        </clipPath>
      </defs>
      <path
        fillRule="evenodd"
        d={tshirtPath}
        fill={tShirtColour}
        stroke="black"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <g clipPath="url(#edge-of-tshirt)">{children}</g>
    </svg>
  );
};

Tshirt.propTypes = {
  containerWidth: PropTypes.number,
  tShirtColour: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const Legs = ({ containerWidth }) => {
  return (
    <svg
      width="207"
      height="226"
      viewBox="0 0 207 226"
      x={xOffset(207, containerWidth)}
      y="550"
    >
      <path d="M66.7 0H0.700012V225.5H66.7V0Z" fill="black" />
      <path d="M206.2 0H140.2V225.5H206.2V0Z" fill="black" />
    </svg>
  );
};

Legs.propTypes = {
  containerWidth: PropTypes.number,
};

const ShoeStyle1Right = ({ shoeColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M102.8 0.100098H80.5C62.3 0.100098 47.5 14.9001 47.5 33.1001V75.3001H33.1C14.9 75.3001 0.0999756 90.1001 0.0999756 108.3V116.3C0.0999756 134.5 14.9 149.3 33.1 149.3H80.5H102.8C121 149.3 135.8 134.5 135.8 116.3V108.3V33.1001C135.8 14.9001 121.1 0.100098 102.8 0.100098Z"
      fill={shoeColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path opacity="0.2" d="M135.8 53.8H47.5V63.8H135.8V53.8Z" fill="black" />
    <path
      opacity="0.1"
      d="M102.8 134.6H33.1C16 134.6 1.99998 121.6 0.29998 105C0.19998 106.1 0.0999756 107.2 0.0999756 108.4V116.4C0.0999756 134.6 14.9 149.4 33.1 149.4H102.8C121 149.4 135.8 134.6 135.8 116.4V108.4C135.8 107.3 135.7 106.1 135.6 105C134 121.6 119.9 134.6 102.8 134.6Z"
      fill="black"
    />
  </svg>
);

ShoeStyle1Right.propTypes = {
  shoeColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const ShoeStyle1Left = ({ shoeColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M289.7 75.3001H275.3V33.1001C275.3 14.9001 260.5 0.100098 242.3 0.100098H220C201.8 0.100098 187 14.9001 187 33.1001V108.3V116.3C187 134.5 201.8 149.3 220 149.3H242.3H289.7C307.9 149.3 322.7 134.5 322.7 116.3V108.3C322.7 90.1001 307.9 75.3001 289.7 75.3001Z"
      fill={shoeColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path opacity="0.2" d="M275.3 53.8H187V63.8H275.3V53.8Z" fill="black" />
    <path
      opacity="0.1"
      d="M289.7 134.6H220C202.9 134.6 188.9 121.6 187.2 105C187.1 106.1 187 107.2 187 108.4V116.4C187 134.6 201.8 149.4 220 149.4H289.7C307.9 149.4 322.7 134.6 322.7 116.4V108.4C322.7 107.3 322.6 106.1 322.5 105C320.8 121.6 306.8 134.6 289.7 134.6Z"
      fill="black"
    />
  </svg>
);

ShoeStyle1Left.propTypes = {
  shoeColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const ShoeStyle1 = ({ containerWidth, shoeColour }) => (
  <svg
    width="323"
    height="150"
    viewBox="-4 0 331 158"
    x={xOffset(323, containerWidth)}
    y="730"
  >
    <ShoeStyle1Left shoeColour={shoeColour} />
    <ShoeStyle1Right shoeColour={shoeColour} />
  </svg>
);

ShoeStyle1.propTypes = {
  containerWidth: PropTypes.number,
  shoeColour: PropTypes.string,
};

const ShoeStyle2Right = ({ shoeColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M0.299988 112.9C1.59999 92.1 18.8 75.5 39.9 75.3C40 75.3 40 75.3 40.1 75.3H47.6V31.6C47.6 31.6 54.3 13.5 80.8 9.40002V4C80.8 1.8 82.6 0 84.8 0H98.9C101.1 0 102.9 1.8 102.9 4V9.40002C129.3 13.5 136.1 31.6 136.1 31.6V75.3C136.1 75.5 136.1 75.8 136.1 76V141.7C136.1 145.9 132.7 149.3 128.5 149.3H110.8C106.6 149.3 103.2 145.9 103.2 141.7V136.9H92V140.8C92 145.5 88.2 149.2 83.6 149.2H56C55.6 149.2 55.1 149.2 54.7 149.1H18.8H9.20001C4.20001 149.1 0.200012 145.1 0.200012 140.1V115.3V114C0.200012 113.7 0.299988 113.3 0.299988 112.9Z"
      fill={shoeColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      opacity="0.15"
      d="M0.299988 112.9C1.59999 92.1001 18.8 75.5 39.9 75.3C40 75.3 40 75.3 40.1 75.3H47.6V79.7001C47.6 95.1001 60.1 107.6 75.5 107.6H84.5V108.5C84.5 112.5 87.8 115.8 91.8 115.8C95.8 115.8 99.1 112.5 99.1 108.5V107.6H108.1C123.5 107.6 136 95.1001 136 79.7001V141.7C136 145.9 132.6 149.3 128.4 149.3H110.7C106.5 149.3 103.1 145.9 103.1 141.7V136.9H91.9V140.8C91.9 145.5 88.1 149.2 83.5 149.2H56C55.6 149.2 55.1 149.2 54.7 149.1H18.8H9.20001C4.20001 149.1 0.200012 145.1 0.200012 140.1V115.3V114C0.200012 113.7 0.299988 113.3 0.299988 112.9Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M84.7 44.3H98.8C101 44.3 102.8 42.5 102.8 40.3V4C102.8 1.8 101 0 98.8 0H84.7C82.5 0 80.7 1.8 80.7 4V40.3C80.7 42.6 82.5 44.3 84.7 44.3Z"
      fill="black"
    />
    <path
      d="M135.9 134.4V137.2V141.7V143.3H135.7C135 146.8 131.9 149.3 128.2 149.3H110.5C106.8 149.3 103.8 146.7 103 143.3H91.3C90.3 146.8 87 149.3 83.2 149.3H56C55.6 149.3 55.1 149.3 54.7 149.2H18.8H9.20001C5.30001 149.2 2.00001 146.7 0.700012 143.3H0.0999756V140.2V137.2V134.4H135.9Z"
      fill="black"
    />
  </svg>
);

ShoeStyle2Right.propTypes = {
  shoeColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const ShoeStyle2Left = ({ shoeColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M325.6 112.9C324.3 92.1 307.1 75.5 286 75.3C285.9 75.3 285.9 75.3 285.8 75.3H278.3V31.6C278.3 31.6 271.6 13.5 245.1 9.40002V4C245.1 1.8 243.3 0 241.1 0H227C224.8 0 223 1.8 223 4V9.40002C196.6 13.5 189.8 31.6 189.8 31.6V75.3C189.8 75.5 189.8 75.8 189.8 76V141.7C189.8 145.9 193.2 149.3 197.4 149.3H215.1C219.3 149.3 222.7 145.9 222.7 141.7V136.9H233.9V140.8C233.9 145.5 237.7 149.2 242.3 149.2H269.6C270 149.2 270.5 149.2 270.9 149.1H306.8H316.4C321.4 149.1 325.4 145.1 325.4 140.1V115.3V114C325.7 113.7 325.7 113.3 325.6 112.9Z"
      fill={shoeColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      opacity="0.15"
      d="M325.6 112.9C324.3 92.1001 307.1 75.5 286 75.3C285.9 75.3 285.9 75.3 285.8 75.3H278.3V79.7001C278.3 95.1001 265.8 107.6 250.4 107.6H241.4V108.5C241.4 112.5 238.1 115.8 234.1 115.8C230.1 115.8 226.8 112.5 226.8 108.5V107.6H217.8C202.4 107.6 189.9 95.1001 189.9 79.7001V141.7C189.9 145.9 193.3 149.3 197.5 149.3H215.2C219.4 149.3 222.8 145.9 222.8 141.7V136.9H234V140.8C234 145.5 237.8 149.2 242.4 149.2H269.7C270.1 149.2 270.6 149.2 271 149.1H306.9H316.5C321.5 149.1 325.5 145.1 325.5 140.1V115.3V114C325.7 113.7 325.7 113.3 325.6 112.9Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M241.2 44.3H227.1C224.9 44.3 223.1 42.5 223.1 40.3V4C223.1 1.8 224.9 0 227.1 0H241.2C243.4 0 245.2 1.8 245.2 4V40.3C245.2 42.6 243.4 44.3 241.2 44.3Z"
      fill="black"
    />
    <path
      d="M190 134.4V137.2V141.7V143.3H190.2C190.9 146.8 194 149.3 197.7 149.3H215.4C219.1 149.3 222.1 146.7 222.9 143.3H234.6C235.6 146.8 238.9 149.3 242.7 149.3H270C270.4 149.3 270.9 149.3 271.3 149.2H307.2H316.8C320.7 149.2 324 146.7 325.3 143.3H325.9V140.2V137.2V134.4H190Z"
      fill="black"
    />
  </svg>
);

ShoeStyle2Left.propTypes = {
  shoeColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const ShoeStyle2 = ({ containerWidth, shoeColour }) => {
  return (
    <svg
      width="326"
      height="150"
      viewBox="-10 0 346 150"
      x={xOffset(326, containerWidth)}
      y="730"
    >
      <ShoeStyle2Right shoeColour={shoeColour} />
      <ShoeStyle2Left shoeColour={shoeColour} />
    </svg>
  );
};

ShoeStyle2.propTypes = {
  containerWidth: PropTypes.number,
  shoeColour: PropTypes.string,
};

const ShoeStyle3Right = ({ shoeColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M100.9 13.4999H90.4C84.1 13.4999 78.2 15.1999 73 18.0999V16.8999C73 7.9999 65.8 0.899902 57 0.899902C48.2 0.899902 41 8.0999 41 16.8999V50.2999H33.5H33.2C15.1 50.2999 0.5 64.8999 0.5 82.9999V83.2999V91.2999V110.1C0.5 118 6.89995 124.3 14.7 124.3H33.5H103.2H120.4C129.1 124.3 136.2 117.2 136.2 108.5V91.2999V83.2999V82.9999C136.2 82.8999 136.2 82.7999 136.2 82.6999C136.2 82.5999 136.2 82.5999 136.2 82.4999V64.7999V58.8999V48.6999C136.1 29.2999 120.3 13.4999 100.9 13.4999Z"
      fill={shoeColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M120.2 125.6H16.4C7.60002 125.6 0.400024 118.5 0.400024 109.6H136.1C136.1 118.4 129 125.6 120.2 125.6Z"
      fill="black"
    />
    <path
      d="M62.5 64.5999C61.8 64.5999 61.1 64.4999 60.5 64.1999L33.9 52.4C31.4 51.3 30.2 48.2999 31.4 45.7999C32.5 43.2999 35.5 42.0999 38 43.2999L64.6 55.0999C67.1 56.1999 68.3 59.1999 67.1 61.6999C66.3 63.4999 64.5 64.5999 62.5 64.5999Z"
      fill="black"
    />
    <path
      d="M69.8 42.9C69.8 42.9 69.7001 42.9 69.8 42.9L40.6 42.7C37.8 42.7 35.6 40.4 35.6 37.7C35.6 35 37.9 32.7 40.6 32.7L69.7 32.9C72.5 32.9 74.7 35.2 74.7 37.9C74.7 40.7 72.5 42.9 69.8 42.9Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M136 109.6V65.6001C111.7 65.6001 92 85.3001 92 109.6H136Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M55.2 50.4V48.8C55.2 35.7 62.4 24.2 73 18.2V17C73 8.1 65.8 1 57 1C48.2 1 41 8.2 41 17V50.4H55.2Z"
      fill="black"
    />
  </svg>
);

ShoeStyle3Right.propTypes = {
  shoeColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const ShoeStyle3Left = ({ shoeColour, x, y, width, height, viewBox }) => (
  <svg x={x} y={y} width={width} height={height} viewBox={viewBox}>
    <path
      d="M222.7 13.4999H233.2C239.5 13.4999 245.4 15.1999 250.6 18.0999V16.8999C250.6 7.9999 257.8 0.899902 266.6 0.899902C275.4 0.899902 282.6 8.0999 282.6 16.8999V50.2999H290.1H290.4C308.5 50.2999 323.1 64.8999 323.1 82.9999V83.2999V91.2999V110.1C323.1 118 316.7 124.3 308.9 124.3H290.1H220.4H203.2C194.5 124.3 187.4 117.2 187.4 108.5V91.2999V83.2999V82.9999C187.4 82.8999 187.4 82.7999 187.4 82.6999C187.4 82.5999 187.4 82.5999 187.4 82.4999V64.7999V58.8999V48.6999C187.5 29.2999 203.3 13.4999 222.7 13.4999Z"
      fill={shoeColour}
      stroke="black"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M203.5 125.6H307.3C316.1 125.6 323.3 118.5 323.3 109.6H187.6C187.5 118.4 194.7 125.6 203.5 125.6Z"
      fill="black"
    />
    <path
      d="M261.1 64.5999C261.8 64.5999 262.5 64.4999 263.1 64.1999L289.7 52.4C292.2 51.3 293.4 48.2999 292.2 45.7999C291.1 43.2999 288.1 42.0999 285.6 43.2999L259 55.0999C256.5 56.1999 255.3 59.1999 256.5 61.6999C257.4 63.4999 259.2 64.5999 261.1 64.5999Z"
      fill="black"
    />
    <path
      d="M253.9 42.9L283.1 42.7C285.9 42.7 288.1 40.4 288.1 37.7C288.1 35 285.8 32.7 283.1 32.7L254 32.9C251.2 32.9 249 35.2 249 37.9C248.9 40.7 251.1 42.9 253.9 42.9Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M187.6 109.6V65.6001C211.9 65.6001 231.6 85.3001 231.6 109.6H187.6Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M268.4 50.4V48.8C268.4 35.7 261.2 24.2 250.6 18.2V17C250.6 8.1 257.8 1 266.6 1C275.4 1 282.6 8.2 282.6 17V50.4H268.4Z"
      fill="black"
    />
  </svg>
);

ShoeStyle3Left.propTypes = {
  shoeColour: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

const ShoeStyle3 = ({ containerWidth, shoeColour }) => {
  return (
    <svg
      width="324"
      height="126"
      viewBox="-10 0 344 126"
      x={xOffset(324, containerWidth)}
      y="730"
    >
      <ShoeStyle3Right shoeColour={shoeColour} />
      <ShoeStyle3Left shoeColour={shoeColour} />
    </svg>
  );
};

ShoeStyle3.propTypes = {
  containerWidth: PropTypes.number,
  shoeColour: PropTypes.string,
};

const Shoes = ({ containerWidth, shoeColour, shoeStyle }) => {
  const shoeStyles = [ShoeStyle1, ShoeStyle2, ShoeStyle3];
  const ShoeStyle = shoeStyles[shoeStyle];

  return <ShoeStyle containerWidth={containerWidth} shoeColour={shoeColour} />;
};

Shoes.propTypes = {
  containerWidth: PropTypes.number,
  shoeColour: PropTypes.string,
  shoeStyle: PropTypes.number,
};

export {
  Face,
  Head,
  Headgear,
  Body,
  ShoeStyle1Left,
  ShoeStyle1Right,
  ShoeStyle2Left,
  ShoeStyle2Right,
  ShoeStyle3Left,
  ShoeStyle3Right,
  GloveStyle1Left,
  GloveStyle1Right,
  GloveStyle2Left,
  GloveStyle2Right,
  GloveStyle3Left,
  GloveStyle3Right,
};

export default Robot;
