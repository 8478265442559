const BadgeState = {
  NOT_AWARDED: "not_awarded",
  AWARD_REQUESTED: "award_requested",
  AWARD_COMPLETED: "award_completed",
  AWARDED: "awarded",
  ALREADY_AWARDED: "already_awarded",
};

BadgeState.initialState = (awarded) =>
  awarded ? BadgeState.ALREADY_AWARDED : BadgeState.NOT_AWARDED;

BadgeState.isAwarded = (state) =>
  [BadgeState.AWARDED, BadgeState.ALREADY_AWARDED].some((s) => s === state);

export default BadgeState;
