import Layout from "layout";
import Activity from "activity";
import Blocks from "blocks";
import Block from "blocks/block";
import Category from "blocks/category";
import Colours from "blocks/colours";
import SEO from "seo";
import useAutoSave from "use_auto_save";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";

const MusicPage = () => {
  const blocksChannel = useMemo(() => ({}), []);
  const musicChannel = {};

  const [autoSaveRequested, handleAutoSaveEvent] = useAutoSave();
  const [handleSavedStateEvent, savedState] = useSavedState();

  return (
    <>
      <SEO
        title="Make music - Code Club World"
        description="Use blocks to code some music."
      />
      <Layout savedState={savedState}>
        <Activity
          title="Music"
          activityKey="music"
          visualisationChannel={musicChannel}
          blocksChannel={blocksChannel}
          autoSaveRequested={autoSaveRequested()}
          onAutoSaveEvent={handleAutoSaveEvent}
          onSavedStateEvent={handleSavedStateEvent}
          savedState={savedState}
        >
          <Blocks channel={blocksChannel} onAutoSaveEvent={handleAutoSaveEvent}>
            <Category
              name="Music"
              colour={Colours.teal}
              css-icon="blocklyTreeIcon iconMusic"
            >
              <Block type="PlayMelody" defaults={["C--G--FD"]} />
              <Block
                type="PlayBeat"
                defaults={["ADEF@.---.---|.---.--|.---.---|.---.---"]}
              />
              <Block type="RestBars" defaults={[1]} />
              <Block type="SetTempo" defaults={[120]} />
              <Block type="ChangeTempo" defaults={[10]} />
            </Category>
            <Category
              name="Control"
              colour={Colours.orange}
              css-icon="blocklyTreeIcon iconControl"
            >
              <Block type="controls_repeat_ext" defaults={[3]} />
              <Block type="PlayTogether" />
            </Category>
            <Category
              name="Operators"
              colour={Colours.green}
              css-icon="blocklyTreeIcon iconOperators"
            >
              <Block type="RandomNumber" defaults={[1, 10]} />
              <Block type="Number" defaults={[3]} />
            </Category>
            <Category
              name="Events"
              colour={Colours.yellow}
              css-icon="blocklyTreeIcon iconEvents"
            >
              <Block type="Run" />
            </Category>
          </Blocks>
        </Activity>
      </Layout>
    </>
  );
};

export default MusicPage;
