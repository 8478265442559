import BlocklyHelpers from "blockly_helpers";
import Interpreter from "interpreter";
import Runner from "runner";

const FALLBACK_CODE = "";

const DancePreview = ({
  avatarWorkspace,
  danceWorkspace,
  previewImage,
  playing = false,
  gallery = false,
  onFinished = null,
}) => {
  const visualisationChannel = useMemo(() => {
    return {
      previewImage,
      avatarWorkspace,
      onReady: () => {},
    };
  }, [avatarWorkspace, previewImage]);

  const blocksChannel = useMemo(() => {
    if (!danceWorkspace) return;
    const [deprecated, code] = BlocklyHelpers.generateCode(danceWorkspace.xml);
    return {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
  }, [danceWorkspace]);

  useEffect(() => {
    if (playing) {
      const interpreter = Interpreter.create(
        blocksChannel,
        visualisationChannel
      );
      visualisationChannel.reset();
      interpreter.run();
    } else {
      visualisationChannel.clear();
    }
  }, [danceWorkspace, visualisationChannel, blocksChannel, playing]);

  blocksChannel.seed = Math.random().toString();

  return (
    <Runner
      beforeRun={() => {
        true;
      }}
      fullscreen={false}
      noFullscreen={true}
      onFullscreen={() => true}
      runOnPageLoad={true}
      activityKey={"dance"}
      visualisationChannel={visualisationChannel}
      blocksChannel={blocksChannel}
      iterateRun={playing}
      overwriteRun={playing}
      gallery={gallery}
      uniqueIdentifier={danceWorkspace && danceWorkspace.id}
      onFinished={onFinished}
    />
  );
};

DancePreview.propTypes = {
  avatarWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
  danceWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
  gallery: PropTypes.bool,
  onFinished: PropTypes.func,
  playing: PropTypes.bool,
  previewImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default DancePreview;
