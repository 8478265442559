import Star from "assets/star.svg";
import Chevron2 from "assets/chevron2.svg";
import styles from "./styles.module.scss";

const ProjectPanel = ({ kind, title, content }) => {
  const [visible, setVisible] = useState(false);
  const toggleVisble = () => setVisible(!visible);

  const style = visible ? {} : { display: "none" };

  return (
    <div className={styles.project_panel}>
      <div
        className={styles.project_panel_heading}
        onClick={toggleVisble}
        role="button"
        tabIndex={0}
        onKeyPress={toggleVisble}
      >
        <Star className={classNames(styles.star, styles[kind])} />
        <h3>{title}</h3>
        <Chevron2 className={styles.chevron} />
      </div>
      <div
        className={styles.project_panel_content}
        dangerouslySetInnerHTML={{ __html: content }}
        style={style}
      />
    </div>
  );
};

ProjectPanel.propTypes = {
  kind: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default ProjectPanel;
