import Button from "button";
import styles from "./styles.module.scss";

const Footer = ({
  handle,
  handleBack,
  stepIndex,
  numberOfSteps,
  handleNext,
  parentClassName,
  showNextButton,
  showBackButton,
  showBackToMapButton,
}) => {
  const routes = useRoutes();

  return (
    <div className={classNames(styles.navigation, styles[parentClassName])}>
      <div className={styles.navigation_back_button}>
        {showBackButton && (
          <Button.RectangleTertiary
            text="Back"
            outline={true}
            onClick={handleBack}
          />
        )}
      </div>
      <div className={classNames(styles.progress_container, handle)}>
        <p>
          {stepIndex + 1}/{numberOfSteps}
        </p>
      </div>
      {showNextButton && (
        <Button.RectangleTertiary text="Next" onClick={handleNext} />
      )}
      {showBackToMapButton && (
        <Button.RectangleTertiary
          text="Back to map"
          href={routes.mapRedirectorPath()}
        />
      )}
    </div>
  );
};

Footer.propTypes = {
  handle: PropTypes.string,
  handleBack: PropTypes.func,
  stepIndex: PropTypes.number,
  numberOfSteps: PropTypes.number,
  handleNext: PropTypes.func,
  parentClassName: PropTypes.string,
  showNextButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showBackToMapButton: PropTypes.bool,
};

export default Footer;
