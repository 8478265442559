import Api from "api";

const User = {};

User.update_tshirt = ({ tshirt_workspace_id }) =>
  new Promise((resolve, reject) => {
    Api.put("/user", { tshirt_workspace_id })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

User.update_avatar = ({ avatar_workspace_id }) =>
  new Promise((resolve, reject) => {
    Api.put("/user", { avatar_workspace_id })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

export default User;
