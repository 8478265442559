import Hero from "./components/hero";
import Footer from "footer";
import SEO from "seo";
import styles from "./styles.module.scss";
import Section from "./components/sections/section";
import CcwIslandsScreenshot from "assets/ccw_islands_screenshot.png";
import WhyCCWSection from "./components/sections/why_ccw_section";
import HowItWorksSection from "./components/sections/how_it_works";
import WhatAreTheySayingSection from "./components/sections/what_are_they_saying";
import ProjectsSiteInUse from "assets/projects_site_in_use.jpg";
import Button from "button";
import LandingNav from "landing_nav";

const ParentsPage = () => {
  return (
    <div className={styles.parent_page}>
      <SEO
        title="Coding for kids | Parents’ guide | Code Club World"
        description="Read our Parents’ guide to learn more about coding for kids and how Code Club World will help your child get started on their coding journey."
      />
      <LandingNav />
      <main>
        <Hero />
        <WhyCCWSection />
        <HowItWorksSection />
        <Section image={CcwIslandsScreenshot} title="Coding curriculum">
          <div>
            <p>
              Code Club World activities are based on our research-informed
              Digital Making Framework; a coding and digital making curriculum
              for non-formal settings. That means when children are in the Code
              Club World environment, they are learning to code on their own,
              creatively, and addressing challenges that matter to them.
            </p>
            <p>
              And we’re adding new activities and challenges as we go, using
              feedback from our Code Club World community.
            </p>
          </div>
        </Section>
        <WhatAreTheySayingSection />
        <Section title="Safeguarding" skinny={true}>
          <div>
            <p>
              We know your priority is making sure your kids are able to share
              their work, and engage with other young people, in a safe,
              positive environment.
            </p>
            <p>
              Kids don’t have to create an account to use Code Club World — but
              it does help with saving their work, progress, and badges.
            </p>
            <p>
              Our “Under-13 accounts” notify a parent or guardian via email when
              an account is created — so you can see the nickname your child has
              chosen for their account.
            </p>
            <p>
              When your child shares their work, we only ever show their
              nickname to help keep them safe.
            </p>
          </div>
        </Section>
        <Section image={ProjectsSiteInUse} title="More projects for your kids">
          <div>
            <p>
              Check out our Projects site, for step-by-step coding instructions
              to support young people of all ages and skill levels.
            </p>
            <p>
              We offer individual projects, and project paths to help your child
              develop their coding skills, and grow their confidence.
            </p>
            <Button.RectanglePrimary
              href={"https://projects.raspberrypi.org/en/paths"}
              text="Discover our project paths"
              className={styles.button}
            />
          </div>
        </Section>
      </main>
      <Footer />
    </div>
  );
};

export default ParentsPage;
