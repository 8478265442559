import Analytics from "analytics";
import Arrow from "../assets/arrow";
import CodeClubWorldLogo from "assets/code_club_world_logo.svg";
import Button from "button";
import styles from "./styles.module.scss";
import CsrfContext from "csrf_context";
import MyDataContext from "my_data_context";
import { maxmobilewidth } from "root/breakpoints.scss";

const strToInt = (str) => {
  const num = str.replace(/[^0-9]/g, "");
  return parseInt(num, 10);
};

const LandingNav = () => {
  const { user } = useContext(MyDataContext);
  const routes = useRoutes();
  const csrfToken = useContext(CsrfContext);
  const [navOpen, setNavOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useResize(() => setWindowWidth(window.innerWidth), []);
  const isNarrowWindow = () => windowWidth <= strToInt(maxmobilewidth);

  const toggleHandler = () => {
    setNavOpen(!navOpen);
  };

  return (
    <>
      <div className={styles.background}>
        <header className={styles.header}>
          <div className={styles.header_logo}>
            <a href="/">
              <CodeClubWorldLogo white />
            </a>
            {!isNarrowWindow() && (
              <a className={styles.link} href="/parents">
                <div className={styles.nav_text}>Parents</div>
              </a>
            )}
          </div>
          {!isNarrowWindow() ? (
            user.logged_in ? (
              <div className={styles.flex_row}>
                <Button.RectangleTertiary
                  href={routes.logoutPath()}
                  text="Log out"
                  outline={true}
                  transparent={true}
                ></Button.RectangleTertiary>
              </div>
            ) : (
              <div className={styles.flex_row}>
                <form action={routes.loginPath()} method="post">
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={csrfToken}
                  />
                  <Button.RectangleTertiary
                    text="Log in"
                    outline={true}
                    transparent={true}
                    transparent_border={true}
                    className={styles.button_login}
                    onClick={() =>
                      Analytics.accountActivity("sign_in", "parents_page")
                    }
                  ></Button.RectangleTertiary>
                </form>
                <form action={routes.signupPath()} method="post">
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={csrfToken}
                  />
                  <Button.RectangleTertiary
                    text="Sign up"
                    outline={true}
                    transparent={true}
                    onClick={() =>
                      Analytics.accountActivity("sign_up", "parents_page")
                    }
                  ></Button.RectangleTertiary>
                </form>
              </div>
            )
          ) : (
            <div
              onClick={toggleHandler}
              onKeyDown={toggleHandler}
              role="button"
              tabIndex={0}
              aria-pressed={navOpen}
              className={styles.link}
              type="button"
            >
              <div className={styles.mobile_menu}>
                <div className={styles.nav_text}>Menu</div>
                {navOpen ? <Arrow type="up" /> : <Arrow type="down" />}
              </div>
            </div>
          )}
        </header>
        {isNarrowWindow() && (
          <nav className={styles.mobile_nav}>
            {navOpen && (
              <ul className={styles.dropdown}>
                <li className={styles.dropdown_item}>
                  <a className={styles.link} href="/">
                    <div className={styles.dropdown_text}>Home</div>
                  </a>
                </li>
                <li className={styles.dropdown_item}>
                  <a className={styles.link} href="/parents">
                    <div className={styles.dropdown_text}>Parents</div>
                  </a>
                </li>
                {user.logged_in && (
                  <li className={styles.dropdown_item}>
                    <a className={styles.link} href={routes.logoutPath()}>
                      <div className={styles.dropdown_text}>Logout</div>
                    </a>
                  </li>
                )}
                {!user.logged_in && (
                  <li className={styles.dropdown_item}>
                    <form
                      action={routes.loginPath()}
                      method="post"
                      className={styles.dropdown_form}
                    >
                      <input
                        type="hidden"
                        name="authenticity_token"
                        value={csrfToken}
                      />
                      <button
                        className={styles.link}
                        onClick={() =>
                          Analytics.accountActivity("sign_in", "parents_page")
                        }
                      >
                        <div className={styles.dropdown_text}>Log in</div>
                      </button>
                    </form>
                  </li>
                )}
                {!user.logged_in && (
                  <li className={styles.dropdown_item}>
                    <form
                      action={routes.signupPath()}
                      method="post"
                      className={styles.dropdown_form}
                    >
                      <input
                        type="hidden"
                        name="authenticity_token"
                        value={csrfToken}
                      />
                      <button
                        className={styles.link}
                        onClick={() =>
                          Analytics.accountActivity("sign_up", "parents_page")
                        }
                      >
                        <div className={styles.dropdown_text}>Sign up</div>
                      </button>
                    </form>
                  </li>
                )}
              </ul>
            )}
          </nav>
        )}
      </div>
    </>
  );
};

export default LandingNav;
