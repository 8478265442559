const AllIcon = () => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 10C13.737 10 12.0703 9.00391 10 7.01172C7.92969 9.00391 6.26302 10 5 10C3.61979 10 2.44141 9.51172 1.46484 8.53516C0.488281 7.55859 0 6.38021 0 5C0 3.61979 0.488281 2.44141 1.46484 1.46484C2.44141 0.488281 3.61979 0 5 0C6.27604 0 7.94271 0.996094 10 2.98828C12.0703 0.996094 13.737 0 15 0C16.3802 0 17.5586 0.488281 18.5352 1.46484C19.5117 2.44141 20 3.61979 20 5C20 6.38021 19.5117 7.55859 18.5352 8.53516C17.5586 9.51172 16.3802 10 15 10ZM5 2.5C4.25781 2.5 3.6556 2.74089 3.19336 3.22266C2.73112 3.70443 2.5 4.29688 2.5 5C2.5 5.70312 2.73112 6.29557 3.19336 6.77734C3.6556 7.25911 4.25781 7.5 5 7.5C5.28646 7.5 5.58919 7.41536 5.9082 7.24609C6.22721 7.07682 6.51367 6.86849 6.76758 6.62109C7.02148 6.3737 7.2526 6.1263 7.46094 5.87891C7.66927 5.63151 7.83203 5.42318 7.94922 5.25391L8.125 5C8.08594 4.9349 8.02734 4.84701 7.94922 4.73633C7.87109 4.62565 7.71159 4.42383 7.4707 4.13086C7.22982 3.83789 6.99219 3.58398 6.75781 3.36914C6.52344 3.1543 6.24349 2.95573 5.91797 2.77344C5.59245 2.59115 5.28646 2.5 5 2.5ZM15 2.5C14.7135 2.5 14.4108 2.58464 14.0918 2.75391C13.7728 2.92318 13.4863 3.13151 13.2324 3.37891C12.9785 3.6263 12.7474 3.8737 12.5391 4.12109C12.3307 4.36849 12.168 4.57682 12.0508 4.74609L11.875 5C11.9141 5.0651 11.9727 5.15625 12.0508 5.27344C12.1289 5.39062 12.2884 5.59245 12.5293 5.87891C12.7702 6.16536 13.0078 6.41602 13.2422 6.63086C13.4766 6.8457 13.7565 7.04427 14.082 7.22656C14.4076 7.40885 14.7135 7.5 15 7.5C15.7422 7.5 16.3444 7.25911 16.8066 6.77734C17.2689 6.29557 17.5 5.70312 17.5 5C17.5 4.29688 17.2689 3.70443 16.8066 3.22266C16.3444 2.74089 15.7422 2.5 15 2.5Z"
      fill="white"
    />
  </svg>
);

export default AllIcon;
