import Blockly from "blockly";

Blockly.Blocks.Colour = {
  message0: "%1",
  args0: [
    {
      type: "field_colour",
      name: "value",
      colour: "#0000ff",
    },
  ],
  output: "Colour",
};

Blockly.Blocks.Colour.init = function () {
  this.jsonInit(Blockly.Blocks.Colour);
  this.operator = true;
};

Blockly.JavaScript.Colour = (block) => {
  return [block.getFieldValue("value"), Blockly.JavaScript.ORDER_NONE];
};
