import AppContext from "app_context";
import CanvasAndAvatar from "./canvas_and_avatar";
import AvatarOnly from "./avatar_only";
import randomNumberBetween from "random_number_between";
import convert from "color-convert";
import stamps from "./stamps.jsx";
import seedrandom from "seedrandom";
import penStyles from "./pen_styles";
import { useEffect, useLayoutEffect, useRef } from "react";

const canvasSizeInSteps = 240 * 2;
const canvasDimensions = {
  width: canvasSizeInSteps,
  height: canvasSizeInSteps,
};

const blocklyToSvgCoords = (x, y) => {
  return { x: x, y: -y };
};

const initialState = {
  x: 0,
  y: 0,
  heading: 90,
  penDown: true,
  penColour: "#0000ff",
  penSize: 1,
  penStyle: "pen",
  penDash: "solid",
  penShape: "line",
  scatter: false,
  drawCommands: [],
  defaultBackgroundColour: "#ffffff",
};

const useSeededPRNG = (seed) => {
  const prngRef = useRef(seedrandom(seed));
  useEffect(() => {
    prngRef.current = seedrandom(seed);
  }, [seed]);
  return prngRef;
};

const Art = ({
  channel,
  fullscreen,
  seed,
  uniqueIdentifier = null,
  outputType = "CanvasAndAvatar",
  run = false,
  iterateRun = false,
  gallery = false,
  ...avatarProps
}) => {
  const [art, setArt] = useState(initialState);
  const [canvasStep, setCanvasStep] = useState(0);
  const prng = useSeededPRNG(seed);
  const { CANVAS_STEP_TIME } = useContext(AppContext).env;

  const canvasStepTime = CANVAS_STEP_TIME || 150;

  // references for use in setTimeout
  const runRef = useRef(run);
  runRef.current = run;
  const canvasStepRef = useRef(canvasStep);
  canvasStepRef.current = canvasStep;

  channel.reset = () => {
    setArt(initialState);
    channel.clear();
  };

  channel.onFinishedOrStopped = () => {
    channel.onReady();
  };

  channel.startRun = () => {
    if (iterateRun) {
      setCanvasStep(0);
      channel.clear();
      channel.reset();
    }
  };

  channel.clear = () => {
    setArt((state) => {
      return { ...state, drawCommands: [] };
    });
  };

  channel.penDown = () => {
    setArt((state) => ({ ...state, penDown: true }));
  };

  channel.penUp = () => {
    setArt((state) => ({ ...state, penDown: false }));
  };

  channel.setPenColour = (penColour) => {
    setArt((state) => ({ ...state, penColour }));
  };

  channel.drawShapeOfSize = (name, sideLength) => {
    const sidesForShape = {
      triangle: [3, 120],
      square: [4, 90],
      pentagon: [5, 72],
      hexagon: [6, 60],
      star: [5, 144],
    };
    const [sides, angle] = sidesForShape[name];
    channel.drawFigure(angle, sides, sideLength || 50);
  };

  channel.drawFigure = (angle, numberOfSides, sideLength) => {
    let initialState;
    setArt((state) => {
      initialState = {
        penDown: state.penDown,
        x: state.x,
        y: state.y,
        heading: state.heading,
      };
      return { ...state, penDown: true };
    });
    for (let i = 0; i < numberOfSides; i++) {
      channel.turnClockwise(angle);
      channel.moveNSteps(sideLength);
    }
    setArt((state) => goTo(state, initialState.x, initialState.y));
    setArt((state) => {
      return { ...state, ...initialState };
    });
  };

  channel.setBackgroundColour = (backgroundColour) => {
    setArt((state) => ({ ...state, backgroundColour }));
  };

  channel.setPenSize = (penSize) => {
    setArt((state) => ({ ...state, penSize }));
  };

  channel.changePenSize = (amount) => {
    setArt((state) => ({ ...state, penSize: state.penSize + amount }));
  };

  channel.setPenStyle = (penStyle) => {
    setArt((state) => {
      const atts = penStyles[penStyle].atts;
      return { ...state, ...atts };
    });
  };

  channel.moveNSteps = (steps) => {
    setArt((state) => {
      const radians = ((state.heading - 90) * Math.PI) / 180;

      const xDelta = Math.cos(radians) * steps;
      const yDelta = Math.sin(radians) * steps;

      return goTo(state, state.x + xDelta, state.y + yDelta);
    });
  };

  channel.turnClockwise = (deg) => {
    setArt((state) => ({ ...state, heading: (state.heading + deg) % 360 }));
  };

  channel.turnAntiClockwise = (deg) => {
    setArt((state) => ({ ...state, heading: (state.heading - deg) % 360 }));
  };

  channel.setHeading = (heading) => {
    setArt((state) => ({ ...state, heading }));
  };

  channel.goTo = (x1, y1) => {
    const { x, y } = blocklyToSvgCoords(x1, y1);
    setArt((state) => goTo(state, x, y));
  };

  channel.goToRandomPosition = () => {
    const randomX =
      prng.current() * canvasDimensions.width - canvasDimensions.width / 2;
    const randomY =
      prng.current() * canvasDimensions.height - canvasDimensions.height / 2;
    setArt((state) =>
      goTo(state, randomX - state.penSize, randomY + state.penSize)
    );
  };

  channel.setX = (x1) => {
    const { x } = blocklyToSvgCoords(x1, 0);
    setArt((state) => goTo(state, x, state.y));
  };

  channel.changeX = (x1) => {
    const { x } = blocklyToSvgCoords(x1, 0);
    setArt((state) => goTo(state, state.x + x, state.y));
  };

  channel.setY = (y1) => {
    const { y } = blocklyToSvgCoords(0, y1);
    setArt((state) => goTo(state, state.x, y));
  };

  channel.changeY = (y1) => {
    const { y } = blocklyToSvgCoords(0, y1);
    setArt((state) => goTo(state, state.x, state.y + y));
  };

  channel.changeColourBy = (percent) => {
    setArt((state) => {
      const hueChange = (percent / 101) * 360;
      const hsl = convert.hex.hsl(state.penColour);
      const hex = convert.hsl.hex([(hsl[0] + hueChange) % 360, hsl[1], hsl[2]]);
      return { ...state, penColour: `#${hex}` };
    });
  };

  channel.randomNumberBetween = (a, b) => {
    return randomNumberBetween(a, b, prng.current);
  };

  channel.stamp = (stampKey, size) => {
    const stamp = stamps[stampKey];
    setArt((state) => drawStamp(state, stamp, size));
  };

  const iterateCanvas = () => {
    const canvasStepLoop = () => {
      setTimeout(() => {
        setCanvasStep((canvasStep) => {
          return canvasStep + 1;
        });
        if (canvasStepRef.current < art.drawCommands.length && runRef.current) {
          canvasStepLoop();
        } else if (
          canvasStepRef.current >= art.drawCommands.length &&
          channel.onFinishedIteration
        ) {
          channel.onFinishedIteration();
        }
      }, canvasStepTime);
    };
    canvasStepLoop();
  };

  useLayoutEffect(() => {
    if (iterateRun) {
      if (run) {
        iterateCanvas();
      }
    } else if (!run) {
      setCanvasStep(null);
    }
  }, [run, iterateRun]); // eslint-disable-line react-hooks/exhaustive-deps

  // These methods are used in the unit tests.
  channel.artState = () => art;

  const goTo = (state, x, y) => {
    const {
      x: prevX,
      y: prevY,
      penDown,
      penColour,
      penSize,
      penStyle,
      penShape,
      penDash,
      scatter,
      drawCommands,
    } = state;

    if (penDown) {
      const newCommands = drawCommands.concat({
        command: "line",
        fromX: prevX,
        fromY: prevY,
        toX: x,
        toY: y,
        dash: penDash,
        colour: penColour,
        width: penSize,
        shape: penShape,
        scatter: scatter,
        style: penStyle,
      });
      return { ...state, x, y, drawCommands: newCommands };
    } else {
      return { ...state, x, y };
    }
  };

  const drawStamp = (state, stamp, size) => {
    const { x, y, penColour, drawCommands } = state;

    const newCommands = drawCommands.concat({
      command: "stamp",
      x: x,
      y: y,
      component: stamp.path,
      colour: penColour,
      size: size,
    });
    return { ...state, drawCommands: newCommands };
  };

  useEffect(() => {
    channel.onReady();
    channel.reset();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch (outputType) {
    case "AvatarOnly":
      return (
        <AvatarOnly
          art={art}
          canvasDimensions={canvasDimensions}
          seed={seed}
          {...avatarProps}
        />
      );
    case "CanvasAndAvatar":
      return (
        <CanvasAndAvatar
          fullscreen={fullscreen}
          art={art}
          canvasDimensions={canvasDimensions}
          seed={seed}
          canvasStep={canvasStep}
          iterateRun={iterateRun}
          uniqueIdentifier={uniqueIdentifier}
          gallery={gallery}
        />
      );
  }
};

Art.propTypes = {
  channel: PropTypes.object,
  seed: PropTypes.string.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  outputType: PropTypes.string,
  uniqueIdentifier: PropTypes.string,
  run: PropTypes.bool,
  iterateRun: PropTypes.bool,
  gallery: PropTypes.bool,
};

export default Art;
