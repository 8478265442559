import RemixIcon from "assets/remix_icon.svg";
import Button from "button";

const RemixButton = ({ className, onClick }) => {
  return (
    <Button.RectangleTertiary
      icon={<RemixIcon />}
      text="Remix"
      onClick={onClick}
      className={className}
      textAlways={true}
    ></Button.RectangleTertiary>
  );
};

RemixButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RemixButton;
