import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.PointInDirection = {
  message0: "point in direction %1",
  args0: [
    {
      type: "input_value",
      name: "degrees",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.PointInDirection.init = function () {
  this.jsonInit(Blockly.Blocks.PointInDirection);
};

Blockly.JavaScript.PointInDirection = function (block) {
  const degrees = helpers.codeForArg("degrees", block);

  if (typeof degrees === "undefined") {
    return "";
  } else {
    return `visualisation.setHeading(${degrees});`;
  }
};
