const SavedState = {
  NOT_SAVING: "not-saving",
  SAVING: "saving",
  WAITING_FOR_SAVING_FINISHED: "waiting-for-saving-finished",
  WAITING_FOR_SAVING_TIMER: "waiting-for-saving-timer",
  JUST_SAVED: "just-saved",
  ERROR: "error",
};

export default SavedState;
