const Filters = ({ canvasId, canvasBounds }) => {
  const [x, y, w, h] = canvasBounds;
  return (
    <>
      <filter
        id={canvasId + "-spraypaint"}
        filterUnits="userSpaceOnUse"
        x={x}
        y={y}
        width={w}
        height={h}
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="5,5" />
      </filter>
      <filter
        id={canvasId + "-crayon"}
        filterUnits="userSpaceOnUse"
        x={x}
        y={y}
        width={w}
        height={h}
      >
        <feTurbulence
          type="fractalNoise"
          baseFrequency="1.001"
          numOctaves="10"
          result="noise"
        ></feTurbulence>
        <feDisplacementMap
          xChannelSelector="R"
          yChannelSelector="G"
          scale="50"
          in="SourceGraphic"
          result="newSource"
        ></feDisplacementMap>
        <feGaussianBlur stdDeviation="1.1" />
      </filter>
    </>
  );
};

Filters.propTypes = {
  canvasId: PropTypes.string.isRequired,
  canvasBounds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Filters;
