const SavingErrorIcon = () => (
  <svg
    width="31"
    height="21"
    viewBox="0 0 31 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.034 20.6003H7.78817C5.90449 20.6014 4.0894 19.8935 2.70389 18.6173C1.31837 17.3411 0.463955 15.5902 0.310561 13.7128C0.157167 11.8354 0.716037 9.96899 1.87602 8.48485C3.036 7.0007 4.71211 6.00754 6.57098 5.70287C7.44227 4.15758 8.70821 2.87131 10.2394 1.9755C11.7706 1.07969 13.5122 0.606472 15.2862 0.604192C17.5383 0.595963 19.7258 1.35642 21.4871 2.75988C23.2168 4.13185 24.4465 6.03525 24.9862 8.17598C26.543 8.41519 27.9521 9.23354 28.9313 10.4672C29.9105 11.7009 30.3877 13.2589 30.2674 14.8294C30.147 16.3999 29.4379 17.8669 28.2821 18.937C27.1263 20.007 25.609 20.6011 24.034 20.6003ZM15.2862 3.10479C13.9553 3.10636 12.6487 3.46134 11.4999 4.13343C10.3511 4.80552 9.40143 5.77062 8.74792 6.93005L8.16307 7.97853L6.97588 8.17223C5.74028 8.37924 4.62746 9.04268 3.85777 10.0312C3.08808 11.0197 2.71764 12.2612 2.8198 13.5098C2.92195 14.7585 3.48925 15.9232 4.40938 16.7735C5.3295 17.6237 6.53535 18.0975 7.78817 18.1009H24.034C24.979 18.1019 25.8896 17.7459 26.5834 17.1042C27.2772 16.4625 27.7031 15.5824 27.7757 14.6401C27.8484 13.6979 27.5625 12.7629 26.9753 12.0224C26.3881 11.2819 25.5429 10.7905 24.6088 10.6466L22.9642 10.3967L22.5618 8.78207C22.1582 7.15871 21.2222 5.71742 19.9034 4.68834C18.5846 3.65925 16.959 3.10173 15.2862 3.10479Z"
      fill="black"
      fillOpacity="0.2"
    />
    <rect
      x="20.0767"
      y="8.84595"
      width="11.0465"
      height="2.50464"
      transform="rotate(135 20.0767 8.84595)"
      fill="black"
    />
    <rect
      x="18.3057"
      y="16.657"
      width="11.0465"
      height="2.50464"
      transform="rotate(-135 18.3057 16.657)"
      fill="black"
    />
  </svg>
);

export default SavingErrorIcon;
