import Draggable from "draggable";
import HomeIsland from "./islands/home_island.svg";
import ScratchIsland from "./islands/scratch_island.svg";
import PythonIsland from "./islands/python_island.svg";
import MoreIsland from "./islands/more_island.svg";
import MapAvatar from "map_avatar";
import MapNodeInfo from "map_node_info";
import { useRememberMapPosition, rememberedMapPosition } from "map_position";
import Layout from "layout";
import styles from "./styles.module.scss";
import MapBackgroundAsDataUri from "map_background/data_uri";
import Island from "./island";
import Heading from "heading";
import Analytics from "analytics";
import SEO from "seo";
import MyDataContext from "my_data_context";
import MusicAudio from "music_audio";
import executeCustomiseWorkspace from "execute_customise_workspace";
import useMusicActivityTunes from "use_music_activity_tunes";

const keyCodesVsDirections = {
  ArrowUp: "N",
  ArrowRight: "E",
  ArrowDown: "S",
  ArrowLeft: "W",
};

const metaData = {
  home: {
    title: {
      default: "Home Island - Code Club World",
      art: "Design a T-shirt - Home Island - Code Club World",
      avatar: "Make an avatar - Home Island - Code Club World",
      dance: "Robo-boogie - Home Island - Code Club World",
      music: "Make music - Home Island - Code Club World",
      signpost:
        "Discover the power of programming! Get started with some fun activities.",
    },
    description:
      "Explore our home island. Use block coding to create an avatar, art, music, and make a robot dance.",
  },
  scratch: {
    title: {
      default: "Scratch island - Code Club World",
      "space-talk": "Space talk - Scratch island - Code Club World",
      "catch-the-bus": "Catch the bus - Scratch island - Code Club World",
      "find-the-bug": "Find the bug - Scratch island - Code Club World",
      "silly-eyes": "Silly eyes - Scratch island - Code Club World",
      "surprise-animation":
        "Surprise! animation - Scratch island - Code Club World",
      "i-made-you-a-book":
        "I made you a book - Scratch island - Code Club World",
      signpost:
        "Scratch is a block-based programming language. Use it to create your own interactive stories, games, and animations.",
    },
    description:
      "Explore our Scratch island projects. Learn Scratch to make games, art, and music.",
  },
  python: {
    title: {
      default: "Python island - Code Club World",
      "hello-world": "Hello 🌍🌎🌏 - Python island - Code Club World",
      "target-practice": "Target practice - Python island - Code Club World",
      "rocket-launch": "Rocket launch - Python island - Code Club World",
      "make-a-face": "Make a face - Python island - Code Club World",
      "dont-collide": "Don't collide! - Python island - Code Club World",
      "powerful-patterns":
        "Powerful patterns - Python island - Code Club World",
      signpost:
        "Python is a text-based programming language which is easy to learn. Use it to make art, games and other useful apps.",
    },
    description:
      "Explore our Python island projects. Learn Python to make games and art.",
  },
  more: {
    title: {
      default: "More projects - Code Club World",
      signpost: "Ready for even more projects?",
    },
    description:
      "Check out the Raspberry Pi Projects site for even more projects.",
  },
};

const IslandPage = () => {
  const draggable = useMemo(() => ({}), []);
  const nodeInfo = useMemo(() => ({}), []);

  const { island_name } = useParams();
  const [displayMapNodeInfo, setDisplayMapNodeInfo] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [avatarPosition, setAvatarPosition] = useState();
  const [songPlaying, setSongPlaying] = useState(false);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    // an equation that scales from ~3.5 to 1
    // purely trial and error based with a little spreadsheet formula magic
    var zoom = 38 * width ** -0.416;
    return {
      width,
      height,
      zoom,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const island = Island.forKey(island_name);
  const history = useHistory();

  const { availableSongs, useGeneratedAudio } = useMusicActivityTunes();

  const songPlayingColor = "#817ffd";
  const songNotPlayingColor = "#FFC700";

  useKeydown(({ code }) => {
    if (code === "Escape") {
      handleInfoClose();
    } else {
      const direction = keyCodesVsDirections[code];
      if (direction) {
        handleMoveAvatar(direction);
      }
    }
  });

  const nodeFocus = useCallback(() => {
    const hash = rememberedMapPosition()[1] || "#start";
    const startNode = MapNodeInfo.getElement(hash.substring(1));
    const startBox = draggable.boxRelativeToContent(startNode);
    setAvatarPosition({ x: startBox.centreX, y: startBox.centreY });
    setSelectedNode({ element: startNode, ...startBox });
    setDisplayMapNodeInfo(false);
  }, [draggable]);

  useResize(() => {
    setWindowDimensions(getWindowDimensions());
    nodeFocus();
  }, []);

  useEffect(() => {
    if (selectedNode) {
      history.push("#" + MapNodeInfo.keyOf(selectedNode));
    }
  }, [selectedNode, history]);

  useRememberMapPosition();

  useEffect(() => {
    nodeFocus();
  }, [draggable, nodeFocus]);

  useEffect(() => {
    const reCentreMap = () => {
      if (!selectedNode) {
        return;
      }
      const nodeInfo = MapNodeInfo.getElement("info");
      if (nodeInfo) {
        draggable.moveToCentreOfElement(nodeInfo);
      } else {
        draggable.moveToCentrePosition(
          selectedNode.centreX,
          selectedNode.centreY
        );
      }
    };

    reCentreMap();
  }, [draggable, nodeInfo, selectedNode, displayMapNodeInfo]);

  const handleMapClick = ({ target }) => {
    const content = nodeContent({ element: target });
    const clickedOnANode = !!content;
    const clickedOnTheOpenNode =
      selectedNode && selectedNode.element === target;
    let targetBox;
    if (clickedOnANode) {
      targetBox = draggable.boxRelativeToContent(target);
      if (nodeContent({ element: target }).moveAvatar) {
        setAvatarPosition({ x: targetBox.centreX, y: targetBox.centreY });
      }
    }
    if (!clickedOnANode) {
      setDisplayMapNodeInfo(false);
    } else if (clickedOnTheOpenNode) {
      setDisplayMapNodeInfo(!displayMapNodeInfo);
    } else {
      setSelectedNode((node) => ({ ...node, element: target, ...targetBox }));
      setDisplayMapNodeInfo(true);
    }
  };

  const nodeContent = (node) =>
    node ? island.nodes[MapNodeInfo.keyOf(node)] : {};

  const selectedNodeContent = () => nodeContent(selectedNode);

  const handleMoveAvatar = (direction) => {
    setSelectedNode((selectedNode) => {
      const targetNodeIndex = nodeContent(selectedNode).exits[direction];
      if (!targetNodeIndex) {
        return selectedNode;
      }
      const target = MapNodeInfo.getElement(targetNodeIndex);
      const targetBox = draggable.boxRelativeToContent(target);
      setAvatarPosition({ x: targetBox.centreX, y: targetBox.centreY });
      setDisplayMapNodeInfo(true);
      return { element: target, ...targetBox };
    });
  };

  const handleInfoClose = () => {
    setDisplayMapNodeInfo(false);
  };

  const { customise_workspace } = useContext(MyDataContext);
  const customiseResult = executeCustomiseWorkspace(customise_workspace) || {};
  const dataUri = MapBackgroundAsDataUri(
    customiseResult.mapColour1,
    customiseResult.mapColour2
  );
  const islandStyle = {
    backgroundImage: dataUri,
  };
  const defaultSong = Object.values(availableSongs)[0];
  const [song, setSong] = useState(
    availableSongs[customiseResult.boomboxSong] || defaultSong
  );

  useGeneratedAudio(song, setSong);

  const handlePlaySong = () => {
    Analytics.event("click", {
      event_category: "Home Island",
      event_label: "Boombox",
    });
    setSongPlaying(!songPlaying);
  };
  const handleSongEnded = () => setSongPlaying(false);

  const renderIsland = (islandName) => {
    switch (islandName) {
      case "home":
        return (
          <>
            <MusicAudio
              song={song}
              songPlaying={songPlaying}
              onSongEnded={handleSongEnded}
            />
            <HomeIsland
              windowDimensions={windowDimensions}
              scale={0.45}
              className={styles.island}
              onClick={handleMapClick}
              style={islandStyle}
              onPlaySong={handlePlaySong}
              color={songPlaying ? songPlayingColor : songNotPlayingColor}
            />
          </>
        );
      case "scratch":
        return (
          <ScratchIsland
            windowDimensions={windowDimensions}
            scale={1.5}
            className={styles.island}
            onClick={handleMapClick}
            style={islandStyle}
          />
        );
      case "python":
        return (
          <PythonIsland
            windowDimensions={windowDimensions}
            scale={1.8}
            className={styles.island}
            onClick={handleMapClick}
            style={islandStyle}
          />
        );
      case "more":
        return (
          <MoreIsland
            windowDimensions={windowDimensions}
            scale={1.7}
            className={styles.island}
            onClick={handleMapClick}
            style={islandStyle}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SEO
        title={
          (selectedNode &&
            metaData[island_name].title[MapNodeInfo.keyOf(selectedNode)]) ||
          metaData[island_name].title.default ||
          metaData[island_name].title
        }
        description={metaData[island_name].description}
      />
      <Layout>
        <Heading
          transparent={true}
          invertBackgroundColours={false}
          title={<h1>{island.title}</h1>}
          backPath="/map"
          islandPage
        />
        <div className={styles.island_page}>
          <p className={styles.keyboard_instructions}>
            Use the arrow keys to move your avatar. Press the escape key to
            close the information box.
          </p>
          <Draggable channel={draggable}>
            {avatarPosition && <MapAvatar {...avatarPosition} />}
            {displayMapNodeInfo && (
              <MapNodeInfo
                channel={nodeInfo}
                node={selectedNode}
                content={selectedNodeContent()}
                onClose={handleInfoClose}
              />
            )}

            {renderIsland(island_name)}
          </Draggable>
        </div>
      </Layout>
    </>
  );
};

IslandPage.propTypes = {};

export default IslandPage;
