class OfflineAudioEngine {
  constructor() {
    this.commands = [];
  }

  getCommands() {
    return this.commands;
  }

  getCurrentTime() {
    return 0;
  }

  stopAll() {}

  scheduleNote(instrument, tempo, startTime, note, duration) {
    this.commands.push({
      command: "scheduleNote",
      instrument: instrument,
      tempo: tempo,
      startTime: startTime,
      note: note,
      duration: duration,
    });

    const beatTime = 60 / tempo;
    const endTime = startTime + duration * beatTime;
    return { endTime };
  }

  scheduleDrum(drum, tempo, startTime, duration) {
    this.commands.push({
      command: "scheduleDrum",
      drum: drum,
      tempo: tempo,
      startTime: startTime,
      duration: duration,
    });

    const beatTime = 60 / tempo;
    const endTime = startTime + duration * beatTime;
    return { endTime };
  }

  finished() {}
}

export default OfflineAudioEngine;
