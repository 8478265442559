import styles from "./styles.module.scss";
import palette from "root/js_palette";
import Button from "button";
import Link from "link";
import Next from "assets/next.svg";
import Run from "assets/run.svg";
import Stop from "assets/stop.svg";
import CodeClubWorldLogo from "assets/code_club_world_logo.svg";
import Card from "card";
import CardGrid from "card_grid";
import Art from "assets/activities/art.svg";
import EllipsisMenu from "ellipsis_menu";

const DesignSystemPage = () => {
  const [openEllipsisMenu, setOpenEllipsisMenu] = useState(null);
  const handleEllipsisMenuClick = () => {
    if (openEllipsisMenu) {
      setOpenEllipsisMenu(null);
    } else {
      setOpenEllipsisMenu(true);
    }
  };
  return (
    <div className={styles.design_system_page}>
      <div className={styles.section}>
        <h2 id="colours">Colours</h2>

        {Object.keys(palette).map((category) => (
          <div key={category} className={styles.colour_category}>
            <h3>{category} colours</h3>

            {Object.entries(palette[category]).map(([name, colour]) => (
              <div key={name} className={styles.colour_sample}>
                <div className={styles.sample} style={{ background: colour }} />
                <div className={styles.name}>
                  {name}: {colour}
                </div>
              </div>
            ))}
          </div>
        ))}

        <p className={styles.info}>
          These colours can be imported into scss files so they can be used
          across the project, e.g. $primary_green
        </p>
        <p className={styles.info}>
          You can also include “js_palette” to access the same colours in a
          JavaScript file, e.g. palette.primary.green
        </p>
      </div>

      <div className={styles.section}>
        <h2 id="typography-sizes">Typography – Sizes</h2>

        <div className={styles.font_sizes}>
          {[
            "Rollerskate",
            "Skateboard",
            "Scooter",
            "Bike",
            "Moped",
            "Car",
            "Van",
          ].map((name, i) => (
            <p key={i} className={styles[name.toLowerCase()]}>
              {name}
            </p>
          ))}
        </div>

        <p className={styles.info}>
          These font sizes vary depending on browser width. They can be mixed
          into other css rules, e.g. @include rollerskate;
        </p>
      </div>

      <div className={styles.section}>
        <h2 id="typography-weights">Typography – Weights</h2>

        <div className={styles.font_weights}>
          {[
            ["Poppins", 800, "normal", "(*)"],

            ["Roboto", 100, "normal"],
            ["Roboto", 300, "normal"],
            ["Roboto", 400, "normal", "(*)"],
            ["Roboto", 500, "normal", "(*)"],
            ["Roboto", 700, "normal"],
            ["Roboto", 900, "normal"],

            ["Roboto", 100, "italic"],
            ["Roboto", 300, "italic"],
            ["Roboto", 400, "italic"],
            ["Roboto", 500, "italic"],
            ["Roboto", 700, "italic"],
            ["Roboto", 900, "italic"],
          ].map(([name, weight, style, star], i) => {
            const classes = [];

            classes.push(name.toLowerCase().replaceAll(" ", "_"));
            classes.push(`weight_${weight}`);
            classes.push(style);

            const className = classes.map((c) => styles[c]).join(" ");
            return (
              <p key={i} className={className}>
                {name} {weight} {style} {star}
              </p>
            );
          })}
        </div>

        <p className={styles.info}>
          For convenience, I’ve added all these fonts to the project. Currently
          the design system only requires those that are starred.
        </p>
      </div>

      <div className={styles.section}>
        <h2 id="spacing">Spacing</h2>

        <div className={styles.spacings}>
          {[
            "Ant",
            "Bee",
            "Chaffinch",
            "Dog",
            "Eagle",
            "Flamingo",
            "Grizzly",
            "Hippo",
            "Iguanadon",
          ].map((name, i) => (
            <p key={i} className={styles[name.toLowerCase()]}>
              {name}
            </p>
          ))}
        </div>

        <p className={styles.info}>
          These spacings can be imported into scss files and applied to any css
          property, e.g. @include flamingo(margin);
        </p>
      </div>

      <div className={styles.section}>
        <h2 id="buttons">Buttons</h2>

        <div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Button</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Rectangle Primary</th>
                <td>
                  <Button.RectanglePrimary text="Go!" />
                </td>
              </tr>
              <tr>
                <th>Rectangle Primary (disabled)</th>
                <td>
                  <Button.RectanglePrimary text="Go!" enabled={false} />
                </td>
              </tr>
              <tr>
                <th>Rectangle Primary (outline)</th>
                <td>
                  <Button.RectanglePrimary text="Go!" outline={true} />
                </td>
              </tr>
              <tr>
                <th>Rectangle Secondary</th>
                <td>
                  <Button.RectangleSecondary text="Customise avatar" />
                </td>
              </tr>
              <tr>
                <th>Rectangle Secondary (disabled)</th>
                <td>
                  <Button.RectangleSecondary
                    text="Customise avatar"
                    enabled={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Rectangle Secondary (outline)</th>
                <td>
                  <Button.RectangleSecondary
                    text="Customise avatar"
                    outline={true}
                  />
                </td>
              </tr>
              <tr>
                <th>Rectangle Tertiary - without icon</th>
                <td>
                  <Button.RectangleTertiary text="Next" />
                </td>
              </tr>
              <tr>
                <th>Rectangle Tertiary</th>
                <td>
                  <Button.RectangleTertiary text="Next" icon={<Next />} />
                </td>
              </tr>
              <tr>
                <th>Rectangle Tertiary (disabled)</th>
                <td>
                  <Button.RectangleTertiary
                    text="Next"
                    icon={<Next />}
                    enabled={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Rectangle Tertiary (outline)</th>
                <td>
                  <Button.RectangleTertiary text="Sign up" outline={true} />
                </td>
              </tr>
              <tr>
                <th>Control Primary</th>
                <td>
                  <Button.ControlPrimary text="Run" icon={<Run />} />
                </td>
              </tr>
              <tr>
                <th>Control Primary (disabled)</th>
                <td>
                  <Button.ControlPrimary
                    text="Run"
                    icon={<Run />}
                    enabled={false}
                  />
                </td>
              </tr>
              <tr>
                <th>Control Secondary</th>
                <td>
                  <Button.ControlSecondary text="Stop" icon={<Stop />} />
                </td>
              </tr>
              <tr>
                <th>Control Secondary (disabled)</th>
                <td>
                  <Button.ControlSecondary
                    text="Run"
                    icon={<Run />}
                    enabled={false}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.section}>
        <h2 id="other-elements">Other elements</h2>

        <div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Element</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Standard link</th>
                <td>
                  <Link href="#foo">A standard link</Link>
                </td>
              </tr>
              <tr>
                <th>Link with no href</th>
                <td>
                  <Link>A link with no href</Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={styles.section}>
        <h2>Logo</h2>

        <div className={styles.logo}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>White Version</th>
                <th>Black Version</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.logo_white}>
                  <CodeClubWorldLogo white />
                </td>
                <td>
                  <CodeClubWorldLogo />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.card_section_wrapper}>
        <h2 className={styles.card_section_title}>Card</h2>
        <div className={styles.card_section_item}>
          <h4>Desktop - standard</h4>
          <CardGrid>
            <Card
              title="T-shirt 1"
              subtitle="by Stacey1"
              imageSrc={Art}
              href="#foo"
            />
          </CardGrid>
          <h4>Desktop - with sub-menu</h4>
          <CardGrid>
            <Card
              title="T-shirt 1"
              subtitle="Last modified 1 day ago"
              imageSrc={Art}
              href="#foo"
            >
              <EllipsisMenu
                open={openEllipsisMenu ? true : false}
                handleClick={() => handleEllipsisMenuClick()}
                handleClickOutside={() => setOpenEllipsisMenu(null)}
                items={[
                  <Link
                    key={1}
                    className={styles.menu_link}
                    onClick={() => function () {}}
                  >
                    Delete
                  </Link>,
                ]}
                styles={{}}
              ></EllipsisMenu>
            </Card>
          </CardGrid>
        </div>
      </div>
    </div>
  );
};

export default DesignSystemPage;
