import BlocklyHelpers from "blockly_helpers";
import Interpreter from "interpreter";

const executeCustomiseWorkspace = (workspace) => {
  if (!workspace || !workspace.xml) return;

  let mapColour1;
  let mapColour2;
  let boomboxSong;

  const blocksChannel = { generateCode: () => code };
  const visualisationChannel = {
    setMapColours: (colour1, colour2) => {
      (mapColour1 = colour1), (mapColour2 = colour2);
    },
    customiseBoomboxSong: (boombox_song) => {
      boomboxSong = boombox_song;
    },
  };

  const [, code] = BlocklyHelpers.generateCode(workspace.xml);
  const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
  interpreter.run();

  return {
    mapColour1,
    mapColour2,
    boomboxSong,
  };
};

export default executeCustomiseWorkspace;
