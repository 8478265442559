import styles from "./styles.module.scss";
import Navigation from "navigation";
import FeedbackBar from "feedback_bar";

const Layout = ({
  children,
  hideNavigationLinks = false,
  invertBackgroundColours = false,
  savedState = null,
  hideFeedbackBar = false,
}) => {
  useVhFix();

  const navClasses = hideNavigationLinks
    ? styles.navigation_hidden
    : styles.navigation;

  const contentClasses = classNames(styles.content, {
    [styles.content_inverted]: invertBackgroundColours,
  });

  return (
    <div className={styles.layout}>
      {!hideFeedbackBar && <FeedbackBar />}

      <nav className={navClasses}>
        <Navigation
          hideNavigationLinks={hideNavigationLinks}
          savedState={savedState}
        />
      </nav>

      <div className={contentClasses}>
        <div className={styles.inner}>{children}</div>
      </div>
    </div>
  );
};

Layout.resetInnerScrollPosition = () => {
  document.getElementsByClassName(styles.inner)[0].scrollTop = 0;
};

Layout.propTypes = {
  hideNavigationLinks: PropTypes.bool,
  hideFeedbackBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  invertBackgroundColours: PropTypes.bool,
  savedState: PropTypes.string,
};

export default Layout;
