import MyDataContext from "my_data_context";
import Workspace from "workspace";
import Heading from "heading";
import Button from "button";
import styles from "activity/styles.module.scss";
import FullscreenableRunner from "activity/components/fullscreenable_runner";
import AutoSaveEvent from "auto_save_event";
import SavedStateEvent from "saved_state_indicator/saved_state_event";
import SavedStateIndicator from "saved_state_indicator";
import HintBar from "../hint_bar";
import { useEffect } from "react";

const LaunchActivity = ({
  title,
  activityKey,
  visualisationChannel,
  nextEnabled,
  onNext,
  children,
  blocksChannel,
  defaultXml,
  maxBlocksPerSecond,
  runOnPageLoad,
  autoSaveRequested = false,
  onAutoSaveEvent = () => {},
  onSavedStateEvent = () => {},
  savedState,
}) => {
  const history = useHistory();
  const routes = useRoutes();
  const { workspaces } = useContext(MyDataContext);
  const [workspace, setWorkspace] = useState(workspaces[0]);

  const saveWorkspace = () => {
    const xml = blocksChannel.generateWorkspaceXml();
    const seed = blocksChannel.seed;
    onSavedStateEvent(SavedStateEvent.SAVING_STARTED);
    if (workspace.is_new_record) {
      SavedStateEvent.wrap(onSavedStateEvent, Workspace.create, [
        activityKey,
        { xml, seed },
      ]).then(handleWorkspaceSaved);
    } else {
      SavedStateEvent.wrap(onSavedStateEvent, Workspace.update, [
        workspace,
        activityKey,
        { xml, seed },
      ]).then(handleWorkspaceSaved);
    }
  };

  blocksChannel.initialXml = workspace.xml || defaultXml;
  blocksChannel.seed = workspace.seed || Math.random().toString();

  useEffect(() => {
    if (!autoSaveRequested) return;
    onAutoSaveEvent(AutoSaveEvent.PROCESSED);

    setWorkspace((w) => {
      if (!w.is_new_record && w.belongs_to_me) {
        const xml = blocksChannel.generateWorkspaceXml();
        const seed = blocksChannel.seed;
        SavedStateEvent.wrap(onSavedStateEvent, Workspace.update, [
          w,
          activityKey,
          { xml, seed },
        ]);
      }
      return w;
    });
  }, [
    autoSaveRequested,
    onAutoSaveEvent,
    onSavedStateEvent,
    activityKey,
    blocksChannel,
  ]);

  const handleWorkspaceSaved = async (savedWorkspace) => {
    if (workspace.is_new_record) {
      const workspacePath = routes.launchPath();
      history.replace(workspacePath);
    }

    setWorkspace(savedWorkspace);
    onSavedStateEvent(SavedStateEvent.SAVING_FINISHED);
  };

  const backPath = routes.rootPath();

  const hints = [
    "Click or tap the paintbrush icon to open the 'Looks' menu",
    "Drag blocks to connect them to the 'when ▶ clicked' block",
    "Use the dropdowns to customise your avatar",
    "Click '▶ Run' to see the changes",
    "Click 'Finish' when you are done!",
  ];

  return (
    <>
      <Heading
        title={<h1>{title}</h1>}
        backPath={backPath}
        className={classNames(styles.heading, styles.launch)}
      >
        <div
          className={classNames(
            styles.mobile_menu_button,
            styles.saved_state_indicator
          )}
        >
          <SavedStateIndicator state={savedState} iconOnly={true} />
        </div>
      </Heading>

      <HintBar hints={hints} eventCategory="Launch Page" />
      <div className={classNames(styles.activity, styles.launch)}>
        <div className={styles.blockly_container}>{children}</div>
        <FullscreenableRunner
          beforeRun={saveWorkspace}
          runOnPageLoad={runOnPageLoad}
          activityKey={activityKey}
          visualisationChannel={visualisationChannel}
          blocksChannel={blocksChannel}
          maxBlocksPerSecond={maxBlocksPerSecond}
        ></FullscreenableRunner>
      </div>
      <div className={styles.bottom_bar}>
        <div className={styles.bottom_bar_pad}>
          <Button.RectanglePrimary
            text="Finish"
            onClick={onNext}
            enabled={nextEnabled}
            className={styles.next_button}
          />
        </div>
      </div>
    </>
  );
};

LaunchActivity.propTypes = {
  title: PropTypes.string.isRequired,
  activityKey: PropTypes.string.isRequired,
  visualisationChannel: PropTypes.object.isRequired,
  nextEnabled: PropTypes.bool,
  onNext: PropTypes.func,
  blocksChannel: PropTypes.object.isRequired,
  defaultXml: PropTypes.string,
  maxBlocksPerSecond: PropTypes.number,
  runOnPageLoad: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  ready: PropTypes.bool,
  autoSaveRequested: PropTypes.bool,
  onAutoSaveEvent: PropTypes.func,
  onSavedStateEvent: PropTypes.func,
  savedState: PropTypes.string,
};

export default LaunchActivity;
