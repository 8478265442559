import Runner from "runner";
import Button from "button";
import BlocklyHelpers from "blockly_helpers";
import ExclamationIcon from "../../../assets/exclamation_icon.svg";
import styles from "./styles.module.scss";
import Avatar from "visualisations/avatar";
import Interpreter from "interpreter";

const SharingNaming = ({
  activityFlow,
  name: existingName,
  onBack,
  onSubmit,
  activityKey,
  workspace,
}) => {
  const [name, setName] = useState(existingName);
  const [validationError, setValidationError] = useState(null);
  const inputClassName = validationError
    ? styles.sharing_naming__invalid
    : null;

  const handleOnBack = () => {
    onBack(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.replace(/ /g, "").length == 0) {
      setValidationError("Please enter a project name");
    } else if (name.length > 20) {
      setValidationError(
        "That name's a bit too long for a gallery submission (more than 20 characters)."
      );
    } else {
      setValidationError(null);
      onSubmit(name);
    }
  };

  const handleChange = (e) => {
    const nameValue = e.target.value;
    setName(nameValue);
    if (nameValue.replace(/ /g, "").length > 0 && nameValue.length < 20) {
      setValidationError(null);
    }
  };

  const FALLBACK_CODE = "";
  const visualisationChannel = useMemo(() => {
    return { onReady: () => {}, onStyled: () => {} };
  }, []);

  const blocksChannel = useMemo(() => {
    const [deprecated, code] = BlocklyHelpers.generateCode(workspace.xml);
    return {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
  }, [workspace.xml]);

  useEffect(() => {
    if (activityKey !== "avatar") return;
    const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
    interpreter.run();
  }, [activityKey, blocksChannel, visualisationChannel]);

  return (
    <div className={classNames(styles.sharing_wrapper, styles.sharing_naming)}>
      <div className={styles.sharing_naming__left}>
        <h2 className={styles.heading}>
          {activityFlow?.name
            ? `Name your ${activityFlow.name} project`
            : "Name your project"}
        </h2>
        <p className={styles.paragraph}>
          Your project name helps other people know what your project is about.
          Other people will be able to try your project, remix it and see your
          nickname.
        </p>
        <form
          className={styles.sharing_naming__form}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <label htmlFor="name">Project title</label>
          <input
            value={name}
            className={inputClassName}
            type="text"
            name="name"
            maxLength="20"
            aria-label="Project title"
            onChange={(e) => handleChange(e)}
          />
          {validationError && (
            <div className={styles.validation_error}>
              <ExclamationIcon />
              {validationError}
            </div>
          )}
          <p className={styles.paragraph}>
            Our team will check your project before it gets shared to the
            community gallery, so:
          </p>
          <ul className={styles.sharing_naming__checklist}>
            <li>
              <span className={styles.green}>DO</span> use your project name to
              describe your project.
            </li>
            <li>
              <span className={styles.green}>DO</span> tell a trusted adult that
              you are sharing your project.
            </li>
            <li>
              <span className={styles.red}>DON&rsquo;T</span> create projects
              that contain offensive or inappropriate information or images.
            </li>
            <li>
              <span className={styles.red}>DON&rsquo;T</span> use personal
              information (such as a name, date of birth, or school) in your
              project name.
            </li>
          </ul>
          <div className={styles.sharing_naming_buttons}>
            <Button.RectanglePrimary
              outline={true}
              text={"Back"}
              type="button"
              onClick={handleOnBack}
            />
            <Button.RectanglePrimary text={"Next"} type="submit" />
          </div>
        </form>
      </div>
      <div className={styles.sharing_naming__right}>
        {activityKey == "avatar" ? (
          <Avatar
            channel={visualisationChannel}
            className={styles.sharing_naming__avatar}
          />
        ) : (
          <Runner
            beforeRun={() => {
              true;
            }}
            fullscreen={false}
            noFullscreen={true}
            onFullscreen={() => true}
            runOnPageLoad={true}
            activityKey={activityKey}
            visualisationChannel={visualisationChannel}
            blocksChannel={blocksChannel}
            iterateRun={false}
          />
        )}
      </div>
    </div>
  );
};

SharingNaming.propTypes = {
  activityFlow: PropTypes.object.isRequired,
  activityKey: PropTypes.string.isRequired,
  name: PropTypes.string,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }).isRequired,
};

export default SharingNaming;
