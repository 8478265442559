import Api from "api";

const IssuedBadge = {};

IssuedBadge.create = (badge_template) => {
  return new Promise((resolve, reject) => {
    Api.post(`/issued_badge`, { badge_template_id: badge_template.id })
      .then(() => resolve(true))
      .catch((error) => {
        const status = error.response && error.response.status;
        if (status === 400) {
          resolve(false);
        } else {
          reject(error);
        }
      });
  });
};

export default IssuedBadge;
