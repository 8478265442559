import { useLocation } from "react-router-dom";

const useRememberMapPosition = () => {
  const loc = useLocation();
  useEffect(() => {
    localStorage.setItem("map_position", loc.pathname);
    localStorage.setItem("map_position_hash", loc.hash);
  }, [loc]);
};

const rememberedMapPosition = () => {
  return [
    localStorage.getItem("map_position"),
    localStorage.getItem("map_position_hash") || "",
  ];
};

export { rememberedMapPosition, useRememberMapPosition };
