import Blockly from "blockly";
import helpers from "blocks/helpers";

const options = [
  ["triangle", "triangle"],
  ["square", "square"],
  ["pentagon", "pentagon"],
  ["hexagon", "hexagon"],
  ["star", "star"],
];

Blockly.Blocks.DrawShapeOfSize = {
  message0: "draw %1 of size %2",
  args0: [
    {
      type: "field_dropdown",
      name: "shape",
      options,
    },
    {
      type: "input_value",
      name: "size",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.DrawShapeOfSize.init = function () {
  this.jsonInit(Blockly.Blocks.DrawShapeOfSize);
};

Blockly.JavaScript.DrawShapeOfSize = function (block) {
  const shape = block.getFieldValue("shape");
  const size = helpers.codeForArg("size", block);
  return `visualisation.drawShapeOfSize("${shape}", ${size});`;
};
