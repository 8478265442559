import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.ChangeColourBy = {
  message0: "change colour by %1",
  args0: [
    {
      type: "input_value",
      name: "percent",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.ChangeColourBy.init = function () {
  this.jsonInit(Blockly.Blocks.ChangeColourBy);
};

Blockly.JavaScript.ChangeColourBy = function (block) {
  const percent = helpers.codeForArg("percent", block);

  if (typeof percent === "undefined") {
    return "";
  } else {
    return `visualisation.changeColourBy(${percent});`;
  }
};
