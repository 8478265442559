import styles from "./styles.module.scss";

const AwardNotification = ({ badge, onClose }) => {
  return (
    <CloseAfter delay={7000} onClose={onClose}>
      <div className={classNames(styles.toast, styles.show)}>
        <div className={styles.icon}>
          <img src={badge.image} alt={""} />
        </div>
        <div className={styles.desc}>
          You earned the <br /> {badge.title} badge!
        </div>
      </div>
    </CloseAfter>
  );
};

const CloseAfter = ({ delay, children, onClose }) => {
  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setVisible(false);
      onClose();
    }, delay);
    return function () {
      clearTimeout(timeoutRef.current);
    };
  }, [delay, onClose]);

  return visible ? <div>{children}</div> : <div />;
};

CloseAfter.propTypes = {
  delay: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func.isRequired,
};

AwardNotification.propTypes = {
  badge: PropTypes.shape({ title: PropTypes.string, image: PropTypes.string })
    .isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AwardNotification;
