import MusicImage from "assets/music_image.svg";
import AudioEngine from "./audio_engine";
import { AudioContext } from "standardized-audio-context";
import wireUpMusicApi from "wire_up_music_api";
import styles from "./styles.module.scss";

const Music = ({ channel, initialWait = 0.2, onFinished }) => {
  const [animationData, setAnimationData] = useState(new Uint8Array());
  const [playback, setPlayback] = useState({});
  const [audioEngine, setAudioEngine] = useState();

  wireUpMusicApi({
    channel,
    playback,
    setPlayback,
    initialWait,
    audioEngine,
    onFinished,
  });

  useEffect(() => {
    const context = new AudioContext();
    setAudioEngine(new AudioEngine(context));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (audioEngine) {
      audioEngine.init().then(channel.onReady);
    }
  }, [audioEngine]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const animationTimer = setInterval(() => {
      if (!audioEngine) return;

      setAnimationData(audioEngine.getAnimationData());
    }, 100);

    return () => {
      clearInterval(animationTimer);
    };
  }, [audioEngine]);

  useEffect(() => {
    if (audioEngine && audioEngine.finished()) {
      channel.onReady();
    }
  }, [playback]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.visualisation}>
      <MusicImage width="100%" height="100%" animationData={animationData} />
    </div>
  );
};

Music.propTypes = {
  channel: PropTypes.object,
  initialWait: PropTypes.number,
  onFinished: PropTypes.func,
};

export default Music;
