import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const IconButton = ({
  testId,
  className,
  contentClassName = "",
  enabled = true,
  active,
  icon,
  onClick = () => {},
  href,
  solid = false,
  "aria-label": ariaLabel = "",
}) => {
  const classes = classNames(styles.icon_button, className, {
    [styles.solid]: solid,
    [styles.transparent]: !solid,
    [styles.active]: active,
    [styles.disabled]: !enabled,
  });

  const contentClasses = classNames(contentClassName, {
    [styles.icon_content]: !contentClassName,
  });

  const content = <div className={contentClasses}>{icon}</div>;

  if (href) {
    return (
      <a
        data-testid={`${testId}-link`}
        className={classes}
        aria-label={ariaLabel}
        href={href}
        onClick={onClick}
        onKeyPress={onClick}
      >
        {content}
      </a>
    );
  } else {
    return (
      <button
        data-testid={testId}
        className={classes}
        aria-label={ariaLabel}
        onClick={onClick}
        onKeyPress={onClick}
        disabled={!enabled}
      >
        {content}
      </button>
    );
  }
};

IconButton.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.element,
  href: PropTypes.string,
  onClick: PropTypes.func,
  solid: PropTypes.bool,
  testId: PropTypes.string,
  textAlways: PropTypes.bool,
  "aria-label": PropTypes.string,
};

export default IconButton;
