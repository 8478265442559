const Heart = ({ key, x, y, size, colour }) => {
  const height = 22;
  const width = 24;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2515 3.05542C22.2463 0.992619 19.3509 -0.695129 15.9829 0.287516C14.3735 0.752388 12.9694 1.74932 11.9998 3.11543C11.0303 1.74932 9.62614 0.752388 8.01675 0.287516C4.64125 -0.680127 1.75333 0.992619 0.748179 3.05542C-0.662028 5.94335 -0.0769419 9.19133 2.48844 12.7093C4.49873 15.4622 7.37165 18.2527 11.5423 21.4931C11.6741 21.5959 11.8364 21.6518 12.0036 21.6518C12.1707 21.6518 12.3331 21.5959 12.4649 21.4931C16.628 18.2602 19.5084 15.4923 21.5187 12.7093C24.0766 9.19133 24.6617 5.94335 23.2515 3.05542Z"
        fill={colour}
      />
    </svg>
  );
};

Heart.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Heart;
