const MusicImage = ({ animationData, ...rest }) => {
  const numberOfBars = animationData.length;
  const barMaxHeight = 200;
  const barMinHeight = 10;
  const svgWidth = 480;
  const svgHeight = 2 * (barMaxHeight + barMinHeight + 30);
  const barWidth = (svgWidth / numberOfBars / 2) * 0.6;
  const barMargin = (svgWidth / numberOfBars / 2) * 0.4;

  const bars = Array.from(animationData).map((i, idx) => {
    const barX = (barMargin + barWidth) * idx;
    const barHeight = (i / 255.0) * barMaxHeight + barMinHeight;
    return (
      <g key={idx}>
        <rect
          x={barX}
          y={-barHeight}
          width={barWidth / 2.0}
          height={barHeight}
          fill="#FFC700"
        />
        <rect
          x={-barX}
          y={-barHeight}
          width={barWidth / 2.0}
          height={barHeight}
          fill="#FFC700"
        />
        <rect
          x={barX}
          y="0"
          width={barWidth / 2.0}
          height={barHeight}
          fill="white"
        />
        <rect
          x={-barX}
          y="0"
          width={barWidth / 2.0}
          height={barHeight}
          fill="white"
        />
      </g>
    );
  });

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={`${-(svgWidth / 2)} ${-(
        svgHeight / 2
      )} ${svgWidth} ${svgHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x={-svgWidth / 2}
        y={-svgHeight / 2}
        width={svgWidth}
        height={svgHeight}
        rx="8.5"
        fill="#0B6FFB"
        key="background"
      />

      {bars}
    </svg>
  );
};

MusicImage.propTypes = {
  animationData: PropTypes.instanceOf(Uint8Array),
};
export default MusicImage;
