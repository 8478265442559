import BadgeState from "../components/project_badge_for_checklist/badge_state";
import IssuedBadge from "issued_badge";
import Analytics from "analytics";
import useBadge from "use_badge";

const useBadgeState = (badgeKey, pathwayKey) => {
  const { badgeTemplate, issued } = useBadge(badgeKey);
  const [badgeState, setBadgeState] = useState(BadgeState.initialState(issued));

  useEffect(() => {
    const issueBadge = async (badgeTemplate) => {
      const created = await IssuedBadge.create(badgeTemplate);

      if (created) {
        setBadgeState(BadgeState.AWARD_COMPLETED);

        Analytics.badgeIssued(badgeTemplate, pathwayKey);
      } else {
        setBadgeState(BadgeState.AWARDED);
      }
    };

    switch (badgeState) {
      case BadgeState.AWARD_REQUESTED:
        issueBadge(badgeTemplate);
        break;
      case BadgeState.AWARD_COMPLETED:
        setBadgeState(BadgeState.AWARDED);
        break;
    }
  }, [badgeState, badgeTemplate, pathwayKey]);

  return [badgeTemplate, badgeState, setBadgeState];
};

export default useBadgeState;
