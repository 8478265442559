import convert from "color-convert";

const Cursor = ({ x, y, heading, colour, canvasDimensions }) => {
  const transform = `translate(-50%,-50%) rotate(${heading}deg)`;
  const percentageX = (x / canvasDimensions.width) * 100 + 50;
  const percentageY = (y / canvasDimensions.height) * 100 + 50;
  const left = `${percentageX}%`;
  const top = `${percentageY}%`;

  const contrastingStroke = (hexColor) => {
    const [red, green, blue] = convert.hex.rgb(hexColor);
    const luma = red * 0.299 + green * 0.587 + blue * 0.114;
    return luma > 186 ? "black" : "white";
  };

  const strokeProps = { stroke: contrastingStroke(colour), strokeWidth: 2 };

  return (
    <svg
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "10%",
        minWidth: "20px",
        maxWidth: "35px",
        position: "absolute",
        left,
        top,
        transform,
      }}
    >
      <path
        d="M 11,0 22,18 11,11.9286 M 11,0 0,18 11,11.9286"
        fill={colour}
        {...strokeProps}
      />
    </svg>
  );
};

Cursor.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  heading: PropTypes.number,
  colour: PropTypes.string,
  canvasDimensions: PropTypes.object,
};

export default Cursor;
