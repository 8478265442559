import ConfirmationPrompt from "confirmation_prompt";

const RemixingConfirmation = ({ onConfirm }) => {
  return (
    <ConfirmationPrompt
      question="Project saved as a remix"
      confirmText="Start remixing"
      onConfirm={onConfirm}
    />
  );
};

RemixingConfirmation.propTypes = {
  onConfirm: PropTypes.func,
};

export default RemixingConfirmation;
