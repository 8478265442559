import { rememberedMapPosition } from "map_position";
import { Redirect } from "react-router-dom";

const MapRedirector = () => {
  const routes = useRoutes();
  const [rememberedPath, rememberedHash] = rememberedMapPosition();
  if (rememberedPath) {
    return <Redirect to={rememberedPath + rememberedHash} />;
  } else {
    return <Redirect to={routes.mapPath()} />;
  }
};

MapRedirector.propTypes = {};

export default MapRedirector;
