import Draggable from "draggable";
import XMark from "assets/x_mark.svg";
import Button from "button";
import BadgeCTA from "./badge_cta";

import Project from "project";
import ActivityInfo from "activity_info";

import styles from "./styles.module.scss";
import { useEffect } from "react";

const MapNodeInfo = ({ node, content, channel = {}, onClose = () => {} }) => {
  const routes = useRoutes();
  const innerRef = useRef();
  const closeButtonRef = useRef();

  const nodeKey = MapNodeInfo.keyOf(node);
  const [badgeSlug, setBadgeSlug] = useState(
    ActivityInfo.badgeKeyFor(nodeKey) || Project.badgeKeyFor(nodeKey)
  );

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [node]);

  useEffect(() => {
    setBadgeSlug(
      ActivityInfo.badgeKeyFor(nodeKey) || Project.badgeKeyFor(nodeKey)
    );
  }, [content, channel, nodeKey]);

  channel.getElement = () => {
    return innerRef.current;
  };

  channel.isVisible = content.visible;

  if (!content.visible) {
    return null;
  }

  const left = node.left + node.width;
  const top = node.top;

  return (
    <Draggable.Relative className={styles.map_node_info}>
      <div
        ref={innerRef}
        className={styles.inner}
        style={{ left, top }}
        id="node-info"
      >
        <div className={styles.top_content}>
          <button
            className={styles.close}
            onClick={onClose}
            tabIndex={0}
            ref={closeButtonRef}
          >
            <XMark size={14} />
          </button>
          <h3 className={styles.title}>{content.title}</h3>
          {content.language && (
            <span className={styles.rounded_tag}>{content.language}</span>
          )}
        </div>

        <div className={styles.bottom_content}>
          <p className={styles.description}>{content.description}</p>
          <BadgeCTA badgeSlug={badgeSlug} />
          {content.callToAction && (
            <div className={styles.call_to_action}>
              <Button.RectanglePrimary
                href={content.nodePath(routes)}
                text={content.callToAction}
              />
            </div>
          )}
        </div>
      </div>
    </Draggable.Relative>
  );
};

MapNodeInfo.getElement = (key) => {
  return document.getElementById(`node-${key}`);
};

MapNodeInfo.keyOf = (node) => {
  return node.element.id.split("node-")[1];
};

MapNodeInfo.fixedToBottom = () => {
  return window.innerWidth <= 500; // Matches the CSS media query.
};

MapNodeInfo.propTypes = {
  node: PropTypes.object.isRequired,
  content: PropTypes.shape({
    visible: PropTypes.bool,
    title: PropTypes.string,
    language: PropTypes.string,
    description: PropTypes.string,
    callToAction: PropTypes.string,
    nodePath: PropTypes.func,
  }),
  channel: PropTypes.object,
  onClose: PropTypes.func,
};

export default MapNodeInfo;
