import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.CustomiseMapColour = {
  message0: "set map colours to %1 and %2",
  args0: [
    {
      type: "input_value",
      name: "colour1",
      check: "Colour",
    },
    {
      type: "input_value",
      name: "colour2",
      check: "Colour",
    },
  ],
  inputsInline: true,
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.CustomiseMapColour.init = function () {
  this.jsonInit(Blockly.Blocks.CustomiseMapColour);
};

Blockly.JavaScript.CustomiseMapColour = (block) => {
  const colour1 = helpers.codeForArg("colour1", block);
  const colour2 = helpers.codeForArg("colour2", block);

  if (typeof colour1 === "undefined") {
    return "";
  } else {
    return `visualisation.setMapColours("${colour1}", "${colour2}");`;
  }
};
