const Grow = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 1.5C24 1.10218 23.842 0.720645 23.5607 0.43934C23.2794 0.158036 22.8978 0 22.5 0H15C14.6022 0 14.2206 0.158036 13.9393 0.43934C13.658 0.720645 13.5 1.10218 13.5 1.5C13.5 1.89782 13.658 2.27936 13.9393 2.56066C14.2206 2.84196 14.6022 3 15 3H18.855L13.935 7.935C13.7944 8.07444 13.6828 8.24035 13.6067 8.42313C13.5305 8.60592 13.4913 8.80198 13.4913 9C13.4913 9.19802 13.5305 9.39408 13.6067 9.57687C13.6828 9.75965 13.7944 9.92556 13.935 10.065C14.0744 10.2056 14.2403 10.3172 14.4231 10.3933C14.6059 10.4695 14.802 10.5087 15 10.5087C15.198 10.5087 15.3941 10.4695 15.5769 10.3933C15.7597 10.3172 15.9256 10.2056 16.065 10.065L21 5.13V9C21 9.39782 21.158 9.77936 21.4393 10.0607C21.7206 10.342 22.1022 10.5 22.5 10.5C22.8978 10.5 23.2794 10.342 23.5607 10.0607C23.842 9.77936 24 9.39782 24 9V1.5ZM10.065 13.935C9.92556 13.7944 9.75965 13.6828 9.57687 13.6067C9.39408 13.5305 9.19802 13.4913 9 13.4913C8.80198 13.4913 8.60592 13.5305 8.42313 13.6067C8.24035 13.6828 8.07444 13.7944 7.935 13.935L3 18.855V15C3 14.6022 2.84196 14.2206 2.56066 13.9393C2.27936 13.658 1.89782 13.5 1.5 13.5C1.10218 13.5 0.720645 13.658 0.43934 13.9393C0.158036 14.2206 0 14.6022 0 15V22.5C0 22.8978 0.158036 23.2794 0.43934 23.5607C0.720645 23.842 1.10218 24 1.5 24H9C9.39782 24 9.77936 23.842 10.0607 23.5607C10.342 23.2794 10.5 22.8978 10.5 22.5C10.5 22.1022 10.342 21.7206 10.0607 21.4393C9.77936 21.158 9.39782 21 9 21H5.13L10.065 16.065C10.2056 15.9256 10.3172 15.7597 10.3933 15.5769C10.4695 15.3941 10.5087 15.198 10.5087 15C10.5087 14.802 10.4695 14.6059 10.3933 14.4231C10.3172 14.2403 10.2056 14.0744 10.065 13.935Z"
      fill="black"
    />
  </svg>
);

export default Grow;
