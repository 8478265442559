import styles from "./styles.module.scss";
import RaspberryPiLogo from "assets/raspberry_pi_logo.svg";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_content}>
        <nav>
          <ul>
            <li>
              <a href="https://www.raspberrypi.org/u13-community-guidelines/">
                Community guidelines
              </a>
            </li>
            <li>
              <a href="https://www.raspberrypi.org/privacy/child-friendly/">
                Child friendly privacy policy
              </a>
            </li>
            <li>
              <a href="https://www.raspberrypi.org/cookies/">Cookie policy</a>
            </li>
          </ul>
        </nav>
        <nav>
          <ul>
            <li>
              <a href="https://www.raspberrypi.org/">Raspberry Pi Foundation</a>
            </li>
            <li>
              <a href="https://codeclub.org/en/">Code Club</a>
            </li>
          </ul>
        </nav>
        <div className={styles.logo_container}>
          <RaspberryPiLogo />
          <p>Code Club World is part of the Raspberry Pi Foundation. </p>
          <p>
            <a href="https://www.raspberrypi.org/about/">Find out more</a>
          </p>
        </div>
        <div className={styles.copyright_container}>
          <p>
            &#169; {new Date().getFullYear()} Raspberry Pi Foundation UK
            Registered Charity 1129409
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
