import Layout from "layout";
import Activity from "activity";
import Blocks from "blocks";
import Block from "blocks/block";
import Category from "blocks/category";
import Colours from "blocks/colours";
import SEO from "seo";
import defaultXml from "./default_xml";
import useAutoSave from "use_auto_save";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";

const CustomisePage = () => {
  const blocksChannel = useMemo(() => ({}), []);
  const customiseChannel = {};

  const [autoSaveRequested, handleAutoSaveEvent] = useAutoSave();
  const [handleSavedStateEvent, savedState] = useSavedState();

  const routes = useRoutes();

  return (
    <>
      <SEO
        title="Customise your world - Code Club World"
        description="Use blocks to change what happens when you do things"
      />
      <Layout savedState={savedState}>
        <Activity
          title="Customise"
          activityKey="customise"
          visualisationChannel={customiseChannel}
          blocksChannel={blocksChannel}
          defaultXml={defaultXml}
          maxBlocksPerSecond={20}
          autoSaveRequested={autoSaveRequested()}
          onAutoSaveEvent={handleAutoSaveEvent}
          onSavedStateEvent={handleSavedStateEvent}
          savedState={savedState}
          runOnPageLoad={true}
          backPath={routes.mapPath()}
          showNavMenu={false}
        >
          <Blocks channel={blocksChannel} onAutoSaveEvent={handleAutoSaveEvent}>
            <Category
              name="Events"
              colour={Colours.yellow}
              css-icon="blocklyTreeIcon iconEvents"
            >
              <Block type="MapOpenedRun" />
              <Block type="BoomboxClicked" />
            </Category>
            <Category
              name="Looks"
              colour={Colours.purple}
              css-icon="blocklyTreeIcon iconLooks"
            >
              <Block
                type="CustomiseMapColour"
                defaults={["#2253E7", "#0B6FFB"]}
              />
            </Category>
          </Blocks>
        </Activity>
      </Layout>
    </>
  );
};

export default CustomisePage;
