import IslandSmallLabel from "./island_small_label.svg";

const MoreIsland = ({ x, y, styles, scale = 1 }) => (
  <svg
    x={x}
    y={y}
    width={scale * 170}
    height={scale * 148}
    viewBox="0 0 170 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.8411"
      y="-3.8411"
      width="161.326"
      height="84.5042"
      rx="19.2055"
      transform="matrix(-1 0 0 1 169.36 63.2246)"
      stroke="#0273F5"
      strokeWidth="7.6822"
    />
    <rect
      x="-0.76822"
      y="0.76822"
      width="152.107"
      height="75.2855"
      rx="14.5962"
      transform="matrix(-1 0 0 1 160.141 62.9729)"
      fill="#8F613C"
      stroke="black"
      strokeWidth="1.53644"
    />
    <rect
      x="-0.76822"
      y="0.76822"
      width="152.107"
      height="75.2855"
      rx="14.5962"
      transform="matrix(-1 0 0 1 160.141 24.5619)"
      fill="#B377F5"
      stroke="black"
      strokeWidth="1.53644"
    />
    <path
      d="M146.315 138.988L147.083 100.577H145.547L146.315 138.988Z"
      fill="black"
    />
    <path
      d="M23.3831 138.988L24.1514 100.577H22.6149L23.3831 138.988Z"
      fill="black"
    />
    <path
      d="M72.5651 85.1158V84.7976L72.7901 84.5726L79.5685 77.7942L79.7935 77.5692H80.1117H89.6015H89.9197L90.1447 77.7942L96.9231 84.5726L97.1481 84.7976V85.1158V91.8942V92.6624H96.3799H73.3333H72.5651V91.8942V85.1158Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
      strokeLinecap="round"
    />
    <rect
      width="23.0466"
      height="6.64284"
      transform="matrix(-1 0 0 1 96.3799 85.2513)"
      fill="#986F4D"
    />
    <path
      d="M37.387 38.5228H60.4336V30.3345L52.1227 23.6103V15.4762H44.7548L37.387 22.2546V38.5228Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
      strokeLinecap="round"
    />
    <rect
      width="21.6545"
      height="7.15419"
      transform="matrix(-1 0 0 1 59.751 30.7106)"
      fill="#986F4D"
    />
    <path
      d="M31.431 37.7567V37.4385L31.656 37.2135L35.1251 33.7444L35.3501 33.5194H35.6683H40.525H40.8433L41.0683 33.7444L44.5374 37.2135L44.7624 37.4385V37.7567V41.2258V41.994H43.9941H32.1992H31.431V41.2258V37.7567Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
      strokeLinecap="round"
    />
    <rect
      width="11.7949"
      height="3.39971"
      transform="matrix(-1 0 0 1 43.9941 37.826)"
      fill="#986F4D"
    />
    <path
      d="M68.6791 91.3563V91.0381L68.9041 90.8131L70.9377 88.7795L71.1627 88.5545H71.4809H74.3278H74.646L74.871 88.7795L76.9045 90.8131L77.1295 91.0381V91.3563V93.3898V94.158H76.3613H69.4474H68.6791V93.3898V91.3563Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
      strokeLinecap="round"
    />
    <rect
      width="6.91398"
      height="1.99285"
      transform="matrix(-1 0 0 1 76.3613 91.397)"
      fill="#986F4D"
    />
    <path
      d="M150.111 40.1567V40.9249H149.343H120.919H120.15V40.1567V28.6334V28.3152L120.375 28.0902L129.369 19.0965V8.65967V8.34147L129.594 8.11646L135.74 1.9707L136.283 1.42749L136.826 1.9707L149.886 15.0304L150.111 15.2554V15.5736V40.1567Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
    />
    <path
      d="M140.62 47.4669V47.1487L140.845 46.9237L144.315 43.4546L144.54 43.2296H144.858H149.714H150.033L150.258 43.4546L153.727 46.9237L153.952 47.1487V47.4669V50.936V51.7042H153.184H141.389H140.62V50.936V47.4669Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
      strokeLinecap="round"
    />
    <rect
      width="11.7949"
      height="3.39971"
      transform="matrix(-1 0 0 1 153.184 47.5364)"
      fill="#986F4D"
    />
    <path
      d="M149.343 28.6334H120.919V40.1567H149.343V28.6334Z"
      fill="#986F4D"
    />
    <path
      d="M115.269 39.0165V38.6983L115.494 38.4732L118.963 35.0041L119.188 34.7791H119.506H124.363H124.681L124.906 35.0041L128.375 38.4732L128.6 38.6983V39.0165V42.4856V43.2538H127.832H116.037H115.269V42.4856V39.0165Z"
      fill="#CA9B71"
      stroke="black"
      strokeWidth="1.53644"
      strokeLinecap="round"
    />
    <rect
      width="11.7949"
      height="3.39971"
      transform="matrix(-1 0 0 1 127.832 39.0859)"
      fill="#986F4D"
    />

    <IslandSmallLabel titleText="More" x={48} y={33} styles={styles} />
  </svg>
);

MoreIsland.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
  scale: PropTypes.number,
};

export default MoreIsland;
