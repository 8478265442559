const beforeSend = (event, hint) => {
  const exception = hint.originalException;

  if (
    exception &&
    exception.isAxiosError &&
    exception.config &&
    exception.response
  ) {
    event.fingerprint = [
      "{{ default }}",
      String(exception.config.url),
      String(exception.response.status),
    ];
    event.extra = { "axios.response": JSON.stringify(exception.response) };
  }

  return event;
};

export default beforeSend;
