import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "button";
import Link from "link";
import styles from "./styles.module.scss";

const SharingPrompt = ({ url, dismissText, onDismiss }) => {
  const initialButtonText = "Copy link";
  const [buttonText, setButtonText] = useState(initialButtonText);
  const timer = useRef();

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  const handleCopy = () => {
    setButtonText("Copied!");
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setButtonText(initialButtonText);
    }, 1000);
  };

  return (
    <div className={styles.sharing_prompt}>
      <h2>Sharing</h2>

      <input name="url" type="text" readOnly value={url} />

      <CopyToClipboard text={url} onCopy={handleCopy}>
        <Button.RectanglePrimary text={buttonText} />
      </CopyToClipboard>

      <Link className={styles.dismiss} onClick={onDismiss}>
        {dismissText}
      </Link>
    </div>
  );
};

SharingPrompt.propTypes = {
  url: PropTypes.string,
  dismissText: PropTypes.string,
  onDismiss: PropTypes.func,
};

export default SharingPrompt;
