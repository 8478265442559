import Button from "button";
import ProjectBadge from "../project_badge";
import BadgeState from "./badge_state";
import useConfettiAnimation from "use_confetti_animation";
import CreateAccountForm from "../create_account_form";
import styles from "./styles.module.scss";

const ProjectBadgeForChecklist = ({
  badgeTemplate,
  badgeState,
  className,
  onGetBadge,
}) => {
  const ref = useRef();
  const startConfettiAnimation = useConfettiAnimation();

  useEffect(() => {
    if (badgeState === BadgeState.AWARD_COMPLETED) {
      startConfettiAnimation();
    }
  }, [badgeState, startConfettiAnimation]);

  useEffect(() => {
    if (badgeState === BadgeState.AWARDED && ref.current)
      ref.current.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      });
  }, [badgeState]);

  return (
    <div className={classNames(className, styles.container)} ref={ref}>
      <h3>You&apos;ve completed the project!</h3>
      {BadgeState.isAwarded(badgeState) ? (
        <>
          <ProjectBadge badgeTemplate={badgeTemplate} />
          <CreateAccountForm />
        </>
      ) : (
        <div className={styles.get_your_badge}>
          <Button.RectangleSecondary
            text="Get your badge"
            onClick={onGetBadge}
            disabled={badgeState === BadgeState.AWARD_REQUESTED}
          />
        </div>
      )}
    </div>
  );
};

ProjectBadgeForChecklist.propTypes = {
  className: PropTypes.string,
  onGetBadge: PropTypes.func.isRequired,
  badgeTemplate: PropTypes.object.isRequired,
  badgeState: PropTypes.string.isRequired,
};

export default ProjectBadgeForChecklist;
