export default {
  avatar: {
    pageTitle: "My avatars",
    newButtonText: "New avatar",
    cardTitlePrefix: "Avatar",
    cardImageSrc: "./activities/avatar.svg",
  },
  art: {
    pageTitle: "My T-shirts",
    newButtonText: "New T-shirt",
    cardTitlePrefix: "T-shirt",
    cardImageSrc: "./activities/art.svg",
  },
  music: {
    pageTitle: "My music",
    newButtonText: "New tune",
    cardTitlePrefix: "Tune",
    cardImageSrc: "./activities/music.svg",
  },
  dance: {
    pageTitle: "My dances",
    newButtonText: "New dance",
    cardTitlePrefix: "Dance",
    cardImageSrc: "./activities/dance.svg",
  },
};
