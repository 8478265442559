import AppContext from "app_context";
import MyDataContext from "my_data_context";

const useBadge = (badgeSlug) => {
  const { badge_templates: badgeTemplates = [] } = useContext(AppContext);
  const { issued_badges: issuedBadges = [] } = useContext(MyDataContext);
  const badgeTemplate = badgeTemplates.find(({ slug }) => slug === badgeSlug);
  let issued = null;
  if (badgeTemplate) {
    issued = issuedBadges.find(
      ({ badge_template }) => badgeTemplate.id === badge_template.id
    );
  }
  return { badgeTemplate, issued };
};

export default useBadge;
