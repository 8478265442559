import palette from "root/js_palette";

const MapIcon = () => {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill={palette.primary.black}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 4.78013V24.106C0 24.7377 0.558106 25.1696 1.07129 24.9353L7.10938 21.4286V0L0.982422 3.12221C0.69247 3.25475 0.443911 3.48353 0.268804 3.77904C0.0936956 4.07455 6.94437e-05 4.42324 0 4.78013ZM10.0781 21.4286L18.0469 25V3.57143L10.0781 0V21.4286ZM27.0537 0.0647321L21.0156 3.57143V25L27.1426 21.8778C27.4326 21.7453 27.6812 21.5166 27.8563 21.221C28.0314 20.9255 28.125 20.5768 28.125 20.2199V0.893973C28.125 0.262277 27.5669 -0.169643 27.0537 0.0647321Z" />
    </svg>
  );
};

MapIcon.propTypes = {};

export default MapIcon;
