import Blockly from "blockly";
import songs from "visualisations/dance/songs";

const options = Object.entries(songs).map(([key, song]) => [song.name, key]);

Blockly.Blocks.StartMusic = {
  message0: "start music %1",
  args0: [
    {
      type: "field_dropdown",
      name: "song",
      options,
    },
  ],
  previousStatement: "DanceSetup",
  nextStatement: "Statement",
};

Blockly.Blocks.StartMusic.init = function () {
  this.jsonInit(Blockly.Blocks.StartMusic);
};

Blockly.JavaScript.StartMusic = function (block) {
  const song = block.getFieldValue("song");
  return `visualisation.startMusic("${song}");`;
};
