import Interpreter from "js-interpreter";

const create = (workspace, visualisation, maxBlocksPerSecond) => {
  const apiWrapper = function (name, arg1, arg2, arg3) {
    return visualisation[name](arg1, arg2, arg3);
  };

  const generateProxyCode = (visualisation) => {
    var preamble = `
        var visualisation = {};
      `;
    for (var propertyName in visualisation) {
      preamble =
        preamble +
        `
          visualisation.${propertyName} = function(arg1, arg2, arg3) {
            return visualisation_api("${propertyName}", arg1, arg2, arg3);
          };
        `;
    }
    return preamble;
  };

  var paused = false;
  var stopped = false;

  const steppedExecution = !!maxBlocksPerSecond;

  const blockStarted = (id) => {
    if (steppedExecution) {
      workspace.highlightBlock(id);
      paused = true;
    }
  };

  const blockStartedWrapper = function (id) {
    id = String(id || "");
    return blockStarted(id);
  };

  const initFunc = (interpreter, globalObject) => {
    interpreter.setProperty(
      globalObject,
      "visualisation_api",
      interpreter.createNativeFunction(apiWrapper)
    );
    interpreter.setProperty(
      globalObject,
      "blockStarted",
      interpreter.createNativeFunction(blockStartedWrapper)
    );
  };

  const preamble = generateProxyCode(visualisation);
  const interpreter = new Interpreter(preamble, initFunc);
  interpreter.run();

  const immediateInterpreter = {
    run: () => {
      stopped = false;
      const code = workspace.generateCode();
      interpreter.appendCode(code);
      interpreter.run();
    },
    stop: () => {
      visualisation.onFinishedOrStopped();
      stopped = true;
    },
  };

  const steppedInterpreter = {
    run: () => {
      stopped = false;
      workspace.setStatementPrefix("blockStarted(%1);\n");
      const code = workspace.generateCode();
      interpreter.appendCode(code);
      const blockStep = () => {
        paused = false;

        do {
          var hasMoreCode = interpreter.step();
        } while (hasMoreCode && !stopped && !paused);

        if (paused) {
          const millisecondDelay = 1000 / maxBlocksPerSecond;
          window.setTimeout(blockStep, millisecondDelay);
        } else {
          blockStarted(null);
          visualisation.onFinishedOrStopped();
        }
      };
      blockStep();
    },

    stop: () => {
      stopped = true;
    },
  };

  return steppedExecution ? steppedInterpreter : immediateInterpreter;
};

export default { create };
