const ArtIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4861 3.15117L16.8608 0.515007C16.1368 -0.211868 14.9119 -0.164307 14.1269 0.625928C13.3418 1.4142 13.2905 2.64611 14.0164 3.37297L16.6418 6.00913C17.3657 6.73601 18.5906 6.68848 19.3776 5.89823C20.1627 5.10799 20.212 3.88003 19.4861 3.15117ZM2.74963 12.0479L8.00037 17.3203L16.5333 8.75425L11.2826 3.48194L2.74963 12.0479ZM0 20L6.92537 18.6077L1.38665 13.0462L0 20Z"
      fill="black"
    />
  </svg>
);

export default ArtIcon;
