import MapBackgroundAsDataUri from "map_background/data_uri";
import HomeIsland from "island_page/islands/home_island.svg.jsx";
import MusicAudio from "music_audio";
import styles from "./styles.module.scss";
import useMusicActivityTunes from "use_music_activity_tunes";

const Customise = ({ channel }) => {
  const [mapColours, setMapColours] = useState({});
  const [songPlaying, setSongPlaying] = useState(false);
  const [song, setSong] = useState(null);
  const songPlayingColor = "#817ffd";
  const songNotPlayingColor = "#FFC700";

  const { availableSongs, useGeneratedAudio } = useMusicActivityTunes();

  useEffect(() => {
    channel.onReady();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  channel.reset = () => {
    setMapColours({});
    setSong(null);
  };

  channel.onFinishedOrStopped = () => {
    channel.onReady();
  };

  channel.startRun = () => {};

  channel.setMapColours = (colour1, colour2) => {
    setMapColours({ colour1, colour2 });
  };

  channel.customiseBoomboxSong = (boombox_song) => {
    setSong(availableSongs[boombox_song]);
  };

  const dataUri = MapBackgroundAsDataUri(
    mapColours.colour1,
    mapColours.colour2
  );

  useEffect(() => {
    if (!song) setSongPlaying(false);
  }, [song]);

  useGeneratedAudio(song, setSong);

  const handlePlaySong = () => setSongPlaying(!songPlaying);
  const handleSongEnded = () => setSongPlaying(false);

  return (
    <>
      <div className={styles.visualisation}>
        <div
          className={styles.map_background}
          style={{ backgroundImage: dataUri }}
        ></div>
        <HomeIsland
          fillParent
          zoomedIn
          className={classNames(styles.home_island)}
          onPlaySong={handlePlaySong}
          color={songPlaying ? songPlayingColor : songNotPlayingColor}
        />
        <MusicAudio
          song={song}
          songPlaying={songPlaying}
          onSongEnded={handleSongEnded}
        />
      </div>
    </>
  );
};

Customise.propTypes = {
  channel: PropTypes.object,
};

export default Customise;
