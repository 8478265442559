const Butterfly = ({ key, x, y, size, colour }) => {
  const height = 22;
  const width = 24;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9012 8.12533C9.9456 6.38729 8.28779 4.02043 5.88807 2.06865C1.62978 -1.39476 0.437455 0.422114 0.153569 0.933109C-0.130317 1.4441 -0.0735395 7.85993 0.778118 9.6768C1.62978 11.4937 3.27632 12.3453 7.02361 12.2885C5.77451 12.3264 3.11734 12.9358 2.48144 15.0706C1.69714 17.7036 5.00324 20.0603 5.47317 20.3952L5.49063 20.4077C5.88807 20.6916 6.8015 21.2997 7.81849 20.6348C8.91607 19.9172 10.0136 18.8229 10.9012 17.2586V17.7959C10.9012 18.4231 11.4096 18.9315 12.0367 18.9315C12.6639 18.9315 13.1723 18.4231 13.1723 17.7959V17.4676C14.0465 18.964 15.114 20.0183 16.1815 20.7162C17.1985 21.3812 18.1119 20.773 18.5094 20.4891L18.5268 20.4767C18.9967 20.1417 22.3029 17.7851 21.5186 15.1521C20.8827 13.0173 18.2255 12.4079 16.9764 12.37C20.7237 12.4268 22.3702 11.5751 23.2219 9.75825C24.0735 7.94138 24.1303 1.52556 23.8464 1.01456C23.5625 0.503567 22.3702 -1.3133 18.1119 2.15011C15.7726 4.05277 14.1383 6.34989 13.1723 8.07441V7.9167C13.1723 7.61132 13.0517 7.3341 12.8556 7.13001L14.4558 4.35848C14.5342 4.2227 14.4877 4.04907 14.3519 3.97068C14.2161 3.89229 14.0425 3.93881 13.9641 4.07459L12.3723 6.83156C12.2663 6.79879 12.1536 6.78115 12.0367 6.78115C11.9041 6.78115 11.7768 6.80388 11.6585 6.84566L10.0586 4.07459C9.98024 3.93881 9.80661 3.89229 9.67083 3.97068C9.53505 4.04907 9.48853 4.2227 9.56692 4.35848L11.1866 7.16385C11.009 7.36424 10.9012 7.62788 10.9012 7.9167V8.12533Z"
        fill={colour}
      />
    </svg>
  );
};

Butterfly.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Butterfly;
