import classNames from "classnames/bind";
import RobotRig from "./robot_rig";
import styles from "./styles.module.scss";
import DanceSpotlight from "assets/dance_spotlight.svg";
import useRobotStyles from "use_robot_styles";

const RobotOnStage = ({
  avatarWorkspace,
  currentPose,
  spotlights,
  gallery = false,
}) => {
  const robotStyles = useRobotStyles(avatarWorkspace);

  const cx = classNames.bind(styles);

  const className = cx({
    visualisation: true,
    visualisation_preview: gallery,
  });

  return (
    <div className={className}>
      <RobotRig
        className={styles.robot_rig}
        pose={currentPose}
        {...robotStyles}
      />

      <DanceSpotlight
        className={styles.left_spotlight}
        colour={spotlights.left.colour}
        opacity={spotlights.left.opacity}
      />

      <DanceSpotlight
        className={styles.right_spotlight}
        colour={spotlights.right.colour}
        opacity={spotlights.right.opacity}
      />
    </div>
  );
};

RobotOnStage.propTypes = {
  avatarWorkspace: PropTypes.object,
  currentPose: PropTypes.object.isRequired,
  spotlights: PropTypes.object.isRequired,
  gallery: PropTypes.bool,
};

export default RobotOnStage;
