import Api from "api";

const Workspace = {};

Workspace.create = (activityKey, { xml, seed, remixedFrom, name }) =>
  new Promise((resolve, reject) => {
    const data = { xml, seed, name };
    if (remixedFrom) {
      data.remixed_from_submission_id = remixedFrom.id;
    }
    Api.post(`/activities/${activityKey}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

Workspace.update = (workspace, activityKey, { xml, seed, name }) =>
  new Promise((resolve, reject) => {
    Api.put(`/activities/${activityKey}/${workspace.id}`, { xml, seed, name })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

Workspace.delete = (workspace, activityKey) =>
  new Promise((resolve, reject) => {
    Api.delete(`/activities/${activityKey}/${workspace.id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

Workspace.submitToGallery = (workspace, data) =>
  new Promise((resolve, reject) => {
    Api.post("/gallery_submissions", { ...data, workspace_id: workspace.id })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        reject(error);
      });
  });

export default Workspace;
