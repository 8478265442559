import DismissCloseImage from "assets/dismiss-cross.svg";
import styles from "./styles.module.scss";
import Link from "link";

const FeedbackBar = () => {
  const [showFeedbackBar, setShowFeedbackBar] = useState(true);

  const dismissFeedbackBar = () => {
    setShowFeedbackBar(false);
    useResize.triggerManually();
  };

  return (
    <>
      {showFeedbackBar && (
        <div className={styles.feedback_bar}>
          <p>
            We need your{" "}
            <Link
              className={styles.link}
              href="https://form.raspberrypi.org/4873315"
            >
              feedback
            </Link>{" "}
            to make Code Club World even better
          </p>
          <button onClick={dismissFeedbackBar} className={styles.dismiss}>
            <img src={DismissCloseImage} alt="Dismiss" />
          </button>
        </div>
      )}
    </>
  );
};

export default FeedbackBar;
