import Button from "button";
import styles from "../activity_filters/styles.module.scss";

const GalleryPageAdminFilters = ({ stateButtonProps = () => {} }) => {
  const filters = ["all", "pending", "accepted", "rejected"];
  return (
    <div className={styles.filters}>
      {filters.map((filter) => (
        <Button.RectangleSecondary
          className={styles.filter_button}
          {...stateButtonProps(filter === "all" ? null : filter)}
          key={filter}
          text={filter[0].toUpperCase() + filter.substring(1)}
        />
      ))}
    </div>
  );
};

GalleryPageAdminFilters.propTypes = {
  stateButtonProps: PropTypes.func,
};

export default GalleryPageAdminFilters;
