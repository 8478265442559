const Shrink = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.4948 7.5015H18.6272L23.5591 2.56955C23.8414 2.28727 24 1.90441 24 1.50521C24 1.106 23.8414 0.723146 23.5591 0.440865C23.2769 0.158584 22.894 0 22.4948 0C22.0956 0 21.7127 0.158584 21.4305 0.440865L16.4985 5.35783V1.50521C16.4985 1.10763 16.3406 0.726333 16.0594 0.445203C15.7783 0.164072 15.397 0.00613382 14.9994 0.00613382C14.6018 0.00613382 14.2206 0.164072 13.9394 0.445203C13.6583 0.726333 13.5004 1.10763 13.5004 1.50521V9.00057C13.5004 9.39815 13.6583 9.77945 13.9394 10.0606C14.2206 10.3417 14.6018 10.4996 14.9994 10.4996H22.4948C22.8924 10.4996 23.2737 10.3417 23.5548 10.0606C23.8359 9.77945 23.9939 9.39815 23.9939 9.00057C23.9939 8.60299 23.8359 8.2217 23.5548 7.94057C23.2737 7.65944 22.8924 7.5015 22.4948 7.5015ZM9.00313 13.4978H1.50777C1.11019 13.4978 0.728892 13.6557 0.447761 13.9369C0.16663 14.218 0.00869214 14.5993 0.00869214 14.9969C0.00869214 15.3944 0.16663 15.7757 0.447761 16.0569C0.728892 16.338 1.11019 16.4959 1.50777 16.4959H5.36038L0.443423 21.4279C0.302918 21.5673 0.191395 21.733 0.115289 21.9157C0.0391835 22.0984 0 22.2943 0 22.4922C0 22.6901 0.0391835 22.8861 0.115289 23.0687C0.191395 23.2514 0.302918 23.4172 0.443423 23.5566C0.582782 23.6971 0.748581 23.8086 0.931257 23.8847C1.11393 23.9608 1.30987 24 1.50777 24C1.70566 24 1.9016 23.9608 2.08427 23.8847C2.26695 23.8086 2.43275 23.6971 2.57211 23.5566L7.50406 18.6246V22.4922C7.50406 22.8898 7.662 23.2711 7.94313 23.5522C8.22426 23.8334 8.60555 23.9913 9.00313 23.9913C9.40071 23.9913 9.78201 23.8334 10.0631 23.5522C10.3443 23.2711 10.5022 22.8898 10.5022 22.4922V14.9969C10.5022 14.5993 10.3443 14.218 10.0631 13.9369C9.78201 13.6557 9.40071 13.4978 9.00313 13.4978Z"
      fill="black"
    />
  </svg>
);

export default Shrink;
