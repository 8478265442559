const styles = {};

styles.blocks = {
  width: "100%",
  height: "100%",
};

styles.blocksArea = {
  width: "100%",
  height: "100%",
};

styles.toolbox = {
  display: "none",
};

export default styles;
