import Blockly from "blockly";
import {
  AvatarBodyPartStyleOptions,
  avatarStyleIndexFromKey,
  avatarStyleOptions,
  defaultBodyPartKey,
} from "./avatar_blocks_configuration.js";

Blockly.Blocks.AvatarStyle = {
  init: function () {
    this.appendDummyInput()
      .appendField("set style of")
      .appendField(
        new Blockly.FieldDropdown(
          AvatarBodyPartStyleOptions,
          this.validateBodyPart
        ),
        "body_part"
      )
      .appendField("to")
      .appendField(new Blockly.FieldDropdown(this.styleOptions), "style");
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setStyle("rpf_blocks");
    this.setTooltip("Choose styles of body parts for avatar");
  },

  validateBodyPart: function (bodyPart) {
    const sourceBlock = this.getSourceBlock();
    const style = sourceBlock.getFieldValue("style");
    const styleOptions = avatarStyleOptions(bodyPart);

    if (!styleOptions.some((option) => option[1] == style)) {
      const styleDropdown = sourceBlock.getField("style");
      styleDropdown.menuGenerator_ = avatarStyleOptions(bodyPart);
      sourceBlock.setFieldValue(styleOptions[0][1], "style");
    }

    return bodyPart;
  },

  styleOptions: function () {
    const sourceBlock = this.getSourceBlock();
    const bodyPart = sourceBlock
      ? sourceBlock.getFieldValue("body_part")
      : defaultBodyPartKey;

    return avatarStyleOptions(bodyPart);
  },
};

Blockly.JavaScript.AvatarStyle = function (block) {
  const styleKey = block.getFieldValue("style");
  const styleIndex = avatarStyleIndexFromKey(styleKey);
  const bodyPart = block.getFieldValue("body_part");
  return `visualisation.setBodyPartStyle('${bodyPart}', ${styleIndex});\n`;
};
