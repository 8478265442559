const Bolt = ({ key, x, y, size, colour }) => {
  const height = 24;
  const width = 15;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;
  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.78021 0.538468C3.82592 0.38297 3.92077 0.246463 4.05056 0.149391C4.18035 0.0523187 4.3381 -9.4774e-05 4.50017 1.2865e-07H10.4998C10.6186 9.02851e-05 10.7356 0.0283873 10.8413 0.0825617C10.947 0.136736 11.0383 0.215239 11.1078 0.311607C11.1772 0.407975 11.2227 0.519453 11.2406 0.636863C11.2585 0.754273 11.2483 0.874259 11.2108 0.986941L8.54093 8.99946H14.2496C14.39 8.99937 14.5277 9.03871 14.6469 9.11301C14.766 9.1873 14.862 9.29358 14.9237 9.41972C14.9855 9.54586 15.0105 9.68681 14.9961 9.8265C14.9817 9.9662 14.9283 10.099 14.8421 10.2099L4.34268 23.7091C4.23512 23.848 4.08219 23.9448 3.91056 23.9825C3.73893 24.0202 3.55951 23.9965 3.40361 23.9154C3.24771 23.8343 3.12523 23.7011 3.05755 23.5389C2.98986 23.3768 2.98126 23.196 3.03326 23.0281L5.7346 14.2492H0.750395C0.633799 14.2492 0.518792 14.2221 0.41451 14.17C0.310228 14.1178 0.219542 14.0421 0.149654 13.9487C0.0797664 13.8554 0.0326024 13.7471 0.0119076 13.6323C-0.00878717 13.5176 -0.00244273 13.3996 0.0304374 13.2877L3.78021 0.538468Z"
        fill={colour}
      />
    </svg>
  );
};

Bolt.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Bolt;
