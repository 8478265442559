const Island = {};

const content = {
  home: {
    title: "Home",
    nodes: {
      start: {
        title: "Start node",
        description: "This is the initial position of the avatar",
        callToAction: "Start",
        visible: false,
        moveAvatar: true,
        exits: {
          N: "avatar",
          W: "art",
          E: "music",
          S: "dance",
        },
      },
      avatar: {
        title: "Make an avatar",
        description: "Code your very own robot avatar!",
        nodePath: (routes) => routes.workspacesPath({ activityKey: "avatar" }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          S: "start",
        },
      },
      art: {
        title: "Design a T-shirt",
        description:
          "Use code to control a pen to create a beautiful T-shirt for your avatar.",
        nodePath: (routes) => routes.workspacesPath({ activityKey: "art" }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          E: "start",
        },
      },
      music: {
        title: "Make music",
        description: "Compose a piece of music using code.",
        nodePath: (routes) => routes.workspacesPath({ activityKey: "music" }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          W: "start",
        },
      },
      dance: {
        title: "Robo-boogie",
        description: "Make a robot dance in time to music.",
        nodePath: (routes) => routes.workspacesPath({ activityKey: "dance" }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          N: "start",
        },
      },
      signpost: {
        title: "Home",
        description:
          "Discover the power of programming! Get started with some fun activities.",
        visible: true,
        moveAvatar: false,
        exits: {
          N: "start",
          E: "start",
          S: "start",
          W: "start",
        },
      },
    },
  },
  scratch: {
    title: "Scratch",
    nodes: {
      start: {
        title: "Scratch Start node",
        description: "This is the initial position of the avatar",
        callToAction: "Start",
        visible: false,
        moveAvatar: true,
        exits: {
          W: "space-talk",
        },
      },
      "space-talk": {
        title: "Space talk",
        description:
          "Use Scratch to give sprites an 'emote' so that they communicate when clicked",
        language: "Scratch",
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "space-talk",
            stepIndex: 0,
          }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          N: "catch-the-bus",
          E: "start",
        },
      },
      "catch-the-bus": {
        title: "Catch the bus",
        description:
          "Code sprites to walk or fly to the bus before it drives off",
        language: "Scratch",
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "catch-the-bus",
            stepIndex: 0,
          }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          E: "find-the-bug",
          S: "space-talk",
        },
      },
      "find-the-bug": {
        title: "Find the bug",
        description:
          "Create a game in which you have to find a hidden bug on each level",
        language: "Scratch",
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "find-the-bug",
            stepIndex: 0,
          }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          N: "silly-eyes",
          W: "catch-the-bus",
        },
      },
      "silly-eyes": {
        title: "Silly eyes",
        description:
          "Create your own app with a character whose eyes follow the mouse pointer",
        language: "Scratch",
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "silly-eyes",
            stepIndex: 0,
          }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          S: "find-the-bug",
          E: "surprise-animation",
        },
      },
      "surprise-animation": {
        title: "Surprise! animation",
        description: "Create an animation of a story with a surprise",
        language: "Scratch",
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "surprise-animation",
            stepIndex: 0,
          }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          N: "i-made-you-a-book",
          W: "silly-eyes",
        },
      },
      "i-made-you-a-book": {
        title: "I made you a book",
        description:
          "Create a book in Scratch with multiple pages to tell a story or share facts",
        language: "Scratch",
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "i-made-you-a-book",
            stepIndex: 0,
          }),
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        exits: {
          S: "surprise-animation",
        },
      },
      signpost: {
        title: "Scratch",
        description:
          "Scratch is a block-based programming language. Use it to make your own interactive stories, games, and animations.",
        visible: true,
        moveAvatar: false,
        exits: {
          N: "space-talk",
          E: "space-talk",
          S: "space-talk",
          W: "space-talk",
        },
      },
    },
  },
  more: {
    title: "More",
    nodes: {
      start: {
        title: "More Start node",
        description: "This is the initial position of the avatar",
        callToAction: "Start",
        visible: false,
        moveAvatar: true,
        exits: {
          E: "learn-even-more",
        },
      },
      "learn-even-more": {
        title: "Learn even more!",
        description:
          "Check out the projects on the Raspberry Pi Projects site for even more things to learn!",
        nodePath: () => "https://projects.raspberrypi.org",
        callToAction: "Visit Raspberry Pi Projects",
        visible: true,
        moveAvatar: true,
        exits: {
          W: "start",
        },
      },
      signpost: {
        title: "More projects",
        description: "Ready for even more projects?",
        visible: true,
        moveAvatar: false,
        exits: {
          N: "learn-even-more",
          E: "learn-even-more",
          S: "learn-even-more",
          W: "learn-even-more",
        },
      },
    },
  },
  python: {
    title: "Python",
    nodes: {
      start: {
        title: "Start node",
        description: "This is the initial position of the avatar",
        callToAction: "Start",
        visible: false,
        moveAvatar: true,
        exits: {
          N: "hello-world",
        },
      },
      "hello-world": {
        title: "Hello 🌍🌎🌏",
        description: "Write an interactive Python project that uses emoji",
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "hello-world",
            stepIndex: 0,
          }),
        exits: {
          S: "start",
          E: "target-practice",
        },
      },
      "target-practice": {
        title: "Target practice",
        description:
          "Use Python to draw a target and score points by hitting it with arrows",
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "target-practice",
            stepIndex: 0,
          }),
        exits: {
          S: "rocket-launch",
          W: "hello-world",
        },
      },
      "rocket-launch": {
        title: "Rocket launch",
        description:
          "Make an animation of a rocket propelling a satellite into orbit",
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "rocket-launch",
            stepIndex: 0,
          }),
        exits: {
          E: "make-a-face",
          N: "target-practice",
        },
      },
      "make-a-face": {
        title: "Make a face",
        description: "Design and draw a face or mask using geometric shapes",
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "make-a-face",
            stepIndex: 0,
          }),
        exits: {
          N: "dont-collide",
          W: "rocket-launch",
        },
      },
      "dont-collide": {
        title: "Don't collide!",
        description:
          "Build an endless runner game where your character must avoid colliding with obstacles",
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "dont-collide",
            stepIndex: 0,
          }),
        exits: {
          N: "powerful-patterns",
          S: "make-a-face",
        },
      },
      "powerful-patterns": {
        title: "Powerful patterns",
        description:
          "Create a piece of inspirational art that can be scaled using repeated patterns",
        callToAction: "Start",
        visible: true,
        moveAvatar: true,
        nodePath: (routes) =>
          routes.projectStepPath({
            repositoryName: "powerful-patterns",
            stepIndex: 0,
          }),
        exits: {
          S: "dont-collide",
        },
      },
      signpost: {
        title: "Python",
        description:
          "Python is a text based programming language which is easy to learn. Use it to make art, games and other useful apps.",
        visible: true,
        moveAvatar: false,
        exits: {
          N: "start",
          E: "start",
          S: "start",
          W: "start",
        },
      },
    },
  },
};

Island.forKey = (islandKey) => content[islandKey];

Island.keysForNodeKey = (nodeKey) =>
  Object.entries(content)
    .filter((v) => Object.keys(v[1].nodes).some((nk) => nk === nodeKey))
    .map((v) => v[0]);

Island.keyForNodeKey = (nodeKey) => {
  const islandKeys = Island.keysForNodeKey(nodeKey);
  switch (islandKeys.length) {
    case 0:
      throw new Error(`Unknown island for project: ${nodeKey}`);
    case 1:
      return islandKeys[0];
    default:
      throw new Error(`Ambiguous island for project: ${nodeKey}`);
  }
};

export default Island;
