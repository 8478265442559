import Confetti from "canvas-confetti";

const ConfettiAnimation = {};

ConfettiAnimation.start = () => {
  const end = Date.now() + 3 * 1000;

  const colors = ["#26874C", "#FFC700", "#63B4FF", "#FF7A00"];

  const frame = () => {
    Confetti({
      particleCount: 4,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    });
    Confetti({
      particleCount: 4,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };

  frame();
};

export default ConfettiAnimation;
