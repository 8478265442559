import Analytics from "analytics";
import MyDataContext from "my_data_context";
import CsrfContext from "csrf_context";
import Button from "button";
import styles from "./styles.module.scss";

const CreateAccountForm = () => {
  const { user } = useContext(MyDataContext);
  const routes = useRoutes();
  const csrfToken = useContext(CsrfContext);

  return (
    <>
      {!user.logged_in && (
        <form
          action={routes.signupPath()}
          method="post"
          className={styles.create_account_form}
        >
          <p>Sign up to make sure you never lose your badges.</p>
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Button.RectangleTertiary
            text="Create an account"
            outline={true}
            onClick={() =>
              Analytics.accountActivity("sign_up", "project_badge")
            }
          ></Button.RectangleTertiary>
        </form>
      )}
    </>
  );
};

export default CreateAccountForm;
