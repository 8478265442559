import styles from "./styles.module.scss";
import SavedState from "./saved_state";
import SavingIcon from "assets/saving_icon.svg";
import SavedIcon from "assets/saved_icon.svg";
import SavingErrorIcon from "assets/saving_error_icon.svg";

const SavedStateIndicator = ({ state, iconOnly = false }) => {
  if (!state) return null;

  const icon = (state) => {
    switch (state) {
      case SavedState.NOT_SAVING:
        return null;
      case SavedState.SAVING:
      case SavedState.WAITING_FOR_SAVING_FINISHED:
      case SavedState.WAITING_FOR_SAVING_TIMER:
        return <SavingIcon />;
      case SavedState.JUST_SAVED:
        return <SavedIcon />;
      case SavedState.ERROR:
        return <SavingErrorIcon />;
      default:
        throw new Error(`Unrecognized saved state: ${state}`);
    }
  };

  const text = (state) => {
    switch (state) {
      case SavedState.NOT_SAVING:
        return null;
      case SavedState.SAVING:
      case SavedState.WAITING_FOR_SAVING_FINISHED:
      case SavedState.WAITING_FOR_SAVING_TIMER:
        return "Saving";
      case SavedState.JUST_SAVED:
        return "Saved";
      case SavedState.ERROR:
        return "Error";
      default:
        throw new Error(`Unrecognized saved state: ${state}`);
    }
  };

  return (
    <div className={styles.indicator}>
      {icon(state)}
      {!iconOnly && <span className={styles.text}>{text(state)}</span>}
    </div>
  );
};

SavedStateIndicator.propTypes = {
  state: PropTypes.string,
  iconOnly: PropTypes.bool,
};

export default SavedStateIndicator;
