const Star = ({ key, x, y, size, colour }) => {
  const height = 23;
  const width = 24;
  const scale = size / height;
  const scaledHeight = height * scale;
  const scaledWidth = width * scale;

  return (
    <svg
      x={x - scaledWidth / 2}
      y={y - scaledHeight / 2}
      key={key}
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2243 8.07035L16.0296 7.02472L12.8133 0.504407C12.7255 0.325885 12.581 0.181367 12.4024 0.0935222C11.9547 -0.127505 11.4106 0.0566843 11.1868 0.504407L7.97055 7.02472L0.77582 8.07035C0.577461 8.09869 0.396105 8.1922 0.257255 8.33389C0.0893918 8.50642 -0.0031083 8.73854 7.97568e-05 8.97924C0.00326781 9.21994 0.101883 9.44952 0.274257 9.61755L5.47974 14.6927L4.24992 21.8591C4.22108 22.0258 4.23953 22.1972 4.30317 22.354C4.36681 22.5107 4.47311 22.6465 4.60999 22.746C4.74688 22.8454 4.90889 22.9045 5.07764 22.9165C5.24639 22.9285 5.41514 22.893 5.56475 22.814L12.0001 19.4306L18.4354 22.814C18.6111 22.9075 18.8151 22.9387 19.0106 22.9047C19.5037 22.8197 19.8352 22.3521 19.7502 21.8591L18.5204 14.6927L23.7259 9.61755C23.8675 9.4787 23.9611 9.29734 23.9894 9.09898C24.0659 8.60309 23.7202 8.14403 23.2243 8.07035Z"
        fill={colour}
      />
    </svg>
  );
};

Star.propTypes = {
  key: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  size: PropTypes.number,
  colour: PropTypes.string,
};

export default Star;
