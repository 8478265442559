import { compact } from "lodash";

const Analytics = {};

Analytics.event = (name, props) => {
  if (window.gtag) {
    window.gtag("event", name, props);
  }
};

Analytics.pageView = () => {
  Analytics.event("page_view", {
    page_title: document.title,
    page_location: location.href,
    page_path: location.pathname,
  });
};

Analytics.badgeIssued = (badgeTemplate, pathwayKey = null) => {
  Analytics.event("badge_awarded", {
    event_category: "Badge Award",
    event_label: compact([
      pathwayKey,
      badgeTemplate.slug || badgeTemplate.key,
    ]).join("/"),
  });
};

Analytics.clickHint = (eventCategory) => {
  Analytics.event("click_hint", {
    event_category: eventCategory,
    event_label: "",
  });
};

Analytics.satisfactionSurveyResponse = (projectTitle, response) => {
  Analytics.event("satisfaction_survey_response", {
    event_category: "Satisfaction: " + projectTitle,
    event_label: response,
  });
};

Analytics.taskCompleted = (taskKey) => {
  Analytics.event("task_completed", {
    event_category: "Task completed",
    event_label: taskKey,
  });
};

Analytics.clickCciSignpost = () => {
  Analytics.event("click_cci_signpost", {
    event_category: "homepage",
    event_label: "cci signpost",
  });
};

Analytics.accountActivity = (type = "sign_up", location = "nav") => {
  Analytics.event(type, {
    event_category: "Account",
    event_label: `location/${location}`,
  });
};

Analytics.galleryEvent = (type, label) => {
  Analytics.event("gallery_event", {
    event_category: type,
    event_label: label,
  });
};

Analytics.galleryPlayEvent = (activity, id, featured = false) => {
  Analytics.galleryEvent("play", `${activity}/${id}?featured=${!!featured}`);
};

Analytics.galleryActivityEvent = (activity, id, featured = false) => {
  Analytics.galleryEvent("view", `${activity}/${id}?featured=${!!featured}`);
};

Analytics.clickVideo = (label) => {
  Analytics.event("parent_page", {
    event_label: label,
  });
};

export default Analytics;
