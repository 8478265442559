const TickMark = ({ skinny = false }) => {
  if (skinny) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.54995 17.725C9.39995 17.725 9.25828 17.7 9.12495 17.65C8.99162 17.6 8.86662 17.5167 8.74995 17.4L4.44995 13.1C4.23328 12.8833 4.12928 12.6123 4.13795 12.287C4.14595 11.9623 4.25828 11.6917 4.47495 11.475C4.69162 11.2583 4.96262 11.15 5.28795 11.15C5.61262 11.15 5.87495 11.2583 6.07495 11.475L9.54995 14.95L17.925 6.575C18.125 6.35833 18.3916 6.25 18.725 6.25C19.0583 6.25 19.3333 6.35833 19.55 6.575C19.75 6.79167 19.85 7.06233 19.85 7.387C19.85 7.71233 19.75 7.98333 19.55 8.2L10.35 17.4C10.2333 17.5167 10.1083 17.6 9.97495 17.65C9.84162 17.7 9.69995 17.725 9.54995 17.725Z" />
      </svg>
    );
  } else {
    return (
      <svg
        width="27"
        height="20"
        viewBox="0 0 27 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0216 0.901679C26.6282 1.4659 26.6625 2.41502 26.0983 3.02161L11.216 19.0216C10.9399 19.3184 10.5555 19.4908 10.1503 19.4997C9.74501 19.5085 9.35346 19.3529 9.06476 19.0684L0.94711 11.0684C0.357064 10.4869 0.350131 9.53716 0.931626 8.94712C1.51312 8.35707 2.46284 8.35014 3.05289 8.93163L10.0709 15.8479L23.9017 0.978401C24.4659 0.371811 25.415 0.337462 26.0216 0.901679Z"
        />
      </svg>
    );
  }
};

TickMark.propTypes = {
  skinny: PropTypes.bool,
};

export default TickMark;
