import BadgeState from "../project_badge_for_checklist/badge_state";
import ProjectBadge from "../project_badge";
import Button from "button";
import useConfettiAnimation from "use_confetti_animation";
import CreateAccountForm from "../create_account_form";
import styles from "./styles.module.scss";

const ProjectBadgeForQuiz = ({
  badgeTemplate,
  badgeState,
  onStartAgainClick,
  className,
  inScratchOverlay,
}) => {
  const ref = useRef();
  const startConfettiAnimation = useConfettiAnimation();

  useEffect(() => {
    if (badgeState === BadgeState.AWARD_COMPLETED) startConfettiAnimation();
  }, [badgeState, startConfettiAnimation]);

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
  }, [badgeState]);

  if (!BadgeState.isAwarded(badgeState)) return null;

  return (
    <div
      className={classNames(className, styles.container, {
        [styles.inScratchOverlay]: inScratchOverlay,
      })}
      ref={ref}
    >
      <h3>Quiz complete</h3>
      <div className={styles.header}>
        <h4>Well done!</h4>
        <p>
          You got all the questions right, and you&apos;ve <br />
          done great reflecting on what you know!
        </p>
      </div>
      <ProjectBadge badgeTemplate={badgeTemplate} />
      <CreateAccountForm />
      <div className={styles.footer}>
        <Button.RectangleSecondary
          onClick={onStartAgainClick}
          text="Start the quiz again"
          className={styles.start_again_button}
        />
      </div>
    </div>
  );
};

ProjectBadgeForQuiz.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
  badgeState: PropTypes.string.isRequired,
  onStartAgainClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  inScratchOverlay: PropTypes.bool,
};

export default ProjectBadgeForQuiz;
