import Layout from "layout";
import Heading from "heading";
import CardGrid from "card_grid";
import Card from "card";
import Link from "link";
import Button from "button";
import MyDataContext from "my_data_context";
import User from "user";
import Overlay from "overlay";
import ConfirmationPrompt from "confirmation_prompt";
import WorkspacePreview from "workspace_preview";
import PlusIcon from "assets/plus_icon.svg";
import TickMark from "assets/tick_mark.svg";
import Workspace from "workspace";
import WorkspacePresenter from "workspace_presenter";
import timeAgoInWords from "time_ago_in_words";
import allContent from "./content";
import styles from "./styles.module.scss";
import ellipsisMenuStyles from "./ellipsis_menu_styles.module.scss";
import SEO from "seo";
import EllipsisMenu from "ellipsis_menu";
import useAudioContext from "../../hooks/use_audio_context";
import isPlayable from "activity_playable";

const metaData = {
  avatar: {
    title: "My avatars - Code Club World",
    description:
      "Create a new avatar with code blocks. View all the avatars you have already created.",
  },
  music: {
    title: "My music - Code Club World",
    description:
      "Make a new piece of music with code blocks. View all the pieces you have already created.",
  },
  dance: {
    title: "My dances - Code Club World",
    description:
      "Make a new dance with code blocks. View all the dances you have already created.",
  },
  art: {
    title: "My T-shirts - Code Club World",
    description:
      "Make a new T-shirt design with code blocks. View all the designs you have already created.",
  },
};

const WorkspacesPage = () => {
  const {
    user: { tshirt_workspace, avatar_workspace },
    workspaces,
  } = useContext(MyDataContext);
  const { activity_key } = useParams();
  const content = allContent[activity_key];
  const [localWorkspaces, setLocalWorkspaces] = useState(
    WorkspacePresenter.sortAndAugmentWithTitle({
      workspaces,
      titlePrefix: content.cardTitlePrefix,
    })
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [workspaceToBeDeleted, setWorkspaceToBeDeleted] = useState();
  const [tshirtId, setTshirtId] = useState(
    tshirt_workspace && tshirt_workspace.id
  );
  const [avatarId, setAvatarId] = useState(
    avatar_workspace && avatar_workspace.id
  );
  const [playingId, setPlayingId] = useState();
  const routes = useRoutes();
  const audioContext = useAudioContext();

  useEffect(() => {
    if (activity_key === "art") {
      User.update_tshirt({ tshirt_workspace_id: tshirtId });
    }
  }, [tshirtId, activity_key]);

  useEffect(() => {
    if (activity_key === "avatar") {
      User.update_avatar({ avatar_workspace_id: avatarId });
    }
  }, [avatarId, activity_key]);

  const handleWear = (index) => {
    setTshirtId(localWorkspaces[index].id);
  };

  const handleRemove = () => {
    setTshirtId(null);
  };

  const handleSelect = (index) => {
    setAvatarId(localWorkspaces[index].id);
  };

  const handleUnselect = () => {
    setAvatarId(null);
  };

  const handlePlay = (index) => {
    if (audioContext.current.state === "suspended") {
      audioContext.current
        .resume()
        .then(() => setPlayingId(localWorkspaces[index].id));
    } else {
      setPlayingId(localWorkspaces[index].id);
    }
  };

  const handleStop = () => {
    setPlayingId(null);
  };

  const handleFinished = () => {
    setPlayingId(null);
  };

  const confirmDelete = (workspace) => {
    setShowDeleteConfirmation(true);
    setWorkspaceToBeDeleted(workspace);
  };

  const handleDeleteConfirmation = () => {
    Workspace.delete(workspaceToBeDeleted, activity_key).then(() => {
      const copy = localWorkspaces
        .slice()
        .filter((w) => w.id !== workspaceToBeDeleted.id);
      setLocalWorkspaces(copy);
      setWorkspaceToBeDeleted(null);
      setShowDeleteConfirmation(false);
    });
  };

  return (
    <>
      <SEO
        title={metaData[activity_key].title}
        description={metaData[activity_key].description}
      />
      <Layout invertBackgroundColours={true}>
        <Heading
          title={<h1>{content.pageTitle}</h1>}
          invertBackgroundColours={true}
          backPath={
            routes.islandPath({ island_name: "home" }) + "#" + activity_key
          }
        />

        <CardGrid>
          <Card.Button
            title={content.newButtonText}
            imageSrc={<PlusIcon />}
            href={`/activities/${activity_key}/new`}
            aria-label={`New ${activity_key}`}
          />

          {localWorkspaces.map((w, index) => {
            return (
              <Card
                key={w.id}
                title={w.title}
                subtitle={`Last modified ${timeAgoInWords(
                  new Date(w.updated_at)
                )}`}
                playing={w.id === playingId}
                handlePlay={
                  isPlayable(w.activity.key) ? () => handlePlay(index) : null
                }
                handleStop={handleStop}
                childrenInline={true}
                imageSrc={
                  <WorkspacePreview
                    activityKey={activity_key}
                    workspace={w}
                    playing={w.id === playingId}
                    onFinished={handleFinished}
                    audioContext={audioContext.current}
                  />
                }
                href={`/activities/${activity_key}/${w.id}`}
              >
                {activity_key === "art" &&
                  (w.id === tshirtId ? (
                    <Button.RectangleSecondary
                      className={styles.inner_button}
                      icon={<TickMark />}
                      text="Remove"
                      textOnHover={true}
                      onClick={handleRemove}
                    />
                  ) : (
                    <Button.RectangleSecondary
                      className={styles.inner_button}
                      text="Wear"
                      onClick={() => handleWear(index)}
                    />
                  ))}
                {activity_key === "avatar" &&
                  (w.id === avatarId ? (
                    <Button.RectangleSecondary
                      className={styles.inner_button}
                      icon={<TickMark />}
                      text="Unselect"
                      textOnHover={true}
                      onClick={handleUnselect}
                    />
                  ) : (
                    <Button.RectangleSecondary
                      className={styles.inner_button}
                      text="Select"
                      onClick={() => handleSelect(index)}
                    />
                  ))}

                <EllipsisMenu
                  index={index}
                  styles={ellipsisMenuStyles}
                  items={[
                    <Link
                      key={1}
                      className={styles.menu_link}
                      onClick={() => confirmDelete(w)}
                    >
                      Delete
                    </Link>,
                  ]}
                ></EllipsisMenu>
              </Card>
            );
          })}
        </CardGrid>
        {showDeleteConfirmation && (
          <Overlay>
            <ConfirmationPrompt
              question={`Are you sure you want to delete this ${content.cardTitlePrefix}?`}
              dismissText="Cancel"
              onDismiss={() => setShowDeleteConfirmation(false)}
              confirmText="Delete"
              onConfirm={handleDeleteConfirmation}
            />
          </Overlay>
        )}
      </Layout>
    </>
  );
};

export default WorkspacesPage;
