import AutoSaveEvent from "auto_save_event";

const useAutoSave = () => {
  const [autoSaveRequestCount, setAutoSaveRequestCount] = useState(0);

  const deltaFrom = (event) => {
    switch (event) {
      case AutoSaveEvent.REQUESTED:
        return +1;
      case AutoSaveEvent.PROCESSED:
        return -1;
      default:
        throw new Error(`Unrecognized event: ${event}`);
    }
  };

  const handleAutoSaveEvent = useCallback(
    (event) => {
      setAutoSaveRequestCount((count) => Math.max(0, count + deltaFrom(event)));
    },
    [setAutoSaveRequestCount]
  );

  const autoSaveRequested = () => autoSaveRequestCount > 0;

  return [autoSaveRequested, handleAutoSaveEvent];
};

export default useAutoSave;
