import Bolt from "./stamps/bolt.svg";
import Butterfly from "./stamps/butterfly.svg";
import Circle from "./stamps/circle.svg";
import Cloud from "./stamps/cloud.svg";
import Flower from "./stamps/flower.svg";
import Heart from "./stamps/heart.svg";
import Raindrop from "./stamps/raindrop.svg";
import Snowflake from "./stamps/snowflake.svg";
import Splat from "./stamps/splat.svg";
import Square from "./stamps/square.svg";
import Star from "./stamps/star.svg";
import Triangle from "./stamps/triangle.svg";

const stamps = {
  bolt: {
    name: "bolt",
    path: Bolt,
  },
  butterfly: {
    name: "butterfly",
    path: Butterfly,
  },
  circle: {
    name: "circle",
    path: Circle,
  },
  cloud: {
    name: "cloud",
    path: Cloud,
  },
  flower: {
    name: "flower",
    path: Flower,
  },
  heart: {
    name: "heart",
    path: Heart,
  },
  raindrop: {
    name: "raindrop",
    path: Raindrop,
  },
  snowflake: {
    name: "snowflake",
    path: Snowflake,
  },
  splat: {
    name: "splat",
    path: Splat,
  },
  square: {
    name: "square",
    path: Square,
  },
  star: {
    name: "star",
    path: Star,
  },
  triangle: {
    name: "triangle",
    path: Triangle,
  },
};

export default stamps;
