import Hero from "./components/hero";
import EarnBadgesSection from "./components/earn_badges_section";
import Footer from "footer";
import styles from "./styles.module.scss";
import Section from "./components/section";
import MakeThingsWithCodeImg from "assets/landing_page_make_things_with_code.svg";
import ShareProjectsImg from "assets/landing_page_share_projects.svg";
import StartYourJourneySection from "./components/start_your_journey_section";
import WhatIsCodeClubWorldSection from "./components/what_is_code_club_world_section";
import LandingNav from "landing_nav";

const LandingPage = () => {
  const makeThingsWithCodeRef = useRef(null),
    earnBadgesRef = useRef(null),
    shareProjectsRef = useRef(null),
    whatIsCodeClubWorldRef = useRef(null),
    startJourneyRef = useRef(null);

  const homepageSections = useMemo(() => {
    const sections = [
      makeThingsWithCodeRef,
      shareProjectsRef,
      earnBadgesRef,
      startJourneyRef,
      whatIsCodeClubWorldRef,
    ];
    return sections;
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(styles.animate);
          observer.unobserve(entry.target);
        } else {
          entry.target.classList.remove(styles.animate);
        }
      }),
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        };
    });
    homepageSections.forEach((section) => {
      section.current && observer.observe(section.current);
    });
  }, [homepageSections]);

  return (
    <div className={styles.landing_page}>
      <LandingNav />
      <Hero />
      <WhatIsCodeClubWorldSection ref={whatIsCodeClubWorldRef} />
      <Section
        ref={makeThingsWithCodeRef}
        title="Make things with code"
        paragraph="Code your own character, design a T-shirt, and create some music. Learn to make games, art, websites, and more!"
        image={MakeThingsWithCodeImg}
      />
      <EarnBadgesSection ref={earnBadgesRef} />
      <Section
        ref={shareProjectsRef}
        title="Share projects"
        paragraph="Share your projects with our community of learners, and remix your friends' projects!"
        image={ShareProjectsImg}
      />
      <StartYourJourneySection ref={startJourneyRef} />
      <Footer />
    </div>
  );
};

export default LandingPage;
