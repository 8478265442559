import { AvatarBodyPartOptions } from "blocks/custom_blocks/avatar_blocks_configuration.js";
import styles from "./styles.module.scss";
import Robot from "assets/robot.svg";

const Avatar = ({ channel, outputType = null, className = null }) => {
  const [robotStyles, setRobotStyles] = useState({});

  channel.reset = () => {
    setRobotStyles({});
  };

  channel.onFinishedOrStopped = () => {
    channel.onReady();
  };

  channel.startRun = () => {};

  channel.setStyleEntry = (key, value) => {
    setRobotStyles((styles) => ({ ...styles, [key]: value }));
  };

  channel.setBodyPartStyle = (bodyPart, styleIndex) => {
    channel.setStyleEntry(`${bodyPart}Style`, styleIndex);
  };

  channel.setColour = (bodyPart, colour) => {
    channel.setStyleEntry(`${bodyPart}Colour`, colour);
  };

  channel.setColourForAll = (colour) => {
    AvatarBodyPartOptions.map((option) => {
      channel.setColour(option[1], colour);
    });
  };

  useEffect(() => {
    if (outputType === "preview") return;

    if (Object.keys(robotStyles).length > 0) {
      channel.onStyled();
    }
  }, [robotStyles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {outputType === "preview" ? (
        <>
          <div className={styles.preview}>
            <div className={styles.container}>
              <Robot width="100%" height="100%" {...robotStyles} />
            </div>
          </div>
        </>
      ) : (
        <div className={classNames(styles.visualisation, className)}>
          <div className={styles.robot}>
            <Robot width="100%" height="100%" {...robotStyles} />
          </div>
        </div>
      )}
    </>
  );
};

Avatar.propTypes = {
  channel: PropTypes.object,
  outputType: PropTypes.string,
  className: PropTypes.string,
};

export default Avatar;
