import styles from "./styles.module.scss";
import Analytics from "analytics";

function handleBlur() {
  if (
    document.activeElement &&
    document.activeElement.nodeName.toLowerCase() === "iframe"
  ) {
    // Call GA event
    Analytics.clickVideo("promo video home section");
  }
}

const WhatIsCodeClubSection = forwardRef((props, ref) => {
  React.useEffect(() => {
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  });
  return (
    <section className={styles.section_container}>
      <div className={styles.section_animate} ref={ref}>
        <div className={styles.section_copy}>
          <h1>What is Code Club World?</h1>
        </div>
        <div className={styles.section_video}>
          <figure className={styles.section_figure}>
            <iframe
              src="https://www.youtube.com/embed/FUNucqFmd28"
              title="YouTube video player: Coding games for kids"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </figure>
        </div>
      </div>
    </section>
  );
});

export default WhatIsCodeClubSection;
