const convert = require("xml-js");
const { differenceBy, differenceWith, isEqual } = require("lodash");

const isArrayEqual = (x, y) => differenceWith(x, y, isEqual);
const isArrayIdsEqual = (x, y) => differenceBy(x, y, "_attributes.id");

const xml2obj = (xml, options = { compact: true, spaces: 2 }) => {
  return convert.xml2js(xml, options);
};

const unnestBlocks = ({ next, statement, ...values }) => {
  // for blockly blocks are nested in next and statement tags
  return [
    values,
    ...(next ? unnestBlocks(next.block) : []),
    ...(statement ? unnestBlocks(statement.block) : []),
  ];
};

const excludeBlockTypes = (blocks, types) => {
  return blocks.filter((block) => {
    return !types.includes(block._attributes?.type);
  });
};

export const remixed_workspace_diff = (remixedXml, originalXml) => {
  if (!remixedXml || !originalXml) return [];
  const remixedObj = xml2obj(remixedXml);
  const remixedBlocks = unnestBlocks(remixedObj?.xml?.block);
  const originalObj = xml2obj(originalXml);
  const originalBlocks = unnestBlocks(originalObj?.xml?.block);
  // additional + changed blocks
  const additionsPlusChanges = isArrayEqual(remixedBlocks, originalBlocks);
  // removed blocks
  const removals = isArrayIdsEqual(originalBlocks, remixedBlocks);
  const diffBlocks = excludeBlockTypes(
    [...additionsPlusChanges, ...removals],
    ["Run"]
  );
  return diffBlocks;
};

export default remixed_workspace_diff;
