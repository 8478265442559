import CardGrid from "card_grid";
import Card from "card";
import ActivityFilters from "activity_filters";
import IconButton from "icon_button";
import WorkspacePreview from "workspace_preview";
import Arrow from "assets/arrow";
import EllipsisMenu from "ellipsis_menu";
import ellipsisMenuStyles from "workspaces_page/ellipsis_menu_styles.module.scss";
import Link from "link";
import Overlay from "overlay";
import ConfirmationPrompt from "confirmation_prompt";
import GallerySubmission from "gallery_submission";
import getActivityKey from "activity_key";
import useAudioContext from "../../../hooks/use_audio_context";
import pagination_styles from "../../gallery_page/styles.module.scss";
import MyDataContext from "my_data_context";
import isPlayable from "activity_playable";

const MySharedProjects = ({ styles }) => {
  const {
    my_gallery: myGallery,
    my_gallery_pages: myGalleryPages,
    activities,
  } = useContext(MyDataContext);

  const audioContext = useAudioContext();
  const routes = useRoutes();
  const [playingId, setPlayingId] = useState();
  const [localWorkspaces] = useState(myGallery);

  const {
    current_page: currentPage = 1,
    total_pages: totalPages = 1,
    first_page: firstPage = true,
    last_page: lastPage = true,
    total_count: totalCount = 1,
  } = myGalleryPages;
  const prevPage = Math.max(currentPage - 1, 1);
  const nextPage = Math.min(currentPage + 1, totalPages);

  const paginationControlClasses = classNames(
    pagination_styles.pagination_controls,
    {
      [pagination_styles.pagination_controls_left]: !firstPage && lastPage,
      [pagination_styles.pagination_controls_right]: firstPage && !lastPage,
    }
  );

  const activityKey = new URLSearchParams(useLocation().search).get("activity");
  const featuredFlag =
    new URLSearchParams(useLocation().search).get("featured") == "true";

  const buttonProps = (key) => {
    return {
      active: key
        ? activityKey === key && !featuredFlag
        : !activityKey && !featuredFlag,
      outline: key
        ? activityKey !== key || featuredFlag
        : !!activityKey || featuredFlag,
      href: profilePath({ key: key }),
    };
  };

  const featuredButtonProps = () => {
    return {
      active: featuredFlag,
      outline: !featuredFlag,
      href: profilePath({ featured: true }),
    };
  };

  const handlePlay = (index) => {
    setPlayingId(localWorkspaces[index].id);
  };

  const handleStop = () => {
    setPlayingId(null);
  };

  const handleFinished = () => {
    setPlayingId(null);
  };

  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [submissionToBeDeleted, setSubmissionToBeDeleted] = useState();

  const confirmDelete = (workspace) => {
    setSubmissionToBeDeleted(workspace);
    setDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = () => {
    GallerySubmission.delete(submissionToBeDeleted.id).then(() => {
      location.reload();
    });
  };

  const profilePath = ({ key = null, page = null, featured = false }) => {
    let path = `${routes.profilePath()}?tab=MySharedProjects`;

    if (featured) {
      path = `${path}&featured=true`;
    } else if (key) {
      path = `${path}&activity=${key}`;
    }

    if (page) {
      path = `${path}&page=${page}`;
    }

    return path;
  };

  return (
    <>
      <ActivityFilters
        buttonProps={buttonProps}
        featuredButtonProps={featuredButtonProps}
      />
      <div className={styles.projects_info}>
        <h2 className={styles.projects_count}>
          {`Showing ${totalCount} project${totalCount > 1 ? "s" : ""}...`}
        </h2>
      </div>
      <CardGrid extraClass={styles.card_grid}>
        {localWorkspaces.map((exhibit, index) => {
          exhibit.activity_key = getActivityKey(
            activities,
            exhibit.activity_id
          );
          return (
            <Card
              testId={exhibit.activity_key}
              key={exhibit.id}
              title={exhibit.name}
              subtitle={`by ${exhibit.author}`}
              isFeatured={!!exhibit.featured_at}
              isPending={exhibit.state === "pending"}
              remixCount={exhibit.remix_count}
              playing={exhibit.id === playingId}
              handlePlay={
                isPlayable(exhibit.activity_key)
                  ? () => handlePlay(index)
                  : null
              }
              handleStop={handleStop}
              childrenInline={true}
              imageSrc={
                <WorkspacePreview
                  activityKey={exhibit.activity_key}
                  avatarWorkspace={exhibit.avatar_workspace}
                  workspace={exhibit}
                  playing={exhibit.id === playingId}
                  onFinished={handleFinished}
                  audioContext={audioContext.current}
                  previewImage={exhibit.preview_image}
                />
              }
              href={routes.galleryActivityPath({
                activityKey: exhibit.activity_key,
                id: exhibit.id,
              })}
            >
              <EllipsisMenu
                index={index}
                styles={ellipsisMenuStyles}
                items={[
                  <Link
                    key={1}
                    className={styles.menu_link}
                    onClick={() => confirmDelete(exhibit)}
                  >
                    Delete
                  </Link>,
                ]}
              ></EllipsisMenu>
            </Card>
          );
        })}
      </CardGrid>
      {showDeleteConfirmation && (
        <div className="js-test-overlay">
          <Overlay>
            <ConfirmationPrompt
              question={`Are you sure you want to delete this ${submissionToBeDeleted.name}?`}
              dismissText="Cancel"
              onDismiss={() => setDeleteConfirmation(false)}
              confirmText="Delete"
              onConfirm={handleDeleteConfirmation}
            />
          </Overlay>
        </div>
      )}
      <div className={pagination_styles.pagination}>
        <div className={paginationControlClasses}>
          {!firstPage && (
            <>
              <IconButton
                icon={<Arrow type="first" />}
                aria-label="First page"
                href={profilePath({
                  key: activityKey,
                  page: 1,
                  featured: featuredFlag,
                })}
              ></IconButton>
              <IconButton
                solid={true}
                icon={<Arrow type="previous" />}
                aria-label="Previous page"
                href={profilePath({
                  key: activityKey,
                  page: prevPage,
                  featured: featuredFlag,
                })}
              ></IconButton>
            </>
          )}
          <div className={pagination_styles.pagination_pages}>
            <span className={"screen_reader_only"}>Page</span> {currentPage}{" "}
            <span className={"screen_reader_only"}>of</span>{" "}
            <span aria-hidden={true}>/</span> {totalPages}
          </div>
          {!lastPage && (
            <>
              <IconButton
                solid={true}
                icon={<Arrow type="next" />}
                aria-label="Next page"
                href={profilePath({
                  key: activityKey,
                  page: nextPage,
                  featured: featuredFlag,
                })}
              ></IconButton>
              <IconButton
                icon={<Arrow type="last" />}
                aria-label="Last page"
                href={profilePath({
                  key: activityKey,
                  page: totalPages,
                  featured: featuredFlag,
                })}
              ></IconButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

MySharedProjects.propTypes = {
  styles: PropTypes.object,
};

export default MySharedProjects;
