const BurgerMenuIcon = () => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0H24.5V3H0.5V0ZM0.5 7.5H24.5V10.5H0.5V7.5ZM0.5 15H24.5V18H0.5V15Z"
        fill="black"
      />
    </svg>
  );
};

export default BurgerMenuIcon;
