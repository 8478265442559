import styles from "./styles.module.scss";

const Section = forwardRef((props, ref) => {
  return (
    <section className={styles.section_container} ref={ref}>
      <img alt="" src={props.image} />
      <div className={styles.section_copy}>
        <h1>{props.title}</h1>
        <p>{props.paragraph}</p>
      </div>
    </section>
  );
});

Section.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Section;
