import Button from "button";
import styles from "./styles.module.scss";
import Card from "card";
import WorkspacePreview from "workspace_preview";
import useAudioContext from "../../../../hooks/use_audio_context";
import isPlayable from "activity_playable";

const SharingPreview = ({
  activityKey,
  workspace,
  avatarWorkspace,
  gallerySubmission,
  onSubmit,
  onBack,
}) => {
  const audioContext = useAudioContext();
  const [playing, setPlaying] = useState(false);

  const handlePlay = () => {
    if (audioContext.current.state === "suspended") {
      audioContext.current.resume().then(() => setPlaying(true));
    } else {
      setPlaying(true);
    }
  };

  const handleStop = () => {
    setPlaying(false);
  };

  return (
    <div className={styles.sharing_wrapper}>
      <h2 className={styles.heading}>Preview your project</h2>
      <p className={styles.paragraph}>
        This is how your project will appear in the gallery. If you’re happy
        with how it looks, submit your project to be reviewed by our team!
      </p>

      <Card
        className={styles.sharing_preview__card}
        key={1}
        title={gallerySubmission.name}
        isFeatured={false}
        subtitle={`by ${gallerySubmission.author}`}
        playing={playing}
        handlePlay={isPlayable(activityKey) ? handlePlay : null}
        handleStop={handleStop}
        imageSrc={
          <div className={styles.sharing_preview__card_image}>
            <WorkspacePreview
              activityKey={activityKey}
              previewImage={gallerySubmission.preview_image}
              avatarWorkspace={avatarWorkspace}
              workspace={workspace}
              playing={playing}
              audioContext={audioContext.current}
            />
          </div>
        }
        href={null}
      />

      <div className={styles.sharing_buttons}>
        <Button.RectanglePrimary
          outline={true}
          text={"Back"}
          type="button"
          onClick={onBack}
        />
        <Button.RectanglePrimary
          text={"Submit project"}
          type="button"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

SharingPreview.propTypes = {
  activityKey: PropTypes.string,
  available: PropTypes.object,
  avatarWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
  gallerySubmission: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }),
};

export default SharingPreview;
