import Analytics from "analytics";
import Layout from "layout";
import Heading from "heading";
import Button from "button";
import MyBadges from "my_badges";
import MyDataContext from "my_data_context";
import AppContext from "app_context";
import CsrfContext from "csrf_context";
import AvatarWithTshirt from "avatar_with_tshirt";
import Footer from "footer";
import styles from "./styles.module.scss";
import SaveIconPath from "assets/save_icon.svg";
import SEO from "seo";
import Link from "link";
import MyProjects from "./my_projects";
import MySharedProjects from "./my_shared_projects";

const ProfilePage = () => {
  const {
    user,
    issued_badges: issuedBadges,
    workspaces,
    my_gallery: myGallery,
  } = useContext(MyDataContext);
  const { badge_templates: badgeTemplates } = useContext(AppContext);
  const routes = useRoutes();
  const csrfToken = useContext(CsrfContext);
  const title = user.logged_in && user.name ? user.name : "Profile";

  const tabStates = {
    badges: "Badges",
    myProjects: "MyProjects",
    mySharedProjects: "MySharedProjects",
  };

  const tabUrlParams = new URLSearchParams(useLocation().search).get("tab");
  const tabState = tabUrlParams || tabStates.badges;

  useEffect(() => {
    const element = document.getElementsByClassName(styles.profile_page);
    if (element && tabUrlParams) {
      element[0].scrollIntoView();
    }
  }, [tabUrlParams]);

  return (
    <>
      <SEO
        title="Profile - Code Club World"
        description="View your profile to see what badges you have collected so far."
      />
      <Layout invertBackgroundColours={true}>
        <Heading
          title={<h1>{title}</h1>}
          className={styles.heading}
          backPath={false}
          invertBackgroundColours={true}
        >
          <div className={styles.right_heading}>
            {user.logged_in ? (
              <Button.RectangleTertiary
                href={routes.logoutPath()}
                text="Log out"
                outline={true}
              />
            ) : (
              <form
                action={routes.loginPath()}
                method="post"
                className={styles.sign_in}
              >
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={csrfToken}
                />
                <Button.RectangleTertiary
                  text="Log in"
                  outline={true}
                  onClick={() =>
                    Analytics.accountActivity("sign_in", "profile")
                  }
                ></Button.RectangleTertiary>
              </form>
            )}
          </div>
        </Heading>
        {!user.logged_in && (
          <div className={styles.save_your_progress_container}>
            <form
              action={routes.signupPath()}
              method="post"
              className={styles.save_your_progress_form}
            >
              <img src={SaveIconPath} alt="" />
              <h2>Save your progress</h2>
              <p>Make sure you never lose your work.</p>
              <input
                type="hidden"
                name="authenticity_token"
                value={csrfToken}
              />
              <Button.RectangleTertiary
                text="Create an account"
                outline={true}
                onClick={() => Analytics.accountActivity("sign_up", "profile")}
              ></Button.RectangleTertiary>
            </form>
          </div>
        )}
        <div className={styles.avatar_container}>
          <AvatarWithTshirt tshirtWorkspace={user.tshirt_workspace} />
          <Button.RectangleSecondary
            href={routes.workspacesPath({ activityKey: "avatar" })}
            text="Customise avatar"
          />
        </div>
        <div className={styles.profile_page}>
          <div className={styles.tab_section}>
            <header>
              <Link
                href={`${routes.profilePath()}?tab=${tabStates.badges}`}
                className={classNames(styles.tab_name, {
                  [styles.tab_active]: tabState == tabStates.badges,
                })}
              >
                My badges
              </Link>
              <Link
                href={`${routes.profilePath()}?tab=${tabStates.myProjects}`}
                className={classNames(styles.tab_name, {
                  [styles.tab_active]: tabState == tabStates.myProjects,
                })}
              >
                My Projects
              </Link>
              <Link
                href={`${routes.profilePath()}?tab=${
                  tabStates.mySharedProjects
                }`}
                className={classNames(styles.tab_name, {
                  [styles.tab_active]: tabState == tabStates.mySharedProjects,
                })}
              >
                My shared projects
              </Link>
            </header>

            {tabState == tabStates.badges && (
              <MyBadges
                issuedBadges={issuedBadges}
                badgeTemplates={badgeTemplates}
              />
            )}
            {tabState == tabStates.myProjects && (
              <>
                {!workspaces.length ? (
                  <div className={styles.empty_projects}>
                    <div className={styles.empty_projects_text}>
                      <p>
                        You haven’t made any projects! <br />
                        Visit Home island to start making and sharing projects.
                      </p>
                    </div>
                    <Button.RectangleTertiary
                      text="Go to Home Island"
                      href="/islands/home"
                    />
                  </div>
                ) : (
                  <MyProjects styles={styles} />
                )}
              </>
            )}
            {tabState == tabStates.mySharedProjects && (
              <>
                {!myGallery.length ? (
                  <div className={styles.empty_projects}>
                    <div className={styles.empty_projects_text}>
                      <p>
                        You haven’t shared any projects to the gallery! <br />
                      </p>
                    </div>
                    <Button.RectangleTertiary
                      text="Share some of your projects"
                      href={`${routes.profilePath()}?tab=${
                        tabStates.myProjects
                      }`}
                    />
                  </div>
                ) : (
                  <MySharedProjects styles={styles} />
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </Layout>
    </>
  );
};

export default ProfilePage;
