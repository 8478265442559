import styles from "./styles.module.scss";
import Button from "button";
import ExclamationIcon from "assets/exclamation_icon.svg";

const EditableName = ({ name, onSave, onDismiss }) => {
  const maxLength = 20;
  const [editedName, setName] = useState(name);
  const [validationError, setValidationError] = useState(null);
  const inputClassName = classNames(
    styles.editable_name_input,
    validationError ? styles.editable_name_input__invalid : null
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editedName.replace(/ /g, "").length == 0) {
      setValidationError("Please enter a name");
    } else if (editedName.length > maxLength) {
      setValidationError(
        `That name is a bit too long (more than ${maxLength} characters).`
      );
    } else {
      setValidationError(null);
      onSave(editedName);
    }
  };

  const onChangeText = (e) => {
    const nameValue = e.target.value;
    setName(nameValue);
    if (
      nameValue.replace(/ /g, "").length > 0 &&
      nameValue.length < maxLength
    ) {
      setValidationError(null);
    }
  };
  return (
    <div className={styles.edit_name_prompt}>
      <h2 className={styles.heading}>Edit your project name</h2>
      <form
        className={styles.edit_name_form}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <label htmlFor="name">Project title</label>
        <input
          name="name"
          type="text"
          className={inputClassName}
          value={editedName}
          maxLength={maxLength}
          onChange={onChangeText}
        />
        {validationError && (
          <div className={styles.validation_error}>
            <ExclamationIcon />
            {validationError}
          </div>
        )}
        <div className={styles.buttons}>
          <Button.RectanglePrimary
            text={"Cancel"}
            outline={true}
            onClick={onDismiss}
          />
          <Button.RectanglePrimary text={"Save name"} type="submit" />
        </div>
      </form>
    </div>
  );
};

EditableName.propTypes = {
  name: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default EditableName;
