import Link from "link";
import styles from "./styles.module.scss";

const ScratchUITutorialDropdown = ({ steps, onLinkClick, onHide }) => {
  const navRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (navRef.current && navRef.current.contains(e.target)) {
        return;
      }
      onHide();
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onHide]);

  return (
    <div className={styles.nav_menu_container} ref={navRef}>
      <nav className={styles.nav_menu}>
        <ol>
          {steps.map(({ title }, index) => {
            return (
              <li key={index}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    onLinkClick(index);
                  }}
                  className={styles.link}
                >
                  {title}
                </Link>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

ScratchUITutorialDropdown.propTypes = {
  steps: PropTypes.array.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ScratchUITutorialDropdown;
