const ReviewIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0171 16.9748H7.02578V14.9748H10.1799C10.0623 15.464 10 15.9747 10 16.5C10 16.6597 10.0058 16.818 10.0171 16.9748Z"
      fill="black"
    />
    <path
      d="M10.4674 18.9248H5.07578V5.0748H18.9258V10.4678C19.7922 10.8165 20.5662 11.3465 21.2008 12.0108V5.0748C21.2008 4.44147 20.9801 3.9038 20.5388 3.4618C20.0968 3.02047 19.5591 2.7998 18.9258 2.7998H5.07578C4.44245 2.7998 3.90511 3.02047 3.46378 3.4618C3.02178 3.9038 2.80078 4.44147 2.80078 5.0748V18.9248C2.80078 19.5581 3.02178 20.0958 3.46378 20.5378C3.90511 20.9791 4.44245 21.1998 5.07578 21.1998H12.0098C11.3457 20.5652 10.8159 19.7912 10.4674 18.9248Z"
      fill="black"
    />
    <path
      d="M13.0346 10.9998H7.02578V12.9998H11.0219C11.5375 12.1945 12.2259 11.5104 13.0346 10.9998Z"
      fill="black"
    />
    <path d="M16.9758 9.0248H7.02578V7.02481H16.9758V9.0248Z" fill="black" />
    <path
      d="M22.2008 23.7748L19.0508 20.6498C18.7008 20.8498 18.3301 21.0121 17.9388 21.1368C17.5468 21.2621 17.1258 21.3248 16.6758 21.3248C15.3924 21.3248 14.3008 20.8748 13.4008 19.9748C12.5008 19.0748 12.0508 17.9748 12.0508 16.6748C12.0508 15.3915 12.5008 14.2998 13.4008 13.3998C14.3008 12.4998 15.3924 12.0498 16.6758 12.0498C17.9758 12.0498 19.0758 12.4998 19.9758 13.3998C20.8758 14.2998 21.3258 15.3915 21.3258 16.6748C21.3258 17.1248 21.2634 17.5458 21.1388 17.9378C21.0134 18.3291 20.8508 18.6998 20.6508 19.0498L23.7758 22.1998L22.2008 23.7748ZM16.6758 19.0498C17.3424 19.0498 17.9051 18.8208 18.3638 18.3628C18.8218 17.9041 19.0508 17.3415 19.0508 16.6748C19.0508 16.0248 18.8218 15.4705 18.3638 15.0118C17.9051 14.5538 17.3424 14.3248 16.6758 14.3248C16.0258 14.3248 15.4718 14.5538 15.0138 15.0118C14.5551 15.4705 14.3258 16.0248 14.3258 16.6748C14.3258 17.3415 14.5551 17.9041 15.0138 18.3628C15.4718 18.8208 16.0258 19.0498 16.6758 19.0498Z"
      fill="black"
    />
  </svg>
);

ReviewIcon.propTypes = {
  className: PropTypes.string,
};

export default ReviewIcon;
