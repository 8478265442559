import MyDataContext from "my_data_context";
import BlocklyHelpers from "blockly_helpers";
import Interpreter from "interpreter";
import { AvatarBodyPartOptions } from "blocks/custom_blocks/avatar_blocks_configuration.js";

const useRobotStyles = (avatarWorkspace) => {
  const { user } = useContext(MyDataContext);
  const [robotStyles, setRobotStyles] = useState({});
  const chosenAvatarWorkspace = avatarWorkspace || user.avatar_workspace;

  useEffect(() => {
    if (!chosenAvatarWorkspace) return;
    const [, code] = BlocklyHelpers.generateCode(chosenAvatarWorkspace.xml);
    const blocksChannel = {
      onReady: () => {},
      generateCode: () => code,
    };
    const styles = {};
    const setStyleEntry = (key, value) => {
      styles[key] = value;
    };
    const visualisationChannel = {
      startRun: () => {},
      setBodyPartStyle: (bodyPart, styleIndex) => {
        setStyleEntry(`${bodyPart}Style`, styleIndex);
      },
      setColour: (bodyPart, colour) => {
        setStyleEntry(`${bodyPart}Colour`, colour);
      },
      setColourForAll: (colour) => {
        AvatarBodyPartOptions.map((option) => {
          setStyleEntry(`${option[1]}Colour`, colour);
        });
      },
    };

    const interpreter = Interpreter.create(blocksChannel, visualisationChannel);
    interpreter.run();
    setRobotStyles(styles);
  }, [user, chosenAvatarWorkspace]);

  return robotStyles;
};

export default useRobotStyles;
