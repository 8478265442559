const DanceIcon = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76049 9.72871C7.23105 10.9602 7.18099 12.2417 5.76931 12.7523C2.86584 13.8236 2.21507 11.0102 2.17502 10.8701L6.76049 9.72871ZM1.72448 8.91775L6.04964 7.84647C5.85942 6.79522 6.14976 5.74396 6.14976 4.50248C6.14976 2.82048 4.81817 -0.473452 2.69564 0.0571805C0.272752 0.657897 -0.0776655 3.35111 0.012442 4.65266C0.132585 5.95421 1.6544 8.73753 1.72448 8.91775ZM13.879 17.8684C13.8489 18.0186 13.1882 20.8219 10.2847 19.7607C8.88302 19.2401 8.82295 17.9585 9.29351 16.7271L13.879 17.8684ZM16.0315 11.661C16.1317 10.3595 15.7913 7.65624 13.3584 7.05553C11.2458 6.50487 9.91425 9.81882 9.91425 11.5108C9.91425 12.7423 10.1946 13.7936 10.0244 14.8448L14.3295 15.9161C14.4096 15.7359 15.9214 12.9526 16.0315 11.661Z"
      fill="black"
    />
  </svg>
);

export default DanceIcon;
