import Blockly from "blockly/core";

const BlocklyHelpers = {};

BlocklyHelpers.deprecateOldBlocks = (dom) => {
  const blocks = dom.getElementsByTagName("block");
  var deprecations = false;
  for (var block of blocks) {
    if (!Blockly.Blocks[block.getAttribute("type")]) {
      block.setAttribute("type", "Deprecated");
      deprecations = true;
    }
  }
  return deprecations;
};

BlocklyHelpers.generateCode = (workspaceXml) => {
  const dom = Blockly.Xml.textToDom(workspaceXml);
  const deprecated = BlocklyHelpers.deprecateOldBlocks(dom);
  let code;
  if (!deprecated) {
    const workspace = new Blockly.Workspace();
    Blockly.Xml.domToWorkspace(dom, workspace);
    code = Blockly.JavaScript.workspaceToCode(workspace);
  }
  return [deprecated, code];
};

BlocklyHelpers.appendTunesToStartMusicDropdown = (musicWorkspaces) => {
  musicWorkspaces.forEach((w) => {
    [Blockly.Blocks.StartMusic, Blockly.Blocks.CustomiseBoomboxSong].forEach(
      (b) => {
        const options = b.args0[0].options;
        if (!options.find((e) => e[1] === w.id)) options.push([w.title, w.id]);
      }
    );
  });
};

export default BlocklyHelpers;
