import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.SetPenColour = {
  message0: "set pen colour to %1",
  args0: [
    {
      type: "input_value",
      name: "colour",
      check: "Colour",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.SetPenColour.init = function () {
  this.jsonInit(Blockly.Blocks.SetPenColour);
};

Blockly.JavaScript.SetPenColour = (block) => {
  const colour = helpers.codeForArg("colour", block);

  if (typeof colour === "undefined") {
    return "";
  } else {
    return `visualisation.setPenColour("${colour}");`;
  }
};
