import LaunchActivity from "launch_activity";
import Blocks from "blocks";
import Block from "blocks/block";
import Category from "blocks/category";
import Colours from "blocks/colours";
import Layout from "layout";
import Overlay from "overlay";
import BadgeNotification from "badge_notification";
import IssuedBadge from "issued_badge";
import ConfirmationPrompt from "confirmation_prompt";
import Analytics from "analytics";
import SEO from "seo";
import useAutoSave from "use_auto_save";
import useSavedState from "saved_state_indicator/hooks/use_saved_state";
import useBadge from "use_badge";

const LaunchPage = () => {
  const [overlayState, setOverlayState] = useState();
  const [eligibleForBadge, setEligibleForBadge] = useState(false);
  const { badgeTemplate, issued: userHasAvatarMakerBadge } = useBadge(
    "ccw-avatar-maker"
  );

  const avatarChannel = {};
  const blocksChannel = useMemo(() => ({}), []);

  const [autoSaveRequested, handleAutoSaveEvent] = useAutoSave();
  const [handleSavedStateEvent, savedState] = useSavedState();

  avatarChannel.onStyled = () => {
    setEligibleForBadge(true);
  };

  let handleNext;

  if (!userHasAvatarMakerBadge) {
    handleNext = () => {
      setOverlayState("FinishedPrompt");
    };
  }

  const nextButtonEnabled = () => eligibleForBadge;

  const handleFinishedConfirmation = async () => {
    const created = await IssuedBadge.create(badgeTemplate);

    if (created) {
      Analytics.badgeIssued(badgeTemplate);
    }

    setOverlayState("BadgeNotification");
  };

  const overlay = () => {
    switch (overlayState) {
      case "FinishedPrompt":
        return (
          <ConfirmationPrompt
            question="Are you finished designing your avatar?"
            dismissText="Not yet"
            onDismiss={() => setOverlayState(null)}
            confirmText="Yes"
            onConfirm={handleFinishedConfirmation}
          />
        );
      case "BadgeNotification":
        return <BadgeNotification badgeTemplate={badgeTemplate} />;
      default:
        return null;
    }
  };

  return (
    <>
      <SEO
        title="Create your avatar - Code Club World"
        description="Use blocks to code your own robot avatar."
      />
      <Layout
        hideNavigationLinks={true}
        savedState={savedState}
        hideFeedbackBar
      >
        <Overlay>{overlay()}</Overlay>

        <LaunchActivity
          title="Design your avatar"
          activityKey="avatar"
          visualisationChannel={avatarChannel}
          nextEnabled={nextButtonEnabled()}
          onNext={handleNext}
          blocksChannel={blocksChannel}
          maxBlocksPerSecond={20}
          runOnPageLoad={true}
          autoSaveRequested={autoSaveRequested()}
          onAutoSaveEvent={handleAutoSaveEvent}
          onSavedStateEvent={handleSavedStateEvent}
          savedState={savedState}
        >
          <Blocks channel={blocksChannel} onAutoSaveEvent={handleAutoSaveEvent}>
            <Category
              name="Looks"
              colour={Colours.purple}
              css-icon="blocklyTreeIcon iconLooks"
            >
              <Block type="AvatarColour" defaults={[null, "#ffcc00"]} />
              <Block type="AvatarStyle" />
            </Category>
          </Blocks>
        </LaunchActivity>
      </Layout>
    </>
  );
};

LaunchPage.propTypes = {};

export default LaunchPage;
