const PlusIcon = () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M89.4443 45C89.4443 53.7903 86.8377 62.3832 81.9541 69.692C77.0705 77.0009 70.1292 82.6974 62.008 86.0613C53.8869 89.4252 44.9506 90.3054 36.3292 88.5905C27.7078 86.8756 19.7886 82.6427 13.5729 76.427C7.35725 70.2113 3.12433 62.2921 1.40943 53.6707C-0.305471 45.0493 0.574677 36.113 3.93857 27.9919C7.30247 19.8707 12.999 12.9294 20.3079 8.04581C27.6167 3.16219 36.2096 0.555573 44.9999 0.555573C56.7873 0.555573 68.0919 5.2381 76.4269 13.573C84.7618 21.908 89.4443 33.2126 89.4443 45ZM66.0832 40.8333H49.1666V23.9167C49.1666 22.8116 48.7276 21.7518 47.9462 20.9704C47.1648 20.189 46.105 19.75 44.9999 19.75C43.8948 19.75 42.835 20.189 42.0536 20.9704C41.2722 21.7518 40.8332 22.8116 40.8332 23.9167V40.8333H23.9166C22.8115 40.8333 21.7517 41.2723 20.9703 42.0537C20.1889 42.8351 19.7499 43.8949 19.7499 45C19.7499 46.1051 20.1889 47.1649 20.9703 47.9463C21.7517 48.7277 22.8115 49.1667 23.9166 49.1667H40.8332V66.0834C40.8332 67.1884 41.2722 68.2482 42.0536 69.0296C42.835 69.811 43.8948 70.25 44.9999 70.25C46.105 70.25 47.1648 69.811 47.9462 69.0296C48.7276 68.2482 49.1666 67.1884 49.1666 66.0834V49.1667H66.0832C67.1883 49.1667 68.2481 48.7277 69.0295 47.9463C69.8109 47.1649 70.2499 46.1051 70.2499 45C70.2499 43.8949 69.8109 42.8351 69.0295 42.0537C68.2481 41.2723 67.1883 40.8333 66.0832 40.8333Z" />
  </svg>
);

export default PlusIcon;
