import styles from "./styles.module.scss";

const Overlay = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.overlay_content}>{children}</div>
    </div>
  );
};

Overlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Overlay;
