import RobotOnStage from "../../../visualisations/dance/robot_on_stage";
import availablePoses from "../../../visualisations/dance/poses";
import palette from "root/js_palette";
import GallerySelection from "./gallery_selection";

import styles from "./styles.module.scss";

export const AvatarPoses = ({ avatarWorkspace, pose, handleChange }) => {
  const spotlights = {
    left: {
      colour: palette.tone1.yellow,
      opacity: 1,
    },
    right: {
      colour: palette.tone1.red,
      opacity: 1,
    },
  };

  const chooserPoses = [
    "arms out",
    "arms up",
    "arms down",
    "left arm up",
    "right arm up",
    "heel click right",
    "heel click left",
    "high splits",
    "low splits",
  ];

  const poses = Object.keys(availablePoses).reduce((acc, pose) => {
    if (chooserPoses.indexOf(availablePoses[pose].name) !== -1) {
      acc.push(availablePoses[pose]);
    }
    return acc;
  }, []);

  const RobotOnStageItem = (p) => (
    <RobotOnStage
      avatarWorkspace={avatarWorkspace}
      currentPose={p}
      spotlights={spotlights}
      gallery={true}
    />
  );

  return (
    <GallerySelection
      images={poses}
      selection={pose}
      handleChange={handleChange}
      className={styles.sharing_choosing_image_robot}
      nameAttribute="name"
    >
      {RobotOnStageItem}
    </GallerySelection>
  );
};

AvatarPoses.propTypes = {
  avatarWorkspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    xml: PropTypes.string.isRequired,
    seed: PropTypes.string,
  }).isRequired,
  pose: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AvatarPoses;
