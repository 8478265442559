import Blockly from "blockly";

Blockly.Blocks.MapOpenedRun = {
  message0: `when map opened`,
  nextStatement: "Statement",
  style: "rpf_start_blocks",
};

Blockly.Blocks.MapOpenedRun.init = function () {
  this.jsonInit(Blockly.Blocks.MapOpenedRun);
};

Blockly.JavaScript.MapOpenedRun = function () {
  return "";
};
