import styles from "./styles.module.scss";
import RemixIcon from "assets/remix_icon.svg";

const Label = ({ remixCount }) => {
  if (remixCount) {
    return (
      <div className={styles.label}>
        <RemixIcon pathClassName={styles.label_remix_icon} />
        {`${remixCount} remix${remixCount > 1 ? "es" : ""}`}
      </div>
    );
  }
  return null;
};

Label.propTypes = {
  remixCount: PropTypes.number,
};

export default Label;
