import Filters from "./filters";
import Line from "./line";
import seedrandom from "seedrandom";

const Canvas = ({
  canvasId,
  canvasDimensions,
  backgroundColour,
  drawCommands,
  seed,
  title,
}) => {
  const canvasBounds = [
    canvasDimensions.width / -2.0,
    canvasDimensions.height / -2.0,
    canvasDimensions.width,
    canvasDimensions.height,
  ];

  const drawing = (drawCommands, canvasId) => {
    const seedrandomfunc = seedrandom(seed);
    return drawCommands.map((command, i) => {
      const filter =
        command.style === "pen" ? null : `url('#${canvasId}-${command.style}')`;

      switch (command.command) {
        case "line":
          return (
            <Line
              key={i}
              x1={command.fromX}
              y1={command.fromY}
              x2={command.toX}
              y2={command.toY}
              stroke={command.colour}
              dash={command.dash}
              width={command.width}
              filter={filter}
              shape={command.shape}
              scatter={command.scatter}
              rng={seedrandomfunc}
              canvasBounds={canvasBounds}
            />
          );
        case "stamp":
          return command.component({
            key: i,
            x: command.x,
            y: command.y,
            size: command.size || 100,
            colour: command.colour,
          });
      }
    });
  };

  return (
    <svg
      viewBox={canvasBounds.join(" ")}
      style={{ backgroundColor: backgroundColour }}
      title={title}
    >
      <defs>
        <Filters canvasId={canvasId} canvasBounds={canvasBounds} />
      </defs>
      {drawing(drawCommands, canvasId)}
    </svg>
  );
};

Canvas.propTypes = {
  canvasId: PropTypes.string.isRequired,
  canvasDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  backgroundColour: PropTypes.string.isRequired,
  drawCommands: PropTypes.arrayOf(PropTypes.object).isRequired,
  seed: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Canvas;
