import SavedState from "../saved_state";
import SavedStateEvent from "../saved_state_event";

const useSavedState = () => {
  const minimumSavingTimeInMilliseconds = 1000;
  const justSavedTimeInMilliseconds = 1000;

  const [savedState, _setSavedState] = useState(SavedState.NOT_SAVING);
  const savedStateRef = useRef(savedState);
  const setSavedState = (newState) => {
    savedStateRef.current = newState;
    _setSavedState(newState);
  };

  const savingTimer = useRef();
  useEffect(() => () => clearTimeout(savingTimer.current), []);

  const justSavedTimer = useRef();
  useEffect(() => () => clearTimeout(justSavedTimer.current), []);

  const transitionToSavingState = () => {
    setSavedState(SavedState.SAVING);
    savingTimer.current = setTimeout(
      () => handleSavedStateEvent(SavedStateEvent.SAVING_TIMER_EXPIRED),
      minimumSavingTimeInMilliseconds
    );
  };

  const transitionToJustSavedState = () => {
    setSavedState(SavedState.JUST_SAVED);
    justSavedTimer.current = setTimeout(
      () => handleSavedStateEvent(SavedStateEvent.JUST_SAVED_TIMER_EXPIRED),
      justSavedTimeInMilliseconds
    );
  };

  const transitionToErrorState = () => {
    setSavedState(SavedState.ERROR);
  };

  const handleSavedStateEvent = (event) => {
    const currentState = savedStateRef.current;
    switch (event) {
      case SavedStateEvent.SAVING_STARTED:
        switch (currentState) {
          case SavedState.NOT_SAVING:
            transitionToSavingState();
            break;
          case SavedState.SAVING:
            break;
          case SavedState.WAITING_FOR_SAVING_FINISHED:
            transitionToSavingState();
            break;
          case SavedState.WAITING_FOR_SAVING_TIMER:
            clearTimeout(savingTimer.current);
            transitionToSavingState();
            break;
          case SavedState.JUST_SAVED:
            clearTimeout(justSavedTimer.current);
            transitionToSavingState();
            break;
          case SavedState.ERROR:
            break;
          default:
            throw new Error(`Unrecognized state: ${currentState}`);
        }
        break;
      case SavedStateEvent.SAVING_TIMER_EXPIRED:
        switch (currentState) {
          case SavedState.NOT_SAVING:
            break;
          case SavedState.SAVING:
            setSavedState(SavedState.WAITING_FOR_SAVING_FINISHED);
            break;
          case SavedState.WAITING_FOR_SAVING_FINISHED:
            break;
          case SavedState.WAITING_FOR_SAVING_TIMER:
            transitionToJustSavedState();
            break;
          case SavedState.JUST_SAVED:
            break;
          case SavedState.ERROR:
            break;
          default:
            throw new Error(`Unrecognized state: ${currentState}`);
        }
        break;
      case SavedStateEvent.SAVING_FINISHED:
        switch (currentState) {
          case SavedState.NOT_SAVING:
            break;
          case SavedState.SAVING:
            setSavedState(SavedState.WAITING_FOR_SAVING_TIMER);
            break;
          case SavedState.WAITING_FOR_SAVING_FINISHED:
            transitionToJustSavedState();
            break;
          case SavedState.WAITING_FOR_SAVING_TIMER:
            break;
          case SavedState.JUST_SAVED:
            break;
          case SavedState.ERROR:
            break;
          default:
            throw new Error(`Unrecognized state: ${currentState}`);
        }
        break;
      case SavedStateEvent.JUST_SAVED_TIMER_EXPIRED:
        switch (currentState) {
          case SavedState.NOT_SAVING:
            break;
          case SavedState.SAVING:
            break;
          case SavedState.WAITING_FOR_SAVING_FINISHED:
            break;
          case SavedState.WAITING_FOR_SAVING_TIMER:
            break;
          case SavedState.JUST_SAVED:
            setSavedState(SavedState.NOT_SAVING);
            break;
          case SavedState.ERROR:
            break;
          default:
            throw new Error(`Unrecognized state: ${currentState}`);
        }
        break;
      case SavedStateEvent.SAVING_ERROR:
        switch (currentState) {
          case SavedState.NOT_SAVING:
            transitionToErrorState();
            break;
          case SavedState.SAVING:
            transitionToErrorState();
            break;
          case SavedState.WAITING_FOR_SAVING_FINISHED:
            transitionToErrorState();
            break;
          case SavedState.WAITING_FOR_SAVING_TIMER:
            clearTimeout(savingTimer.current);
            transitionToErrorState();
            break;
          case SavedState.JUST_SAVED:
            clearTimeout(justSavedTimer.current);
            transitionToErrorState();
            break;
          case SavedState.ERROR:
            break;
          default:
            throw new Error(`Unrecognized state: ${currentState}`);
        }
        break;
    }
  };
  return [handleSavedStateEvent, savedState];
};

export default useSavedState;
