import MapBackground from "map_background";
import { renderToStaticMarkup } from "react-dom/server";

const MapBackgroundAsDataUri = (backgroundColour, foregroundColour) => {
  const svgString = encodeURIComponent(
    renderToStaticMarkup(
      <MapBackground
        background={backgroundColour ? backgroundColour : undefined}
        accent={foregroundColour ? foregroundColour : undefined}
      />
    )
  );

  return `url("data:image/svg+xml,${svgString}")`;
};

export default MapBackgroundAsDataUri;
