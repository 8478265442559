import Blockly from "blockly";
import helpers from "blocks/helpers";

Blockly.Blocks.MoveNSteps = {
  message0: "move %1 steps",
  args0: [
    {
      type: "input_value",
      name: "steps",
      check: "Number",
    },
  ],
  previousStatement: "Statement",
  nextStatement: "Statement",
};

Blockly.Blocks.MoveNSteps.init = function () {
  this.jsonInit(Blockly.Blocks.MoveNSteps);
};

Blockly.JavaScript.MoveNSteps = function (block) {
  const steps = helpers.codeForArg("steps", block);

  if (typeof steps === "undefined") {
    return "";
  } else {
    return `visualisation.moveNSteps(${steps});`;
  }
};
