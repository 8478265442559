import styles from "./styles.module.scss";

const MyBadges = ({ issuedBadges, badgeTemplates }) => {
  const awarded = (badge) =>
    issuedBadges.some((a) => a.badge_template.id === badge.id);

  return (
    <ol>
      {badgeTemplates.map((badgeTemplate) => (
        <BadgeItem
          badgeTemplate={badgeTemplate}
          awarded={awarded(badgeTemplate)}
          key={badgeTemplate.id}
        />
      ))}
    </ol>
  );
};

MyBadges.propTypes = {
  issuedBadges: PropTypes.arrayOf(PropTypes.object),
  badgeTemplates: PropTypes.arrayOf(PropTypes.object),
};

const BadgeItem = ({ badgeTemplate, awarded }) => {
  return (
    <li className={classNames(styles.badge, { [styles.disabled]: !awarded })}>
      <img src={badgeTemplate.image} alt={""} />
      <div className={styles.badge_info}>
        <p>{badgeTemplate.title}</p>
        <p>{badgeTemplate.description || "\u00A0"}</p>
      </div>
    </li>
  );
};

BadgeItem.propTypes = {
  badgeTemplate: PropTypes.object,
  awarded: PropTypes.bool,
};

export default MyBadges;
