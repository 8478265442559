import styles from "./styles.module.scss";

const Button = () => {
  throw new Error(
    "Please use either Button.Primary or Button.Secondary instead of Button."
  );
};

Button.RectanglePrimary = ({ className, ...rest }) => (
  <Button_ className={classNames(className, styles.primary)} {...rest} />
);

Button.RectanglePrimary.displayName = "Button.RectanglePrimary";

Button.RectanglePrimary.propTypes = {
  className: PropTypes.string,
};

Button.RectangleSecondary = ({ className, ...rest }) => (
  <Button_ className={classNames(className, styles.secondary)} {...rest} />
);

Button.RectangleSecondary.displayName = "Button.RectangleSecondary";

Button.RectangleSecondary.propTypes = {
  className: PropTypes.string,
};

Button.RectangleTertiary = ({ className, ...rest }) => (
  <Button_ className={classNames(className, styles.tertiary)} {...rest} />
);

Button.RectangleTertiary.displayName = "Button.RectangleTertiary";

Button.RectangleTertiary.propTypes = {
  className: PropTypes.string,
};

Button.ControlPrimary = ({ className, ...rest }) => (
  <Button_
    className={classNames(className, styles.control, styles.primary_control)}
    {...rest}
  />
);

Button.ControlPrimary.displayName = "Button.ControlPrimary";

Button.ControlPrimary.propTypes = {
  className: PropTypes.string,
};

Button.ControlSecondary = ({ className, ...rest }) => (
  <Button_
    className={classNames(className, styles.control, styles.secondary_control)}
    {...rest}
  />
);

Button.ControlSecondary.displayName = "Button.ControlSecondary";

Button.ControlSecondary.propTypes = {
  className: PropTypes.string,
};

Button.BlocklyControl = ({ className, ...rest }) => (
  <Button_
    className={classNames(className, styles.control, styles.blockly_control)}
    {...rest}
  />
);

Button.BlocklyControl.displayName = "Button.BlocklyControl";

Button.BlocklyControl.propTypes = {
  className: PropTypes.string,
};

const Button_ = ({
  className,
  enabled = true,
  active,
  icon,
  onClick = () => {},
  href,
  text,
  "aria-label": ariaLabel = "",
  outline = false,
  textOnHover = false,
  textAlways = false,
  transparent = false,
  transparent_border = false,
}) => {
  const classes = classNames(styles.button, className, {
    [styles.outline]: outline,
    [styles.active]: active,
    [styles.disabled]: !enabled,
    [styles.with_icon]: icon != undefined && !textAlways,
    [styles.text_on_hover]: textOnHover,
    [styles.transparent]: transparent,
    [styles.transparent_border]: transparent_border,
  });

  const iconClasses = classNames(styles.icon, {
    [styles.icon_with_text]: !textOnHover,
    [styles.icon_without_text]: textOnHover,
    [styles.icon_only]: !textOnHover && text === undefined,
  });

  const content = (
    <div className={styles.content}>
      {icon && <div className={iconClasses}>{icon}</div>}
      <span className={styles.text}>{text}</span>
    </div>
  );

  if (href) {
    return (
      <a
        className={classes}
        aria-label={ariaLabel || text}
        href={href}
        onClick={onClick}
      >
        {content}
      </a>
    );
  } else {
    return (
      <button
        className={classes}
        aria-label={ariaLabel || text}
        onClick={onClick}
        disabled={!enabled}
      >
        {content}
      </button>
    );
  }
};

Button_.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.element,
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  outline: PropTypes.bool,
  textAlways: PropTypes.bool,
  "aria-label": PropTypes.string,
  textOnHover: PropTypes.bool,
  transparent: PropTypes.bool,
  transparent_border: PropTypes.bool,
};

export default Button;
