import BlocklyHelpers from "blockly_helpers";
import Interpreter from "interpreter";
import MusicImagePath from "assets/activities/music.svg";
import AudioEngine from "visualisations/music/audio_engine";
import wireUpMusicApi from "wire_up_music_api";

const FALLBACK_CODE = "visualisation.startRun();visualisation.playNote(0, 0);";

const MusicPreview = ({
  musicWorkspace,
  playing = false,
  audioContext,
  onFinished,
  previewImage = MusicImagePath,
}) => {
  const audioEngine = useRef(new AudioEngine(audioContext));

  const visualisationChannel = useMemo(() => {
    return {
      onReady: () => {},
    };
  }, []);

  let playback;
  const setPlayback = (value) => {
    playback = typeof value === "function" ? value(playback) : value;
  };

  wireUpMusicApi({
    channel: visualisationChannel,
    playback,
    setPlayback,
    audioEngine: audioEngine.current,
    onFinished,
  });

  const blocksChannel = useMemo(() => {
    const [deprecated, code] = BlocklyHelpers.generateCode(musicWorkspace.xml);
    return {
      onReady: () => {},
      generateCode: () => (deprecated ? FALLBACK_CODE : code),
    };
  }, [musicWorkspace.xml]);

  useEffect(() => {
    if (playing) {
      const interpreter = Interpreter.create(
        blocksChannel,
        visualisationChannel
      );
      visualisationChannel.reset();
      interpreter.run();
    } else {
      visualisationChannel.clear();
    }
  }, [playing, blocksChannel, visualisationChannel]);

  return <img src={previewImage} alt="" />;
};

MusicPreview.propTypes = {
  musicWorkspace: PropTypes.shape({
    xml: PropTypes.string.isRequired,
  }),
  audioContext: PropTypes.object,
  playing: PropTypes.bool,
  onFinished: PropTypes.func,
  previewImage: PropTypes.string,
};

export default MusicPreview;
